import React from 'react';
import { MyContext } from "../context";
import { Header, SubHeader, ComingSoon, Images } from '../components';
import MetaData from "../helper/MetaData";
import { Helmet } from "react-helmet";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from 'lodash';

export default class Compare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            player_a: null,
            player_b: null,
            player_a_search: "",
            player_b_search: "",
            player_a_suggestions: [],
            player_b_suggestions: [],
            player_a_points_data: [],
            player_b_points_data: [],
            selectedCategory: "",
            selectedRound: "",
            selectedLeague: "",
            selectedContest: "",
            selectedMonth: "",
            selectedFormat: "",
            player_a_search_loading:false,
            player_b_search_loading:false
        }

        this.UserSearchHandle = _.debounce(this.searchHandler.bind(this), 1000);
    }


    setParams = () => {
        if (this.props.location.state) {

            let data = this.props.location.state;
            this.setState({
                selectedLeague: data.selectedLeague,
                selectedRound: data.selectedRound,
                selectedContest: data.selectedContest,
                selectedMonth: data.selectedMonth,
                selectedFormat: data.selectedFormat,
                selectedCategory: data.selectedCategory,
                player_a: data.player
            }, () => {
                if (this.state.player_a) {
                    this.getUserScores('player_a')
                } else {
                    this.getUserList('player_a')
                }

                this.getUserList('player_b')
            })

        } else {
            window.location.href = "/stats-awards"
        }
    }

    componentWillMount() {
        this.setParams()
    }



    searchHandler(player) {
        this.getUserList(player)
    }


    handleChange = (e, key) => {
        let value = e.target.value;
        this.setState({ [key]: value });
    }

    searchPlayerArea = (key) => {

        let player_suggestions = this.state[key + "_suggestions"];

        let search_loading = this.state[key + "_search_loading"];


        return (
            <div className={'search-container'}>
                <div className={'search-box'}>
                    <label>Search Player</label>
                    <div className={'srch-grp'}>
                        <input
                            type={'text'}
                            name={'player_search'}
                            value={this.state[key + '_search'] || ""}
                            onChange={(e) => this.handleChange(e, key + '_search')}
                            onKeyUp={(e) => this.UserSearchHandle(key)}
                            disabled={search_loading}

                        />
                        <i className={'icon-search search-ic'}></i>
                    </div>
                </div>
                

                <div className={'suggestion-box'}>
                    {
                        search_loading && <div className={'text-center'}>Loading...</div>
                    }
                    {
                        player_suggestions.length > 0  && !search_loading?

                            player_suggestions.map((itm, i) => {
                                return <div className={'suggested-pl'} key={i} onClick={() => this.selectPlayer(itm, key)}>
                                    <div className={'prof-pc'}>
                                        <img src={itm.image || Images.DEFAULT_USER} />
                                    </div>
                                    <div className={'name'}>{itm.display_name}</div>
                                    <div className={'t_p'}>{itm.score}</div>
                                </div>
                            }) :

                            !search_loading?
                            <div className={'text-center'}>No Record</div>:
                            ''
                    }
                </div>
            </div>
        )
    }




    getUserList = (key) => {

        const { selectedLeague, selectedMonth, selectedRound, selectedContest, selectedCategory, } = this.state;


        let param = {
            search_key: this.state[key + '_search'],
            contest_type_id: (selectedLeague && selectedLeague != "") ? selectedLeague.value : "",
            month: (selectedMonth && selectedMonth != "") ? selectedMonth.value : "",
            round_id: (selectedRound && selectedRound != "") ? selectedRound.value : "",
            contest_id: (selectedContest && selectedContest != "") ? selectedContest.value : "",
        }

        if (key == 'player_a' && this.state.player_b) {
            param.exclude_user = this.state.player_b.user_id;
        }

        if (key == 'player_b' && this.state.player_a) {
            param.exclude_user = this.state.player_a.user_id;
        }


        if (selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
            param['type'] = selectedCategory.id;
            if (selectedCategory.id == '2') {
                param['country_id'] = selectedCategory.value;
            }
            if (selectedCategory.id == '3') {
                param['team_id'] = selectedCategory.value;
            }
        }

        this.setState({ [key + '_search_loading']: true, [key +"_suggestions"]:[] })
        WSManager.Rest(NC.baseURL + NC.GET_USERS_LIST_FOR_COMPARE, param)
            .then(responseJson => {
                if (responseJson.response_code === NC.successCode) {
                    this.setState({
                        [key + '_suggestions']: responseJson.data,
                        [key + '_search_loading']: false
                    })
                } else {
                    this.setState({ [key + '_search_loading']: false })
                }
            })


    }


    selectPlayer = (itm, key) => {
        this.setState({
            [key]: {...itm, total_score:itm.score},
            [key + '_search']: "",
            [key + '_suggestions']: [],
            [key + '_points_data']: [],
        }, () => {
            this.getUserScores(key)

            let otherPlayer = key == 'player_a' ? 'player_b' : 'player_a';
            if (!this.state[otherPlayer]) {
                this.getUserList(otherPlayer)
            }
        }
        )
    }

    removePlayer = (key) => {
        this.setState({
            [key]: null,
            [key + '_search']: "",
            [key + '_suggestions']: [],
            [key + '_points_data']: [],
        }, () => {
            this.getUserList('player_a')
            this.getUserList('player_b')
        })
    }


    PlayerBox = (key) => {

        let player = this.state[key];
        let player_points_data = this.state[key + '_points_data'];

        return (
            <div className={'c-player-box'}>
                <i className={'icon icon-close'} onClick={() => this.removePlayer(key)}></i>
                <div className={'head-p'}>
                    <div className={'prof-pc'}>
                        <img src={player.image || Images.DEFAULT_USER} />
                    </div>
                    <div className={'nm'}>#{player.display_name}</div>
                    <div className={'pts'}>Points <span>{player.total_score || '--'}</span></div>
                </div>
                <div className={'c-pts-tble'}>
                    <table>
                        <thead>
                            <tr>
                                <th>Parameters</th>
                                <th>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                player_points_data.length > 0 ?

                                    player_points_data.map((itm, i) => {
                                        return <tr key={i}>
                                            <td><span className={'ques-td'}>{itm.question_name}</span></td>
                                            <td><span className={'pts-td'}>{itm.score}</span></td>
                                        </tr>
                                    }) :
                                    <tr><td colspan={2} className={'text-center'}>Loading...</td></tr>
                            }


                        </tbody>
                    </table>
                </div>
            </div>
        )
    }


    getUserScores = (key) => {
        if (!this.state[key]) return;

        const { selectedLeague, selectedMonth, selectedRound, selectedContest, selectedCategory } = this.state;

        let param = {
            user_id: this.state[key].user_id,
            contest_type_id: (selectedLeague && selectedLeague != "") ? selectedLeague.value : "",
            month: (selectedMonth && selectedMonth != "") ? selectedMonth.value : "",
            round_id: (selectedRound && selectedRound != "") ? selectedRound.value : "",
            contest_id: (selectedContest && selectedContest != "") ? selectedContest.value : "",
        }

        if (selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
            param['type'] = selectedCategory.id;
            if (selectedCategory.id == '2') {
                param['country_id'] = selectedCategory.value;
            }
            if (selectedCategory.id == '3') {
                param['team_id'] = selectedCategory.value;
            }
        }

        this.setState({ points_loading: true })
        WSManager.Rest(NC.baseURL + NC.GET_USER_SCORES, param)
            .then(responseJson => {
                if (responseJson.response_code === NC.successCode) {
                    this.setState({
                        [key + '_points_data']: responseJson.data.list,
                        [key]:{...this.state[key], total_score:responseJson.data.total_score},
                        points_loading: false
                    })
                } else {
                    this.setState({ points_loading: false })
                }
            })

    }


    render() {


        const HeaderOption = {
            menu: false,
            Notification: false,
            Logo: true,
            back: true
        }

        const { player_a, player_b } = this.state;

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container compare-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.compare.title}</title>
                            <meta name="description" content={MetaData.compare.description} />
                            <meta name="keywords" content={MetaData.compare.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} showNavigation={false} />


                        <SubHeader Title="Compare" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="" />

                        <div className={'compare-container'}>
                            <div className={'com-head'}>
                                <div className={'player player-a'}>Player A</div>
                                <div className={'player player-b'}>Player B</div>
                            </div>

                            <div className={'com-body'}>
                                <div className={'com-col'}>
                                    {player_a ? this.PlayerBox('player_a') : this.searchPlayerArea('player_a')}
                                </div>
                                <div className={'com-col'}>
                                    {player_b ? this.PlayerBox('player_b') : this.searchPlayerArea('player_b')}
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}
