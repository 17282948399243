import React from "react";
import { Row, Col, PanelGroup, Panel } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Images, Header, Loader, NoDataScreen, SubHeader } from "../components";
import MetaData from "../helper/MetaData";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import Slider from "react-slick";

export default class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ResultList: "",
      RoundResultKey: "",
      isLoading: false,
      pageBanner: []
    };
  }

  gotoDashboard() {
    this.props.history.push({
      pathname: "/dashboard/0"
    });
  }

  componentWillMount() {
    this.setState({ isLoading: true });
    let param = {
      sports_id: NC.sportsId
    };

    WSManager.Rest(NC.baseURL + NC.GET_ROUND_RESULT_LIST, param).then(
      responseJson => {
        this.setState({ isLoading: false });
        if (responseJson.response_code === NC.successCode) {
          this.setState({
            RoundResultKey: responseJson.data.round_keys,
            ResultList: responseJson.data.round_list
          });
        }
      }
    );

    this.getPageBanner()
  }

  getPageBanner = () => {

    let param = {
      page_type_id: Constants.BANNER_PAGE_TYPE_ID.myResult,
      position_id: "1"
    }
    WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }

  onDetailClick = item => {
    this.props.history.push({
      pathname: "/answer/" + item.fixture_unique_id,
      state: { ContestData: item }
    });
  };

  render() {
    const { RoundResultKey, ResultList, isLoading, pageBanner } = this.state;

    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container ">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.Result.title}</title>
              <meta name="description" content={MetaData.Result.description} />
              <meta name="keywords" content={MetaData.Result.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            {isLoading && <Loader />}




            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Results" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />


                  {
                    (pageBanner && pageBanner.length > 0) &&
                    <div className={'lb-ban-wrap'}>
                      <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                        {pageBanner.map((itm, i) => {
                          return <div key={i} className={'lb-banner'}>
                            <img src={itm.image} />
                          </div>
                        })}
                      </Slider>
                    </div>
                  }

                  {/* Result Section start */}
                  <div className="result-section">
                    <PanelGroup
                      onSelect={value =>
                        Constants.SetValues.resultOpenIDX(value)
                      }
                      defaultActiveKey={Constants.resultOpenIDX}
                      accordion
                      id="accordion-uncontrolled-example"
                    >
                      {RoundResultKey.length > 0 &&
                        RoundResultKey.map((item, idx) => {
                          return (
                            <Panel key={idx} eventKey={idx + 1}>
                              <Panel.Heading>
                                <Panel.Title toggle>
                                  {ResultList[item][0].round_name}
                                  <i className="icon-plus" />
                                  <i className="icon-remove" />
                                </Panel.Title>
                              </Panel.Heading>
                              <Panel.Body collapsible>
                                <ul>
                                  {ResultList[item].map((round_item, idxx) => {
                                    return (
                                      <li
                                        key={idxx}
                                        onClick={() =>
                                          this.onDetailClick(round_item)
                                        }
                                      >
                                        <div className="display-table">
                                          <div className="display-table-cell">
                                            {round_item.match_data.home}
                                            <span> v </span>
                                            {round_item.match_data.away}
                                          </div>
                                          <div className="display-table-cell your-score">
                                            You Scored
                                            <p>
                                              {round_item.total_score}/
                                              {round_item.total_contest_point}
                                            </p>
                                            <i className="icon-arrow-right" />
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Panel.Body>
                            </Panel>
                          );
                        })}
                      {RoundResultKey.length == 0 && !isLoading && (
                        <NoDataScreen
                          Content={{
                            ImgShown: Images.no_data_bg_image,
                            IsButton: true,
                            ButtonText: "Go to Lobby",
                            Title: "OOPS! NO Results YET.",
                            NoDataContent: "Please Join Fixtures.",
                            BtnAction: () => {
                              this.props.history.push({
                                pathname: "/dashboard/0"
                              });
                            },
                            WithFilter: ""
                          }}
                        />
                      )}
                    </PanelGroup>
                  </div>
                  {/* Result Section end */}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
