import React from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { MyContext } from "../context";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import WSManager from "../helper/WSManager";

export default class MembershipModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      freemiumData: "",
      premiumData: "",
      premiumPlusData: ""
    };
  }
  componentWillMount() {
    WSManager.Rest(NC.baseURL + NC.GET_MEMBERSHIP_MASTER_DATA, {}).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          _.map(responseJson.data, (item, idx) => {
            if (item.master_plan_id == 1) {
              this.setState({ freemiumData: item });
            }
            if (item.master_plan_id == 2) {
              this.setState({ premiumData: item });
            }
            if (item.master_plan_id == 3) {
              this.setState({ premiumPlusData: item });
            }
          });
        }
      }
    );
  }

  render() {
    const {
      IsMembershipModalShow,
      IsMembershipModalHide,
      countineAction,
      countineData,
      countineType,
      contestEntryFee
    } = this.props;
    const { premiumData, premiumPlusData } = this.state;


    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsMembershipModalShow}
              onHide={IsMembershipModalHide}
              bsSize="small"
              className="center-modal"
              dialogClassName="membership-modal "
            >
              <Modal.Header>
                <Modal.Title>
                  {/* Select Membership Plan & Enjoy Premium Benefits */}
                  Select membership & enjoy associated benefits & privileges.
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={6} className="premium-block">
                    <div className="plan-heading">Premium</div>
                    <div className="plan-block">
                      <div className="block-header">

                        {
                          premiumData &&  premiumData.sub_plan.length > 0 ?
                            <div className={'sub-pls-mp'}>
                              {
                                premiumData.sub_plan.map((itm, i) => {
                                  return <span key={i}>
                                    <span className={'vl'}> <i className="icon-Rits" /> {Number(itm.amount) - (Number(itm.amount) * Number(itm.discount_percent) / 100)} </span>
                                    <span className={'lbl'}>{itm.plan_name}</span>
                                  </span>
                                })
                              }

                            </div> :
                            <React.Fragment>
                              <div className="amt">
                                <span>
                                  <i className="icon-Rits" />
                                  {premiumData.amount || 0}
                                </span>
                              </div>
                              <div className="plan-duration">Per Round</div>
                            </React.Fragment>
                        }


                      </div>
                      <div className="block-body">
                        {/* <p>Free Sign Up</p>
                        <p>Cash Prizes</p>
                        <p>ReFSocial Features</p>
                        <p>Eligible to Join paid contests</p>
                        <p>League Badges & Awards </p> */}
                        <p>Freemium benefits and more</p>
                        <p>Skip video ads after 7sec</p>
                        <p>Eligible to win cash prizes</p>
                        <p>Higher priority in ranking tie</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="premium-plus-block">
                    <div className="plan-heading">Premium +</div>
                    <div className="plan-block">
                      <div className="block-header">
                        <div className="amt">
                          <span>
                            <i className="icon-Rits" />
                            {premiumPlusData.amount || 0}
                          </span>
                        </div>
                        <div className="plan-duration">Per Season</div>
                      </div>
                      <div className="block-body">
                        {/* <p>Premium features</p>
                        <p>Premium Badges</p>
                        <p>Create private contest</p> */}
                        <p>Premium privileges & more</p>
                        <p>Premium+ badge</p>
                        <p>Skip video ad anytime</p>
                        <p>Earn from watching Ads</p>
                        <p>Highest priority in ranking tie</p>
                        <p>Create Private & Public contest</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="text-center buy-plan-btn">
                    <Button
                      onClick={() =>
                        this.props.history.push({ pathname: "/membership-plan", state: { countineType: countineType } })
                      }
                      className="btn btn-rounded btn-primary"
                    >
                      SELECT PREMIUM MEMBERSHIP
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="text-center green-text">
                    {countineType == "contest" && contestEntryFee > 0 ? (
                      "You're a Freemium member & Ineligible to Join Entry fee Contests.\nUpgrade to Premium membership. Select a Plan to continue."
                    ) : (
                        <a
                          href
                          onClick={() => {
                            countineAction(countineData, countineType);
                            IsMembershipModalHide();
                          }}
                          className="link-anchor cnti-fre"
                        >
                          Continue as Freemium Member
                        </a>
                      )}
                  </Col>
                </Row>
              </Modal.Body>
              <i
                className="icon-close icc"
                onClick={() => {
                  IsMembershipModalHide()
                }
                }
              />
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
