import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { MyContext } from "./context";
import { Footer, Header, Loader } from "./components";
import {
  Dashboard, Lobby, FansChallenge, Comment, Blog, BlogDetail, MyGames, Result, MyContest, Training, QuestionScreen, AnswerScreen, Profile, RefSocial, BlogComment, RefPay,
  Transaction, AddFunds, Withdraw, MemberShipPlan, RefMail, CreatePrivateLeague, CreateAddUser, TeamDetail, MyPrivateContest, Notifications, StatsAwards,
  CreatePost, LeaderBoard, VideoS, ReferFriend, RefPodcast, RefPodcastDetail, Achievements, Pass, TermsCondition, PrivacyPolicy, ProfileEdit, ProfileSetting,
  SetSecurityPin, ChangePassword, Performance, FAQ, News, LiveScoreWidgets, Help, UserProfile, Authenticate, Compare, StatsDetails, CreatePrivateContest,
  EditContest, ContestDetail, GameDetail
} from "./views";

import ScrollMemory from "react-router-scroll-memory";
import Notification from "react-notify-toast";
import * as Constants from "./helper/Constants";
import WSManager from "./helper/WSManager";
import * as NC from "./helper/NetworkingConstants";
import _ from "lodash";

import config from './Config';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let lastScrollY = 0;
let ticking = false;


const queryString = require('query-string');

var createGuest = require('cross-domain-storage/guest');
// var createHost = require('cross-domain-storage/host');



// var storageHost = createHost([
//   {
//     origin: config.MyUrl,
//     allowedMethods: ['get', 'set', 'remove'],
//   },
//   {
//     origin: config.RefpredictoUrl,
//     allowedMethods: ['get', 'set', 'remove'],
//   },
// ]);


class Main extends Component {
  constructor(params) {
    super(params);
    this.state = {
      loading: true
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    // this.getParentDomainStorageValues()
  }

  getParentDomainStorageValues = () => {

    var bazStorage = createGuest(config.RefpredictoUrl);
    bazStorage.get(Constants.localStorageKeys.refP_session_key, function (error, value) {
      if (error) {
        console.log(error)
      } else {
        console.log(value)
      }
    })

  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }


  isBottom(el) {
    return (el.getBoundingClientRect().top + 45) <= window.innerHeight;
  }

  nav = React.createRef();

  handleScroll = () => {
    lastScrollY = window.scrollY;

    if (!ticking) {
      if (Constants.WindowScrollY > 0 && lastScrollY > 0) {

      } else {
        window.requestAnimationFrame(() => {
          Constants.SetValues.yScroll(lastScrollY);
          ticking = false;
          if (lastScrollY > 0) {
            Header.updateHeaderShadow(true);
            QuestionScreen.updatePredictionFixed(true);
          }
          else {
            Header.updateHeaderShadow(false);
            QuestionScreen.updatePredictionFixed(false);
          }
        });

        ticking = true;
      }
    }

    const wrappedElement = document.getElementById('footerid');
    let element = document.querySelector(".page-footer");
    if (this.isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this.trackScrolling);
      element && element.classList.add('page-footer-absolute');
    }
    else {
      element && element.classList.remove('page-footer-absolute');
    }
  };

  static scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  componentWillMount() {
    let masterData = WSManager.getMasterData();
    this.callWSGetMasterData();
    if (masterData) {
      this.setMasterDataConst(masterData);
    }
    let location = this.props.location;
    const parsed = queryString.parse(location.search);
    if (parsed.token && Object.hasOwnProperty.bind(parsed)('token')) {
      WSManager.logout();
      WSManager.setToken(parsed.token);
    }
    if (WSManager.loggedIn()) {
      Constants.SetUserBalance.callBalanceApi();
      Header.callGetMyProfileApi()
    }
  }
  callWSGetMasterData() {
    WSManager.Rest(NC.baseURL + NC.MASTER_DATA).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        WSManager.setMasterData(responseJson.data);
        this.setMasterDataConst(responseJson.data);
        this.setState({ loading: false })
      }
    });
  }
  setMasterDataConst(masterData) {
    if (!_.isUndefined(masterData) && masterData.languages.length > 0) {
      let tmpArray = [];
      _.map(masterData.languages, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.language(tmpArray);
    }

    if (masterData.networks.length > 0) {
      let tmpArray = [];
      _.map(masterData.networks, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.networks(tmpArray);
    }

    if (masterData.banks.length > 0) {
      let tmpArray = [];
      _.map(masterData.banks, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.banks(tmpArray);
    }

    if (masterData.leagues.length > 0) {
      // let tmpArray = [];
      // _.map(masterData.leagues, (item, idx) => {
      //   tmpArray.push({ label: item, value: item });
      // });

      Constants.SetValues.leagues(masterData.leagues);
    }

    if (masterData.scoring_rules.length > 0) {
      Constants.SetValues.scoring_rules(masterData.scoring_rules);
    }

    Constants.SetValues.membership_plans(masterData.membership_plans);
    Constants.SetValues.clubs(masterData.club);
    Constants.SetValues.current_round(masterData.current_round);
    if (masterData.categories.length > 0) {
      Constants.SetValues.set_categories(masterData.categories);
    }
    Constants.SetValues.set_sports(masterData.sports);


  }



  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          WSManager.loggedIn() === true ? (
            <Component {...props} />
          ) : (
              <Redirect
                to={{
                  pathname: "/authenticate",
                  state: { from: props.location }
                }}
              />
            )
        }
      />
    );

    if (this.state.loading) {
      // return <Loader />
      return <div></div>
    }

    return (
      <MyContext.Consumer>
        {context => (
          <React.Fragment>
            <Router basename={NC.BASE_NAME}>
              <main className="Site">
                <div className="Site-content">
                  <ScrollMemory />
                  <Notification options={{ zIndex: 99999 }} />
                  <Switch>
                    <Route path="/" component={Lobby} exact />
                    <Route path="/authenticate" component={Authenticate} exact />
                    <PrivateRoute path="/dashboard/:tab_id" component={Lobby} exact />
                    {/* <Route path="/dashboard" component={Dashboard} exact /> */}
                    {/* <Route path="/signup/:user_type" component={Lobby} exact /> */}
                    {/* <Route path="/login" component={Lobby} exact />
                    <Route path="/signup" component={Lobby} exact /> */}
                    <Route path="/fans-challenge" component={FansChallenge} exact />
                    <Route path="/comment" component={Comment} exact />
                    <Route path="/blog" component={Blog} exact />
                    <Route path="/blog-detail" component={BlogDetail} exact />
                    <PrivateRoute path="/result" component={Result} exact />
                    <PrivateRoute path="/my-contest" component={MyContest} exact />
                    <PrivateRoute path="/training" component={Training} exact />
                    <PrivateRoute path="/prediction/:fixture_id" component={QuestionScreen} exact />
                    <PrivateRoute path="/prediction/:fixture_id/:sponser_id" component={VideoS} exact />
                    <PrivateRoute path="/my-games/:tab_id" component={MyGames} exact />
                    <PrivateRoute path="/answer/:fixture_unique_id" component={AnswerScreen} exact />
                    <PrivateRoute path="/profile" component={Profile} exact />
                    <PrivateRoute path="/profile/:user_id" component={UserProfile} exact />
                    <Route path="/ref-social" component={RefSocial} exact />
                    <Route path="/blog-comment" component={BlogComment} exact />
                    <PrivateRoute path="/refpay" component={RefPay} exact />
                    <PrivateRoute path="/transaction" component={Transaction} exact />
                    <PrivateRoute path="/add-funds" component={AddFunds} exact />
                    <PrivateRoute path="/withdraw" component={Withdraw} exact />
                    <PrivateRoute path="/profile-setting" component={ProfileSetting} exact />
                    <PrivateRoute path="/set-security-pin" component={SetSecurityPin} exact />
                    <PrivateRoute path="/change-password" component={ChangePassword} exact />
                    <PrivateRoute path="/performance" component={Performance} exact />
                    <PrivateRoute path="/membership-plan" component={MemberShipPlan} exact />
                    <Route path="/refmail" component={RefMail} exact />
                    <PrivateRoute path="/create-contest" component={CreatePrivateLeague} exact />
                    <PrivateRoute path="/contest/detail/:contest_unique_id" component={ContestDetail} exact />
                    <PrivateRoute path="/create-contest/add-users" component={CreateAddUser} exact />
                    <PrivateRoute path="/team-detail/:group_name/:invite_group_id" component={TeamDetail} exact />
                    <PrivateRoute path="/private-contest" component={MyPrivateContest} exact />
                    <PrivateRoute path="/edit-contest" component={EditContest} exact />
                    <PrivateRoute path="/notification" component={Notifications} exact />
                    <Route path="/terms-condition" component={TermsCondition} exact />
                    <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                    <Route path="/faq" component={FAQ} exact />
                    <PrivateRoute path="/stats-awards" component={StatsAwards} exact />
                    <Route path="/create-post" component={CreatePost} exact />
                    <PrivateRoute path="/leaderboard" component={LeaderBoard} exact />
                    <PrivateRoute path="/refer-friend" component={ReferFriend} exact />
                    <Route path="/refpodcast" component={RefPodcast} exact />
                    <Route path="/news" component={News} exact />
                    <Route path="/score" component={LiveScoreWidgets} exact />
                    <Route path="/help" component={Help} exact />
                    <Route path="/refpodcast-detail" component={RefPodcastDetail} exact />
                    <Route path="/achievements" component={Achievements} exact />
                    <Route path="/pass" component={Pass} exact />
                    <PrivateRoute path="/edit-profile" component={ProfileEdit} exact />
                    {/* <PrivateRoute path="/compare" component={Compare} exact /> */}
                    <PrivateRoute path="/compare" component={Compare} exact />
                    <PrivateRoute path="/stats-details" component={StatsDetails} exact />
                    <PrivateRoute path="/game/detail/:game_unique_id" component={GameDetail} exact />
                    {/* <PrivateRoute path="/home" component={ProfileEdit} exact /> */}
                    <Redirect from="*" to="/" />
                  </Switch>
                  <Footer />
                </div>
              </main>
            </Router>

          </React.Fragment>
        )}
      </MyContext.Consumer>
    );
  }
}
export default Main;
