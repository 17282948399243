import React, { Fragment } from "react";
import { Row, Col, Tab, Nav, NavItem, FormGroup, Media, FormControl } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images, ComingSoon } from "../components";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import Moment from "react-moment";
import MetaData from "../helper/MetaData";
import Rating from "react-rating";
import PerformanceComponent from "./PerformanceComponent";
import * as Constants from "../helper/Constants";

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      Tabkey: 1,
      user_profile: WSManager.getProfile()
    };
  }

  componentWillMount() {
    this.callGetUserProfile();
  }

  
  callGetUserProfile() {
    WSManager.Rest(NC.baseURL + NC.GET_MY_PROFILE).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        WSManager.setProfile(responseJson.data.user_profile);
        this.setState({
          user_profile: responseJson.data.user_profile
        });
      }
    });
  }

  handleSelect(Tabkey) {
    this.setState({ Tabkey });
  }

  SkillBadgesTitle = percent => {
    if (percent < 40) {
      return "Amateur";
    }
    if (percent < 50) {
      return "Average";
    }
    if (percent < 60) {
      return "Advanced";
    }
    if (percent < 70) {
      return "Pro";
    }
    if (percent < 80) {
      return "Expert";
    }
    if (percent >= 80) {
      return "Genius";
    }
  };

  SkillBadgesStar = percent => {
    if (percent < 40) {
      return 0;
    }
    if (percent < 50) {
      return 20;
    }
    if (percent < 60) {
      return 40;
    }
    if (percent < 70) {
      return 60;
    }
    if (percent < 80) {
      return 80;
    }
    if (percent >= 80) {
      return 100;
    }
  };

  render() {
    const {
      user_profile,
    } = this.state;
    const HeaderOption = {
      menu: true,
      Logo: true,
      setting: true,
      edit: true
    };

    const user_permissions = WSManager.getUserPermissions();
    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container profile-web-container white-bg">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.myprofile.title}</title>
              <meta
                name="description"
                content={MetaData.myprofile.description}
              />
              <meta name="keywords" content={MetaData.myprofile.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <div
                    className="page-inner-header"
                    style={{
                      backgroundImage: `url(${user_profile.cover_image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }}
                  >
                    {!user_profile.cover_image && (
                      <img
                        src={Images.HEADER_BG_FULL}
                        alt=""
                        className="header-bg"
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <div className="profile-header">
                <div className="user-thumbnail">
                  <img
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = Images.DEFAULT_USER;
                    }}
                    src={user_profile.image || Images.DEFAULT_USER}
                    alt=""
                    onClick={() => Header.ShowProfileModal({ image: user_profile.image || Images.DEFAULT_USER })}
                  />
                </div>
                <div className="user-detail">

                  <div className="user-name">

                    <span
                      style={{
                        fontFamily:
                          // user_profile.plan_id == 3 || user_profile.plan_id == 2 ||
                          // Constants.PAID_PLANS_ID.includes(user_profile.plan_id)
                          user_permissions.username_bold == '1'
                            ? "MuliBlack"
                            : "MuliRegular"
                      }}
                    >
                      {user_profile.user_name}
                      {
                        // user_profile.plan_id == 3 
                        user_permissions.premium_badge == '1'
                        && (
                          <img
                            src={Images.P_PLUS}
                            alt=""
                            style={{ marginLeft: 5, marginTop: -5 }}
                          />
                        )}
                    </span>

                  </div>
                  <div className={'ref-id'}>
                    {user_profile.ref_id ? `R-${user_profile.ref_id}` : '--'}
                  </div>
                  <div className="member-since">
                    Member Since -
                    <Moment
                      date={user_profile.member_since}
                      format="MMM, YYYY"
                    />
                  </div>
                  <ul className="your-profile-detail">
                    <li>
                      Posts
                      <span>--</span>
                    </li>
                    <li>
                      Followers
                      <span>
                        {user_profile.follower_count > 0
                          ? user_profile.follower_count
                          : "--"}
                      </span>
                    </li>
                    <li>
                      Following
                      <span>
                        {user_profile.following_count > 0
                          ? user_profile.following_count
                          : "--"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <Row className="">
                <Col xs={6} className="user-records">
                  <div className="records-label">
                    Skill Gauge -{" "}
                    <span>
                      {this.SkillBadgesTitle(
                        user_profile.skill_badge
                          ? user_profile.skill_badge.percentage
                          : 0
                      )}
                    </span>
                  </div>
                  <div>
                    <Rating
                      start={0}
                      stop={100}
                      step={20}
                      initialRating={
                        user_profile.skill_badge
                          ? this.SkillBadgesStar(
                            user_profile.skill_badge.percentage || 0
                          )
                          : 0
                      }
                      emptySymbol="icon-star"
                      fullSymbol="icon-star highlighted"
                      fractions={3}
                      readonly
                    />
                  </div>
                </Col>
                <Col xs={6} className="user-records">
                  <div className="records-label">
                    FORM <span>(Last 6 games)</span>
                  </div>
                  <div className="your-team-records">
                    {user_profile.user_form &&
                      user_profile.user_form.length > 6 ? (
                        <Fragment>
                          {_.map(user_profile.user_form, (item, idx) => {
                            return idx === 0 ? (
                              ""
                            ) : (
                                <img
                                  key={idx}
                                  src={
                                    parseInt(item) <
                                      parseInt(user_profile.user_form[idx - 1])
                                      ? Images.TEAM_WIN
                                      : parseInt(item) >
                                        parseInt(user_profile.user_form[idx - 1])
                                        ? Images.TEAM_LOSE
                                        : Images.TEAM_TIE
                                  }
                                  alt=""
                                />
                              );
                          })}
                        </Fragment>
                      ) : user_profile.user_form &&
                        user_profile.user_form.length > 0 &&
                        user_profile.user_form.length <= 6 ? (
                          <Fragment>
                            {_.map(user_profile.user_form, (item, idx) => {
                              return idx === 0 ? (
                                <img src={Images.TEAM_TIE} alt="" />
                              ) : (
                                  <img
                                    key={idx}
                                    src={
                                      parseInt(item) <
                                        parseInt(user_profile.user_form[idx - 1])
                                        ? Images.TEAM_WIN
                                        : parseInt(item) >
                                          parseInt(user_profile.user_form[idx - 1])
                                          ? Images.TEAM_LOSE
                                          : Images.TEAM_TIE
                                    }
                                    alt=""
                                  />
                                );
                            })}
                          </Fragment>
                        ) : (
                          "--"
                        )}
                  </div>
                </Col>
              </Row>
              <Tab.Container
                className="default-tab less-padding"
                id="tabs-with-dropdown"
                defaultActiveKey="BasicInfo"
              >
                <Row className="clearfix">
                  <Col sm={12}>
                    <Nav bsStyle="tabs shadow">
                      <NavItem eventKey="BasicInfo">Basic Info</NavItem>
                      <NavItem eventKey="Performance">Performance</NavItem>
                      <NavItem eventKey="Refsocial">Refsocial</NavItem>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content animation>
                      <Tab.Pane eventKey="BasicInfo">
                        <div className="user-basic-info-section">
                          <div className="your-detail">
                            <div className="left-section">
                              <i className="icon-note" />
                            </div>
                            <div className="right-section">
                              <h5>About Me</h5>
                              <p>{user_profile.about || "--"}</p>
                            </div>
                          </div>
                          <div className="your-detail">
                            <div className="left-section">
                              <i className="icon-trophy" />
                            </div>
                            <div className="right-section">
                              <h5>RPL Rank (SP)</h5>
                              <p>{user_profile.user_rank || "--"}</p>
                            </div>
                          </div>
                          <div className="your-detail">
                            <div className="left-section">
                              <i className="icon-note" />
                            </div>
                            <div className="right-section">
                              <h5>Favorite Club</h5>
                              <p>{user_profile.team_name || "--"}</p>
                            </div>
                          </div>
                          <div className="your-detail">
                            <div className="left-section">
                              <i className="icon-calender-2" />
                            </div>
                            <div className="right-section">
                              <h5>Birthday</h5>
                              <p>{user_profile.dob}</p>
                            </div>
                          </div>
                          <div className="your-detail">
                            <div className="left-section">
                              <i className="icon-website" />
                            </div>
                            <div className="right-section">
                              <h5>User Website</h5>
                              <p className="user-website">
                                {user_profile.user_website ? (
                                  <a
                                    target="__blank"
                                    href={user_profile.user_website}
                                  >
                                    {user_profile.user_website}
                                  </a>
                                ) : (
                                    "--"
                                  )}
                              </p>
                            </div>
                          </div>
                          {/* <div className="similar-profile">
                            <div className="heading">Similar Profile</div>
                            <ul>
                              <li>
                                <div>
                                  <img src={Images.USERIMG} alt="" />
                                  Anna Zales
                                  <i className="icon-add-user" />
                                </div>
                              </li>
                              <li>
                                <div>
                                  <img src={Images.USERIMG2} alt="" />
                                  Anna Zales
                                  <i className="icon-mail" />
                                </div>
                              </li>
                              <li>
                                <div>
                                  <img src={Images.USERIMG3} alt="" />
                                  Anna Zales
                                  <i className="icon-add-user" />
                                </div>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Performance">
                        <PerformanceComponent history={this.props.history} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Refsocial">
                        <ComingSoon />
                        <div className="your-post-section hide">
                          <div className="post-card">
                            <div className="post-card-header">
                              <div className="postuser-detail">
                                <img src={Images.USERIMG} alt="" />
                                <div className="user-name">Mark.Zucker</div>
                                <div className="post-time">1 min ago</div>
                                <i className="icon-share" />
                              </div>
                            </div>
                            <div className="post-card-body">
                              <div className="post-description">
                                Derby county on brink of Deal for 2018
                                AFCON-Winning Defender, Lampard Reveals :: All
                                Nigeria Soccer - The complete Nigerian Football
                                Portal
                              </div>
                              <div className="posted-img">
                                <img src={Images.BLOG_IMG} alt="" />
                              </div>
                              <div className="blog-action post-action-section">
                                <Row>
                                  <Col xs={3}>
                                    <span className="user-fav liked">
                                      <i className="icon-like" />
                                    </span>
                                    <span className="txt">546</span>
                                  </Col>
                                  <Col xs={3} className="text-center">
                                    <span className="action-count">
                                      <i className="icon-reload" />
                                      <span className="txt">0</span>
                                    </span>
                                  </Col>
                                  <Col xs={3} className="text-center">
                                    <span className="action-count">
                                      <i className="icon-comment" />
                                      <span className="txt">0</span>
                                    </span>
                                  </Col>
                                  <Col xs={3} className="text-center">
                                    <span className="action-count">
                                      <i className="icon-dislike" />
                                      <span className="txt">0</span>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <div className="post-card-footer enter-comment-section">
                              <Media className="incoming-msg msg-section">
                                <img
                                  src={Images.USERIMG2}
                                  alt="sender-img"
                                  className="user-img"
                                />
                                <Media.Body>
                                  <div className="type-comment-area">
                                    <form>
                                      <FormGroup controlId="formInlineName">
                                        <FormControl
                                          type="text"
                                          placeholder="Jane Doe"
                                        />
                                        <i className="icon-send" />
                                      </FormGroup>
                                    </form>
                                  </div>
                                </Media.Body>
                              </Media>
                            </div>
                          </div>
                          <div className="post-card">
                            <div className="post-card-header">
                              <div className="postuser-detail">
                                <img src={Images.USERIMG2} alt="" />
                                <div className="user-name">Mark.Zucker</div>
                                <div className="post-time">1 min ago</div>
                                {/* <i className="icon-user-select"></i> */}
                                <i className="icon-share" />
                              </div>
                            </div>
                            <div className="post-card-body">
                              <div className="post-description">
                                Derby county on brink of Deal for 2018
                                AFCON-Winning Defender, Lampard Reveals :: All
                                Nigeria Soccer - The complete Nigerian Football
                                Portal
                              </div>
                              <div className="post-tag">
                                <span>#Unofficial</span>
                                <span>#BR vs DD</span>
                              </div>
                              <div className="blog-action post-action-section">
                                <Row>
                                  <Col xs={3}>
                                    <span className="user-fav">
                                      <i className="icon-like" />
                                    </span>
                                    <span className="txt">546</span>
                                  </Col>

                                  <Col xs={3} className="text-right">
                                    <span className="action-count">
                                      <i className="icon-reload" />
                                      <span className="txt">0</span>
                                    </span>
                                  </Col>
                                  <Col xs={3} className="text-right">
                                    <span className="action-count">
                                      <i className="icon-comment" />
                                      <span className="txt">0</span>
                                    </span>
                                  </Col>
                                  <Col xs={3} className="text-right">
                                    <span className="action-count">
                                      <i className="icon-dislike" />
                                      <span className="txt">0</span>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <div className="post-card-footer enter-comment-section">
                              <Media className="incoming-msg msg-section">
                                <img
                                  src={Images.USERIMG3}
                                  alt="sender-img"
                                  className="user-img"
                                />
                                <Media.Body>
                                  <div className="type-comment-area">
                                    <form>
                                      <FormGroup controlId="formInlineName">
                                        <FormControl
                                          type="text"
                                          placeholder="Jane Doe"
                                        />
                                        <i className="icon-send" />
                                      </FormGroup>
                                    </form>
                                  </div>
                                </Media.Body>
                              </Media>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
