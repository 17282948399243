import React from 'react';
import { Row, Col, Button, FormGroup, Tab, Nav, NavItem } from 'react-bootstrap';
import { MyContext } from '../context';
import { Header, SubHeader, ComingSoon, Images, Loader, NoDataScreen } from '../components';
import { Helmet } from "react-helmet";
import { inputStyle } from '../helper/input-style';
import MetaData from "../helper/MetaData";
import Select from 'react-select';

import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import Slider from "react-slick";
import * as Constants from "../helper/Constants";

export default class StatsAwards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leaguesArray: [],
            selectedLeague: '',
            pageBanner: [],
            selectedType: Constants.GLOBAL_CATEGORY,
            selectedCategory: Constants.categories[0],
            selectedTab: "stats",
            myProfile: WSManager.getProfile(),
            stats_ques_list: [],
            isLoading: false,
            selectedRound: "",
            selectedLeague: "",
            selectedContest: "",
            selectedMonth: "",
            selectedFormat: "",
            categories_list: Constants.categories,
        }
    }



    componentDidMount() {
        this.getPageBanner()

        if (this.state.selectedTab == "stats") {
            this.getStatsQuestions()
        }
    }


    onTabClick = selectedTab => {
        this.setState(
            {
                selectedTab: selectedTab,
                selectedCategory: Constants.categories[0],
                selectedType: Constants.GLOBAL_CATEGORY,
                stats_ques_list: [],
                selectedContest: "",
                selectedLeague: "",
                selectedRound: "",
                selectedMonth: "",
                selectedFormat: ""
            },
            () => {
                if (this.state.selectedTab == "stats") {
                    this.getStatsQuestions()
                }
            }
        );
    };


    selectCategory = (itm) => {
        if (this.state.selectedCategory.value == itm.value) return;

        this.setState({
            selectedCategory: itm,
            selectedType: itm.id,
            stats_ques_list: [],
            selectedContest: "",
            selectedLeague: "",
            selectedRound: "",
            selectedMonth: "",
            selectedFormat: ""
        },
            this.getStatsQuestions
        )
    }

    getPageBanner = () => {

        let param = {
            page_type_id: Constants.BANNER_PAGE_TYPE_ID.statsAwards,
            position_id: "1"
        }
        WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
            .then(res => {
                if (res.response_code === NC.successCode) {
                    this.setState({ pageBanner: res.data })
                }
            })
    }


    getStatsQuestions = () => {

        const { selectedType, myProfile, selectedCategory, selectedLeague, selectedMonth, selectedContest, selectedRound, selectedFormat } = this.state;

        let param = {
            contest_type_id: selectedLeague != "" ? selectedLeague.value : "",
            month: selectedMonth != "" ? selectedMonth.value : "",
            // round_id: selectedRound != "" ? selectedRound.value : selectedLeague == "" ? Constants.current_roundData.round_id : "",
            round_id: selectedRound != "" ? selectedRound.value : "",
            contest_id: selectedContest != "" ? selectedContest.value : "",
            format:selectedFormat.value || 1
        }

        if(selectedLeague.value == '0'){
            param.rpl_cup_type = 1
        }

        if (selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
            param['type'] = selectedCategory.id;
            if (selectedCategory.id == '2') {
                param['country_id'] = selectedCategory.value;
            }
            if (selectedCategory.id == '3') {
                param['team_id'] = selectedCategory.value;
            }
        }

        this.setState({ isLoading: true })

        WSManager.Rest(NC.baseURL + NC.GET_QUESTION_STATS_LIST, param)
            .then(res => {
                if (res.response_code === NC.successCode) {
                    this.setState({
                        stats_ques_list: res.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }


    StatsRenderer = () => {

        const { stats_ques_list, selectedLeague, selectedRound, selectedContest, selectedMonth, selectedFormat, selectedCategory } = this.state;
        return (
            <React.Fragment>

                <div className="leaderboard-header">
                    <div
                        className="select-option-btn"
                        onClick={() =>
                            Header.FilterModalShow(
                                {
                                    filterOption: ["league", "round", "contest", "month"],
                                    selectedLeague: selectedLeague,
                                    selectedRound: selectedRound,
                                    selectedContest: selectedContest,
                                    selectedMonth: selectedMonth,
                                    selectedFormat: selectedFormat,
                                    selectedCategory: selectedCategory,
                                    // selectedFollowOption: selectedFollowOption,
                                    isFrom: "leaderboard",

                                },
                                this.filterAction.bind(this)
                            )
                        }
                    >
                        {selectedLeague != "" ||
                            selectedRound != "" ||
                            selectedContest != "" ||
                            selectedMonth != ""
                            ? "Filters Applied"
                            : "Select"}
                        <div className="arrow-section">
                            <i className="icon-arrow-sort" />
                        </div>
                    </div>
                </div>
                <div className="stats-section ">

                    {
                        (stats_ques_list && stats_ques_list.length > 0) ?

                            <React.Fragment>
                                {this.state.stats_ques_list.map((itm, i) => {
                                    return (
                                        <div className="stats-list" key={i}>
                                            <div className="listing-section">
                                                <i className="icon-football"></i>
                                            </div>
                                            <div className="stats-data-section">
                                                <div
                                                    className="display-table-cell nm-area"
                                                    onClick={() => this.props.history.push({
                                                        pathname: "stats-details",
                                                        state: {
                                                            player: itm,
                                                            selectedCategory,
                                                            selectedLeague,
                                                            selectedRound,
                                                            selectedContest,
                                                            selectedMonth,
                                                            selectedFormat,
                                                        }
                                                    })
                                                    }
                                                >
                                                    <img src={itm.image || Images.DEFAULT_USER} alt="" />
                                                    <div className="pass-count">{itm.question_name}</div>
                                                    <div className="stats-msg">{itm.user_name}</div>
                                                </div>
                                                <div className="display-table-cell">
                                                    <i
                                                    title={'Compare'}
                                                        onClick={() => this.props.history.push({
                                                            pathname: 'compare',
                                                            state: {
                                                                player: itm,
                                                                selectedCategory,
                                                                selectedLeague,
                                                                selectedRound,
                                                                selectedContest,
                                                                selectedMonth,
                                                                selectedFormat,
                                                            }
                                                        })}
                                                        className={'icon icon-compare compare-usr'}></i>
                                                </div>
                                                <div className="display-table-cell">
                                                    <div className="stats-pts">{itm.score}</div>
                                                    <div className="stats-pts-label">pts</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </React.Fragment> :
                            <React.Fragment>
                                <NoDataScreen
                                    Content={{
                                        ImgShown: Images.no_data_bg_image,
                                        IsButton: false,
                                        ButtonText: "Go to Lobby",
                                        Title: "Oops! No data AVAILABLE",
                                        NoDataContent: "",
                                        BtnAction: () => {
                                            this.props.history.push("/dashboard/0");
                                        },
                                        WithFilter: "1"
                                    }}
                                />
                            </React.Fragment>


                    }



                </div>
            </React.Fragment>
        )
    }

    filterAction(selectleague, selectround, selectedContest, selectedMonth, selectedFormat, selectedCategory, selectedFollowOption, selectedContestType) {
        this.setState({
            selectedLeague: selectleague,
            selectedRound: selectround,
            selectedContest: selectedContest,
            selectedMonth: selectedMonth,
            selectedFormat: selectedFormat,
            selectedCategory: selectedCategory,
            selectedFollowOption: selectedFollowOption,
            selectedContestType: selectedContestType,
        }, () => {
            this.getStatsQuestions();
        }
        );
    }


    render() {
        const {
            leaguesArray,
            selectedLeague,
            pageBanner,
            categories_list,
            selectedTab,
            isLoading,
            selectedCategory,
        } = this.state;

        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container lobby-web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.awards.title}</title>
                            <meta name="description" content={MetaData.awards.description} />
                            <meta name="keywords" content={MetaData.awards.keywords}></meta>
                        </Helmet>

                        {isLoading && <Loader />}

                        <Header {...this.props} HeaderOption={HeaderOption} />
                        <div >
                            <Tab.Container id="left-tabs-example" defaultActiveKey="stats">
                                <Row>
                                    <Col sm={12}>
                                        <SubHeader Title="Stats & Awards" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="" />



                                        <Nav className="tab-navigation tab-navigation-two tab-navigation-upper">
                                            <NavItem
                                                onClick={() => this.onTabClick("stats")}
                                                className={`${selectedTab == "stats" ? 'active' : ''}`}
                                            // eventKey="stats"
                                            >Stats</NavItem>
                                            <NavItem
                                                onClick={() => this.onTabClick("awards")}
                                                className={`awards-tab ${selectedTab == "awards" ? 'active' : ''}`}
                                            // eventKey="awards"
                                            >Awards</NavItem>
                                        </Nav>

                                        {
                                            (pageBanner && pageBanner.length > 0) &&
                                            <div className={'lb-ban-wrap'}>
                                                <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                                                    {pageBanner.map((itm, i) => {
                                                        return <div key={i} className={'lb-banner'}>
                                                            <img src={itm.image} />
                                                        </div>
                                                    })}
                                                </Slider>
                                            </div>
                                        }
                                        {/* </div> */}
                                        <Tab.Content >
                                            <Tab.Pane eventKey="stats" className={`${selectedTab == "stats" ? 'active in' : ''}`} >

                                                <div className={'lb_type_tab15'}>
                                                    {categories_list.map((itm, i) => {
                                                        return <div
                                                            className={`ct-tab ${selectedCategory.id == itm.id ? 'active' : ''}`}
                                                            onClick={() => this.selectCategory(itm)}
                                                            key={i}>{itm.name}</div>
                                                    })}
                                                </div>

                                                {/* <ComingSoon /> */}

                                                {this.StatsRenderer()}



                                            </Tab.Pane>


                                            <Tab.Pane eventKey="awards" className={`${selectedTab == "awards" ? 'active in' : ''}`}>
                                                {/* <ComingSoon /> */}
                                                <div className="awards-container ">
                                                    <div className="awards-filter-section">
                                                        <Row>
                                                            <Col xs={6} className="award-filter-border">
                                                                <FormGroup className='input-transparent font-14 league-select m-0'>
                                                                    <div className="select-league">
                                                                        <label style={inputStyle.label}>Filter by Season</label>
                                                                        <div className="genderStyle">
                                                                            <Select
                                                                                onChange={this.handleLeagueChange}
                                                                                options={leaguesArray}
                                                                                classNamePrefix="secondary"
                                                                                className="select-secondary minusML10"
                                                                                value={selectedLeague}
                                                                                placeholder="--"
                                                                                isSearchable={false} isClearable={false}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    borderRadius: 0,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        primary: '#FB2508',
                                                                                    },
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <span className="select-arr"><i className="icon-arrow-sort"></i></span>
                                                                        <div className='league-border col-sm-12' />
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <FormGroup className='input-transparent font-14 league-select m-0'>
                                                                    <div className="select-league">
                                                                        <label style={inputStyle.label}>Filter by Awards</label>
                                                                        <div className="genderStyle">
                                                                            <Select
                                                                                onChange={this.handleLeagueChange}
                                                                                options={leaguesArray}
                                                                                classNamePrefix="secondary"
                                                                                className="select-secondary minusML10"
                                                                                value={selectedLeague}
                                                                                placeholder="--"
                                                                                isSearchable={false} isClearable={false}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    borderRadius: 0,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        primary: '#FB2508',
                                                                                    },
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        <span className="select-arr"><i className="icon-arrow-sort"></i></span>
                                                                        <div className='league-border col-sm-12' />
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="award-section">
                                                        <div className="award-header">Predictor of the Month</div>
                                                        <div className="award-card">
                                                            <img src={Images.TEAM_LOGO} alt="" className="team-logo" />
                                                            <img src={Images.PLAYER_IMG} alt="" className="player-img" />
                                                            <div className="player-info-section">
                                                                <div className="player-first-name">Marcus</div>
                                                                <div className="player-last-name">Rashford</div>
                                                                <div className="player-team-position">Manchester United, FW</div>
                                                                <div>
                                                                    <Button className="btn btn-rounded">Vote</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="award-section">
                                                        <div className="award-header">Predictor of the Month</div>
                                                        <div className="award-card">
                                                            <img src={Images.TEAM_LOGO} alt="" className="team-logo" />
                                                            <img src={Images.PLAYER_IMG} alt="" className="player-img" />
                                                            <div className="player-info-section">
                                                                <div className="player-first-name">Marcus</div>
                                                                <div className="player-last-name">Rashford</div>
                                                                <div className="player-team-position">Manchester United, FW</div>
                                                                <div>
                                                                    <Button className="btn btn-rounded">Vote</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="award-section">
                                                        <div className="award-header">Predictor of the Month</div>
                                                        <div className="award-card">
                                                            <img src={Images.TEAM_LOGO} alt="" className="team-logo" />
                                                            <img src={Images.PLAYER_IMG} alt="" className="player-img" />
                                                            <div className="player-info-section">
                                                                <div className="player-first-name">Marcus</div>
                                                                <div className="player-last-name">Rashford</div>
                                                                <div className="player-team-position">Manchester United, FW</div>
                                                                <div>
                                                                    <Button className="btn btn-rounded">Vote</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}
