import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../context';
import { Header, SubHeader, Images, ComingSoon } from '../components';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";

export default class Training extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container lobby-web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.Prediction.title}</title>
                            <meta name="description" content={MetaData.Prediction.description} />
                            <meta name="keywords" content={MetaData.Prediction.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} />
                        <div >
                            <Row>
                                <Col sm={12}>
                                    <SubHeader Title="Training" ImgShown={Images.HEADER_BG_FULL} BannerImage="" AddClassName="page-inner-header-sm" />
                                    <ComingSoon />
                                    <div className="fixture-collection-wrapper training-fixture-collection-wrap hide">
                                        <div className="card-view">
                                            <i className="icon-share"></i>
                                            <div className="card-view-right">
                                                <h2>GER <span>v</span> SWE</h2>
                                                <p>Referee: Shinji Takoma | Arena Stadium, Kyiv </p>
                                                <div className="match-date">SUN, FEB 23</div>
                                                <img src={Images.VISSEL} alt="" className="vissel-img" />
                                                <div className="text-right">
                                                    <a href className="play-btn">Play</a>
                                                </div>
                                            </div>
                                            <div className="card-view-left">
                                                <div className="team-section">
                                                    <img src={Images.SWEDEN} alt="" />
                                                    <div className="vs-section">
                                                        <i className="icon-football"></i>
                                                    </div>
                                                </div>
                                                <div className="team-section">
                                                    <img src={Images.SWEDEN} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}
