import React from "react";
import ContestDetailModal from "../Modals/ContestDetail";
import { Row, Col, ProgressBar, FormGroup, FormControl } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images, NoDataScreen, SubHeader, Loader } from "../components";
import MetaData from "../helper/MetaData";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import CountdownTimer from "./CountDownTimer";
import UtilityFunctions from "../helper/UtilityFunctions";
import * as Constants from "../helper/Constants";
import Slider from "react-slick";
import config from '../Config';

var globalThis = null;

export default class MyContest extends React.Component {
  constructor(props) {
    super(props);
    this.SearchUserReq = _.debounce(this.SearchUserReq.bind(this), 700);
    this.state = {
      showContestDetail: false,
      offset: 0,
      limit: 20,
      hasMore: false,
      roundId: !_.isUndefined(props.location.state) ? props.location.state.round_id : "",
      myContestData: [],
      contestInfo: "",
      selectedRound: "",
      selectedLeague: "",
      searchClick: false,
      Searchkey: "",
      scheduleTime: [],
      isLoading: false,
      selectedCategory: Constants.categories[0],
      pageBanner: [],
      categories_list: Constants.categories,
      contestType: 1,
      contestTypeObj: Constants.contest_type[0],
      selectedWinner: "",
      selectedEntryFee: "",
      minSize: "",
      contest_duration_options: Constants.contest_duration,
      userProfile: WSManager.getProfile(),
    };
  }
  SearchUserReq() {
    const { Searchkey } = this.state;
    if (Searchkey.trim().length < 2 && Searchkey.trim().length > 0) return;
    this.contestListApi();
  }

  componentDidMount() {
    globalThis = this;
  }

  componentWillMount() {
    this.contestListApi();
    this.getPageBanner()
  }


  getPageBanner = () => {

    let param = {
      page_type_id: Constants.BANNER_PAGE_TYPE_ID.myContest,
      position_id: "1"
    }
    WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }


  goOnLeaderBoard = item => {

    let selectedContestDuration = this.state.contest_duration_options[0];
    _.map(this.state.contest_duration_options, (obj, idx) => {
      if (item.is_season_long == obj.value) {
        selectedContestDuration = obj;
      }
    });


    // console.log(item);return
    this.props.history.push({
      pathname: "/leaderboard",
      state: {
        selectedRound: {
          label: item.round_name,
          value: item.round_id
        },
        selectedLeague: {
          label: item.name,
          value: item.contest_type
        },
        selectedContest: {
          label: item.contest_name,
          value: item.contest_id
        },
        selectedMonth: {
          label: item.month_name,
          value: item.month
        },
        selectedCategory: this.state.selectedCategory,
        selectedContestType: this.state.contestTypeObj,
        selectedContestDuration: selectedContestDuration,
        selectedMainTab: 1
      }
    });
  };

  filterAction(selectleague, selectround, selectedContest, selectedMonth, selectedFormat, selectedCategory,
    selectedFollowOption, selectedContestType, selectedWinner, selectedEntryFee, minSize) {
    // console.log(selectleague, selectround, selectedContest, selectedMonth, selectedFormat, selectedCategory)

    this.setState(
      {
        selectedLeague: selectleague,
        selectedRound: selectround,
        // selectedCategory: selectedCategory,
        selectedWinner: selectedWinner,
        selectedEntryFee: selectedEntryFee,
        minSize: minSize,
        offset: 0,
        hasMore: false
      },
      () => {
        this.contestListApi();
      }
    );
  }

  contestListApi() {
    let {
      myContestData,
      roundId,
      offset,
      limit,
      selectedLeague,
      selectedRound,
      selectedCategory,
      Searchkey,
      contestType,
      selectedWinner,
      selectedEntryFee,
      minSize
    } = this.state;


    let param = {
      sports_id: NC.sportsId,
      round_id: selectedRound == "" ? roundId : selectedRound.value,
      offset: offset,
      limit: limit,
      contest_type: selectedLeague == "" ? "" : selectedLeague.value,
      contest_name: Searchkey,
      // contest_type: contestType == 1 ? Constants.PUBLIC_LEAGUE_ID : Constants.PRIVATE_LEAGUE_ID,
      entry_fee: selectedEntryFee != "" ? selectedEntryFee.entry_fee : "",
      number_of_winner: selectedWinner != "" ? selectedWinner.places : "",
      min_size: minSize,
      contest_access_type: contestType == 1 ? 0 : 1,

    };

    if (selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
      param['type'] = selectedCategory.id;
      if (selectedCategory.id == '2') {
        param['country_id'] = selectedCategory.value;
      }
      if (selectedCategory.id == '3') {
        param['team_id'] = selectedCategory.value;
      }
    }

    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.GET_MY_CONTEST_LIST, param).then(
      responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          if (offset == 0) {
            this.setState({
              myContestData: responseJson.data.result,
              hasMore: responseJson.data.total > limit,
              offset: responseJson.data.result.length,
              scheduleTime: responseJson.data.schedule
            });
          } else {
            this.setState({
              myContestData: [...myContestData, ...responseJson.data.result],
              hasMore: responseJson.data.total > limit,
              offset: myContestData.length + responseJson.data.result.length,
              scheduleTime: responseJson.data.schedule
            });
          }
        }
      }
    );
  }

  ContestDetailShow = (event, item) => {
    this.setState({
      showContestDetail: true,
      contestInfo: item
    });
  };

  ContestDetailHide = () => {
    this.setState({
      showContestDetail: false
    });
  };

  showProgressBar = (join, total) => {
    return (join * 100) / total;
  };

  handleRefresh = () => {
    this.setState(
      {
        offset: 0,
        hasMore: false
      },
      () => {
        this.contestListApi();
      }
    );
  };

  fetchMoreContestData = () => {
    this.contestListApi();
  };




  selectCategory = (itm) => {
    this.setState({
      selectedCategory: itm,
      offset: 0,
      hasMore: false,
      selectedRound: "",
      selectedLeague: "",
      selectedWinner: "",
      selectedEntryFee: "",
      minSize: ""
    },
      () => {
        this.contestListApi();
      })

  }


  changeContestType = (key) => {
    this.setState({
      contestType: key.value,
      contestTypeObj: key,
      offset: 0,
      hasMore: false,
      selectedRound: "",
      selectedLeague: "",
      selectedWinner: "",
      selectedEntryFee: "",
      minSize: ""
    },
      () => {
        this.contestListApi();
      })
  }

  shareContest = (contest_unique_id) => {
    let title =
      `Your friend ${this.state.userProfile.display_name} has challenged you for the contest. Join and win big by beating your friend, using the following link : ${config.MyUrl}/contest/detail/${contest_unique_id}
    Cheers,
    Team Refpredictor`;

    Header.ShareModalShow(contest_unique_id, title);
  }

  render() {
    const {
      showContestDetail,
      isLoading,
      myContestData,
      contestInfo,
      selectedLeague,
      selectedRound,
      searchClick,
      hasMore,
      scheduleTime,
      selectedCategory,
      pageBanner,
      categories_list,
      contestType,
      selectedEntryFee,
      selectedWinner,
      minSize,
      contestTypeObj
    } = this.state;
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };



    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container lobby-web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.contests.title}</title>
              <meta name="description" content={MetaData.contests.description} />
              <meta name="keywords" content={MetaData.contests.keywords} />
            </Helmet>
            <Header {...this.props} HeaderOption={HeaderOption} showNavigation={false} />
            {isLoading && <Loader />}
            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="My Contests" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                </Col>
              </Row>

              {
                (pageBanner && pageBanner.length > 0) &&
                <div className={'lb-ban-wrap'}>
                  <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                    {pageBanner.map((itm, i) => {
                      return <div key={i} className={'lb-banner'}>
                        <img src={itm.image} />
                      </div>
                    })}
                  </Slider>
                </div>
              }


              {
                <div className={'lb_type_tab15'}>
                  {categories_list.map((itm, i) => {
                    return <div
                      className={`ct-tab ${selectedCategory.id == itm.id ? 'active' : ''}`}
                      onClick={() => this.selectCategory(itm)}
                      key={i}>{itm.name}</div>
                  })}
                </div>
              }


              <div className="my-4">
                <Row>
                  <Col xs={12} className={'text-center'}>
                    <div className={'rad-switch'}>
                      {
                        Constants.contest_type.map((itm, i) => {
                          return <div
                            className={`${contestTypeObj.value == itm.value ? 'active' : ''}`}
                            onClick={() => this.changeContestType(itm)}
                            key={i}
                          >
                            <span className={'icon-tick check'}></span> {itm.label}
                          </div>
                        })
                      }
                    </div>
                  </Col>
                </Row>
              </div>

              {/* <div className={'px-3 py-2'}>
                <button onClick={() => this.props.history.push('/private-contest')} type="button" className="btn btn-block btn-2 btn-round pc-btn">
                  My Private Contest
                </button>
              </div> */}


              <FormGroup className="input-label-center input-transparent font-14 mb-0">
                <div className="my-contest-filter">
                  <div
                    className="select-option-btn"
                    onClick={() =>
                      Header.FilterModalShow(
                        {
                          filterOption: ["league", "round", 'winner', "entryfee", "minsize"],
                          selectedLeague: selectedLeague,
                          selectedRound: selectedRound,
                          selectedCategory: selectedCategory,
                          selectedEntryFee: selectedEntryFee,
                          selectedWinner: selectedWinner,
                          minSize: minSize,
                          isFrom: 'MyContest',
                          contest_type: contestType == 1 ? 0 : 1,
                          contest_access_type: contestType == 1 ? Constants.PUBLIC_LEAGUE_ID : Constants.PRIVATE_LEAGUE_ID


                        },
                        this.filterAction.bind(this)
                      )
                    }
                  >
                    {selectedLeague != "" || selectedRound != ""
                      ? "Filters Applied"
                      : "Select"}
                    <div className="arrow-section">
                      <i className="icon-arrow-sort" />
                    </div>
                  </div>
                </div>
              </FormGroup>




              <div className="search-header">
                <div className="heading-section ">
                  <Row>
                    <Col
                      md={6}
                      xs={12}
                      className={
                        "hidden-xs" +
                        (scheduleTime.game_starts_in_timestamp
                          ? " p-0"
                          : " p-v-sm")
                      }
                    >
                      <React.Fragment>
                        {selectedLeague.value == 2 && <img src={Images.LOGO} width="20px" alt="" className="filter-with-logo" />}
                        {selectedLeague.label || "All Contest"}
                      </React.Fragment>
                      <span>
                        {scheduleTime.game_starts_in_timestamp &&
                          UtilityFunctions.showCountDown(scheduleTime) ? (
                            <div className="countdown time-line text-danger">
                              <CountdownTimer
                                deadlineTimeStamp={
                                  scheduleTime.game_starts_in_timestamp
                                }
                                currentDateTimeStamp={
                                  scheduleTime.current_timestamp
                                }
                              />
                            </div>
                          ) : (
                            <span />
                          )}
                      </span>
                    </Col>
                    <Col md={6} xs={12}>
                      {!(this.state.Searchkey.length == 0 && myContestData.length == 0) && <div
                        className={
                          "search-btn" + (searchClick ? " search-full" : "")
                        }
                      >
                        <FormControl
                          type="text"
                          placeholder="Search"
                          value={this.state.Searchkey}
                          onChange={e => {
                            this.setState({
                              Searchkey: e.target.value,
                              offset: 0,
                              hasMore: false
                            });
                            this.SearchUserReq();
                          }}
                        />
                        <div
                          className="search-box"
                          onClick={() =>
                            this.setState(
                              {
                                searchClick: !searchClick
                              },
                              () => {
                                if (searchClick && this.state.Searchkey.length > 0) {
                                  this.setState(
                                    {
                                      Searchkey: "",
                                      offset: 0,
                                      hasMore: false
                                    },
                                    () => {
                                      this.contestListApi();
                                    }
                                  );
                                }
                              }
                            )
                          }
                        >
                          <i
                            className={
                              searchClick ? "icon-close" : "icon-search"
                            }
                          />
                        </div>
                      </div>}
                      <span
                        className={
                          "filter-by-name" +
                          (scheduleTime.game_starts_in_timestamp ? " p-0" : "")
                        }
                      >
                        <React.Fragment>
                          {selectedLeague.value == 2 && <img src={Images.LOGO} width="20px" alt="" className="filter-with-logo" />}
                          {selectedLeague.label || "All Contests"}
                        </React.Fragment>
                      </span>
                      <span className="hidden-lg hidden-md hidden-sm">
                        {scheduleTime.game_starts_in_timestamp &&
                          UtilityFunctions.showCountDown(scheduleTime) ? (
                            <div className="countdown time-line text-danger">
                              <CountdownTimer
                                deadlineTimeStamp={
                                  scheduleTime.game_starts_in_timestamp
                                }
                                currentDateTimeStamp={
                                  scheduleTime.current_timestamp
                                }
                              />
                            </div>
                          ) : (
                            <span />
                          )}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="wrapper-spacing mycontest-wrap">
                <InfiniteScroll
                  pullDownToRefresh={false}
                  refreshFunction={() => this.handleRefresh()}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: "center", fontSize: 14 }}>{""}</h3>
                  }
                  releaseToRefreshContent={
                    <h3 style={{ textAlign: "center", fontSize: 14 }}>{""}</h3>
                  }
                  dataLength={myContestData.length}
                  next={this.fetchMoreContestData}
                  hasMore={hasMore}
                >
                  {myContestData.length > 0 &&
                    myContestData.map((item, idx) => {
                      return (
                        <div key={idx} className="collection-list-wrapper">

                          <div className={'actn-area'}>
                            <i className="icon-share" onClick={() => this.shareContest(item.contest_unique_id)} />

                          </div>
                          <div className={'wrp-body'}>
                            <div className="collection-heading">
                              <span
                                onClick={event =>
                                  this.ContestDetailShow(event, item)
                                }
                                className="contest-name"
                              >
                                {item.contest_name}
                              </span>
                              <span className="your-rank-label">
                                your rank
                              <span>{item.game_rank || "N/A"} {
                                  item.game_rank ?
                                    item.game_rank == 1 ? "st" :
                                      item.game_rank == 2 ? 'nd' :
                                        item.game_rank == 3 ? 'rd' : 'th' : ''
                                }</span>
                              </span>
                            </div>
                            <div className="prizepool">
                              Prize pool
                            <span className="prizepool-amt">
                                <i className={'icon-Rits font-12'}></i> {item.prize_pool}
                              </span>
                              {(
                                <span className="prizepool-for">
                                  Top {item.number_of_winner}
                                </span>
                              )}
                              <span className="your-rank-count">
                                {item.game_rank == 0 && <span>--</span>}
                                {item.game_rank != 0 && (
                                  <span>{item.game_rank}</span>
                                )}
                              </span>
                            </div>
                            <a
                              href
                              onClick={() => this.goOnLeaderBoard(item)}
                              className="btn btn-rounded"
                            >
                              View Standing
                          </a>
                            <ProgressBar
                              className={
                                parseInt(item.total_user_joined) <
                                  parseInt(item.minimum_size)
                                  ? "danger"
                                  : ""
                              }
                              now={globalThis.showProgressBar(
                                item.total_user_joined,
                                item.size
                              )}
                            />
                            <div className="progressbar-entries">
                              {item.total_user_joined} Joined
                          </div>
                          </div>
                          <div class="creater-info">Created by - <span>{item.user_name}</span></div>
                        </div>
                      );
                    })}
                  {myContestData.length == 0 && !isLoading && (
                    <NoDataScreen Content={{
                      ImgShown: Images.TROPHY_IC,
                      IsButton: true,
                      ButtonText: "Go to Lobby",
                      Title: "OOPS! NO DATA AVAILABLE.",
                      NoDataContent: "CHECK LATER!",
                      BtnAction: () => {
                        this.props.history.push({
                          pathname: "/dashboard/0"
                        });
                      },
                      WithFilter: '1'
                    }} />
                  )}
                </InfiniteScroll>
              </div>
              {/* <div className="page-footer" id="page-footer">
                <button onClick={() => this.props.history.push('/private-contest')} type="button" className="btn-block btn-primary btn ">
                  My Private Contest
                </button>
              </div> */}
            </div>
            {showContestDetail && (
              <ContestDetailModal
                IsContestDetailShow={showContestDetail}
                onJoinBtnClick={this.onSubmitBtnClick}
                IsContestDetailHide={this.ContestDetailHide}
                showContestDetailFor={contestInfo}
                isFrom={"myContest"}
              />
            )}
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
