import React from 'react';
import { Row, Col, Media, FormGroup, FormControl, Tab, Nav, NavItem, ProgressBar, Modal } from 'react-bootstrap';
import { MyContext } from '../context';
import { Header, SubHeader, Images, ComingSoon } from '../components';
import { Helmet } from "react-helmet";
import ContestDetailModal from '../Modals/ContestDetail';
import MetaData from "../helper/MetaData";

export default class RefSocial extends React.Component {
    constructor(props) {
        super(props);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            showContestDetail: false,
        }

    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    ContestDetailShow = () => {
        this.setState({
            showContestDetail: true,
        });
    }

    ContestDetailHide = () => {
        this.setState({
            showContestDetail: false,
        });
    }


    render() {
        const {
            showContestDetail,
            activeTab
        } = this.state;

        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container refsocial-web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.RefSocial.title}</title>
                            <meta name="description" content={MetaData.RefSocial.description} />
                            <meta name="keywords" content={MetaData.RefSocial.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} />
                        <div >
                            <Tab.Container id="left-tabs-example" defaultActiveKey="refsocial">
                                <Row>
                                    <Col sm={12}>
                                        <SubHeader Title="ReFSocial" ImgShown={Images.REFSOCIAL_HEADER_BG} BannerImage="" AddClassName="" />
                                        <Nav className="tab-navigation tab-navigation-four tab-navigation-upper">
                                            <NavItem eventKey="refpredictor">ReFPredictor</NavItem>
                                            <NavItem eventKey="contest">Contests</NavItem>
                                            <NavItem eventKey="personal">Personal</NavItem>
                                            <NavItem eventKey="refsocial">ReFSocial</NavItem>
                                        </Nav>
                                        {/* </div> */}
                                        <Tab.Content >
                                            <Tab.Pane eventKey="refpredictor">
                                                <ComingSoon />
                                                <div className="fixture-collection-wrapper hide">
                                                    <div className="card-view">
                                                        <i className="icon-share"></i>
                                                        <div className="card-view-right">
                                                            <h2>GER <span>v</span> SWE</h2>
                                                            <p>Referee: Shinji Takoma | Arena Stadium, Kyiv </p>
                                                            <div className="match-date">SUN, FEB 23</div>
                                                            <div className="match-time">
                                                                <i className="icon-clock"></i> 7.00 PM
                                                            </div>
                                                            <div className="game-closed">Game Closes 6.45 PM</div>
                                                            <img src={Images.VISSEL} alt="" className="vissel-img" />
                                                            <div className="text-right">
                                                                <a href className="play-btn">Play</a>
                                                            </div>
                                                        </div>
                                                        <div className="card-view-left">
                                                            <div className="team-section">
                                                                <img src={Images.SWEDEN} alt="" />
                                                                <div className="vs-section">
                                                                    <i className="icon-football"></i>
                                                                </div>
                                                            </div>
                                                            <div className="team-section">
                                                                <img src={Images.SWEDEN} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="contest">
                                                <ComingSoon />
                                                <div className="wrapper-spacing hide">
                                                    <div className="select-option-btn">
                                                        Select
                                                        <div className="arrow-section">
                                                            <i className="icon-arrow-sort"></i>
                                                        </div>
                                                    </div>

                                                    {/* ReFPredictor I/O section start */}
                                                    <div className="heading-section">
                                                        ReFPredictor I/O
                                                    </div>
                                                    <div className="collection-list-wrapper" >
                                                        <i className="icon-share"></i>
                                                        <div className="collection-heading">Fantasy Winners League</div>
                                                        <div className="prizepool" onClick={() => this.ContestDetailShow()}>
                                                            Prize pool
                                                            <span className="prizepool-amt">₦10,000</span>
                                                            <span className="prizepool-for">Top3</span>
                                                        </div>
                                                        <a href className="btn btn-rounded">Join ₦10</a>
                                                        <ProgressBar now={60} />
                                                        <div className="progressbar-entries">
                                                            <span>5 </span>/ 50 Entries
                                                        </div>
                                                    </div>
                                                    {/* ReFPredictor I/O section end */}

                                                    {/* Fans Challenge section start */}
                                                    <div className="heading-section">
                                                        Fans Challenge
                                                    </div>
                                                    <div className="fans-challenge-list-wrapper ">
                                                        <ul>
                                                            <li>
                                                                <img src={Images.SWEDEN} alt="" className="team-img" />
                                                                <p className="team-name">NFA</p>
                                                                <p className="joined-entries">300/500 Entries</p>
                                                                <a href className="btn btn-rounded">Join</a>
                                                            </li>
                                                            <li>
                                                                <div className="contest-type">Fans Challenge</div>
                                                                <div className="timer">20 : 59 : 00</div>
                                                            </li>
                                                            <li>
                                                                <img src={Images.SWEDEN} alt="" className="team-img" />
                                                                <p className="team-name">NFA</p>
                                                                <p className="joined-entries">300/500 Entries</p>
                                                                <a href className="btn btn-rounded" onClick={this.handleShow}>Join</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* Fans Challenge section end */}
                                                </div>

                                                {/* Fans Challenge section select palyer start */}
                                                <div className="heading-section-alt hide">
                                                    Fans Challenge
                                                </div>
                                                <div className="fan-challenge-team-selection hide">
                                                    <div className="fans-challenge-list-wrapper">
                                                        <span className="comment-span">
                                                            <i className="icon-comment"></i>
                                                        </span>
                                                        <ul>
                                                            <li>
                                                                <img src={Images.SWEDEN} alt="" className="team-img" />
                                                                <p className="team-name">NFA</p>
                                                                <p className="joined-entries">300/500 Entries</p>
                                                                <a href className="btn btn-rounded">
                                                                    <i className="icon-tick"></i> Joined
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <div className="contest-type">Fans <br /> Challenge</div>
                                                                <div className="timer">20 : 59 : 00</div>
                                                            </li>
                                                            <li>
                                                                <img src={Images.SWEDEN} alt="" className="team-img" />
                                                                <p className="team-name">NFA</p>
                                                                <p className="joined-entries">300/500 Entries</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="select-player-section">
                                                        <table className="select-player-list">
                                                            <thead>
                                                                <tr>
                                                                    <th>Username</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                        <span>Mark.Zucker</span>
                                                                        <i className="icon-add-user"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                        <span>Mark.Zucker</span>
                                                                        <i className="icon-add-user"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                        <span>Mark.Zucker</span>
                                                                        <i className="icon-add-user"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                        <span>Mark.Zucker</span>
                                                                        <i className="icon-add-user"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                        <span>Mark.Zucker</span>
                                                                        <i className="icon-add-user"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                        <span>Mark.Zucker</span>
                                                                        <i className="icon-add-user"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                        <span>Mark.Zucker</span>
                                                                        <i className="icon-add-user"></i>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                        <span>Mark.Zucker</span>
                                                                        <i className="icon-add-user"></i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table className="select-player-list select-player-list-right">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-right">Username</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        <i className="icon-add-user"></i>
                                                                        <span>Mark.Zucker</span>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        <i className="icon-add-user"></i>
                                                                        <span>Mark.Zucker</span>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        <i className="icon-add-user"></i>
                                                                        <span>Mark.Zucker</span>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        <i className="icon-add-user"></i>
                                                                        <span>Mark.Zucker</span>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        <i className="icon-add-user"></i>
                                                                        <span>Mark.Zucker</span>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        <i className="icon-add-user"></i>
                                                                        <span>Mark.Zucker</span>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-right">
                                                                        <i className="icon-add-user"></i>
                                                                        <span>Mark.Zucker</span>
                                                                        <img src={Images.USERIMG} alt="" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {/* Fans Challenge section select palyer end */}
                                                <table className="select-player-list last-fan-joined-section hide">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p className="timer">18:10:08</p>
                                                                Last fan joined
                                                            </td>
                                                            <td>
                                                                <p className="timer">18:10:08</p>
                                                                Last fan joined
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="personal">
                                                <ComingSoon />
                                                <div className="wrapper-spacing hide">
                                                    <div className="select-option-btn">
                                                        Select
                                                        <div className="arrow-section">
                                                            <i className="icon-arrow-sort"></i>
                                                        </div>
                                                    </div>
                                                    <div className="heading-section">
                                                        Fans Challenge
                                                    </div>
                                                    <div className="collection-list-wrapper">
                                                        <i className="icon-share"></i>
                                                        <div className="collection-heading">Fantasy Winners League</div>
                                                        <div className="prizepool">
                                                            Practice Contest
                                                        </div>
                                                        <a href className="btn btn-rounded">Join ₦10</a>
                                                        <ProgressBar now={60} />
                                                        <div className="progressbar-entries">
                                                            <span>5 </span>/ 50 Entries
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="refsocial">
                                                <ComingSoon />
                                                <div className="refsocial-sub-header hide">
                                                    <div>
                                                        <i className="icon-logo" style={{ fontSize: 24 }}></i>
                                                    </div>
                                                    <div>
                                                        <i className="icon-search"></i>
                                                    </div>
                                                    <div>
                                                        <i className="icon-edit-pen"></i>
                                                    </div>
                                                </div>
                                                <div className="your-post-section hide">
                                                    <div className="post-card">
                                                        <div className="post-card-header">
                                                            <div className="postuser-detail">
                                                                <img src={Images.USERIMG} alt="" />
                                                                <div className="user-name">Mark.Zucker<span className="user-tag">Posted</span></div>
                                                                <div className="post-time">1 min ago</div>
                                                                <i className="icon-user-select"></i>
                                                                <i className="icon-share"></i>
                                                            </div>
                                                        </div>
                                                        <div className="post-card-body">
                                                            <div className="post-description">
                                                                Derby county on brink of Deal for 2018 AFCON-Winning Defender, Lampard Reveals :: All Nigeria Soccer - The complete Nigerian Football Portal
                                                            </div>
                                                            <div className="posted-img">
                                                                <img src={Images.BLOG_IMG3} alt="" />
                                                            </div>
                                                            <div className="blog-action post-action-section">
                                                                <Row>
                                                                    <Col xs={3}>
                                                                        <span className="user-fav liked"><i className="icon-like"></i></span>
                                                                        <span className="txt">546</span>
                                                                    </Col>
                                                                    <Col xs={3} className="text-center">
                                                                        <span className="action-count">
                                                                            <i className="icon-reload"></i>
                                                                            <span className="txt">0</span>
                                                                        </span>
                                                                    </Col>
                                                                    <Col xs={3} className="text-center">
                                                                        <span className="action-count">
                                                                            <i className="icon-comment"></i>
                                                                            <span className="txt">0</span>
                                                                        </span>
                                                                    </Col>
                                                                    <Col xs={3} className="text-center">
                                                                        <span className="action-count">
                                                                            <i className="icon-dislike"></i>
                                                                            <span className="txt">0</span>
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                        <div className="post-card-footer enter-comment-section">
                                                            <Media className="incoming-msg msg-section">
                                                                <img src={Images.DEFAULT_USER} alt="sender-img" className="user-img" />
                                                                <Media.Body>
                                                                    <div className="type-comment-area">
                                                                        <form>
                                                                            <FormGroup controlId="formInlineName">
                                                                                <FormControl type="text" placeholder="Jane Doe" />
                                                                                <i className="icon-send"></i>
                                                                            </FormGroup>
                                                                        </form>
                                                                    </div>
                                                                </Media.Body>
                                                            </Media>
                                                        </div>
                                                    </div>

                                                    <div className="post-card">
                                                        <div className="post-card-header">
                                                            <div className="postuser-detail">
                                                                <img src={Images.USERIMG2} alt="" />
                                                                <div className="user-name">Mark.Zucker<span className="user-tag">Posted</span></div>
                                                                <div className="post-time">1 min ago</div>
                                                                <i className="icon-user-select"></i>
                                                                <i className="icon-share"></i>
                                                            </div>
                                                        </div>
                                                        <div className="post-card-body">
                                                            <div className="post-description">
                                                                Derby county on brink of Deal for 2018 AFCON-Winning Defender, Lampard Reveals :: All Nigeria Soccer - The complete Nigerian Football Portal
                                                            </div>
                                                            <div className="post-tag">
                                                                <span>#Unofficial</span>
                                                                <span>#BR vs DD</span>
                                                            </div>
                                                            <div className="blog-action post-action-section">
                                                                <Row>
                                                                    <Col xs={3}>
                                                                        <span className="user-fav"><i className="icon-like"></i></span>
                                                                        <span className="txt">546</span>
                                                                    </Col>
                                                                    <Col xs={3} className="text-center">
                                                                        <span className="action-count">
                                                                            <i className="icon-reload"></i>
                                                                            <span className="txt">0</span>
                                                                        </span>
                                                                    </Col>
                                                                    <Col xs={3} className="text-center">
                                                                        <span className="action-count">
                                                                            <i className="icon-comment"></i>
                                                                            <span className="txt">0</span>
                                                                        </span>
                                                                    </Col>
                                                                    <Col xs={3} className="text-center">
                                                                        <span className="action-count">
                                                                            <i className="icon-dislike"></i>
                                                                            <span className="txt">0</span>
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                        <div className="post-card-footer enter-comment-section">
                                                            <Media className="incoming-msg msg-section">
                                                                <img src={Images.USERIMG} alt="sender-img" className="user-img" />
                                                                <Media.Body>
                                                                    <div className="type-comment-area">
                                                                        <form>
                                                                            <FormGroup controlId="formInlineName">
                                                                                <FormControl type="text" placeholder="Jane Doe" />
                                                                                <i className="icon-send"></i>
                                                                            </FormGroup>
                                                                        </form>
                                                                    </div>
                                                                </Media.Body>
                                                            </Media>
                                                        </div>
                                                    </div>

                                                    <div className="post-card repost-card">
                                                        <div className="post-card-header repost-card-header">
                                                            <div className="postuser-detail">
                                                                <img src={Images.USERIMG3} alt="" />
                                                                <div className="user-name">Sara Jackson<span className="user-tag">Posted</span></div>
                                                                <div className="post-time">1 min ago</div>
                                                                <i className="icon-dots"></i>
                                                            </div>
                                                        </div>
                                                        <div className="post-card-body">
                                                            <div className="post-card">
                                                                <div className="post-card-header">
                                                                    <div className="postuser-detail">
                                                                        <img src={Images.USERIMG2} alt="" />
                                                                        <div className="user-name">Anna Zales<span className="user-tag">Posted</span></div>
                                                                        <div className="post-time">1 min ago</div>
                                                                        <i className="icon-add-user"></i>
                                                                        <i className="icon-share"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="post-card-body">
                                                                    <div className="post-description">
                                                                        Derby county on brink of Deal for 2018 AFCON-Winning Defender, Lampard Reveals :: All Nigeria Soccer - The complete Nigerian Football Portal
                                                                    </div>
                                                                    <div className="posted-img">
                                                                        <img src={Images.BLOG_IMG} alt="" />
                                                                    </div>
                                                                    <div className="blog-action post-action-section">
                                                                        <Row>
                                                                            <Col xs={3}>
                                                                                <span className="user-fav liked"><i className="icon-like"></i></span>
                                                                                <span className="txt">546</span>
                                                                            </Col>
                                                                            <Col xs={3} className="text-center">
                                                                                <span className="action-count">
                                                                                    <i className="icon-reload"></i>
                                                                                    <span className="txt">0</span>
                                                                                </span>
                                                                            </Col>
                                                                            <Col xs={3} className="text-center">
                                                                                <span className="action-count">
                                                                                    <i className="icon-comment"></i>
                                                                                    <span className="txt">0</span>
                                                                                </span>
                                                                            </Col>
                                                                            <Col xs={3} className="text-center">
                                                                                <span className="action-count">
                                                                                    <i className="icon-dislike"></i>
                                                                                    <span className="txt">0</span>
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                                <div className="post-card-footer enter-comment-section">
                                                                    <Media className="incoming-msg msg-section">
                                                                        <img src={Images.USERIMG} alt="sender-img" className="user-img" />
                                                                        <Media.Body>
                                                                            <div className="type-comment-area">
                                                                                <form>
                                                                                    <FormGroup controlId="formInlineName">
                                                                                        <FormControl type="text" placeholder="Jane Doe" />
                                                                                        <i className="icon-send"></i>
                                                                                    </FormGroup>
                                                                                </form>
                                                                            </div>
                                                                        </Media.Body>
                                                                    </Media>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </Col>
                                </Row>

                            </Tab.Container>
                            {showContestDetail &&
                                <ContestDetailModal IsContestDetailShow={showContestDetail} onJoinBtnClick={this.onSubmitBtnClick} IsContestDetailHide={this.ContestDetailHide} activeTabIndex={activeTab} />
                            }

                            <Modal show={this.state.show} onHide={this.handleClose} className="joined-modal">
                                <Modal.Body>
                                    <div className="text-center">
                                        <img src={Images.THUMBSUP} alt="" className="thumbs-up-img" />
                                        <div className="name">BRAVO!</div>
                                        <img src={Images.SWEDEN} alt="" className="team-img" />
                                        <p className="joined-txt">You joined the Fans Challenge <br /> as NFA fan</p>
                                        <a href className="btn btn-rounded btn-block btn-xlg">Proceed</a>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
