import React from 'react';
import { Modal, Button } from "react-bootstrap";
import { MyContext } from "../context";
import { notify } from "react-notify-toast";

import * as Constants from "../helper/Constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import config from '../Config';
import WSManager from "../helper/WSManager";

import {
    WhatsappShareButton, FacebookShareButton, TwitterShareButton
} from "react-share";

class ContestShareModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_profile: WSManager.getProfile()
        }
    }


    onCopyCode = () => {
        notify.show(Constants.Messages.code_copy, "success", 1000);
        this.setState({ copied: true });
    };



    render() {

        const { isOpen, onHide, code, title, link } = this.props;

        return (
            <React.Fragment>
                <MyContext.Consumer>
                    {context => (
                        <Modal
                            show={isOpen}
                            onHide={onHide}
                            bsSize="small"
                            className="center-modal"
                            dialogClassName="share-contest-modal"
                        >


                            <Modal.Body>
                                <h5>Invite Your Friends Via</h5>
                                <ul className="social-icons">
                                    <li>
                                        <CopyToClipboard
                                            onCopy={this.onCopyCode}
                                            text={`${link ? link: `${config.MyUrl}/contest/detail/${code}`}`}
                                        >
                                            <div className="social-circle icon-link"></div>
                                        </CopyToClipboard>
                                        <label>Linking</label>


                                    </li>
                                    <li>
                                        <FacebookShareButton
                                            url={`${config.MyUrl}`}
                                            quote={title}>
                                            <div className="social-circle icon-facebook"></div>
                                        </FacebookShareButton>
                                        <label>Facebook</label>
                                    </li>
                                    <li>
                                        <WhatsappShareButton
                                            url={' '}
                                            title={title}
                                        >
                                            <div className="social-circle icon-whatsapp" ></div>
                                        </WhatsappShareButton>

                                        <label>Whatsapp</label>
                                    </li>
                                    <li>
                                        <TwitterShareButton
                                            url={`${config.MyUrl}`}
                                            title={title}
                                        >
                                            <div className="social-circle icon-twitter" ></div>
                                        </TwitterShareButton>

                                        <label>Twitter</label>
                                    </li>
                                </ul>


                                {/* <div className={'share-box'}>
                                    <CopyToClipboard
                                        onCopy={this.onCopyCode}
                                        text={code}
                                    >
                                        <span className={'copy-c'}>Copy</span>
                                    </CopyToClipboard>
                                    <div className={'t-bdy'}>
                                        <div className={'lab'}>Share contest code</div>
                                        <div className={'code'}>{code}</div>
                                    </div>
                                    <div className={'t-foot'}>Tell your frieds to enter this code to join this contest</div>
                                </div> */}
                            </Modal.Body>
                        </Modal>
                    )}
                </MyContext.Consumer>
            </React.Fragment>
        )
    }
}

export default ContestShareModal;