import React from "react";
import { Row, Col, FormGroup, ControlLabel, FormControl, Media } from "react-bootstrap";
import { Header, Images, SubHeader } from "../components";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";

export default class BlogDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container ">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.Blog.title}</title>
              <meta name="description" content={MetaData.Blog.description} />
              <meta name="keywords" content={MetaData.Blog.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Blogs" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                  {/* Blog Section start */}
                  <div className="blog-detail-section">
                    <div className="blog-detail">
                      <div className="blog-header">
                        <img src={Images.BLOG_IMG3} alt="" />
                      </div>
                      <div className="blog-body">
                        <div className="blog-heading">
                          All six Miami Hurricanes at the Soccer Scouting
                          Combine
                        </div>
                        <div className="blog-time">
                          Oct 12 - 8:00 pm
                          <span className="blog-by">
                            By <span>Lester Thomas</span>
                          </span>
                        </div>
                        <div className="blog-action">
                          <Row>
                            <Col xs={6}>
                              <span className="user-fav liked">
                                <i className="icon-like" />
                              </span>
                              <span className="txt">546</span>
                              <span className="pull-right mt-1">
                                <i className="icon-comment" />
                                <span className="txt">0</span>
                              </span>
                            </Col>
                            <Col xs={6} className="blog-social-ic text-right">
                              <span className="circle-social-ic">
                                <i className="icon-facebook-2" />
                              </span>
                              <span className="circle-social-ic">
                                <i className="icon-twitter-2" />
                              </span>
                              <span className="circle-social-ic">
                                <i className="icon-dots" />
                              </span>
                            </Col>
                          </Row>
                        </div>
                        <div className="blog-description">
                          <p>
                            Soccer Draft analyst Mel Kiper Jr. of ESPN indicated
                            Tuesday during a national teleconference that
                            there’s a good chance all six Miami Hurricanes who
                            were invited to next week’s Scouting Combine (Feb.
                            26 through March 4) in Indianapolis will be drafted.
                            And he believes two of them could be taken in the
                            first two rounds.
                          </p>
                          <p>
                            When Kiper was asked by the Miami Herald who among
                            the six UM players — defensive tackle Gerald Willis
                            III, defensive end Joe Jackson, cornerback Michael
                            Jackson, running back Travis Homer and safeties
                            Jaquan Johnson and Sheldrick Redwine — does he
                            believe will be drafted, and when they will be
                            picked, here was his response:
                          </p>
                          <p>
                            Soccer Draft analyst Mel Kiper Jr. of ESPN indicated
                            Tuesday during a national teleconference that
                            there’s a good chance all six Miami Hurricanes who
                            were invited to next week’s Scouting Combine (Feb.
                            26 through March 4) in Indianapolis will be drafted.
                            And he believes two of them could be taken in the
                            first two rounds.
                          </p>
                          <p>
                            {" "}
                            When Kiper was asked by the Miami Herald who among
                            the six UM players — defensive tackle Gerald Willis
                            III, defensive end Joe Jackson, cornerback Michael
                            Jackson, running back Travis Homer and safeties
                            Jaquan Johnson and Sheldrick Redwine — does he
                            believe will be drafted, and when they will be
                            picked, here was his response:
                          </p>
                        </div>
                      </div>
                      <div className="blog-comment">
                        <h2>Write a Comment</h2>
                        <form>
                          <FormGroup controlId="formInlineName">
                            <ControlLabel>Add Comment</ControlLabel>
                            <FormControl type="text" placeholder="Jane Doe" />
                          </FormGroup>
                        </form>
                        <div className="text-right comment-action-btn">
                          <a href className="btn btn-link ">
                            Cancel
                          </a>
                          <a href className="btn btn-rounded ">
                            Post
                          </a>
                        </div>
                      </div>
                      <div className="particular-blog-comments">
                        <Media className="msg-section">
                          <Media.Left>
                            <img
                              src={Images.DEFAULT_USER}
                              alt="thumbnail"
                              className="user-img"
                            />
                          </Media.Left>
                          <Media.Body>
                            <div className="comment-txt">
                              <div className="comment-by">Joyce Williams</div>
                              Louis; Can you tell me what a clean escalation
                              <div className="comment-time">July 18, 2017</div>
                            </div>
                          </Media.Body>
                        </Media>
                        <Media className="msg-section">
                          <Media.Left>
                            <img
                              src={Images.DEFAULT_USER}
                              alt="thumbnail"
                              className="user-img"
                            />
                          </Media.Left>
                          <Media.Body>
                            <div className="comment-txt">
                              <div className="comment-by">Joyce Williams</div>
                              Louis; Can you tell me what a clean escalation
                              <div className="comment-time">July 18, 2017</div>
                            </div>
                          </Media.Body>
                        </Media>
                      </div>
                      <div className="similar-blogs-section">
                        <div className="heading">Similar Blogs</div>
                        <div className="similar-blog-wrap">
                          <div className="left-section">
                            <img src={Images.BLOG_IMG2} alt="" />
                          </div>
                          <div className="right-section">
                            <div
                              className="txt"
                              style={{ WebkitBoxOrient: "vertical" }}
                            >
                              Haitian police arrest five Americans who claimed
                              they were on a ‘government Haitian police arrest
                              five Americans who claimed they were on a
                              ‘government
                            </div>
                            <div className="date-time">Oct 12 - 8:00 pm</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Blog section end */}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
