import React from 'react';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { MyContext } from '../context';
import { Header, SubHeader, Images, ComingSoon } from '../components';
import { Helmet } from "react-helmet";
import Select from 'react-select';
import MetaData from "../helper/MetaData";

const options = [
    { value: 'optoion1', label: 'optoion1' },
    { value: 'optoion2', label: 'optoion2' },
    { value: 'optoion3', label: 'optoion3' }
];

export default class RefPodcast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.RefPodcast.title}</title>
                            <meta name="description" content={MetaData.RefPodcast.description} />
                            <meta name="keywords" content={MetaData.RefPodcast.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} />
                        <div >
                            <Row>
                                <Col sm={12}>
                                    <SubHeader Title="ReFPodcast" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                                    <ComingSoon />
                                    <div className="sorting-section p-h-15 hide">
                                        <Row>
                                            <Col xs={12} className="sort-by-section">
                                                Sort by
                                                <FormGroup className='input-label-center input-transparent font-14 mb-0'>
                                                    <div className="sortby">
                                                        <Select
                                                            placeholder="Most Popular"
                                                            options={options}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="refpodcast-section hide">
                                        <div className="card-playlist">
                                            <img src={Images.SINGER_IMG} alt="" />
                                            <div className="music-name">La Vida Tombola</div>
                                            <div className="singer-name">by Manu Chao</div>
                                            <img src={Images.PLAY_BUTTON} alt="" className="audio-action" />
                                            <div>
                                                <a href className="download"><i className="icon-left-arrow"></i> Download</a>
                                            </div>
                                        </div>
                                        <div className="card-playlist highlight-card-playlist">
                                            <img src={Images.SINGER_IMG} alt="" />
                                            <div className="music-name">La Vida Tombola</div>
                                            <div className="singer-name">by Manu Chao</div>
                                            <img src={Images.PAUSE} alt="" className="audio-action" />
                                            <div>
                                                <a href className="download"><i className="icon-left-arrow"></i> Download</a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
