import React from "react";
import { Row, Col } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images, SubHeader } from "../components";
import MetaData from "../helper/MetaData";
import PerformanceComponent from "./PerformanceComponent";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import Slider from "react-slick";

export default class Performance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageBanner: []
    };
  }


  componentDidMount() {
    this.getPageBanner()
  }

  getPageBanner = () => {

    let param = {
      page_type_id: Constants.BANNER_PAGE_TYPE_ID.myPerformance,
      position_id: "1"
    }
    WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }

  render() {

    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    const { pageBanner } = this.state;

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container  white-bg performance-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.Performance.title}</title>
              <meta
                name="description"
                content={MetaData.Performance.description}
              />
              <meta name="keywords" content={MetaData.Performance.keywords} />
            </Helmet>
            <Header {...this.props} HeaderOption={HeaderOption} />

            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Performance" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="single-title-header" />
                </Col>
              </Row>

              {
                (pageBanner && pageBanner.length > 0) &&
                <div className={'lb-ban-wrap'}>
                  <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                    {pageBanner.map((itm, i) => {
                      return <div key={i} className={'lb-banner'}>
                        <img src={itm.image} />
                      </div>
                    })}
                  </Slider>
                </div>
              }

              <PerformanceComponent history={this.props.history} />
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
