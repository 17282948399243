import React from 'react';
import { Loader } from "../components";
import * as NC from "../helper/NetworkingConstants";
import WSManager from "../helper/WSManager";
import Config from '../Config';

const queryString = require('query-string');
class Authenticate extends React.Component {
    constructor(params) {
        super(params);
        this.state = {
        };
    }


    componentDidMount() {
        let location = this.props.location;
        const parsed = queryString.parse(location.search);
        if (parsed.token && Object.hasOwnProperty.bind(parsed)('token')) {
            WSManager.logout();
            WSManager.setToken(parsed.token);

            if(Object.hasOwnProperty.bind(parsed)('topage') && parsed.topage){
                window.location.assign(`/${parsed.topage}`);
            }
            else{
                window.location.assign("/dashboard/0");
            }

            // window.location.assign("/dashboard/0");
        }
        else{
            window.location.href = Config.RefpredictoUrl
        }


    }
    render() {
        return (
            <React.Fragment>
                {/* <Loader /> */}
            </React.Fragment>
        )
    }
}

export default Authenticate;