import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import { Images, Header, Loader, VideoPlayer } from "../components";
import MetaData from "../helper/MetaData";
import * as Constants from "../helper/Constants";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import Countdown from 'react-countdown-now';

var stCountdown = null;
export default class VideoStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ContestData: !_.isUndefined(props.location.state)
        ? props.location.state.ContestData
        : "",
      QuestionList: !_.isUndefined(props.location.state)
        ? props.location.state.QuestionList
        : "",
      SponserVideo: !_.isUndefined(props.location.state)
        ? props.location.state.SponserVideo
        : "",
      isVideoEnded: false,
      isLoading: false,
      user_profile: WSManager.getProfile(),
      AddCountdownTime: '',
      CntdwnComplete: false,
      secsLeft: 7,
      isVideoPaused:true
    };
  }
  videoEndCallBack = () => {
    if (this.state.isVideoEnded == false) {
      this.setState({ isVideoEnded: true });
      this.updateVideoStatus();
    }
  };

  updateVideoStatus() {
    const { ContestData, SponserVideo } = this.state;

    let param = {
      sponsor_activity_unique_id: SponserVideo.sponsor_activity_unique_id,
      activity_type: SponserVideo.activity_type,
      fixture_id: ContestData.fixture_id
    };

    WSManager.Rest(NC.baseURL + NC.SAVE_SPONSOR_ACTIVITY, param).then(
      responseJson => {
        if (responseJson && responseJson.response_code === NC.successCode) {
          this.setState({ isVideoEnded: true });
        }
      }
    );
  }

  submitPrediction() {
    this.setState({ isLoading: true });
    const { ContestData, QuestionList } = this.state;

    let param = {
      league_id: "2",
      sports_id: 5,
      fixture_id: ContestData.fixture_id,
      lineup: QuestionList
    };

    WSManager.Rest(NC.baseURL + NC.SUBMIT_PREDICTION, param).then(
      responseJson => {
        this.setState({ isLoading: false });
        if (responseJson.response_code === NC.successCode) {
          Constants.SetValues.predicted_ans([]);
          notify.show(responseJson.message || Constants.Messages.predct_success, "success", 3000);
          Header.showSubmitModal(Constants.Messages.predct_submission, "prediction");
        }
      }
    );
  }

  showScoringRules() {
    Header.showScoringModal();
  }

  componentDidMount() {
  }

  videoStartCallback = () => {
    this.setState({isVideoPaused:false})
    if(!this.state.CntdwnComplete){
      this.setState({ AddCountdownTime: Date.now() + (this.state.secsLeft * 1000) })
      stCountdown =  setInterval(() => this.setState({secsLeft : this.state.secsLeft - 1}), 1000)
    }
    
  }

  videPauseCallback = () => {
    this.setState({isVideoPaused:true})
    if(!this.state.CntdwnComplete){
      clearInterval(stCountdown);
    }
  }

  countdownComplete = () => {
    this.setState({ CntdwnComplete: true, AddCountdownTime: '' })
    clearInterval(stCountdown);
  }

  render() {
    const { SponserVideo, isVideoEnded, isLoading, ContestData, user_profile, 
      AddCountdownTime, CntdwnComplete, secsLeft, isVideoPaused } = this.state;

    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };

    const renderer = ({ hours, minutes, seconds }) => {
      // Render a countdown
      return <span>{seconds}</span>;

    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container video-step">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.Prediction.title}</title>
              <meta name="description" content={MetaData.Prediction.description} />
              <meta name="keywords" content={MetaData.Prediction.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            {isLoading && <Loader />}
            <div>
              <Row>
                <Col sm={12}>
                  <div className="page-inner-header page-inner-header-sm ">
                    <h2>
                      {ContestData.match_data.home} v{" "}
                      {ContestData.match_data.away}
                    </h2>
                    <div className="match-date">
                      {ContestData.game_date}, {ContestData.game_time}
                    </div>
                    <div>
                      <a href
                        onClick={() => this.showScoringRules()}
                      >Scoring Rules</a>
                    </div>
                    <img src={Images.HEADER_BG} alt="" className="header-bg" />
                  </div>
                  <div className="video-section-header">
                    <h2>Almost done!</h2>
                    <p>Watch the video & submit your prediction.</p>
                    <div className="show-step">
                      <span>
                        <i className="icon-tick" />
                      </span>
                      <span>2</span>
                    </div>
                  </div>

                  {/* <Countdown
                      date={AddCountdownTime}
                      renderer={renderer} 
                      onComplete={() => this.setState({CntdwnComplete:true})}
                      /> */}
                  <div className="video-body">
                    {
                      Constants.PAID_PLANS_ID.includes(user_profile.plan_id) ?
                        user_profile.plan_id == 3 ||
                          (
                            // (
                            //   user_profile.plan_id == 2 ||
                            //   user_profile.plan_id == 4 ||
                            //   user_profile.plan_id == 5 ||
                            //   user_profile.plan_id == 6
                            // ) && 
                            CntdwnComplete
                          )
                          ?
                          <a
                            href
                            onClick={() => this.submitPrediction()}
                            className="skip-ad"
                          >Skip Ad <i className="icon-arrow-right" /></a>
                          :
                          AddCountdownTime && !isVideoPaused ?
                            <a
                              href
                              className="skip-ad"
                            >Skip Ad in :<Countdown
                                date={AddCountdownTime}
                                renderer={renderer}
                                onComplete={this.countdownComplete}
                                pause={true}
                              /></a>
                            : 
                            isVideoPaused && !CntdwnComplete?
                            <a
                              href
                              className="skip-ad"
                            >Skip Ad in :0{secsLeft}</a>
                            :''

                        : ''


                    }
                    {/* {
                      // (user_profile.plan_id == 2 || user_profile.plan_id == 3) 
                      Constants.PAID_PLANS_ID.includes(user_profile.plan_id) && <a
                        href
                        onClick={() => this.submitPrediction()}
                        className="skip-ad"
                      >
                        Skip Ad <i className="icon-arrow-right" />
                      </a>} */}
                    <VideoPlayer
                      videoEndCallBack={this.videoEndCallBack}
                      videoStartCallback={this.videoStartCallback}
                      videPauseCallback={this.videPauseCallback}
                      playerType={
                        SponserVideo.youtube_id != "" ? "Youtube" : SponserVideo.vimeo_id != '' ? "Vimeo" : ''
                      }
                      playlist={[
                        {
                          file: SponserVideo.video_url,
                          image: SponserVideo.image,
                          videoID:
                            SponserVideo.youtube_id != ""
                              ? SponserVideo.youtube_id
                              : SponserVideo.vimeo_id
                        }
                      ]}
                    />
                  </div>
                  <div className="text-center">
                    <Button
                      onClick={() => this.submitPrediction()}
                      disabled={!isVideoEnded}
                      className="btn btn-rounded btn-primary btn-lg"
                    >
                      submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
