import React from 'react';
import { MyContext } from "../context";
import { Header, SubHeader, Loader, Images } from "../components";
import { Row, Col, ProgressBar, FormGroup } from "react-bootstrap";
import * as NC from "../helper/NetworkingConstants";
import WSManager from "../helper/WSManager";
import ContestDetailModal from "../Modals/ContestDetail";
import * as Constants from "../helper/Constants";
import { notify } from "react-notify-toast";
import util from "util";



var globalThis = null;

const ContestRenderer = ({ item, myContext, roundPredictionCount }) => {
    return (
        <div className="collection-list-wrapper">

            <div className={'wrp-body'}>

                <span
                    onClick={event =>
                        globalThis.ContestDetailShow(event, item, roundPredictionCount)
                    }
                    className="collection-heading cursor-pointer"
                >
                    {item.contest_name}
                </span>
                <div className="prizepool">
                    Prize pool <span className="prizepool-amt"><i className={'icon-Rits font-12'}></i> {item.prize_pool}</span>
                    {<span className="prizepool-for">Top {item.number_of_winner}</span>}
                </div>
                <a

                    className="btn btn-rounded"
                    onClick={event =>
                        globalThis.joinGame(
                            item.contest_id,
                            item.round_id,
                            item.entry_fee,
                            item.contest_name,
                            roundPredictionCount,
                            event
                        )
                    }
                >
                    Join <i className={'icon-Rits font-12'}></i> {item.entry_fee}
                </a>
                <ProgressBar
                    className={
                        parseInt(item.total_user_joined) < parseInt(item.minimum_size)
                            ? "danger"
                            : ""
                    }
                    now={globalThis.showProgressBar(item.total_user_joined, item.size)}
                />
                <div
                    className={
                        "progressbar-entries " +
                        (parseInt(item.total_user_joined) < parseInt(item.minimum_size)
                            ? "danger"
                            : "")
                    }
                >
                    <span>{item.total_user_joined} </span>/
          {item.size == null ? (
                        <i className="icon-infinite" />
                    ) : (
                            item.size + " Entries"
                        )}
                </div>
            </div>
            <div class="creater-info">Created by - <span>{item.user_name}</span></div>

        </div>
    );
};


class ContestDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            predictionCount: '',
            contest_detail: null,
            showContestDetail: false,
            contestInfo: ""
        }
    }


    ContestDetailShow = (event, item) => {
        event.stopPropagation();
        this.setState({
            showContestDetail: true,
            contestInfo: item
        });
    };

    getContestDetail = () => {
        let location = this.props.match.params;
        let param = {
            contest_unique_id: location.contest_unique_id
        }

        WSManager.Rest(NC.baseURL + NC.GET_CONTEST_DETAIL, param)
            .then(res => {
                if (res.response_code === NC.successCode) {
                    this.setState({
                        contest_detail: res.data.contest_detail,
                        predictionCount: res.data.current_round_prediction_count
                    })
                } else {
                    this.props.history.push('/')
                }
            })

    }

    componentDidMount() {
        globalThis = this;
        this.getContestDetail()
    }



    showProgressBar = (join, total) => {
        return (join * 100) / total;
    };

    joinGame = (
        contestid,
        round_id,
        entryFee,
        contestname,
        roundPredictionCount,
        event
    ) => {
        event.stopPropagation();
        this.setState({
            round_id: round_id
        });
        let EntryFee = parseFloat(entryFee);
        if (WSManager.isProfileCompleted()) {

            console.log(roundPredictionCount, 'roundPredictionCount')
            if (roundPredictionCount != 0) {
                if (
                    parseFloat(Constants.userBalanceInfo.user_balance.real_amount) <
                    EntryFee
                ) {
                    if (WSManager.getProfile().plan_id == 1) {
                        notify.show(util.format(Constants.Messages.less_fund, 'join the contest'), "error", 4000);
                    } else {
                        notify.show(util.format(Constants.Messages.less_fund, 'join the contest'), "error", 4000);
                    }
                    WSManager.goToAppUrl('refpay', `topage=add-funds`)
                    // this.props.history.push("/add-funds");
                } else {
                    if (
                        WSManager.getProfile().plan_id != 1
                    ) {
                        Header.showConfirmationModal(
                            contestid,
                            "contest",
                            this.countinueAsFree.bind(this),
                            EntryFee,
                            contestname
                        );
                    } else {
                        Header.showMembershipModal(
                            contestid,
                            "contest",
                            this.countinueAsFree.bind(this),
                            EntryFee
                        );
                    }
                }
            } else {
                notify.show(Constants.Messages.pred_count, "error", 2000);
            }
        } else {
            notify.show('Please Complete Your profile.', "error", 2000);
            // Header.CompleteProfileShow();
        }
    };
    ContestDetailHide = () => {
        this.setState({
            showContestDetail: false
        });
    };

    countinueAsFree(data, action_type) {
        if (action_type == "fixture") {
            this.goOnPrediction(data);
        }
        if (action_type == "contest") {
            this.callJoinGameApi(data);
        }
    }

    goOnPrediction = item => {
        this.props.history.push({
            pathname: "/prediction/" + item.fixture_unique_id,
            state: { ContestData: item }
        });
    };

    callJoinGameApi = contestid => {
        let param = {
          contest_id: contestid
        };
        this.setState({ isLoading: true });
        WSManager.Rest(NC.baseURL + NC.CONTEST_JOIN_GAME, param)
          .then(responseJson => {
            this.setState({
              isLoading: false
            });
            if (responseJson.response_code === NC.successCode) {
              Header.showSubmitModal(responseJson.message, "contest");
            }
          })
          .catch(error => {
            this.setState({ hasMoreFixture: false });
          });
      };

    render() {

        const { predictionCount, contest_detail, showContestDetail, contestInfo } = this.state;
        const HeaderOption = {
            back: true,
            Notification: false,
            Logo: true,
            backLink:'/'
        };


        return (
            <MyContext.Consumer>
                {context => (
                    <React.Fragment>

                        <div className="web-container edit-contest-container">
                            <Header {...this.props} HeaderOption={HeaderOption} showNavigation={false} />

                            <Row>
                                <Col sm={12}>
                                    <SubHeader
                                        Title="Contest Detail"
                                        ImgShown={Images.HEADER_BG}
                                        BannerImage=""
                                        AddClassName="page-inner-header-sm"
                                    />
                                </Col>
                            </Row>

                            <div className={'cont-detl-area'}>
                                {
                                    contest_detail && <ContestRenderer
                                        item={contest_detail}
                                        myContext={this}
                                        roundPredictionCount={predictionCount}
                                    />
                                }
                            </div>



                            {showContestDetail && (
                                <ContestDetailModal
                                    roundPredictionCount={predictionCount}
                                    history={this.props.history}
                                    IsContestDetailShow={showContestDetail}
                                    onJoinBtnClick={this.joinGame.bind(this)}
                                    IsContestDetailHide={this.ContestDetailHide}
                                    showContestDetailFor={contestInfo}
                                />
                            )}


                        </div>
                    </React.Fragment>
                )}
            </MyContext.Consumer>
        )
    }
}

export default ContestDetail;