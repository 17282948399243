import React from "react";
import { Row, Col, Media } from "react-bootstrap";
import { Images, Header, ComingSoon, SubHeader } from "../components";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";

import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";

import Slider from "react-slick";

export default class Achievements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageBanner: []
    };
  }


  componentDidMount() {
    this.getPageBanner()
  }
  getPageBanner = () => {

    let param = {
      page_type_id: Constants.BANNER_PAGE_TYPE_ID.myAchievement,
      position_id: "1"
    }
    WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }

  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    const { pageBanner } = this.state;

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container achivements-web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.Achievements.title}</title>
              <meta
                name="description"
                content={MetaData.Achievements.description}
              />
              <meta name="keywords" content={MetaData.Achievements.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Achievements" ImgShown={Images.HEADER_BG_FULL} BannerImage="" AddClassName="page-inner-header-sm" />



                  {
                    (pageBanner && pageBanner.length > 0) &&
                    <div className={'lb-ban-wrap'}>
                      <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                        {pageBanner.map((itm, i) => {
                          return <div key={i} className={'lb-banner'}>
                            <img src={itm.image} />
                          </div>
                        })}
                      </Slider>
                    </div>
                  }


                  <ComingSoon />
                  <div className="profile-header hide">
                    <div className="user-thumbnail">
                      <img src={Images.USERIMG} alt="" />
                    </div>
                    <div className="user-detail">
                      <div className="user-name">Mark.Zucker</div>
                      <ul className="your-profile-detail">
                        <li>
                          Played
                          <span>954</span>
                        </li>
                        <li>
                          Season Pts
                          <span>704</span>
                        </li>
                        <li>
                          Average Rating
                          <span>7.54</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="achivements-wrapper hide">
                    <div className="achivements-card">
                      <div className="achivements-header">
                        <img src={Images.LOGO} alt="" /> Accolades
                      </div>
                      <div className="achivements-body">
                        <div className="display-table">
                          <div className="achivments-section">
                            <figure>
                              <img src={Images.CRSTALL_BALL} alt="" />
                            </figure>
                            <p>Crystall Ball Trophy</p>
                          </div>
                          <div className="achivments-section">
                            <figure>
                              <img src={Images.RPL_CUP} alt="" />
                            </figure>
                            <p>RPL Cup</p>
                          </div>
                        </div>
                        <div className="display-table">
                          <div className="achivments-section">
                            <figure>
                              <img src={Images.GOLDEN_STAR} alt="" />
                            </figure>
                            <p>Golden Star</p>
                          </div>
                          <div className="achivments-section">
                            <figure>
                              <img src={Images.C6RRECT} alt="" />
                            </figure>
                            <p>C6rrect</p>
                          </div>
                        </div>
                        <div className="display-table">
                          <div className="achivments-section">
                            <figure>
                              <img src={Images.QUAD3RICK} alt="" />
                            </figure>
                            <p>Quad3rick</p>
                          </div>
                          <div className="achivments-section">
                            <figure>
                              <img src={Images.MEDALS} alt="" />
                            </figure>
                            <p>Medals</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="achivements-card">
                      <div className="achivements-header">Awards</div>
                      <div className="achivements-body p-0">
                        <div className="display-table">
                          <div className="achivments-section p-0">
                            <Media>
                              <img alt="" className="" src={Images.AWARD1} />
                              <Media.Body>
                                <h5>Team of the Week</h5>
                                <p>Soccer Winners</p>
                              </Media.Body>
                            </Media>
                            <Media>
                              <img alt="" className="" src={Images.AWARD2} />
                              <Media.Body>
                                <h5>Fans Predictor of the Month</h5>
                                <p>#Born2Win</p>
                              </Media.Body>
                            </Media>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
