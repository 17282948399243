import React from "react";
import { MyContext } from "../context";
import { Modal, Row, Col, FormGroup, Button } from "react-bootstrap";
import { inputStyle } from "../helper/input-style";
import Select from "react-select";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import _ from "lodash";
import { Loader } from "../components";

export default class SelectFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leaguesArray: [],
      roundArray: [],
      contestArray: [],
      monthArray: [],
      selectedLeague: this.props.filterData.selectedLeague || "",
      selectedMonth: this.props.filterData.selectedMonth || "",
      selectedRound: this.props.filterData.selectedRound || "",
      selectedContest: this.props.filterData.selectedContest || "",
      selectedFormat: this.props.filterData.selectedFormat || Constants.rplCupFormatArray[0],
      selectedWinner: this.props.filterData.selectedWinner || "",
      selectedEntryFee: this.props.filterData.selectedEntryFee || "",
      minSize: this.props.filterData.minSize || "",
      activeFilter: false,
      isFrom: this.props.filterData.isFrom || "",
      isElimination: this.props.filterData.isElimination || false,
      isLoading: false,
      categories_list: [],
      selectedCategory: this.props.filterData.selectedCategory || "",
      FollowOptions: Constants.FollowOption,
      selectedFollowOption: this.props.filterData.selectedFollowOption || "",
      contestType: Constants.contest_type,
      selectedContestType: this.props.filterData.selectedContestType || Constants.contest_type[0],
      EnteryFeeData: [],
      NoOFWinners: [],
      contest_duration_options: Constants.contest_duration,
      selectedContestDuration: this.props.filterData.selectedContestDuration || Constants.contest_duration[0]
    };
  }

  componentWillMount() {
    this.setCategories()

    if (this.state.isFrom === "leaderboard" || this.state.isFrom === "contest" || this.state.isFrom === "PersonalLeaderboard") {
      this.getLeagueFilter();
      if (this.state.selectedLeague) {
        this.getLeagueMonth();
      }
      if (this.state.selectedMonth.value) {
        this.getPastRound();
      }
      if (this.state.selectedRound.value) {
        this.getRoundContest();
      }

      if (this.state.selectedLeague != "" && this.state.selectedContestDuration.value == 1) {
        this.getRoundContest();
      }
    }
    else {
      this.getLeagueFilter();
      this.getPastRound();

      if (this.state.isFrom == "MyContest") {
        this.getAllPrizeData()
      }
    }


  }

  getAllPrizeData = () => {
    let param = {
      contest_type_id: this.props.filterData.contest_access_type,
      contest_access_type: this.props.filterData.contest_type
    };

    WSManager.Rest(NC.baseURL + NC.GET_ALL_PRIZE_DATA, param).then(res => {
      if (res.response_code === NC.successCode) {
        this.setState({
          NoOFWinners: res.data.number_of_winner_validation,
          EnteryFeeData: res.data.entry_fee_data
        })
      }

    })
  }




  isRPLCupSelected() {
    return this.state.selectedLeague != "" && this.state.selectedLeague.value != '' && this.state.selectedLeague.value == 0
  }

  getLeagueFilter() {

    let { selectedCategory, selectedContestType } = this.state;
    let param = {};

    let url = this.state.isFrom === "leaderboard" || this.state.isFrom === "PersonalLeaderboard" || this.state.isFrom === "stats" ?
      NC.GET_LEADERBOARD_LEAGUE_FILTERS
      : NC.GET_FILTER_CONTEST_TYPE;

    if (this.state.isFrom == "contest") {
      // param = { contest_type_id: [1, 3] };
      if (selectedContestType != "" && selectedContestType.value == "2") {
        // param.is_private = 1
      }

      param['contest_access_type'] = selectedContestType.value == "2" ? 1 : 0
      param['contest_type_id'] = selectedContestType.value == "2" ? Constants.PRIVATE_LEAGUE_ID : Constants.PUBLIC_LEAGUE_ID
    }

    else if (this.state.isFrom != "leaderboard" || this.state.isFrom != "stats") {
      // param = { contest_type_id: [1, 2] };
    }

    if (this.state.isFrom == "MyContest") {
      param['contest_access_type'] = this.props.filterData.contest_type
      param['contest_type_id'] = this.props.filterData.contest_access_type
    }

    if (selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
      param['type'] = selectedCategory.id;
      param['type_name'] = selectedCategory.name;
      if (selectedCategory.id == '2') {
        param['country_id'] = selectedCategory.value;
      }
      if (selectedCategory.id == '3') {
        param['team_id'] = selectedCategory.value;
      }
    }


    WSManager.Rest(NC.baseURL + url, param)
      .then(responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          let tmpArray = [];


          let selectedLeague = this.state.selectedLeague;
          _.map(responseJson.data, (item, idx) => {

            let obj = {
              label: item.name,
              value: item.contest_type_id
            }

            if (selectedLeague && selectedLeague.value == obj.value) {
              selectedLeague = obj;
            }
            // if(item.contest_type_id == )
            tmpArray.push(obj);
          });



          this.setState({
            leaguesArray: tmpArray,
            selectedLeague: selectedLeague || ""
          });

          if (this.state.selectedCategory.id == 1 && this.state.selectedLeague == "" && tmpArray.length > 0) {
            this.setState({
              selectedLeague: tmpArray[0]
            }, () => {
              if(this.state.selectedContestDuration.value == 1){
                this.getRoundContest()
              }
            })

            if (this.state.selectedMonth == "") {
              if (
                this.state.isFrom === "leaderboard" ||
                this.state.isFrom === "contest" ||
                this.state.isFrom === "PersonalLeaderboard" ||
                this.state.isFrom === "stats"
              ) {
                this.getLeagueMonth();
              }
            }
          }
        }
      })
      .catch(error => {
        this.setState({ leaguesArray: [] });
      });
  }

  handleCategoryChange = (e) => {
    this.setState({
      selectedCategory: e,
      activeFilter: true,
      selectedLeague: '',
      selectedMonth: '',
      selectedRound: "",
      selectedContest: "",
      selectedFormat: '',
    },
      () => {
        this.getLeagueFilter();

        if (this.state.isFrom === "MyContest" || this.state.isFrom === "PrivateContest") {
          this.getPastRound()
        }
      }

    )
  }

  handleFollowChange = (e) => {
    this.setState({ selectedFollowOption: e, activeFilter: true, })
  }

  setCategories = () => {
    let cats = Constants.categories;
    let { filterData } = this.props;

    this.setState({
      categories_list: cats,
      selectedCategory: filterData.selectedCategory ? filterData.selectedCategory : cats[0]
    })
  }

  getLeagueMonth() {

    let { selectedCategory, selectedLeague } = this.state;
    this.setState({
      isLoading: true
    });

    let param = this.isRPLCupSelected() ? { rpl_cup_type: this.state.isElimination ? 2 : 1 } : {} // 1 for qualification & 2 for elimination

    if (selectedCategory != "" && selectedCategory.hasOwnProperty('id') && selectedLeague.value != Constants.GLOBAL_LEAGUE) {
      param['type'] = selectedCategory.id;
      param['type_name'] = selectedCategory.name;
      if (selectedCategory.id == '2') {
        param['country_id'] = selectedCategory.value;
      }
      if (selectedCategory.id == '3') {
        param['team_id'] = selectedCategory.value;
      }
    } else {
      param['type'] = '1';
    }

    WSManager.Rest(NC.baseURL + NC.GET_LEADERBOARD_MONTHS_FILTERS, param)
      .then(responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          let tmpArray = [];
          tmpArray.push({
            label: "Season Overall",
            value: ''
          });
          _.map(responseJson.data, (item, idx) => {
            tmpArray.push({
              label: item.name,
              value: item.month
            });
          });
          this.setState({
            monthArray: tmpArray
          });
        }
      })
      .catch(error => {
        this.setState({ monthArray: [] });
      });
  }

  getPastRound() {

    let { selectedCategory, selectedLeague } = this.state;

    var url = NC.GET_PAST_GAME_ROUND;
    var param = {};


    if (this.state.isFrom === "leaderboard" || this.state.isFrom === "contest" || this.state.isFrom === "stats") {
      url = NC.GET_LEADERBOARD_ROUNDS_FILTERS;
      param = {
        month: this.state.selectedMonth.value
      };
      this.setState({
        isLoading: true
      });
    }

    if (selectedCategory != "" && selectedCategory.hasOwnProperty('id') && selectedLeague.value != Constants.GLOBAL_LEAGUE) {
      param['type'] = selectedCategory.id;
      param['type_name'] = selectedCategory.name;
      if (selectedCategory.id == '2') {
        param['country_id'] = selectedCategory.value;
      }
      if (selectedCategory.id == '3') {
        param['team_id'] = selectedCategory.value;
      }
    } else {
      param['type'] = '1';
    }


    WSManager.Rest(NC.baseURL + url, param)
      .then(responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          let tmpArray = [];
          tmpArray.push({
            label: "Month Overall",
            value: ""
          });
          _.map(responseJson.data, (item, idx) => {
            tmpArray.push({
              label: item.round_name,
              value: item.round_id
            });
          });
          this.setState({
            roundArray: tmpArray
          });
        }
      })
      .catch(error => {
        this.setState({ roundArray: [] });
      });
  }

  getRoundContest() {
    let param = {
      contest_type_id: this.state.selectedLeague.value,
      round_id: this.state.selectedRound.value
    };

    if (this.state.isFrom === "contest" ) {
      param.is_season_long = this.state.selectedContestDuration.value
    }
    
    this.setState({
      isLoading: true
    });

    WSManager.Rest(NC.baseURL + NC.GET_LEADERBOARD_CONTESTS_FILTERS, param)
      .then(responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          let tmpArray = [];
          _.map(responseJson.data, (item, idx) => {
            tmpArray.push({
              label: item.contest_name,
              value: item.contest_id
            });
          });
          this.setState({
            contestArray: tmpArray
          });
        }
      })
      .catch(error => {
        this.setState({ contestArray: [] });
      });
  }


  handleContestTypeChange = (e) => {
    this.setState({
      selectedContestType: e,
      activeFilter: true,
      selectedLeague: ""
    }, () => {
      this.getLeagueFilter();
    })
  }

  handleLeagueChange = option => {
    this.setState(
      {
        selectedLeague: option,
        activeFilter: true,
        selectedMonth: "",
        selectedRound: "",
        selectedContest: ""
      },
      () => {
        if (
          this.state.isFrom === "leaderboard" ||
          this.state.isFrom === "contest" ||
          this.state.isFrom === "PersonalLeaderboard" ||
          this.state.isFrom === "stats"
        ) {
          this.getLeagueMonth();
        }
      }
    );
  };

  handleFormatChange = option => {
    this.setState(
      {
        selectedFormat: option,
        activeFilter: true
      });
  };

  handleMonthChange = option => {
    this.setState(
      {
        selectedMonth: option,
        activeFilter: true,
        selectedRound: "",
        selectedContest: "",
        roundArray: [],
        selectedRound: ""
      },
      () => {
        if (
          (
            this.state.isFrom === "leaderboard" ||
            this.state.isFrom === "PersonalLeaderboard" ||
            this.state.isFrom === "contest" ||
            this.state.isFrom === "stats"
          )
          && option.value != ""
        ) {
          this.getPastRound();
        }
      }
    );
  };

  handleRoundChange = option => {
    this.setState(
      {
        selectedRound: option,
        activeFilter: true,
        selectedContest: "",
        contestArray: []
      },
      () => {
        if (
          (
            this.state.isFrom === "leaderboard" ||
            this.state.isFrom === "PersonalLeaderboard" ||
            this.state.isFrom === "contest" ||
            this.state.isFrom === "stats"
          ) &&
          option.value != ""
        ) {
          this.getRoundContest();
        }
      }
    );
  };

  handleContestChange = option => {
    this.setState({
      selectedContest: option,
      activeFilter: true
    });
  };

  resetFilter = () => {
    this.setState({
      selectedFormat: "",
      selectedLeague: "",
      selectedRound: "",
      selectedContest: "",
      selectedMonth: "",
      selectedCategory: this.state.categories_list[0],
      activeFilter: true,
      selectedFollowOption: Constants.FollowOption[0],
      selectedContestType: Constants.contest_type[0],
      selectedContestDuration: Constants.contest_duration[0],
      selectedWinner: "",
      selectedEntryFee: ""
    }, () => {
      if (
        this.state.isFrom == "leaderboard" ||
        this.state.isFrom === "contest" ||
        this.state.isFrom == "PersonalLeaderboard" ||
        this.state.isFrom === "stats"

      ) {
        this.getLeagueFilter();
      }
    });
  }

  handleWinnerChange = option => {
    this.setState({
      selectedWinner: option,
      activeFilter: true
    });
  };


  handleEntryFeeChange = option => {
    this.setState({
      selectedEntryFee: option,
      activeFilter: true
    });
  };

  inputHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      activeFilter: true
    });
  }


  handleContestDurationChange = option => {
    this.setState({
      selectedContestDuration: option,
      activeFilter: true
    }, 
    () => {
      if(this.state.selectedContestDuration.value == 0){
        this.getLeagueMonth();
      }
      if(this.state.selectedContestDuration.value == 1){
        this.getRoundContest();
      }
    }
   );
  };


  render() {
    const {
      IsFilterModalShow,
      IsFilterModalHide,
      filterData,
      filterAction
    } = this.props;
    const {
      leaguesArray,
      selectedLeague,
      roundArray,
      selectedRound,
      selectedContest,
      selectedMonth,
      selectedFormat,
      contestArray,
      monthArray,
      isLoading,
      isFrom,
      isElimination,
      categories_list,
      selectedCategory,
      FollowOptions,
      selectedFollowOption,
      contestType,
      selectedContestType,
      selectedWinner,
      selectedEntryFee,
      NoOFWinners,
      EnteryFeeData,
      minSize,
      contest_duration_options,
      selectedContestDuration
    } = this.state;


    return (
      <MyContext.Consumer>
        {context => (
          <Modal
            show={IsFilterModalShow}
            onHide={IsFilterModalHide}
            className="select-more-modal"
          >
            <Modal.Header>
              <Modal.Title>
                Select
                <a
                  href
                  className="custom-close-btn"
                  onClick={IsFilterModalHide}
                >
                  <i className="icon-close" />
                </a>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* {isLoading && <Loader />} */}


              {
                filterData.filterOption &&
                filterData.filterOption.includes("network") && (
                  <div className="verification-block ">
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                          <div className="select-league">
                            <label style={inputStyle.label}>
                              Select Network
                            </label>
                            <div className="genderStyle">
                              <Select
                                onChange={this.handleFollowChange}
                                options={FollowOptions}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                value={selectedFollowOption}
                                placeholder="--"
                                isSearchable={false}
                                isClearable={false}
                                // getOptionLabel={(option) => option.name}
                                // getOptionValue={(option) => option.id}
                                menuPlacement="auto"
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#37003C"
                                  }
                                })}
                              />
                            </div>
                            <span className="select-arr">
                              <i className="icon-arrow-sort" />
                            </span>
                            <div className="league-border col-sm-12" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )
              }



              {
                filterData.filterOption &&
                filterData.filterOption.includes("category") && (
                  <div className="verification-block ">
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                          <div className="select-league">
                            <label style={inputStyle.label}>
                              Select Category
                            </label>
                            <div className="genderStyle">
                              <Select
                                onChange={this.handleCategoryChange}
                                options={categories_list}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                value={selectedCategory}
                                placeholder="--"
                                isSearchable={false}
                                isClearable={false}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                menuPlacement="auto"
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#37003C"
                                  }
                                })}
                              />
                            </div>
                            <span className="select-arr">
                              <i className="icon-arrow-sort" />
                            </span>
                            <div className="league-border col-sm-12" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )
              }

              {
                filterData.filterOption &&
                filterData.filterOption.includes("contestType") && (
                  <div className="verification-block ">
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                          <div className="select-league">
                            <label style={inputStyle.label}>
                              Select Contest Type
                            </label>
                            <div className="genderStyle">
                              <Select
                                onChange={this.handleContestTypeChange}
                                options={contestType}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                value={selectedContestType}
                                placeholder="--"
                                isSearchable={false}
                                isClearable={false}
                                menuPlacement="auto"
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#37003C"
                                  }
                                })}
                              />
                            </div>
                            <span className="select-arr">
                              <i className="icon-arrow-sort" />
                            </span>
                            <div className="league-border col-sm-12" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )
              }

              {filterData.filterOption &&
                filterData.filterOption.includes("league") && (
                  <div className="verification-block ">
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                          <div className="select-league">
                            <label style={inputStyle.label}>
                              Select League
                            </label>
                            <div className="genderStyle">
                              <Select
                                onChange={this.handleLeagueChange}
                                options={leaguesArray}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                value={selectedLeague}
                                placeholder="--"
                                isSearchable={false}
                                isClearable={false}
                                menuPlacement="auto"
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#37003C"
                                  }
                                })}
                              />
                            </div>
                            <span className="select-arr">
                              <i className="icon-arrow-sort" />
                            </span>
                            <div className="league-border col-sm-12" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )}

              {(this.isRPLCupSelected() && !isElimination) && (
                <div className="verification-block ">
                  <Row>
                    <Col xs={12}>
                      <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                        <div className="select-league">
                          <label style={inputStyle.label}>
                            Select Format
                            </label>
                          <div className="genderStyle">
                            <Select
                              onChange={this.handleFormatChange}
                              options={Constants.rplCupFormatArray}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10"
                              value={selectedFormat}
                              placeholder="--"
                              isSearchable={false}
                              isClearable={false}
                              menuPlacement="auto"
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#37003C"
                                }
                              })}
                            />
                          </div>
                          <span className="select-arr">
                            <i className="icon-arrow-sort" />
                          </span>
                          <div className="league-border col-sm-12" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              )}


              {filterData.filterOption &&
                filterData.filterOption.includes("contestDuration") && (
                  <div className="verification-block ">
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                          <div className="select-league">
                            <label
                              style={inputStyle.label}
                            >
                              Select Duration
                            </label>
                            <div className="genderStyle">
                              <Select
                                onChange={this.handleContestDurationChange}
                                options={contest_duration_options}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                value={selectedContestDuration}
                                placeholder="--"
                                isSearchable={false}
                                isClearable={false}
                                menuPlacement="auto"
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#37003C"
                                  }
                                })}
                              />
                            </div>
                            <span className="select-arr">
                              <i className="icon-arrow-sort" />
                            </span>
                            <div className="league-border col-sm-12" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )}


              {
                (selectedContestDuration != "" && selectedContestDuration.value == 0) &&

                <React.Fragment>


                  {filterData.filterOption &&
                    filterData.filterOption.includes("month") && (
                      <div className="verification-block ">
                        <Row>
                          <Col xs={12}>
                            <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                              <div className="select-league">
                                <label
                                  className={
                                    (
                                      isFrom === "leaderboard" ||
                                      isFrom === "PersonalLeaderboard" ||
                                      isFrom === "contest") &&
                                      (monthArray.length == 0 || selectedLeague == "")
                                      ? "secondary--lable--is-disabled"
                                      : ""
                                  }
                                  style={inputStyle.label}
                                >
                                  Select Month
                            </label>
                                <div className="genderStyle">
                                  <Select
                                    isDisabled={
                                      (isFrom === "leaderboard" ||
                                        isFrom === "PersonalLeaderboard" ||
                                        isFrom === "contest") &&
                                      (monthArray.length == 0 ||
                                        selectedLeague == "")
                                    }
                                    onChange={this.handleMonthChange}
                                    options={monthArray}
                                    classNamePrefix="secondary"
                                    className="select-secondary minusML10"
                                    value={selectedMonth}
                                    placeholder="--"
                                    isSearchable={false}
                                    isClearable={false}
                                    menuPlacement="auto"
                                    theme={theme => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#37003C"
                                      }
                                    })}
                                  />
                                </div>
                                <span className="select-arr">
                                  <i className="icon-arrow-sort" />
                                </span>
                                <div className="league-border col-sm-12" />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}

                  {filterData.filterOption &&
                    filterData.filterOption.includes("round") && (
                      <div className="verification-block ">
                        <Row>
                          <Col xs={12}>
                            <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                              <div className="select-league">
                                <label
                                  className={
                                    (isFrom === "leaderboard" ||
                                      isFrom === "PersonalLeaderboard" ||
                                      isFrom === "contest") &&
                                      (roundArray.length == 0 || selectedMonth == "")
                                      ? "secondary--lable--is-disabled"
                                      : ""
                                  }
                                  style={inputStyle.label}
                                >
                                  Select Round
                            </label>
                                <div className="genderStyle">
                                  <Select
                                    isDisabled={
                                      (isFrom === "leaderboard" || isFrom === "contest") &&
                                      (roundArray.length == 0 || selectedMonth == "")
                                    }
                                    onChange={this.handleRoundChange}
                                    options={roundArray}
                                    classNamePrefix="secondary"
                                    className="select-secondary minusML10"
                                    value={selectedRound}
                                    placeholder="--"
                                    menuPlacement="auto"
                                    isSearchable={false}
                                    isClearable={false}
                                    theme={theme => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#37003C"
                                      }
                                    })}
                                  />
                                </div>
                                <span className="select-arr">
                                  <i className="icon-arrow-sort" />
                                </span>
                                <div className="league-border col-sm-12" />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}


                </React.Fragment>
              }
              {filterData.filterOption &&
                filterData.filterOption.includes("contest") &&
                (selectedLeague.value > 0 && (
                  <div className="verification-block m-t-20">
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                          <div className="select-league">
                            <label
                              className={
                                (isFrom === "leaderboard" ||
                                  isFrom === "PersonalLeaderboard" ||
                                  isFrom === "contest") &&
                                  (contestArray.length == 0 ||
                                    selectedRound == "")
                                  ? "secondary--lable--is-disabled"
                                  : ""
                              }
                              style={inputStyle.label}
                            >
                              Select Contest
                            </label>
                            <div className="genderStyle">
                              <Select
                                isDisabled={
                                  (isFrom === "leaderboard" ||
                                    isFrom === "PersonalLeaderboard" ||
                                    isFrom === "contest") &&
                                  (contestArray.length == 0 ||
                                    selectedRound == "")
                                }
                                onChange={this.handleContestChange}
                                options={contestArray}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                value={selectedContest}
                                placeholder="--"
                                isSearchable={false}
                                isClearable={false}
                                menuPlacement="auto"
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#37003C"
                                  }
                                })}
                              />
                            </div>
                            <span className="select-arr">
                              <i className="icon-arrow-sort" />
                            </span>
                            <div className="league-border col-sm-12" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                ))}




              {
                filterData.filterOption &&
                filterData.filterOption.includes("winner") && (
                  <div className="verification-block ">
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                          <div className="select-league">
                            <label style={inputStyle.label}>
                              Select Winner
                            </label>
                            <div className="genderStyle">
                              <Select
                                onChange={this.handleWinnerChange}
                                options={NoOFWinners}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                value={selectedWinner}
                                placeholder="--"
                                isSearchable={false}
                                isClearable={false}
                                getOptionLabel={(option) => option.master_contest_type_desc}
                                getOptionValue={(option) => option.master_contest_type_id}
                                menuPlacement="auto"
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#37003C"
                                  }
                                })}
                              />
                            </div>
                            <span className="select-arr">
                              <i className="icon-arrow-sort" />
                            </span>
                            <div className="league-border col-sm-12" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )
              }



              {
                filterData.filterOption &&
                filterData.filterOption.includes("entryfee") && (
                  <div className="verification-block ">
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                          <div className="select-league">
                            <label style={inputStyle.label}>
                              Select Entry Fee
                            </label>
                            <div className="genderStyle">
                              <Select
                                onChange={this.handleEntryFeeChange}
                                options={EnteryFeeData}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                value={selectedEntryFee}
                                placeholder="--"
                                isSearchable={false}
                                isClearable={false}
                                getOptionLabel={(option) => option.entry_fee}
                                getOptionValue={(option) => option.entry_fee}
                                menuPlacement="auto"
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#37003C"
                                  }
                                })}
                              />
                            </div>
                            <span className="select-arr">
                              <i className="icon-arrow-sort" />
                            </span>
                            <div className="league-border col-sm-12" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )
              }


              {
                filterData.filterOption &&
                filterData.filterOption.includes("minsize") && (
                  <div className="verification-block ">
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="input-label-center m-b-sm input-transparent font-14 bordered-color">
                          <div className="select-league">
                            <label style={inputStyle.label}>
                              Enter Number of participants
                            </label>
                            <input
                              type={'number'}
                              name={'minSize'}
                              value={minSize || ""}
                              onChange={this.inputHandler}
                              className={'sf-input'}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )
              }


              <div className="button-section">
                <Button
                  disabled={!this.state.activeFilter}
                  onClick={() => {
                    filterAction(
                      selectedLeague,
                      selectedRound,
                      selectedContest,
                      selectedMonth,
                      selectedFormat,
                      selectedCategory,
                      selectedFollowOption,
                      selectedContestType,
                      selectedWinner,
                      selectedEntryFee,
                      minSize,
                      selectedContestDuration
                    );
                    IsFilterModalHide();
                  }}
                  className="btn btn-rounded btn-lg"
                >
                  Apply Selections
                </Button>
                <br />
                <a
                  href
                  onClick={this.resetFilter}
                  className={
                    "btn btn-link" +
                    (selectedLeague != "" ||
                      selectedRound != "" ||
                      selectedContest != "" ||
                      selectedMonth != "" ||
                      (selectedCategory && selectedCategory.id != "1") ||
                      (selectedFollowOption != "" && selectedFollowOption.value != "1") ||
                      (selectedContestType != "" && selectedContestType.value != "1") ||
                      (selectedWinner != "") ||
                      (selectedEntryFee != "") ||
                      (minSize != "")
                      ? ""
                      : " disabled")
                  }
                >
                  Reset
                </a>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </MyContext.Consumer>
    );
  }
}
