import React, { Fragment } from "react";
import { Row, Col, Tab, Nav, NavItem, FormGroup, Media, FormControl } from "react-bootstrap";
import { Header, Images, ComingSoon } from "../components";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import MetaData from "../helper/MetaData";
import { Helmet } from "react-helmet";

class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    componentWillMount() {
    }


    render() {

        const HeaderOption = {
            Logo: true,
            back:true
        };

        return (
            <React.Fragment>

                <Helmet titleTemplate={`${MetaData.template} | %s`}>
                    <title>{MetaData.myprofile.title}</title>
                    <meta
                        name="description"
                        content={MetaData.myprofile.description}
                    />
                    <meta name="keywords" content={MetaData.myprofile.keywords} />
                </Helmet>


                <Header
                    {...this.props}
                    HeaderOption={HeaderOption}
                    showFilter={this.showFilter}
                />

            </React.Fragment>
        )
    }
}


export default UserProfile;