import React from "react";
import { MyContext } from "../context";
import { Header, Loader } from "../components";
import { Helmet } from "react-helmet";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import MetaData from "../helper/MetaData";

const FixtureResultList = ({ fixtureQueResult, myContext, idx, ContestData }) => {
  return (
    <div className={fixtureQueResult.is_correct == 1 ? "choosen-correct" : ""}>
      <ul
        className={
          "quiz-section" + (fixtureQueResult.home_score == null && fixtureQueResult.question_type == "text" ? " quiz-section-rounded" : "")
        }
      >
        <li>
          <div className="numbers-text">{idx + 1}</div>
        </li>
        {fixtureQueResult.question_type == "text" && (
          <React.Fragment>
            <li className="team-score-list">
              <img src={ContestData.match_data.home_flag} alt="" />
              <div className="quiz-query">{ContestData.match_data.home}</div>
              <div className="team-selected">
                {fixtureQueResult.user_home_score}
              </div>
            </li>
            <li className="team-score-list">
              <img src={ContestData.match_data.away_flag} alt="" />
              <div className="quiz-query">{ContestData.match_data.away}</div>
              <div className="team-selected">
                {fixtureQueResult.user_away_score}
              </div>
            </li>
            {fixtureQueResult.away_score == null &&
              fixtureQueResult.home_score == null && (
                <li>
                  <div className="prediction-result">
                    <span>Pending</span>
                    <i className="icon-circular-menu pending" />
                  </div>
                </li>
              )}
            {fixtureQueResult.away_score != null &&
              fixtureQueResult.home_score != null && (
                <li>
                  <div className="prediction-result">
                    {fixtureQueResult.is_correct == 1 ? "Correct" : "Wrong"}
                    <i
                      className={fixtureQueResult.is_correct == 1 ? "icon-tick-circular" : "icon-cross-circular"
                      }
                    />
                  </div>
                </li>
              )}
          </React.Fragment>
        )}
        {fixtureQueResult.question_type != "text" && (
          <React.Fragment>
            <li>
              {fixtureQueResult.selected_option_name ==
                ContestData.match_data.home ? (
                  <img src={ContestData.match_data.home_flag} alt="" />
                ) : fixtureQueResult.selected_option_name ==
                  ContestData.match_data.away ? (
                    <img src={ContestData.match_data.away_flag} alt="" />
                  ) : (
                    <i className="icon-stop blocked" />
                  )}
              <div className="quiz-query">{fixtureQueResult.question_name}</div>
              <div className="team-selected">
                {fixtureQueResult.selected_option_name}
              </div>
            </li>
            {fixtureQueResult.correct_answer == null && (
              <li>
                <div className="prediction-result">
                  <span>Pending</span>
                  <i className="icon-circular-menu pending" />
                </div>
              </li>
            )}
            {fixtureQueResult.correct_answer != null && (
              <li>
                <div className="prediction-result">
                  {fixtureQueResult.is_correct == 1 ? "Correct" : "Wrong"}
                  <i
                    className={fixtureQueResult.is_correct == 1 ? "icon-tick-circular" : "icon-cross-circular"
                    }
                  />
                </div>
              </li>
            )}
          </React.Fragment>
        )}
      </ul>
      <ul className="ans-picked-detail">
        {fixtureQueResult.question_type == "text" && (
          <React.Fragment>
            {fixtureQueResult.home_score != null && (
              <li className="width-100">
                <p className="ans-label">Correct Answer</p>
                <span className="correct-ans m-r-md">
                  {ContestData.match_data.home}
                  <span>{fixtureQueResult.home_score}</span>
                </span>
                <span className="correct-ans">
                  {ContestData.match_data.away}
                  <span> {fixtureQueResult.away_score}</span>
                </span>
              </li>
            )}
          </React.Fragment>
        )}
        {fixtureQueResult.question_type != "text" && (
          <React.Fragment>
            <li className="text-left">
              <span className="ans-picked-by">
                {fixtureQueResult.correct_percentage}%
                <span> of ReFPredictors picked </span>
                {fixtureQueResult.correct_option_name}
              </span>
            </li>
            {fixtureQueResult.is_correct != 1 &&
              fixtureQueResult.correct_answer != null && (
                <li>
                  <React.Fragment>
                    <p className="ans-label">Correct Answer</p>
                    <div className="correct-ans">
                      {fixtureQueResult.correct_option_name}
                    </div>
                  </React.Fragment>
                </li>
              )}
          </React.Fragment>
        )}
      </ul>
    </div>
  );
};
export default class AnswerScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ContestData: !_.isUndefined(props.location.state)
        ? props.location.state.ContestData
        : "",
      isFrom: !_.isUndefined(props.location.state)
        ? props.location.state.isFrom
        : "",
      isLoading: false,
      fixtureResult: "",
      fixtureQueResult: []
    };
  }

  componentWillMount() {
    let { ContestData } = this.state;
    let param = {
      fixture_id: ContestData.fixture_id,
      sports_id: NC.sportsId
    };
    this.setState({
      isLoading: true
    });

    WSManager.Rest(NC.baseURL + NC.GET_FIXTURE_RESULT, param).then(
      responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          this.setState({
            fixtureResult: responseJson.data,
            fixtureQueResult: responseJson.data.questions
          });
        }
      }
    );
  }

  render() {
    const {
      fixtureResult,
      fixtureQueResult,
      ContestData,
      isLoading
    } = this.state;
    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };
    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.Prediction.title}</title>
              <meta
                name="description"
                content={MetaData.Prediction.description}
              />
              <meta name="keywords" content={MetaData.Prediction.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            {isLoading && <Loader />}
            <div className="heading-section-sm">
              {ContestData.match_data.home}
              <span> v </span>
              {ContestData.match_data.away}
            </div>
            <div className="prediction-quiz">
              {fixtureResult.bonus_race && (
                <div className="section-txt">
                  {fixtureResult.bonus_race.message}
                </div>
              )}
              <div>
                {fixtureQueResult.length > 0 &&
                  fixtureQueResult.map((item, idx) => {
                    return (
                      <FixtureResultList
                        key={"fixtureQueResult" + idx}
                        idx={idx}
                        fixtureQueResult={item}
                        myContext={this}
                        ContestData={ContestData}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
