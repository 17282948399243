import React from "react";
import { Row, Col } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Transfer, Subscribe } from "../Modals";
import { notify } from "react-notify-toast";
import { Images, Header, Loader, NoDataScreen, SubHeader } from "../components";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import _ from "lodash";
import MetaData from "../helper/MetaData";
import RefPayCardHeader from "./RefPayCardHeader";
import TransactionRecord from "./TransactionRecord";
import {FooterClassAdd} from '../ReduxStore/Actions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class RefPay extends React.Component {
  constructor(props) {
    super(props);
    this.IsTransferHide = this.IsTransferHide.bind(this);
    this.IsSubscribeHide = this.IsSubscribeHide.bind(this);
    this.state = {
      transactionList: [],
      showTransfer: false,
      showSubscribe: false,
      isLoading: false,
      refreshPage: true
    };
  }


  componentWillUnmount(){
    this.props.FooterClassAdd('')
  }


  componentDidMount() {
    this.callWSForTransaction();
    this.props.FooterClassAdd('refpay-foot')
  }



  callWSForTransaction() {
    WSManager.Rest(NC.baseURL + NC.GET_TRANSACTION_HISTORY, {
      offset: 0,
      limit: 3,
      season_type: 1
    }).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        this.setState({
          transactionList: responseJson.data.transaction_history
        });
      }
    });
  }

  callTransferSubmitApi(amount, SelectedUser, accPin) {
    let param = {
      amount: amount,
      receiver_user_id: SelectedUser.value,
      security_pin:
        WSManager.getProfile().security_pin_enabled == "1" ? accPin : ""
    };
    this.setState({ isLoading: true });
    WSManager.Rest(NC.baseURL + NC.FUND_TRANSFER, param).then(responseJson => {
      this.callWSForTransaction();
      this.setState({ isLoading: false, refreshPage: false }, () => {
        this.setState({ refreshPage: true });
      });
      if (responseJson.response_code === NC.successCode) {
        notify.show(responseJson.message || Constants.Messages.transfr_success, "success", 2000);
      }
    });
  }

  IsTransferHide() {
    this.setState({ showTransfer: false });
  }

  callSubscribeSubmitApi(SelectedMembership, SelectedUser, accPin) {
    let param = {
      plan_id: SelectedMembership.value,
      user_id: SelectedUser.value,
      security_pin: WSManager.getProfile().security_pin_enabled == "1" ? accPin : ""
    };

    this.setState({ isLoading: true });
    WSManager.Rest(NC.baseURL + NC.PURCHASE_MEMBERSHIP, param).then(responseJson => {
      this.callWSForTransaction();
      this.setState({ isLoading: false, refreshPage: false }, () => {
        this.setState({ refreshPage: true });
      });
      if (responseJson.response_code === NC.successCode) {
        notify.show(responseJson.message || Constants.Messages.subscrb_success, "success", 2000);
      }
      else {
        var errorMsg = WSManager.parseErrorMessage(responseJson)
        if (errorMsg.toLowerCase().includes('insufficient')) {
          this.props.history.push('/add-funds');
        }
      }
    });
  }

  IsSubscribeHide() {
    this.setState({ showSubscribe: false });
  }

  featureNotAvailable = () => {
    notify.show("This feature is only available with Premium & Premium+ Users", "error", 2000);
  }

  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    let {
      transactionList,
      showTransfer,
      showSubscribe,
      isLoading,
      refreshPage
    } = this.state;

    // const user_permissions = WSManager.getUserPermissions();


    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container refpay-web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.mywallet.title}</title>
              <meta name="description" content={MetaData.mywallet.description} />
              <meta name="keywords" content={MetaData.mywallet.keywords} />
            </Helmet>
            {isLoading && <Loader />}
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
              showNavigation={false}
            />
            {refreshPage && (
              <div>
                <Row>
                  <Col sm={12}>
                    <SubHeader Title="ReFPay" ImgShown={Images.REFPAY_HEADER_BG} BannerImage="" AddClassName="page-inner-header-lg refpay-header" />
                  </Col>
                </Row>
                <RefPayCardHeader />
                <div className="refpay-header refpay-btn-group">
                  <div className="btn-wrapper">
                    <a
                      href
                      onClick={() => this.props.history.push("/add-funds")}
                      className="btn btn-rounded"
                    >
                      <i className="icon-plus" />
                      Fund Wallet
                    </a>
                  </div>
                  <div className="btn-wrapper">
                    <a
                      href
                      onClick={() => this.setState({ showSubscribe: true })}
                      className="btn btn-rounded"
                    >
                      <i className="icon-circle-tick" />
                      Subscribe
                    </a>
                  </div>
                  <div className="btn-wrapper">


                    <a
                      href
                      onClick={
                        () => WSManager.getProfile().plan_id != '1' ?
                          this.setState({ showTransfer: true }) :
                          this.featureNotAvailable()
                      }
                      className="btn btn-rounded"
                    >
                      <i className="icon-transfer" />
                      Transfer
                    </a>
                  </div>
                  <div className="btn-wrapper">
                    <a
                      href
                      onClick={() => this.props.history.push("/withdraw")}
                      onClick={
                        () => WSManager.getProfile().plan_id != '1' ?
                          this.props.history.push("/withdraw") :
                          this.featureNotAvailable()
                      }
                      className="btn btn-rounded"
                    >
                      <i className="icon-Rits" />
                      Withdraw
                    </a>
                  </div>
                </div>
                <React.Fragment>
                  <div className="recent-transaction-label">
                    Recent Transactions
                  </div>
                  {transactionList && transactionList.length > 0 && (
                    <div className="trasaction-record">
                      <ul>
                        {_.map(transactionList, (item, idx) => {
                          return (
                            <TransactionRecord
                              key={idx}
                              item={item}
                              idx={idx}
                            />
                          );
                        })}

                        <li className="view-all-transaction">
                          <a
                            onClick={() =>
                              this.props.history.push("/transaction")
                            }
                            href
                          >
                            View All Transactions
                          </a>
                        </li>
                      </ul>
                      <div className="refpay-footer page-footer">
                        <i className="icon-circular-menu" />
                        <span>Pending</span>
                        <i className="icon-tick-circular" />
                        <span>Completed</span>
                        <i className="icon-cross-circular" />
                        <span>Cancel</span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
                {transactionList.length == 0 &&
                  <NoDataScreen Content={{
                    ImgShown: Images.no_data_bg_image,
                    IsButton: false,
                    ButtonText: "Go to Lobby",
                    Title: "There is no transaction.",
                    NoDataContent: "",
                    BtnAction: () => {
                      this.props.history.push({
                        pathname: "/dashboard/0"
                      });
                    },
                    WithFilter: ''
                  }} />
                }
              </div>
            )}
            {showTransfer && (
              <Transfer
                history={this.props.history}
                IsTransferShow={showTransfer}
                IsTransferHide={this.IsTransferHide}
                callSubmitApi={this.callTransferSubmitApi.bind(this)}
              />
            )}
            {showSubscribe && (
              <Subscribe
                history={this.props.history}
                IsSubscribeShow={showSubscribe}
                IsSubscribeHide={this.IsSubscribeHide}
                callSubmitApi={this.callSubscribeSubmitApi.bind(this)}
              />
            )}
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  FooterClassAdd
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RefPay);
