import React from "react";
import { Row, Col, PanelGroup, Tab, Nav, NavItem, FormControl, FormGroup } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { MyContext } from "../context";
import { notify } from "react-notify-toast";
import { Header, Loader, Images, ComingSoon, NoDataScreen, SubHeader } from "../components";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import Slider from "react-slick";
import _ from "lodash";
import MetaData from "../helper/MetaData";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import LeaderBoardRecord from "./LeaderBoardRecord";
import RPLFinalTab from "./RPLFinalTab";

import Authenticate  from "../views/Authenticate";

export default class LeaderBoard extends React.Component {
  constructor(props) {
    super(props);

    

    
    this.SearchUserReq = _.debounce(this.SearchUserReq.bind(this), 700);
    this.state = {
      isLoading: false,
      leaderboardResult: [],
      ownLeaderboard: [],
      searchClick: false,
      selectedRound: "",
      selectedLeague: "",
      selectedContest: "",
      selectedMonth: "",
      selectedFormat: "",
      offset: 0,
      hasMore: false,
      Searchkey: "",
      selectedMainTab: 0,
      selectedUserTab: 0,
      selectedRPLCupTab: 0,
      selectedFollowOption: Constants.FollowOption[0],
      selectedFollow: "",
      selectedGrpIDX: 0,
      selectedCategory: Constants.categories[0],
      selectedContestType: "",
      pageBanner: [],
      current_rank: "",
      categories_list: Constants.categories,

      contest_duration_options: Constants.contest_duration,
      selectedContestDuration: Constants.contest_duration[0]
    };
  }

  setRouteParam() {


    if (!_.isUndefined(this.props.location.state)) {
      let lc = this.props.location.state;

      console.log(lc)
      this.setState({
        selectedRound: lc.selectedRound || "",
        selectedLeague: lc.selectedLeague || "",
        selectedContest: lc.selectedContest || "",
        selectedMonth: lc.selectedMonth || "",
        selectedMainTab: lc.selectedLeague.value == "1" || lc.selectedLeague.value == "2" || lc.selectedLeague.value == "4" ||  (lc.selectedContestType && lc.selectedContestType.value == '2') || lc.selectedMainTab == 1 ? 1 : 0,
        // selectedMainTab: (lc.selectedContestType && lc.selectedContestType.value == '2') ? 1 : 0,
        selectedCategory: lc.selectedCategory || Constants.categories[0],
        selectedContestType: lc.selectedContestType || "",
        selectedContestDuration:lc.selectedContestDuration ||  Constants.contest_duration[0]
      });
    }
  }

  componentWillMount() {
    this.setRouteParam();
  }

  componentDidMount() {
    this.callLeaderBoardApi();
    this.getPageBanner()
  }



  getPageBanner = () => {

    let param = {
      page_type_id: Constants.BANNER_PAGE_TYPE_ID.leaderboard,
      position_id: "1"
    }
    WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }

  SearchUserReq() {
    const { Searchkey } = this.state;
    if (Searchkey.trim().length < 2 && Searchkey.trim().length > 0) return;
    this.callLeaderBoardApi();
  }

  filterAction(selectleague, selectround, selectedContest, selectedMonth, selectedFormat, selectedCategory, selectedFollowOption, selectedContestType, selectedWinner,
    selectedEntryFee, minSize, selectedContestDuration) {
    this.setState({
      selectedLeague: selectleague,
      selectedRound: selectround,
      selectedContest: selectedContest,
      selectedMonth: selectedMonth,
      selectedFormat: selectedFormat,
      selectedCategory: selectedCategory,
      selectedFollowOption: selectedFollowOption,
      selectedContestType: selectedContestType,
      selectedContestDuration:selectedContestDuration,
      offset: 0,
      hasMore: false
    }, () => {
      this.callLeaderBoardApi();
    }
    );
  }

  showScoringRules() {
    Header.showScoringModal();
  }

  callQualificationApi() {
    const {
      selectedRound,
      selectedMonth,
      offset,
      ownLeaderboard,
      leaderboardResult,
      Searchkey,
      selectedFormat,
      selectedRPLCupTab,
      selectedGrpIDX
    } = this.state;

    let round_id = ''
    if (selectedRound != "") {
      round_id = selectedRound.value;
    }
    else if (selectedRPLCupTab == 0) {
      round_id = ''
    }
    else if (selectedMonth != "") {
      round_id = ''
    }
    else {
      // round_id = Constants.current_roundData.round_id
      round_id = ""
    }

    let param = {
      month: selectedMonth != "" ? selectedMonth.value : "",
      round_id: round_id,
      user_name: Searchkey,
      limit: 100,
      offset: offset
    };

    let url = ''
    if (selectedRPLCupTab == 0) {
      url = NC.GET_RPL_CUP_QUALIFICATION_DATA
      param['format'] = selectedFormat.value || 1;
    }
    if (selectedRPLCupTab == 1) {
      url = NC.GET_RPL_CUP_ELIMINATION_DATA
      param['group_name'] = Constants.RPLGroupArray.length > selectedGrpIDX ? Constants.RPLGroupArray[selectedGrpIDX].value : ''
    }

    this.setState({
      isLoading: true
    });

    WSManager.Rest(NC.baseURL + url, param).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          this.setState(
            {
              leaderboardResult: offset == 0 ? responseJson.data.list || [] : [...leaderboardResult, ...responseJson.data.list],
              ownLeaderboard: offset == 0 ? responseJson.data.own_leaderboard : ownLeaderboard,
              hasMore: responseJson.data.is_load_more,
              offset: responseJson.data.offset
            },
            () => {
              this.setState({ isLoading: false });
            }
          );
        } else {
          this.setState({ isLoading: false });
        }
      }
    );
  }

  callLeaderBoardApi() {
    Constants.SetValues.leaderboardOpenIDX(null);
    if (this.isRPLCupSelected()) {
      this.callQualificationApi()
    } else {
      const {
        selectedRound,
        selectedLeague,
        selectedContest,
        selectedMonth,
        offset,
        ownLeaderboard,
        leaderboardResult,
        Searchkey,
        selectedMainTab,
        selectedUserTab,
        selectedCategory,
        selectedFollowOption,
        selectedContestType,
        selectedContestDuration
      } = this.state;


console.log(selectedContest, 'selectedContest')
      let param = {
        // contest_type_id: selectedLeague != "" ? selectedLeague.value : selectedMainTab == 1 ? "5" : "", 
        contest_type_id: selectedLeague != "" ? selectedLeague.value : "",
        month: selectedMonth != "" ? selectedMonth.value : "",
        // round_id: selectedRound != "" ? selectedRound.value : selectedLeague == "" ? Constants.current_roundData.round_id : "",
        round_id: selectedRound != "" ? selectedRound.value : "",
        contest_id: selectedContest != "" ? selectedContest.value : "",
        user_name: Searchkey,
        limit: 100,
        offset: offset,
        user_type: selectedUserTab == 1 ? 1 : 0,
        tab_id: selectedMainTab
      };

      // if (selectedContestType.value == "2" && selectedMainTab == 1) {
      //   param.contest_id = selectedLeague != "" ? selectedLeague.value : "";
      //   param.contest_type_id = 4;
      // }


      if (selectedMainTab == 1) {
        param['is_season_long'] = selectedContestDuration.value;
      }

      if (selectedMainTab == 2) {
        param['follow_type'] = selectedFollowOption.value;
      }

      if (selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
        param['type'] = selectedCategory.id;
        if (selectedCategory.id == '2') {
          param['country_id'] = selectedCategory.value;
        }
        if (selectedCategory.id == '3') {
          param['team_id'] = selectedCategory.value;
        }
      }



      this.setState({
        isLoading: true
      });
      WSManager.Rest(NC.baseURL + NC.GET_LEADERBOARD_RESULTS, param).then(
        responseJson => {
          if (responseJson.response_code === NC.successCode) {
            this.setState(
              {
                leaderboardResult: offset == 0 ? responseJson.data.list : [...leaderboardResult, ...responseJson.data.list],
                ownLeaderboard: offset == 0 ? responseJson.data.own_leaderboard : ownLeaderboard,
                hasMore: responseJson.data.is_load_more,
                offset: responseJson.data.offset,
                current_rank: responseJson.data.current_rank
              },
              () => {
                this.setState({ isLoading: false });
              }
            );
          } else {
            this.setState({ isLoading: false });
          }
        }
      );
    }
  }

  handleFollowerChange = selectedOption => {
    this.setState({
      selectedFollow: selectedOption.value,
      selectedFollowOption: selectedOption
    });
  };

  fetchMoreData = () => {
    console.log('====================================');
    console.log(this.state.offset);
    console.log('====================================');
    if (!this.state.isLoading && this.state.selectedRPLCupTab != 2) {
      this.callLeaderBoardApi();
    }
  };

  followAction = (uid, position, isFollow, event) => {
    event.stopPropagation();
    event.preventDefault();
    let param = {
      follower_id: uid
    };
    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + (isFollow == 0 ? NC.USER_FOLLOW : NC.USER_UNFOLLOW), param).then(responseJson => {
      this.setState({
        isLoading: false
      });
      if (responseJson.response_code === NC.successCode) {
        let items = this.state.leaderboardResult;
        items[position].is_following = isFollow == 0 ? 1 : 0;
        this.setState({
          leaderboardResult: items
        });
        notify.show(responseJson.message, "success", 3000);
      }
    });
  };

  onTabUserClick = selectedTab1 => {
    Constants.SetValues.leaderboardOpenIDX(null);
    this.setState(
      {
        selectedUserTab: selectedTab1,
        offset: 0,
        hasMore: false
      },
      () => {
        this.callLeaderBoardApi();
      }
    );
  };

  onTabClick = selectedTab => {

    Constants.SetValues.leaderboardOpenIDX(null);
    this.setState(
      {
        selectedMainTab: selectedTab,
        selectedUserTab: 0,
        offset: 0,
        hasMore: false,
        selectedContest: "",
        selectedLeague: "",
        selectedRound: "",
        selectedMonth: "",
        Searchkey: "",
        selectedCategory: Constants.categories[0],
        selectedFollowOption: Constants.FollowOption[0],
        selectedContestType: "",
        searchClick: false,
        selectedContestDuration:Constants.contest_duration[0]
      },
      () => {
        this.callLeaderBoardApi();
        // if (selectedTab == 0 || selectedTab == 1 ) {
        //   this.callLeaderBoardApi();
        // }
      }
    );
  };

  renderLeaderboardData = () => {
    const {
      ownLeaderboard,
      leaderboardResult,
      selectedRound,
      selectedMonth,
      selectedRPLCupTab,
      isLoading,
      hasMore,
      selectedCategory,
      selectedContestType,
      selectedContest,
      selectedLeague,
      selectedFollowOption,
      selectedMainTab
    } = this.state;
    let isElimination = this.isRPLCupSelected() && selectedRPLCupTab == 1;
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "elimination-group",
      beforeChange: (current, next) => this.setState({ selectedGrpIDX: next }),
      afterChange: () => this.setState({ offset: 0, hasMore: false }, () => {
        this.callQualificationApi();
      })
    };

    return (
      <div className="leaderboard-data-wrap">
        {
          isElimination && <Slider ref={slider => (this.slider = slider)} {...settings}>
            {Constants.RPLGroupArray.map((item, idx) => {
              return (
                <div key={idx} className="p-2">
                  {item.label}
                </div>
              );
            })}
          </Slider>
        }
        <PanelGroup
          accordion
          id="accordion-controlled-example"
          onSelect={value => {
            Constants.SetValues.leaderboardOpenIDX(value);
          }}
        // defaultActiveKey={Constants.leaderboardOpenIDX}
        >
          {ownLeaderboard && ownLeaderboard.user_id && (
            <LeaderBoardRecord
              key={-1}
              selectedCategory={selectedCategory}
              selectedLeague={selectedLeague}
              selectedRound={selectedRound}
              selectedMonth={selectedMonth}
              selectedContestType={selectedContestType}
              selectedContest={selectedContest}
              selectedFollowOption={selectedFollowOption}
              selectedMainTab={selectedMainTab}
              isElimination={isElimination}
              idx={-1}
              item={ownLeaderboard}
              isOwn={true}
              {...this.props}
            />
          )}

          <div className="leaderboard-table-view leaderboard-table-head">
            <div className="rank-section">Rank</div>
            <div className="ref-section">ReFLeague</div>
            <div className="pl-section">PL</div>
            <div className={`rp-section ${this.state.current_rank == "RP" ? 'active' : ''}`}>RP</div>
            {(selectedMonth != "" && !selectedRound.value) && (
              <div className={`sp-section ${this.state.current_rank == "MP" ? 'active' : ''}`}>MP</div>
            )}
            {(selectedMonth == "" || selectedRound.value) && !isElimination && (
              <div className={`sp-section ${this.state.current_rank == "SP" ? 'active' : ''}`}>SP</div>
            )}
            {(selectedMonth == "" || selectedRound.value) && isElimination && (
              <div className={`sp-section ${this.state.current_rank == "CP" ? 'active' : ''}`}>CP</div>
            )}
            <div className="follow-section">Follow</div>
          </div>

          <InfiniteScroll
            pullDownToRefresh={false}
            dataLength={leaderboardResult.length}
            next={this.fetchMoreData}
            hasMore={hasMore}
          >
            {leaderboardResult.length > 0 &&
              leaderboardResult.map((item, idx) => {
                return (
                  <LeaderBoardRecord
                    key={idx}
                    selectedCategory={selectedCategory}
                    selectedLeague={selectedLeague}
                    selectedContest={selectedContest}
                    selectedMonth={selectedMonth}
                    selectedRound={selectedRound}
                    selectedContestType={selectedContestType}
                    selectedFollowOption={selectedFollowOption}
                    selectedMainTab={selectedMainTab}
                    isElimination={isElimination}
                    idx={idx}
                    item={item}
                    followAction={this.followAction}
                    {...this.props}
                  />
                );
              })}
            {leaderboardResult.length == 0 && !isLoading && (
              <NoDataScreen
                Content={{
                  ImgShown: Images.no_data_bg_image,
                  IsButton: true,
                  ButtonText: "Go to Lobby",
                  Title: "Oops! You may have joined this",
                  NoDataContent: "contest already, or it's closed.",
                  BtnAction: () => {
                    this.props.history.push({
                      pathname: "/dashboard/0"
                    });
                  },
                  WithFilter: ""
                }}
              />
            )}
          </InfiniteScroll>
        </PanelGroup>
      </div>
    );
  };

  renderRefLeague = () => {
    const {
      searchClick,
      selectedRound,
      selectedLeague,
      selectedContest,
      selectedMonth,
      selectedFormat,
      Searchkey,
      selectedCategory,
      selectedFollowOption,
      selectedMainTab,
    } = this.state;


    let filterOptions = ["league", "round", "contest", "month"];
    if (selectedMainTab == 2) {
      filterOptions = ["league", "round", "contest", "month", "network"];
    }
    return (
      <div className="leaderboard-wrapper">


        <div className="leaderboard-header">
          <div
            className="select-option-btn"
            onClick={() =>
              Header.FilterModalShow(
                {
                  filterOption: filterOptions,
                  selectedLeague: selectedLeague,
                  selectedRound: selectedRound,
                  selectedContest: selectedContest,
                  selectedMonth: selectedMonth,
                  selectedFormat: selectedFormat,
                  selectedCategory: selectedCategory,
                  selectedFollowOption: selectedFollowOption,
                  isFrom: selectedMainTab == 2 ? "PersonalLeaderboard" : "leaderboard",

                },
                this.filterAction.bind(this)
              )
            }
          >
            {selectedLeague != "" ||
              selectedRound != "" ||
              selectedContest != "" ||
              selectedMonth != ""
              ? "Filters Applied"
              : "Select"}
            <div className="arrow-section">
              <i className="icon-arrow-sort" />
            </div>
          </div>
        </div>

        <div className="heading-section ">
          <Row>
            <Col md={6} xs={12} className="p-v-sm hidden-xs purple">
              <React.Fragment>
                {/* <img
                  src={Images.LOGO}
                  width="20px"
                  alt=""
                  className="filter-with-logo"
                /> */}
                {selectedLeague.label || "Global Series"}
              </React.Fragment>
            </Col>
            <Col md={6} xs={12}>
              <div
                className={
                  "search-btn" +
                  (searchClick ? " search-full" : "")
                }
              >
                <FormControl
                  type="text"
                  placeholder="Search"
                  value={Searchkey}
                  onChange={e => {
                    this.setState({
                      Searchkey: e.target.value,
                      offset: 0,
                      hasMore: false
                    });
                    this.SearchUserReq();
                  }}
                />
                <div
                  className="search-box"
                  onClick={() =>
                    this.setState(
                      {
                        searchClick: !searchClick
                      },
                      () => {
                        if (
                          searchClick &&
                          Searchkey.length > 0
                        ) {
                          this.setState(
                            {
                              Searchkey: "",
                              offset: 0,
                              hasMore: false
                            },
                            () => {
                              setTimeout(() => {
                                this.callLeaderBoardApi();
                              }, 500);
                            }
                          );
                        }
                      }
                    )
                  }
                >
                  <i
                    className={
                      searchClick
                        ? "icon-close"
                        : "icon-search"
                    }
                  />
                </div>
              </div>
              <span className={"filter-by-name purple"}>
                <React.Fragment>
                  {/* <img
                    src={Images.LOGO}
                    width="20px"
                    alt=""
                    className="filter-with-logo"
                  /> */}
                  {selectedLeague.label || "Global Series"}
                </React.Fragment>
              </span>
            </Col>
          </Row>
          <div className={'er-links'}>
            {/* <a
              href
              onClick={() => this.showScoringRules()}
              className="earn-points-link"
            >How To Earn Points</a> */}
            <a
              // href
              onClick={() => this.showScoringRules()}
              className="earn-points-link"
            >Rules</a>

            <a
              // href
              // onClick={() => this.showScoringRules()}
              className="earn-points-link"
            >Prize</a>

            <a
              // href
              // onClick={() => this.showScoringRules()}
              className="earn-points-link"
            >STATs</a>

          </div>
        </div>
        {this.renderLeaderboardData()}
      </div>
    );
  }

  isRPLCupSelected() {
    return this.state.selectedLeague.value != '' && this.state.selectedLeague.value == 0
  }

  handleRPLCupTab = (tab) => {
    Constants.SetValues.leaderboardOpenIDX(null);
    this.setState(
      {
        selectedRPLCupTab: tab,
        offset: 0,
        hasMore: false,
        selectedMonth: this.state.selectedRPLCupTab == tab ? this.state.selectedMonth : '',
        selectedRound: this.state.selectedRPLCupTab == tab ? this.state.selectedRound : '',
        Searchkey: "",
        searchClick: false
      },
      () => {
        if (tab == 0 || tab == 1) {
          this.callQualificationApi();
        }
      }
    );
  }

  renderRPLCup = () => {

    const {
      searchClick,
      selectedRound,
      selectedLeague,
      selectedContest,
      selectedMonth,
      selectedFormat,
      Searchkey,
      leaderboardResult,
      isLoading,
      hasMore,
      selectedRPLCupTab,
      selectedMainTab,
      selectedFollowOption,
      selectedContestType,
      selectedCategory
    } = this.state;
    let isElimination = this.isRPLCupSelected() && selectedRPLCupTab == 1

    let filterOptions = ["league", "round", "contest", "month", "category"];
    if (selectedMainTab == 2) {
      filterOptions = ["league", "round", "contest", "month", "category", "network"];
    }

    return (
      <div className="leaderboard-wrapper">
        <div className="leaderboard-header">
          <div
            className="select-option-btn"
            onClick={() =>
              Header.FilterModalShow(
                {
                  filterOption: filterOptions,
                  selectedLeague: selectedLeague,
                  selectedRound: selectedRound,
                  selectedContest: selectedContest,
                  selectedMonth: selectedMonth,
                  selectedFormat: selectedFormat,
                  selectedFollowOption: selectedFollowOption,
                  isFrom: selectedMainTab == 2 ? "PersonalLeaderboard" : "leaderboard",
                  isElimination: isElimination
                },
                this.filterAction.bind(this)
              )
            }
          >
            {selectedLeague != "" ||
              selectedRound != "" ||
              selectedContest != "" ||
              selectedMonth != ""
              ? "Filters Applied"
              : "Select"}
            <div className="arrow-section">
              <i className="icon-arrow-sort" />
            </div>
          </div>
        </div>

        <div className="heading-section ">
          <Row>
            <Col md={6} xs={12} className="p-v-sm hidden-xs purple">
              <React.Fragment>
                {/* <img
                  src={Images.LOGO}
                  width="20px"
                  alt=""
                  className="filter-with-logo"
                /> */}
                {selectedLeague.label ||
                  "RPL Cup"}<span style={{ fontSize: 13, fontFamily: 'Muli-BoldItalic' }}> - {selectedFormat.label ||
                    "Stage"}</span>
              </React.Fragment>
            </Col>
            <Col md={6} xs={12}>
              <div
                className={
                  "search-btn" +
                  (searchClick ? " search-full" : "")
                }
              >
                <FormControl
                  type="text"
                  placeholder="Search"
                  value={Searchkey}
                  onChange={e => {
                    this.setState({
                      Searchkey: e.target.value,
                      offset: 0,
                      hasMore: false
                    });
                    this.SearchUserReq();
                  }}
                />
                <div
                  className="search-box"
                  onClick={() =>
                    this.setState(
                      {
                        searchClick: !searchClick
                      },
                      () => {
                        if (
                          searchClick &&
                          Searchkey.length > 0
                        ) {
                          this.setState(
                            {
                              Searchkey: "",
                              offset: 0,
                              hasMore: false
                            },
                            () => {
                              setTimeout(() => {
                                this.callLeaderBoardApi();
                              }, 500);
                            }
                          );
                        }
                      }
                    )
                  }
                >
                  <i
                    className={
                      searchClick
                        ? "icon-close"
                        : "icon-search"
                    }
                  />
                </div>
              </div>
              <span className={"filter-by-name purple"}>
                <React.Fragment>
                  {/* <img
                    src={Images.LOGO}
                    width="20px"
                    alt=""
                    className="filter-with-logo"
                  /> */}
                  {selectedLeague.label || "RPL Cup"}<span style={{ fontSize: 13, fontFamily: 'Muli-BoldItalic' }}> - {selectedFormat.label ||
                    "Stage"}</span>
                </React.Fragment>
              </span>
            </Col>
          </Row>
          <div className={'er-links'}>
            {/* <a
              href
              onClick={() => this.showScoringRules()}
              className="earn-points-link"
            >
              How To Earn Points
                              </a> */}

            <a
              href
              onClick={() => this.showScoringRules()}
              className="earn-points-link"
            >Rules</a>

            <a
              href
              // onClick={() => this.showScoringRules()}
              className="earn-points-link"
            >Prize</a>

            <a
              href
              // onClick={() => this.showScoringRules()}
              className="earn-points-link"
            >STATs</a>
          </div>
        </div>

        <Tab.Container
          id="tabs-with-dropdown1"
          defaultActiveKey="qualification"
          className="default-tab"
        >
          <Row className="clearfix">
            <Col sm={12}>
              <Nav bsStyle="tabs shadow m-b-sm">
                <NavItem onClick={() => this.handleRPLCupTab(0)} eventKey="qualification">
                  Qualification
                                  </NavItem>
                <NavItem
                  onClick={() => this.handleRPLCupTab(1)}
                  eventKey="seeding-elimination"
                  className="bg-label"
                >
                  Seeding/
                                    <br />
                  Elimination
                                  </NavItem>
                <NavItem onClick={() => this.handleRPLCupTab(2)} eventKey="final">Final</NavItem>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content animation>
                <Tab.Pane eventKey="qualification">
                  <div className="leaderboard-data-wrap">
                    <PanelGroup
                      accordion
                      id="accordion-controlled-example"
                    >
                      <div className="leaderboard-table-view leaderboard-table-head">
                        <div className="rank-section serial">#</div>
                        <div className="ref-section username">ReFPredictor</div>
                        <div className="pl-section batch">Batch</div>
                        <div className="follow-section">Follow</div>
                      </div>
                      <InfiniteScroll
                        pullDownToRefresh={false}
                        dataLength={leaderboardResult.length}
                        next={this.fetchMoreData}
                        hasMore={hasMore}
                      >
                        {leaderboardResult.length > 0 &&
                          leaderboardResult.map((item, idx) => {
                            return (
                              <LeaderBoardRecord
                                key={idx}
                                selectedCategory={selectedCategory}
                                selectedLeague={selectedLeague}
                                selectedRound={selectedRound}
                                selectedMonth={selectedMonth}
                                selectedContestType={selectedContestType}
                                selectedContest={selectedContest}
                                selectedFollowOption={selectedFollowOption}
                                selectedMainTab={selectedMainTab}
                                idx={idx}
                                item={item}
                                followAction={this.followAction}
                                isQualification={true}
                                {...this.props}
                              />
                            );
                          })}
                        {leaderboardResult.length == 0 && !isLoading && (
                          <NoDataScreen
                            Content={{
                              ImgShown: Images.no_data_bg_image,
                              IsButton: true,
                              ButtonText: "Go to Lobby",
                              Title: "Oops! You may have joined this",
                              NoDataContent: "contest already, or it's closed.",
                              BtnAction: () => {
                                this.props.history.push({
                                  pathname: "/dashboard/0"
                                });
                              },
                              WithFilter: ""
                            }}
                          />
                        )}
                      </InfiniteScroll>
                    </PanelGroup>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="seeding-elimination">
                  {this.renderLeaderboardData()}
                </Tab.Pane>
                <Tab.Pane eventKey="final">
                  <RPLFinalTab tab={selectedRPLCupTab} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }


  selectCategory = (obj) => {
    this.setState({
      selectedCategory:obj,
      selectedRound: "",
      selectedLeague: "",
      selectedContest: "",
      selectedMonth: "",
      selectedFormat: "",
      selectedFollow: "",
      selectedContestType: "",
      leaderboardResult:[],
      ownLeaderboard:[],
      offset: 0,
      hasMore: false,
    }, () => {
      this.callLeaderBoardApi()
    })
  }

  render() {
    const {
      searchClick,
      selectedRound,
      selectedLeague,
      selectedContest,
      selectedMonth,
      isLoading,
      selectedFollowOption,
      Searchkey,
      selectedUserTab,
      selectedMainTab,
      selectedCategory,
      selectedContestType,
      pageBanner,
      categories_list,
      selectedContestDuration
    } = this.state;
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };
    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container leaderboard-web-container web-container-white">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.leaderboard.title}</title>
              <meta
                name="description"
                content={MetaData.leaderboard.description}
              />
              <meta name="keywords" content={MetaData.leaderboard.keywords} />
            </Helmet>
            {isLoading && <Loader />}
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
            />

            
            <div>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={
                  selectedLeague.value == "1" || selectedLeague.value == "4"
                    ? "contest"
                    : "refpredictor"
                }
              >
                <Row>
                  <Col sm={12}>
                    <SubHeader
                      Title="Leaderboard"
                      ImgShown={Images.HEADER_BG}
                      BannerImage=""
                    // AddClassName="page-inner-header-xlg"
                    />
                    <Nav

                      className="tab-navigation tab-navigation-three tab-navigation-upper"
                    >
                      <NavItem
                        onClick={() => this.onTabClick(0)}
                        className={`${selectedMainTab == 0 ? 'active' : ''}`}
                      // eventKey="refpredictor"
                      >
                        ReFLeague
                      </NavItem>
                      <NavItem
                        onClick={() => this.onTabClick(1)}
                        className={`${selectedMainTab == 1 ? 'active' : ''}`}
                      // eventKey="contest"
                      >
                        Contests
                      </NavItem>
                      <NavItem
                        onClick={() => this.onTabClick(2)}
                        className={`${selectedMainTab == 2 ? 'active' : ''}`}
                      // eventKey="personal"
                      >
                        Personal
                      </NavItem>
                      {/* <NavItem
                        onClick={() => this.onTabClick(3)}
                        eventKey="refsocial"
                      >
                        ReFSocial <i className="icon-notification" />{" "}
                        <span className="red-dot" />
                      </NavItem> */}
                    </Nav>

                    {
                      (pageBanner && pageBanner.length > 0) &&
                      <div className={'lb-ban-wrap'}>
                        <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                          {pageBanner.map((itm, i) => {
                            return <div key={i} className={'lb-banner'}>
                              <img src={itm.image} />
                            </div>
                          })}
                        </Slider>
                      </div>
                    }

                    {/* {
                      selectedTab != 2 && */}
                      <div className={'lb_type_tab15'}>
                        {categories_list.map((itm, i) => {
                          return <div
                            className={`ct-tab ${selectedCategory.id == itm.id ? 'active' : ''}`}
                            onClick={() => this.selectCategory(itm)}
                            key={i}>{itm.name}</div>
                        })}
                      </div>
                    {/* } */}


                    <Tab.Content>

                      <Tab.Pane eventKey={0} className={`${selectedMainTab == 0 ? 'active in' : ''}`}>
                        {this.isRPLCupSelected() ? this.renderRPLCup() : this.renderRefLeague()}
                      </Tab.Pane>

                      <Tab.Pane eventKey={1} className={`${selectedMainTab == 1 ? 'active in' : ''}`}>
                        {
                          <div className="leaderboard-wrapper">
                            <div className="leaderboard-header">
                              <div
                                className="select-option-btn"
                                onClick={() =>
                                  Header.FilterModalShow(
                                    {
                                      filterOption: [
                                        "league",
                                        "round",
                                        "contest",
                                        "month",
                                        // "category",
                                        "contestType",
                                        "contestDuration"
                                      ],
                                      selectedLeague: selectedLeague,
                                      selectedRound: selectedRound,
                                      selectedContest: selectedContest,
                                      selectedMonth: selectedMonth,
                                      selectedCategory: selectedCategory,
                                      selectedContestType: selectedContestType,
                                      selectedContestDuration:selectedContestDuration,
                                      isFrom: "contest"
                                    },
                                    this.filterAction.bind(this)
                                  )
                                }
                              >
                                {selectedLeague != "" ||
                                  selectedRound != "" ||
                                  selectedContest != "" ||
                                  selectedMonth != ""
                                  ? "Filters Applied"
                                  : "Select"}
                                <div className="arrow-section">
                                  <i className="icon-arrow-sort" />
                                </div>
                              </div>
                            </div>

                            <div className="heading-section ">
                              <Row>
                                <Col
                                  md={6}
                                  xs={12}
                                  className="p-v-sm hidden-xs"
                                >
                                  <React.Fragment>
                                    {selectedLeague.label || (selectedContestType == "" || selectedContestType.value == 1) ? "Global Series" : ""}
                                  </React.Fragment>
                                </Col>
                                <Col md={6} xs={12}>
                                  <div
                                    className={
                                      "search-btn" +
                                      (searchClick ? " search-full" : "")
                                    }
                                  >
                                    <FormControl
                                      type="text"
                                      placeholder="Search"
                                      value={Searchkey}
                                      onChange={e => {
                                        this.setState({
                                          Searchkey: e.target.value,
                                          offset: 0,
                                          hasMore: false
                                        });
                                        this.SearchUserReq();
                                      }}
                                    />
                                    <div
                                      className="search-box"
                                      onClick={() =>
                                        this.setState(
                                          {
                                            searchClick: !searchClick
                                          },
                                          () => {
                                            if (
                                              searchClick &&
                                              Searchkey.length > 0
                                            ) {
                                              this.setState(
                                                {
                                                  Searchkey: "",
                                                  offset: 0,
                                                  hasMore: false
                                                },
                                                () => {
                                                  setTimeout(() => {
                                                    this.callLeaderBoardApi();
                                                  }, 500);
                                                }
                                              );
                                            }
                                          }
                                        )
                                      }
                                    >
                                      <i
                                        className={
                                          searchClick
                                            ? "icon-close"
                                            : "icon-search"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <span className={"filter-by-name"}>
                                    <React.Fragment>
                                      {selectedLeague.label || "Global Series"}
                                    </React.Fragment>
                                  </span>
                                </Col>
                              </Row>
                              <div className={'er-links'}>
                                {/* <a
                                  href
                                  onClick={() => this.showScoringRules()}
                                  className="earn-points-link"
                                >
                                  How To Earn Points
                                </a> */}

                                <a
                                  // href
                                  onClick={() => this.showScoringRules()}
                                  className="earn-points-link"
                                >Rules</a>

                                <a
                                  // href
                                  // onClick={() => this.showScoringRules()}
                                  className="earn-points-link"
                                >Prize</a>

                                <a
                                  // href
                                  // onClick={() => this.showScoringRules()}
                                  className="earn-points-link"
                                >STATs</a>
                              </div>
                            </div>

                            {selectedMainTab == 1 && (
                              <Tab.Container
                                id="tabs-with-dropdown"
                                defaultActiveKey={
                                  selectedUserTab == 0 ? "general" : "pundits"
                                }
                                className="default-tab default-tab-two"
                              >
                                <Row className="clearfix">
                                  {selectedLeague.value == "1" && (
                                    <Col sm={12}>
                                      <Nav bsStyle="tabs shadow m-b-sm">
                                        <NavItem
                                          onClick={() => this.onTabUserClick(0)}
                                          eventKey="general"
                                        >
                                          General
                                        </NavItem>
                                        <NavItem
                                          onClick={() => this.onTabUserClick(1)}
                                          eventKey="pundits"
                                        >
                                          Pundits Only
                                        </NavItem>
                                      </Nav>
                                    </Col>
                                  )}
                                  <Col sm={12}>
                                    <Tab.Content animation>
                                      <Tab.Pane eventKey="general">
                                        {this.renderLeaderboardData()}
                                      </Tab.Pane>
                                      <Tab.Pane eventKey="pundits">
                                        {this.renderLeaderboardData()}
                                      </Tab.Pane>
                                    </Tab.Content>
                                  </Col>
                                </Row>
                              </Tab.Container>
                            )}
                          </div>
                        }
                      </Tab.Pane>
                      <Tab.Pane eventKey={2} className={`${selectedMainTab == 2 ? 'active in' : ''}`}>
                        {/* <div className="leaderboard-header">
                          <FormGroup className="input-label-center input-transparent font-14 m-b-sm"> */}

                        {/* <div className="filter">
                              <div className="filterStyle">
                                <Select
                                  onChange={this.handleFollowerChange}
                                  options={Constants.FollowOption}
                                  classNamePrefix="secondary"
                                  className="select-secondary minusML10"
                                  placeholder="Select Filter"
                                  isSearchable={false}
                                  isClearable={false}
                                  value={selectedFollowOption}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#37003C"
                                    }
                                  })}
                                />
                              </div>
                              <span className="select-arr">
                                <i className="icon-arrow-sort" />
                              </span>
                              <div className="gender-border col-sm-12" />
                            </div> */}
                        {/* </FormGroup>
                          </div> */}
                        {this.isRPLCupSelected() ? this.renderRPLCup() : this.renderRefLeague()}

                        {/* <ComingSoon /> */}
                      </Tab.Pane>
                      {/* <Tab.Pane   className={`${selectedMainTab == 3 ? 'active in' : ''}`}>
                        <ComingSoon />
                      </Tab.Pane> */}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        )
        }
      </MyContext.Consumer>
    );
  }
}
