import React from "react";
import { Row, Col, Checkbox, FormGroup, Button } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import { Images, Header, RKFloatingLabelInput, SubHeader } from "../components";
import { PhoneVerify } from "../Modals";
import MetaData from "../helper/MetaData";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";

export default class SetSecurityPin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: WSManager.getProfile().security_pin_enabled == 1 ? WSManager.getProfile().security_pin : "",
      pinOption: WSManager.getProfile().security_pin_enabled == 1 ? true : false,
      showPhoneVerifyModal: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true, posting: true });
    let param = {
      security_pin_enabled: this.state.pinOption ? "1" : "0",
      security_pin: this.state.pinOption ? this.state.pin : ""
    };

    WSManager.Rest(NC.baseURL + NC.UPDATE_PIN, param).then(responseJson => {
      this.setState({ isLoading: false, posting: false });
      if (responseJson && responseJson.response_code === NC.successCode) {
        let profile = WSManager.getProfile();
        profile["security_pin"] = this.state.pinOption ? this.state.pin : "";
        profile["security_pin_enabled"] = this.state.pinOption ? "1" : "0";
        WSManager.setProfile(profile);
        notify.show(responseJson.message, "success", 3000);
        this.props.history.goBack();
      }
    });
  }

  ResendOtp = () => {
    let param = {
      phone_no: WSManager.getProfile().phone_no
    };
    WSManager.Rest(NC.baseURL + NC.RESEND_OTP, param).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        this.showPhoneVerifyModal();
      }
    });
  };

  showPhoneVerifyModal = () => {
    this.setState({
      showPhoneVerifyModal: true
    });
  };
  PhoneVerifyModalHide = () => {
    this.setState({
      showPhoneVerifyModal: false
    });
  };

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  render() {
    const { pin, pinOption, showPhoneVerifyModal } = this.state;
    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container ">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.pin.title}</title>
              <meta name="description" content={MetaData.pin.description} />
              <meta name="keywords" content={MetaData.pin.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
              showNavigation={false}
            />
            <div className="otp-field-container">
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Set Security Pin" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="set-pin-label">Set Security Pin</div>
                  <ul className="set-pin">
                    <li>
                      <FormGroup>
                        <Checkbox
                          className="custom-checkbox"
                          checked={pinOption}
                          onClick={() => this.setState({ pinOption: true })}
                        >
                          <span>Yes</span>
                        </Checkbox>
                      </FormGroup>
                    </li>
                    <li>
                      <FormGroup>
                        <Checkbox
                          className="custom-checkbox "
                          onClick={() => this.setState({ pinOption: false })}
                          checked={pinOption === false}
                        >
                          <span>No</span>
                        </Checkbox>
                      </FormGroup>
                    </li>
                  </ul>
                </Col>
              </Row>
              {pinOption && (
                <div className="pin-block verification-block">
                  <Row>
                    <Col xs={12} className="otp-field">
                      <label>Enter your Pin</label>
                      <RKFloatingLabelInput
                        className={
                          "rkfloatingLabelInput editp" +
                          (pin.length > 1 ? " pdding-right" : "")
                        }
                        id="pin"
                        autocomplete="off"
                        type={
                          WSManager.getProfile().security_pin_enabled == 1
                            ? "password"
                            : "text"
                        }
                        isNumberOnly={true}
                        name="pin"
                        value={pin}
                        placeholder=""
                        onChange={this.onChange}
                        maxLength={4}
                      />
                    </Col>
                    <Col xs={12}>
                      <div className="help-text">
                        Only numeric characters allowed
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <div className="text-center profile-btn-section">
              <Button
                disabled={pinOption && pin.trim().length < 4}
                onClick={e =>
                  WSManager.getProfile().security_pin_enabled == 1
                    ? this.ResendOtp(e)
                    : this.onSubmit(e)
                }
                className="btn btn-rounded btn-primary mt-4"
              >
                Save changes
              </Button>
            </div>
            {showPhoneVerifyModal && (
              <PhoneVerify
                isFromPin={true}
                phoneNo={WSManager.getProfile().phone_no}
                onSubmit={this.onSubmit}
                IsPhoneVerifyModalShow={showPhoneVerifyModal}
                IsPhoneVerifyModalHide={this.PhoneVerifyModalHide}
              />
            )}
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
