import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../context';
import { Header, SubHeader, Images } from '../components';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";

export default class Pass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true,
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.lobby.title}</title>
                            <meta name="description" content={MetaData.lobby.description} />
                            <meta name="keywords" content={MetaData.lobby.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} />
                        <div >
                            <Row>
                                <Col sm={12}>
                                    <SubHeader Title="1st Pass" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                                    <table className="pass-detail">
                                        <thead>
                                            <tr>
                                                <th>Rank</th>
                                                <th>ReFpredictor</th>
                                                <th>Points</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="rank">1</td>
                                                <td className="user-detail">
                                                    <img src={Images.USERIMG2} alt="" />
                                                    <span>#Born2Win</span>
                                                </td>
                                                <td className="points">
                                                    39
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="rank">2</td>
                                                <td className="user-detail">
                                                    <img src={Images.USERIMG3} alt="" />
                                                    <span>Sara#Arms</span>
                                                </td>
                                                <td className="points">
                                                    42
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="rank">3</td>
                                                <td className="user-detail">
                                                    <img src={Images.USERIMG} alt="" />
                                                    <span>#Born2Win</span>
                                                </td>
                                                <td className="points">
                                                    49
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}
