import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { Images, SubHeader, Header } from '../components';
import { MyContext } from '../context';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";

export default class FansChallenge extends React.Component {
    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            show: false,
        }
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true,
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container lobby-web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.lobby.title}</title>
                            <meta name="description" content={MetaData.lobby.description} />
                            <meta name="keywords" content={MetaData.lobby.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} showFilter={this.showFilter} />
                        <div >
                            <Row>
                                <Col sm={12}>
                                    <SubHeader Title="Fans Challenge" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                                    {/* Fans Challenge section select palyer start */}
                                    <div className="fan-challenge-team-selection fan-challenge-team-selection-alt">
                                        <div className="fans-challenge-list-wrapper">
                                            <ul>
                                                <li>
                                                    <img src={Images.SWEDEN} alt="" className="team-img" />
                                                    <p className="team-name">NFA</p>
                                                </li>
                                                <li>
                                                    <div className="contest-type">Fans <br /> Challenge</div>
                                                    <div className="fan-count">1000 Fans</div>
                                                    <a href className="btn btn-rounded btn-reflive" onClick={this.handleShow}>ReFLive</a>
                                                </li>
                                                <li>
                                                    <img src={Images.SWEDEN} alt="" className="team-img" />
                                                    <p className="team-name">NFA</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="user-pts-section">
                                            <div className="your-name">Mark.Zucker</div>
                                            <div className="user-pts">14 points</div>
                                            <span className="comment-span">
                                                <i className="icon-comment"></i>
                                            </span>
                                        </div>
                                        <div className="select-player-section">
                                            <table className="select-player-list">
                                                <thead>
                                                    <tr>
                                                        <th>Username</th>
                                                        <th>Pts</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src={Images.USERIMG} alt="" />
                                                            <span>Mark.Zucker</span>
                                                            <i className="icon-add-user"></i>
                                                        </td>
                                                        <td>14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src={Images.USERIMG} alt="" />
                                                            <span>Mark.Zucker</span>
                                                            <i className="icon-add-user"></i>
                                                        </td>
                                                        <td>14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src={Images.USERIMG} alt="" />
                                                            <span>Mark.Zucker</span>
                                                            <i className="icon-add-user"></i>
                                                        </td>
                                                        <td>14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src={Images.USERIMG} alt="" />
                                                            <span>Mark.Zucker</span>
                                                            <i className="icon-add-user"></i>
                                                        </td>
                                                        <td>14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src={Images.USERIMG} alt="" />
                                                            <span>Mark.Zucker</span>
                                                            <i className="icon-add-user"></i>
                                                        </td>
                                                        <td>14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src={Images.USERIMG} alt="" />
                                                            <span>Mark.Zucker</span>
                                                            <i className="icon-add-user"></i>
                                                        </td>
                                                        <td>14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src={Images.USERIMG} alt="" />
                                                            <span>Mark.Zucker</span>
                                                            <i className="icon-add-user"></i>
                                                        </td>
                                                        <td>14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src={Images.USERIMG} alt="" />
                                                            <span>Mark.Zucker</span>
                                                            <i className="icon-add-user"></i>
                                                        </td>
                                                        <td>14</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="select-player-list select-player-list-right">
                                                <thead>
                                                    <tr>
                                                        <th>Pts</th>
                                                        <th>Username</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>
                                                            <i className="icon-add-user"></i>
                                                            <span>Mark.Zucker</span>
                                                            <img src={Images.USERIMG} alt="" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>
                                                            <i className="icon-add-user"></i>
                                                            <span>Mark.Zucker</span>
                                                            <img src={Images.USERIMG} alt="" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>
                                                            <i className="icon-add-user"></i>
                                                            <span>Mark.Zucker</span>
                                                            <img src={Images.USERIMG} alt="" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>
                                                            <i className="icon-add-user"></i>
                                                            <span>Mark.Zucker</span>
                                                            <img src={Images.USERIMG} alt="" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>
                                                            <i className="icon-add-user"></i>
                                                            <span>Mark.Zucker</span>
                                                            <img src={Images.USERIMG} alt="" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>
                                                            <i className="icon-add-user"></i>
                                                            <span>Mark.Zucker</span>
                                                            <img src={Images.USERIMG} alt="" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>
                                                            <i className="icon-add-user"></i>
                                                            <span>Mark.Zucker</span>
                                                            <img src={Images.USERIMG} alt="" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* Fans Challenge section select palyer end */}
                                </Col>
                            </Row>
                        </div>
                        <table className="select-player-list last-fan-joined-section total-pts">
                            <tbody>
                                <tr>
                                    <td>Total Pts<p className="timer">18:10:08</p></td>
                                    <td>37</td>
                                    <td>30</td>
                                    <td>Total Pts<p className="timer">18:10:08</p></td>
                                </tr>
                            </tbody>
                        </table>
                        <Modal show={this.state.show} onHide={this.handleClose} className="joined-modal">
                            <Modal.Body>
                                <div className="text-center">
                                    <img src={Images.CONGRATS_BG} alt="" />
                                    <div className="name">CONGRATULATIONS!</div>
                                    <p className="joined-txt">Mark.Zucker You and other NFA fans won the Fans Challenge.</p>
                                    <a href className="btn btn-rounded btn-block btn-xlg">Ok</a>
                                    <div className="share-txt"><i className="icon-share"></i>Share</div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
