import React from 'react';
import { MyContext } from "../context";
import CreatePrivateContest from './CreatePrivateContest';
import { Header, SubHeader, Loader, Images } from "../components";
import { Row, Col, ProgressBar, FormGroup } from "react-bootstrap";

class EditContest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: "",
            data: {}
        }
    }


    componentWillMount() {
        let location = this.props.location;

        // console.log(location.state.data)
        if (location.state && location.state.data) {
            this.setState({
                data: location.state.data,
                selectedCategory: location.state.data.selectedCategory
            })
        } else {
            window.location.href = "/"
        }
    }

    render() {


        const HeaderOption = {
            back: true,
            Notification: false,
            Logo: true
        };


        return (

            <MyContext.Consumer>
                {context => (
                    <React.Fragment>

                        <div className="web-container edit-contest-container">
                            <Header {...this.props} HeaderOption={HeaderOption} showNavigation={false} />

                            <Row>
                                <Col sm={12}>
                                    <SubHeader
                                        Title="Edit Contest"
                                        ImgShown={Images.HEADER_BG}
                                        BannerImage=""
                                        AddClassName="page-inner-header-sm"
                                    />
                                </Col>
                            </Row>

                            <CreatePrivateContest
                                {...this.props}
                                type={2}
                                data={this.state.data}
                            />

                        </div>
                    </React.Fragment>
                )}
            </MyContext.Consumer>

        )
    }
}

export default EditContest;