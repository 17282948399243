import React from "react";
import { Row, Col, FormGroup, Tab, Nav, NavItem, ToggleButton, ToggleButtonGroup, ButtonToolbar } from "react-bootstrap";
import { Images, Header, Loader, RKFloatingLabelInput, SubHeader, FlutterWave, } from "../components";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import _ from "lodash";
import MetaData from "../helper/MetaData";
import RefPayCardHeader from "./RefPayCardHeader";
import PaystackButton from "react-paystack";
// import { InterswitchPay } from 'react-interswitch'
import { FlutterWaveButton, closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';

import {FooterClassAdd} from '../ReduxStore/Actions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class AddFunds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      updateField: true,
      selectedAmountID: 0,
      depositeData: "",
      isLoading: false,
      refreshPage: true,
      isMembership: !_.isUndefined(props.location.state) ? props.location.state.isMembership : false,
      countineType: !_.isUndefined(props.location.state) ? props.location.state.countineType : "",
      activeTab: '1'
    };
    this.onChange = this.onChange.bind(this);
    this._paystackBtn = React.createRef();
    this._flutterWaveBtn = React.createRef();
    this._InterswitchBtn = React.createRef();
  }

  callWSForDeposit() {

    if (this.state.depositCalled) { //to stop multiple triggers
      console.log('depositCalled')
      return;
    }

    this.setState({ isLoading: true });

    let api = NC.DEPOSIT_BY_FLUTTERWAVE;
    if(this.state.activeTab == "2") {
      api = NC.DEPOSIT_BY_PAYSTACK;
    }

    WSManager.Rest(NC.baseURL + api, {
      amount: this.state.amount
    }).then(responseJson => {
      this.setState({ isLoading: false });
      if (responseJson.response_code === NC.successCode) {
        this.setState({ depositeData: responseJson.data, depositCalled: true }, () => {

          setTimeout(() => {
            if (this.state.activeTab == '1') {
              this._flutterWaveBtn.current.click();


            } else {
              this._paystackBtn.current.payWithPaystack();
            }

          }, 100);
        });
      }
    });
  }



  callback = response => {

    let user_profile = WSManager.getProfile();

    this.setState({ depositCalled: false })

    // setTimeout(() => {

      if (response.status === "success" || response.status === "successful") {

        let api = NC.DEPOSIT_BY_FLUTTERWAVE_VERIFY;
        let param = {
          transaction_id: this.state.depositeData.transaction_id,
          reference: response.flw_ref
        }
        if (this.state.activeTab == "2") {
          api = NC.DEPOSIT_BY_PAYSTACK_VERIFY;
          param = {
            transaction_id: this.state.depositeData.transaction_id,
            reference: response.reference
          }
        }


        this.setState({ isLoading: true });
        WSManager.Rest(NC.baseURL + api, param).then(responseJson => {
          if (responseJson.response_code === NC.successCode) {
            notify.show(responseJson.message, "success", 2000);
            if (this.state.isMembership && user_profile.plan_id == 1) {
              let globalThis = this;
              Constants.SetUserBalance.callBalanceApi()
                .then(isSuccess => {
                  this.setState({ isLoading: false, refreshPage: false }, () => {
                    this.setState({ refreshPage: true });
                  });
                  let balance = Constants.userBalanceInfo.user_balance.real_amount + Constants.userBalanceInfo.user_balance.winning_amount;


                  if (this.props.location.state != undefined && parseFloat(balance) >= parseFloat(this.props.location.state.plan_amount)) {

                    Header.showPaymentConfirmModal(
                      this.props.location.state.plan_id,
                      this.props.location.state.plan_amount,
                      this.state.countineType
                    );
                  } else {
                    globalThis.props.history.goBack();
                  }
                });
            } else {
              this.setState({ isLoading: true });

              Constants.SetUserBalance.callBalanceApi().then(isSuccess => { });
              this.props.history.goBack();
            }
          }

          else {
            this.setState({ isLoading: false });

            let api = NC.DEPOSIT_BY_FLUTTERWAVE_CANCEL;

            if (this.state.activeTab == "2") {
              api = NC.DEPOSIT_BY_PAYSTACK_CANCEL;
            }

            WSManager.Rest(NC.baseURL + api, {
              transaction_id: this.state.depositeData.transaction_id
            }).then(responseJson => { });
          }
        });
      }
      

    // }, 5000)
  };

  close = () => {
    console.log('popclosed')
    this.setState({ depositCalled: false })
  };


  onChange(e) {
    const value = e.target.value;
    this.setState({ amount: value, selectedAmountID: 0 });
  }

  componentDidMount(){
    this.props.FooterClassAdd('refpay-foot')
  }

  componentWillUnmount(){
    this.props.FooterClassAdd('')
  }


  tabsChange = (key) => {
    this.setState({ activeTab: key, depositCalled:false })
  }

  render() {
    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };

    const {
      amount,
      depositeData,
      updateField,
      selectedAmountID,
      isLoading,
      refreshPage,
      activeTab
    } = this.state;



    const flutterWaveConfig = {
      public_key: NC.FLUTTERWAVE_PUBLIC_KEY,
      tx_ref: depositeData.transaction_id,
      amount: amount,
      currency: 'NGN',
      customer: depositeData,
      customizations: {
        title: 'Add Funds',
        description: 'Payment for Add funds',
        logo: Images.logo,
      },
    };

    // const flutterWaveConfig2 = {
    //   public_key: NC.FLUTTERWAVE_PUBLIC_KEY,
    //   tx_ref: Date.now(),
    //   amount: 100,
    //   currency: 'NGN',
    //   // payment_options: "card,ussd,banktransfer",
    //   customer: {
    //     email: 'user@gmail.com',
    //     phonenumber: '07064586146',
    //     name: 'joel ugwumadu',  
    //   },
    //   customizations: {
    //     title: 'Add Funds',
    //     description: 'Payment for Add funds',
    //     logo: Images.logo,
    //   },
    //   callback: (response) => {
    //     console.log('response: ', response)
    //   },
    //   onClose: this.close,
    //   text:'Add Funds'
    // };

    const InterSwitchConfig = {
      merchantCode: 'XXXXXXX',
      payItemID: 'XXXXXXXXXXXX',
      customerEmail: 'johndoe@gmail.com',
      redirectURL: 'http://localhost:3000',
      text: 'Add Funds',
      mode: 'TEST',
      transactionReference: depositeData.transaction_id,
      amount: '10000',
      callback: (response) => {
        console.log('response: ', response)
      }
    }

    return (
      <MyContext.Consumer>



        {context => (
          <div className="web-container refpay-web-container web-container-white">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.addfunds.title}</title>
              <meta
                name="description"
                content={MetaData.addfunds.description}
              />
              <meta name="keywords" content={MetaData.addfunds.keywords} />
            </Helmet>
            {isLoading && <Loader />}
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
              showNavigation={false}
            />

            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader
                    Title="ReFPay"
                    ImgShown={Images.REFPAY_HEADER_BG}
                    BannerImage=""
                    AddClassName="page-inner-header-lg refpay-header" />
                </Col>
              </Row>
              {refreshPage && <RefPayCardHeader />}
              <div className="recent-transaction-label text-center">
                Add Funds
              </div>
              <Tab.Container
                id="tabs-with-dropdown"
                defaultActiveKey="1"
                className="add-funds-tab"
              >
                <Row className="clearfix">
                  <Col sm={12}>
                    <div className={'py-st-tab'}>
                      <Nav
                        bsStyle="tabs"
                        className="tab-navigation tab-navigation-100"
                        style={{ border: "none" }}
                        onClick={() => this.tabsChange('1')}
                      >
                        <NavItem eventKey="1">Channel 1</NavItem>
                      </Nav>
                      <Nav
                        bsStyle="tabs"
                        className="tab-navigation tab-navigation-100"
                        style={{ border: "none" }}
                        onClick={() => this.tabsChange('2')}
                      >
                        <NavItem eventKey="2">Channel 2</NavItem>
                      </Nav>
                    </div>
                  </Col>

                  {/* <FlutterWaveButton {...flutterWaveConfig2} /> */}

                  {/* <InterswitchPay {...InterSwitchConfig} /> */}


                  <Col sm={12}>


                    <div className="paytm-view">
                      <div className="verification-block full-width-input">
                        <Row>
                          <Col xs={12}>
                            <FormGroup
                              className="input-label-center input-transparent font-14"
                              controlId="formBasicText"
                            >
                              {updateField && (
                                <RKFloatingLabelInput
                                  className="rkfloatingLabelInput editp"
                                  id="add-amount"
                                  autocomplete="off"
                                  type="text"
                                  isNumberOnly={true}
                                  name="add-amount"
                                  //value={selectedAmountID > 0 ? "" : amount}
                                  value={amount}
                                  placeholder="Add Amount to your Wallet (₦)"
                                  onChange={this.onChange}
                                  maxLength="6"
                                />
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Col xs={12}>
                          <ButtonToolbar className="custom-radio-group">
                            <ToggleButtonGroup type="radio" name="options">
                              <ToggleButton
                                onClick={() =>
                                  this.setState({ amount: 500, updateField: false, selectedAmountID: 1 },
                                    () => {
                                      this.setState({ updateField: true });
                                    })}
                                value={selectedAmountID > 0 ? 1 : 0}
                              >
                                ₦500
                                  </ToggleButton>
                              <ToggleButton
                                onClick={() =>
                                  this.setState({ amount: 1000, updateField: false, selectedAmountID: 2 },
                                    () => {
                                      this.setState({ updateField: true });
                                    })}
                                value={selectedAmountID > 0 ? 2 : 0}
                              >
                                ₦1,000
                                  </ToggleButton>
                              <ToggleButton
                                onClick={() =>
                                  this.setState({ amount: 5000, updateField: false, selectedAmountID: 3 },
                                    () => {
                                      this.setState({ updateField: true });
                                    })}
                                value={selectedAmountID > 0 ? 3 : 0}
                              >
                                ₦5,000
                                  </ToggleButton>
                              <ToggleButton
                                onClick={() =>
                                  this.setState({ amount: 10000, updateField: false, selectedAmountID: 4 },
                                    () => {
                                      this.setState({ updateField: true });
                                    })}
                                value={selectedAmountID > 0 ? 4 : 0}
                              >
                                ₦10,000
                                  </ToggleButton>
                              <ToggleButton
                                onClick={() =>
                                  this.setState({ amount: 50000, updateField: false, selectedAmountID: 5 },
                                    () => {
                                      this.setState({ updateField: true });
                                    })}
                                value={selectedAmountID > 0 ? 5 : 0}
                              >
                                ₦50,000
                                  </ToggleButton>
                              <ToggleButton
                                onClick={() =>
                                  this.setState({ amount: 100000, updateField: false, selectedAmountID: 6 },
                                    () => {
                                      this.setState({ updateField: true });
                                    })}
                                value={selectedAmountID > 0 ? 6 : 0}
                              >
                                ₦100,000
                                  </ToggleButton>
                            </ToggleButtonGroup>
                          </ButtonToolbar>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="text-center m-b-80">
                          <img
                            src={Images.PAYMENT_METHOD}
                            alt=""
                            className="payment-method-img"
                          />
                        </Col>
                      </Row>
                    </div>
                    {/* <Tab.Content animation>
                      <Tab.Pane eventKey="1" ></Tab.Pane>
                      <Tab.Pane eventKey="2"></Tab.Pane>
                    </Tab.Content> */}
                  </Col>
                </Row>
              </Tab.Container>
            </div>



            <div
              onClick={() => amount === "" || amount < 500 ? "" : this.callWSForDeposit()}
              className="page-footer"
            >

              {
                activeTab == '1' ?
                  <FlutterWave
                    config={flutterWaveConfig}
                    MyRef={this._flutterWaveBtn}
                    disabled={amount === "" || amount < 500}
                    className={'btn btn-block btn-success text-white btn-paystack-text'}
                    callback={this.callback}
                    onClose={this.close}
                    text={"Add Funds"}
                  />
                  :
                  activeTab == '2' ?
                    <PaystackButton
                      text="Add Funds"
                      class="btn btn-block btn-success text-white btn-paystack-text"
                      callback={this.callback}
                      close={this.close}
                      disabled={amount === "" || amount < 500}
                      embed={false}
                      email={depositeData.email}
                      amount={depositeData.amount}
                      metadata={depositeData.metadata}
                      paystackkey={NC.PAYSTACK_PUBLIC_KEY}
                      tag="button"
                      ref={this._paystackBtn}
                    />
                    : ''
              }




            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  FooterClassAdd
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddFunds);
