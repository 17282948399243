import React from 'react';
import { MyContext } from "../context";
import { Header, SubHeader, Loader, Images } from "../components";
import { Row, Col, ProgressBar, FormGroup } from "react-bootstrap";
import * as NC from "../helper/NetworkingConstants";
import WSManager from "../helper/WSManager";
import * as Constants from "../helper/Constants";
import { notify } from "react-notify-toast";
import util from "util";



var globalThis = null;
class GameDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            game_detail: null
        }
    }



    componentDidMount() {
        globalThis = this;
    }






    render() {

        const { game_detail } = this.state;
        const HeaderOption = {
            back: true,
            Notification: false,
            Logo: true
        };


        return (
            <MyContext.Consumer>
                {context => (
                    <React.Fragment>

                        <div className="web-container edit-contest-container">
                            <Header {...this.props} HeaderOption={HeaderOption} showNavigation={false} />

                            <Row>
                                <Col sm={12}>
                                    <SubHeader
                                        Title="Game Detail"
                                        ImgShown={Images.HEADER_BG}
                                        BannerImage=""
                                        AddClassName="page-inner-header-sm"
                                    />
                                </Col>
                            </Row>

                        

                        </div>
                    </React.Fragment>
                )}
            </MyContext.Consumer>
        )
    }
}


export default GameDetail;