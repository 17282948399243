
import _ from 'lodash';

const LocalHostName = [
    "local.refpredictorreact.com"
]

const DevHostname = [
    "192.168.0.41",
    "192.168.0.197",
    "192.168.0.55",
    "192.168.0.102",
    "192.168.0.166",
    "192.168.0.90",
    "192.168.159.249",
    "localhost"
]

const StagingHostname = [
    "134.209.145.148",
    "139.59.2.13",
    "159.89.170.66",
    "www.refpredictor.vinfotechprojects.com",
    "refpredictor.vinfotechprojects.com",
    "www.refleague.vinfotechprojects.com",
    "refleague.vinfotechprojects.com",
    "www.refsocial.vinfotechprojects.com",
    "refsocial.vinfotechprojects.com",
    "www.reflive.vinfotechprojects.com",
    "reflive.vinfotechprojects.com",
    "www.refpay.vinfotechprojects.com",
    "refpay.vinfotechprojects.com"
]



const regex = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm
const hostname = window.location.hostname;
const protocol = window.location.protocol;
const origin = window.location.origin;


const localRefpredictorPort = 4444;
const localRefpayPort = 4446;
const localRefsocialPort = 4447;
const localReflivePort = 4448;


const local = {
    isLOCAL: true,
    lsPrefix: "dev_",
    base_url: "https://local.refpredictorreact.com/",
    MyUrl: origin,
    RefpredictoUrl: `http://localhost:${localRefpredictorPort}/`,
    RefpayUrl: `http://localhost:${localRefpayPort}/`,
    RefsocialUrl: `http://localhost:${localRefsocialPort}/`,
    RefliveUrl: `http://localhost:${localReflivePort}/`
};


const development = {
    isLOCAL: true,
    lsPrefix: "dev_",
    base_url: "https://refpredictor.vinfotechprojects.com/",
    MyUrl: origin,
    RefpredictoUrl: `http://localhost:${localRefpredictorPort}/`,
    RefpayUrl: `http://localhost:${localRefpayPort}/`,
    RefsocialUrl: `http://localhost:${localRefsocialPort}/`,
    RefliveUrl: `http://localhost:${localReflivePort}/`
};


const staging = {
    isLOCAL: true,
    lsPrefix: "staging_",
    base_url: "https://refpredictor.vinfotechprojects.com/",
    MyUrl: origin,
    RefpredictoUrl:`https://refpredictor.vinfotechprojects.com/`,
    RefpayUrl: `https://refpay.vinfotechprojects.com/`,  
    RefsocialUrl:`https://refsocial.vinfotechprojects.com/`,
    RefliveUrl: `https://reflive.vinfotechprojects.com/`
};

const production = {
    isLOCAL: false,
    lsPrefix: "production_",
    base_url: "https://refsocial.app/",
    MyUrl: origin,
    RefpredictoUrl:`https://refsocial.app/`,
    RefpayUrl: `https://www.refpay.app/`,
    RefsocialUrl: `https://www.refid.app/`,
    RefliveUrl: `https://www.reflive.app/`
};


const config = _.includes(LocalHostName, hostname) ? local :
    _.includes(DevHostname, hostname) ? development :
        _.includes(StagingHostname, hostname) ? staging :
            production;



export default {
    ...config
};