import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Header, Images } from "../components";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import _ from "lodash";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    let masterData = WSManager.getMasterData();
    if (!masterData) {
      this.callWSGetMasterData();
    } else {
      this.setMasterDataConst(masterData);
    }
  }

  callWSGetMasterData() {
    WSManager.Rest(NC.baseURL + NC.MASTER_DATA).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        WSManager.setMasterData(responseJson.data);
        this.setMasterDataConst(responseJson.data);
      }
    });
  }

  setMasterDataConst(masterData) {
    if (masterData.languages.length > 0) {
      let tmpArray = [];
      _.map(masterData.languages, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.language(tmpArray);
    }

    if (masterData.networks.length > 0) {
      let tmpArray = [];
      _.map(masterData.networks, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.networks(tmpArray);
    }

    if (masterData.leagues.length > 0) {
      let tmpArray = [];
      _.map(masterData.leagues, (item, idx) => {
        tmpArray.push({ label: item, value: item });
      });
      Constants.SetValues.leagues(tmpArray);
    }

    if (masterData.scoring_rules.length > 0) {
      let tmpArray = [];
      _.map(masterData.scoring_rules, (item, idx) => {
        tmpArray.push({ question_name: item.question_name, score: item.score });
      });
      Constants.SetValues.scoring_rules(tmpArray);
    }
    Constants.SetValues.clubs(masterData.club);
    Constants.SetValues.membership_plans(masterData.membership_plans);

    if (masterData.categories.length > 0) {
      Constants.SetValues.set_categories(masterData.categories);
    }

  }



  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.lobby.title}</title>
              <meta name="description" content={MetaData.lobby.description} />
              <meta name="keywords" content={MetaData.lobby.keywords} />
            </Helmet>

            <Header {...this.props} HeaderOption={HeaderOption} />

            <div>
              <Row>
                <Col sm={12}>
                  <div className="page-inner-header page-inner-header-sm">
                    <h2>Dashboard</h2>
                    <img src={Images.HEADER_BG} alt="" className="header-bg" />
                  </div>
                  <div className="p-20 text-center">
                    <Button
                      className="btn btn-rounded btn-lg btn-primary mr-3"
                      onClick={() => Header.LoginModalShow()}
                    >
                      Login
                    </Button>
                    <Button
                      className="btn btn-rounded btn-lg btn-primary"
                      onClick={() => Header.SignupModalShow()}
                    >
                      Signup
                    </Button>
                  </div>
                  <div className="p-20 text-center">
                    <Button
                      className="btn btn-rounded btn-lg btn-primary"
                      onClick={() => Header.CompleteProfileShow()}
                    >
                      Complete Profile
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
