

const BaseURL = () => {
  let base_url = "";
  switch (window.location.hostname) {
    case "192.168.0.41":
    case "192.168.0.197":
    case "192.168.0.55":
    case "192.168.0.102":
    case "192.168.0.166":
    case "192.168.0.90":
    case "192.168.159.249":
    case "localhost":
      isLOCAL = true;
      lsPrefix = "dev_";
      base_url = stagin_url;
      // base_url = stagin_ip_url;
      isBaseName=false;
      ParentUrl = `http://localhost:${localParentPort}`;
      break;
    case "134.209.145.148":
    case "139.59.2.13":
    case "www.refpredictor.vinfotechprojects.com":
    case "refleague.vinfotechprojects.com":
      lsPrefix = "staging_";
      base_url = stagin_url;
      isLOCAL = true;
      isBaseName=true;
      ParentUrl = "https://www.refpredictor.vinfotechprojects.com";
      break;
    case "159.89.170.66":
      isLOCAL = true;
      lsPrefix = "staging_";
      base_url = stagin_ip_url;
      isBaseName=true;
      ParentUrl = "https://www.refpredictor.vinfotechprojects.com";
      break;
    case "local.refpredictorreact.com":
      isLOCAL = true;
      lsPrefix = "dev_";
      base_url = local_virtual_url;
      isBaseName=true;
      ParentUrl = "http://local.refpredictorreact.com";
      break;
    default:
      lsPrefix = "production_";
      base_url = production_url;
      isLOCAL = false;
      isBaseName=true;
      ParentUrl = production_url;
      break;
  }
  return base_url;
};

export const getImageBaseUrl = image => {
  // let IMAGE_BASE_URL = getS3BucketPath() + "assets/img/" + image;
  // return isLOCAL ? require("./../assets/img/" + image) : IMAGE_BASE_URL;
  return  require("./../assets/img/" + image) 
};

const getS3BucketPath = () => {
  let S3_URL = s3_live;
  switch (window.location.hostname) {
    case "localhost":
    case "134.209.145.148":
    case "139.59.2.13":
    case "159.89.170.66":
      S3_URL = s3_dev;
      break;
    default:
      S3_URL = s3_live;
  }
  return S3_URL;
};

export class Language {
  static update(data) {
    Locale = data;
  }
}
const getFacebookAppId = () => {
  var FB_APP_ID = "";
  switch (window.location.hostname) {
    default:
      FB_APP_ID = "796690807349813";
      break;
  }
  return FB_APP_ID;
};

const getFacebookSecret = () => {
  var FB_SECRET = "";
  switch (window.location.hostname) {
    default:
      FB_SECRET = "b842875a33c9b62c91394b6fa9c5d547";
      break;
  }
  return FB_SECRET;
};
export var local_virtual_url = 'http://local.refpredictorreact.com/';
export var local_url = 'https://159.89.170.66/';
// export var stagin_url = 'http://139.59.2.13/';
export var stagin_ip_url = 'https://159.89.170.66/';
export var stagin_url = 'https://refpredictor.vinfotechprojects.com/';
export var production_url = 'https://refsocial.app/';
export var s3_live = "https://ref-vinfotech-new.s3.us-west-2.amazonaws.com/";
export var s3_dev = "https://ref-vinfotech-new.s3.us-west-2.amazonaws.com/";
export var isLOCAL = false;
export var lsPrefix = "dev_";
export var Locale = "";
export const AppName = "ReFPredictor";
export const BUCKET_STATIC_DATA_ALLOWED = true;
export var isBaseName = false;

export var localParentPort = 4444;
export var ParentUrl = `http://localhost:${localParentPort}`;


export const baseURL = BaseURL();
// export const pageURL = isLOCAL ? "http://134.209.47.184/" : "https://www.refpredictor.com/";
export const pageURL = isLOCAL ? "https://www.refpredictor.vinfotechprojects.com/" : "http://refsocial.app/";
export const S3_BUCKET_PATH = getS3BucketPath();
export const deviceType = "3";
export const deviceID = "";
export const sportsId = "5";

//Session Codes
export const successCode = 200;
export const AUTHENTICATE_REQUIRE_CODE = 401;
export const sessionExpireCode = 401;

//Pagination Code
export const ITEMS_PERPAGE = 10;
export const ITEMS_PERPAGE_LG = 20;
export const CURRENT_PAGE = 1;

export const PAYSTACK_PUBLIC_KEY = isLOCAL ? "pk_test_033791176e22cc3562a43becc64001d016702589" : "pk_live_e508723bff79211f247b05e67d06981b157c18dd";
export const FLUTTERWAVE_PUBLIC_KEY = isLOCAL ? "FLWPUBK_TEST-11c16af590673675ed649bd88e28f7bd-X" : "FLWPUBK-16624751e22670b82cd1a8e2f5ed9332-X";


export const BASE_NAME = isBaseName ? "" : "";

// Social Keys
export const FB_APP_ID = getFacebookAppId();
export const FACEBOOK_API_SECRETE = getFacebookSecret();
export const GPLUS_ID = "104371323097-6cf1hthfgftmdkrvqsfhne59e6b5vmtf.apps.googleusercontent.com";
export const INSTA_APP_ID = "91bdfd6c35a546a5b879f3135c8022f3";
export const INSTAGRAM_REDIRECT = "http://localhost:3000/";
export const TWEET_APP_ID = "uSraG8DGs9iXcon8es4HKHIZh";
export const TWEET_REDIRECT = "http://localhost:3000/";
export const TWEET_CALLBACK_URL = "user/auth/twitter/auth";

//Api Constant
export const STATIC_PAGE_DATA = "user/common/get_static_content";
export const SIGNUP_OTP = "user/auth/signup_otp";
export const SIGNUP = "user/auth/signup";
export const UPDATE_SIGNUP_DATA = "user/my_profile/update_signup_data";
export const GET_MEMBERSHIP_MASTER_DATA = "user/membership/get_master_plan_list";
export const PURCHASE_MEMBERSHIP = "user/membership/purchase_membership";
export const MASTER_DATA = "user/auth/get_master_data";
export const LOGIN = "user/auth/login";
export const RESEND_OTP = "user/auth/resend_signup_otp";
export const FORGOT_PASSWORD = "user/auth/forgot_password";
export const CHANGE_PASSWORD = "user/my_profile/change_password";
export const UPDATE_PIN = "user/my_profile/update_user_pin_settings";
export const RESET_PASSWORD = "user/auth/reset_password";
export const GET_COUNTRY_LIST = "user/auth/get_country_list";
export const CHANGE_MOBILE_NO = "user/my_profile/change_mobile_number";
export const SOCIAL_LOGIN = "user/auth/social_login";
export const GET_FIXTURE_LIST = "fantasy/lobby/get_fixture_list";
export const GET_FIXTURE_QUESTION = "fantasy/lobby/get_fixture_questions";
export const GET_FIXTURE_SPONSOR_ACTIVITY = "user/sponsor/get_fixture_sponsor_activity";
export const SAVE_SPONSOR_ACTIVITY = "user/sponsor/save_activity_response";
export const SUBMIT_PREDICTION = "fantasy/prediction/submit_prediction";
export const GET_FIXTURE_BY_STATUS = "fantasy/prediction/get_user_fixtures_by_status";
export const GET_FIXTURE_RESULT = "fantasy/prediction/get_fixture_result";
export const GET_ROUND_RESULT_LIST = "fantasy/prediction/get_round_result_list";
export const GET_FILTER_CONTEST_TYPE = "fantasy/master/get_all_contest_type";
export const GET_PAST_GAME_ROUND = "fantasy/common/get_past_game_round";
export const GET_LEADERBOARD_LEAGUE_FILTERS = "fantasy/leaderboard/get_leaderboard_league_filters";
export const GET_LEADERBOARD_MONTHS_FILTERS = "fantasy/leaderboard/get_leaderboard_months_filters";
export const GET_LEADERBOARD_ROUNDS_FILTERS = "fantasy/leaderboard/get_leaderboard_round_filters";
export const GET_LEADERBOARD_CONTESTS_FILTERS = "fantasy/leaderboard/get_leaderboard_contest_filters";
export const GET_CONTEST_LIST = "fantasy/contest/contest_list_for_current_round";
export const CONTEST_JOIN_GAME = "fantasy/contest/join_game";
export const GET_GAME_USER_DETAIL = "fantasy/contest/get_game_user_detail";
export const GET_AFFILIATE_MYREFERRAL_LIST = "user/affiliate/get_affiliate_myreferral_list";
export const GET_AFFILIATE_USER_LIST = "user/affiliate/get_affiliate_user_list";
export const GET_MY_CONTEST_LIST = "fantasy/contest/get_user_contest_by_status";
export const GET_LEADERBOARD_RESULTS = "fantasy/leaderboard/leaderboard_result";
export const GET_USER_PERFORMANCE = "fantasy/leaderboard/get_user_performance";
export const GET_RPL_CUP_QUALIFICATION_DATA = "fantasy/leaderboard/get_rpl_cup_qualification_data";
export const GET_RPL_CUP_ELIMINATION_DATA = "fantasy/leaderboard/get_rpl_cup_elimination_data";
export const GET_RPL_CUP_FINAL_DATA = "fantasy/leaderboard/get_rpl_cup_final_data";
export const USER_FOLLOW = "user/follow/follow_user";
export const USER_UNFOLLOW = "user/follow/unfollow_user";
export const GET_MY_PRIVATE_CONTEST = "fantasy/contest/get_my_private_contest";
export const GET_INVITE_CODE = "fantasy/contest/save_invite_for_game";
export const GET_MY_PROFILE = "user/my_profile/get_my_profile";
export const DO_UPLOAD = "user/my_profile/do_upload";
export const DO_UPLOAD_BANNER = "user/my_profile/do_upload_cover_image";
export const UPDATE_PROFILE = "user/my_profile/update_profile";
export const GET_ALL_COUNTRY = "user/my_profile/get_country_list";
export const GET_ALL_STATE = "user/my_profile/get_state_list";
export const UPDATE_BANK_ACC_DETAILS = "user/my_profile/update_bank_ac_detail";
export const DO_UPLOAD_PAN = "user/my_profile/do_upload_pan";
export const GET_PRIZE_DATA = "user/common/get_prize_data";
export const GET_PUBLIC_CONTEST = "fantasy/contest/get_public_contest";
export const GET_LOBBY_BANNER_LIST = "fantasy/lobby/get_lobby_banner_list";
export const FUND_TRANSFER = "user/finance/transfer_fund_to_user";
export const GET_USER_LIST = "user/common/get_user_list_by_keyword";
export const GET_TRANSACTION_HISTORY = "user/finance/get_transaction_history";
export const GET_USER_BALANCE = "user/finance/get_user_balance";
export const GET_WITHDRAW_BALANCE = "user/finance/withdraw";
export const DEPOSIT_BY_PAYSTACK = "user/paystack/deposit";
export const DEPOSIT_BY_PAYSTACK_CANCEL = "user/paystack/cancel_payment";
export const DEPOSIT_BY_PAYSTACK_VERIFY = "user/paystack/verify_payment";
export const VALIDATE_PROMO_CODE = "user/finance/validate_promo_code";
export const GET_USER_LIST_BY_FILTERS = "user/common/get_user_list_by_filters";
export const GET_USER_SAVED_LIST = "fantasy/contest/get_user_saved_list";
export const GET_USER_SAVED_LIST_DETAIL = "fantasy/contest/get_user_saved_list_detail";
export const UPDATE_USER_SAVED_LIST = "fantasy/contest/update_user_saved_list";
export const GET_USER_NOTIFICAITONS = "user/notification/get_notification";
export const GET_UNREAD_NOTIFICATION = "user/notification/get_unread_notification";
export const GET_LOGOUT = "user/auth/logout";
export const GET_ALL_PRIZE_DATA = "fantasy/master/get_all_prize_data";
export const GET_ALL_PRIZE_DETAILS = "fantasy/master/prize_details_by_size_and_fee";
export const GET_EXISTING_COLLECTION = "fantasy/contest/get_existing_collection";
export const CREATE_USER_CONTEST = "fantasy/contest/create_user_private_contest";
export const PRIVATE_CONTEST_ELIGIBILITY = "fantasy/contest/get_user_private_contest_count";
export const CANCEL_CONTEST = "fantasy/contest/cancel_contest";
export const DELETE_SAVED_LIST = "fantasy/contest/delete_user_saved_list";
export const CHECK_ELIGIBILITY_FOR_CONTEST = "fantasy/contest/check_eligibility_for_contest";
export const RESEND_EMAIL_VERIFICATION_LINK = "user/auth/resend_email_verification_link";
export const GET_DOWNLOAD_APP_LINK = "user/auth/send_applink";
export const SEND_LOGIN_OTP = "user/auth/send_login_otp";
export const GET_USER_PERMISSIONS = "user/my_profile/get_user_permissions";

export const DEPOSIT_BY_FLUTTERWAVE = "user/flutterwave/deposit";
export const DEPOSIT_BY_FLUTTERWAVE_VERIFY = "user/flutterwave/verify_payment";
export const DEPOSIT_BY_FLUTTERWAVE_CANCEL = "user/flutterwave/cancel_payment";
export const GET_CURRENT_GAME_ROUND = "fantasy/common/get_current_game_round";
export const GET_PAGE_BANNER = "fantasy/banner/get_page_banner"
export const GET_QUESTION_STATS_LIST = "fantasy/stats_and_awards/get_question_stats_list";
export const GET_USER_RANK_BY_QUESTION="fantasy/stats_and_awards/get_user_rank_by_question";
export const GET_USERS_LIST_FOR_COMPARE = "fantasy/stats_and_awards/get_users_list_for_compare";
export const GET_USER_SCORES = "fantasy/stats_and_awards/get_user_scores";
export const GET_CONTEST_DETAIL = "fantasy/contest/get_contest_detail";
export const UPLOAD_CONTEST_LOGO = "fantasy/contest/upload_contest_logo"