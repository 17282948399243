import React, { Component } from "react";

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTimeStamp: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    };
  }

  componentDidMount() {
    this.state.currentTimeStamp = this.props.currentDateTimeStamp;
    this.interval1 = setInterval(() => {
      const date = this.calculateCountdown(this.props.deadlineTimeStamp);
      date ? this.setTimerValue(date) : this.stop();
      clearInterval(this.interval1);
    }, 0);
    this.startTimer(this.props.deadlineTimeStamp);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (endDate - this.state.currentTimeStamp) / 1000;
    // clear countdown when date is reached

    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  setTimerValue(timeLeft) {
    this.state.currentTimeStamp = this.state.currentTimeStamp + 1000;
    this.setState({
      days: timeLeft.days,
      hours: timeLeft.hours,
      min: timeLeft.min,
      sec: timeLeft.sec
    });
  }

  startTimer(deadlineTimeStamp) {
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(deadlineTimeStamp);
      date ? this.setTimerValue(date) : this.stop();
    }, 1000);
  }

  stop() {
    if (this.props.timerCallback) {
      this.props.timerCallback(true);
    }
    this.setState({
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    });
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = "0" + value;
    }
    return value;
  }

  render() {

    return (
      <span>
        <strong>{this.addLeadingZeros(this.state.hours)}</strong>
        <span>:</span>
        <strong>{this.addLeadingZeros(this.state.min)}</strong>
        <span>:</span>
        <strong>{this.addLeadingZeros(this.state.sec)}</strong>
        <span />
      </span>
    );
  }
}

export default Countdown;
