import React from "react";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Row, Col, ProgressBar, FormGroup } from "react-bootstrap";
import { notify } from "react-notify-toast";
import { Header, Images, NoDataScreen, SubHeader, Loader } from "../components";
import ContestDetailModal from "../Modals/ContestDetail";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import Select from "react-select";
import MetaData from "../helper/MetaData";
import InfiniteScroll from "react-infinite-scroll-component";
import CountdownTimer from "./CountDownTimer";
import UtilityFunctions from "../helper/UtilityFunctions";
import * as Constants from "../helper/Constants";
import util from "util";


export default class MyPrivateContest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      contestList: [],
      contestSchedule: [],
      roundArray: [],
      selectedRound: '',
      roundId: '',
      showContestDetail: false,
      contestInfo: "",
      roundPredictionCount: "",
      offset: 0,
      hasMore: false,
      selectedCategory: Constants.categories[0],
      selectedLeague: "",
    };
  }

  componentDidMount() {
    this.getPrivateContestList();
    // this.getPastRound();
  }

  showProgressBar = (join, total) => {
    return (join * 100) / total;
  };

  ContestDetailShow = (event, item, idx) => {
    event.stopPropagation();
    this.setState({
      showContestDetail: true,
      contestInfo: item
    });
  };

  ContestDetailHide = () => {
    this.setState({
      showContestDetail: false
    });
  };

  joinContest = (contestid,
    round_id,
    entryFee,
    contestname,
    roundPredictionCount,
    event,
    index
  ) => {

    let EntryFee = parseFloat(entryFee);
    if (WSManager.isProfileCompleted()) {
      if (roundPredictionCount != 0) {
        if (
          parseFloat(Constants.userBalanceInfo.user_balance.real_amount) <
          EntryFee
        ) {
          notify.show(util.format(Constants.Messages.less_fund, 'join the contest'), "error", 4000);
          this.props.history.push("/add-funds");
        }
        else {
          Header.showConfirmationModal(
            contestid,
            "contest",
            this.continueProcess.bind(this),
            EntryFee,
            contestname
          );
        }
      }
      else {
        notify.show(Constants.Messages.pred_count, "error", 2000);
      }
    }
    else {
      Header.CompleteProfileShow();
    }
  }

  continueProcess(data) {
    this.callJoinContestApi(data);
  }

  callJoinContestApi = (contestId) => {
    let param = {
      contest_id: contestId
    }
    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.CONTEST_JOIN_GAME, param).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          this.setState({
            isLoading: false,
            hasMore: false,
            offset: 0
          }, () => {
            this.getPrivateContestList();
          });
          notify.show(responseJson.message, "success", 4000);
        }
        else {
          this.setState({
            isLoading: false
          });
        }
      }
    );
  }

  getPrivateContestList() {
    const { selectedRound, roundId, offset, contestList, contestSchedule, roundPredictionCount,
      selectedCategory, selectedLeague } = this.state;
    let param = {
      round_id: selectedRound == "" ? roundId : selectedRound.value,
      limit: 20,
      offset: offset,
      contest_type: selectedLeague == "" ? "" : selectedLeague.value,
    };

    if (selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
      param['type'] = selectedCategory.id;
      if (selectedCategory.id == '2') {
        param['country_id'] = selectedCategory.value;
      }
      if (selectedCategory.id == '3') {
        param['team_id'] = selectedCategory.value;
      }
    }

    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.GET_MY_PRIVATE_CONTEST, param).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          this.setState(
            {
              contestList: offset == 0 ? responseJson.data.result : [...contestList, ...responseJson.data.result],
              contestSchedule: offset == 0 ? responseJson.data.schedule : contestSchedule,
              roundPredictionCount: offset == 0 ? responseJson.data.current_round_prediction_count : roundPredictionCount,
              isLoading: false,
              hasMore: responseJson.data.is_load_more,
              offset: responseJson.data.offset || 0
            }
          )
        }
        else {
          this.setState({
            isLoading: false
          });
        }
      }
    );
  }

  handleRoundChange = option => {
    this.setState(
      {
        selectedRound: option,
        activeFilter: true,
        selectedContest: "",
        offset: 0,
        hasMore: false,
      },
      () => {
        this.getPrivateContestList();
      }
    );
  };


  filterAction(selectleague, selectround, selectedContest, selectedMonth, selectedFormat, selectedCategory) {
    this.setState(
      {
        selectedRound: selectround,
        selectedCategory: selectedCategory,
        selectedLeague: selectleague,
        offset: 0,
        hasMore: false
      },
      () => {
        this.getPrivateContestList();
      }
    );
  }

  getPastRound() {

    let { selectedCategory } = this.state;

    var url = NC.GET_PAST_GAME_ROUND;
    var param = {};

    if (selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
      param['type'] = selectedCategory.id;
      if (selectedCategory.id == '2') {
        param['country_id'] = selectedCategory.value;
      }
      if (selectedCategory.id == '3') {
        param['team_id'] = selectedCategory.value;
      }
    }

    WSManager.Rest(NC.baseURL + url, param).then(responseJson => {
      this.setState({
        isLoading: false
      });
      if (responseJson.response_code == NC.successCode) {
        let tempArray = [];
        _.map(responseJson.data, (item, idx) => {
          tempArray.push({
            label: item.round_name,
            value: item.round_id
          })
        });
        this.setState({
          roundArray: tempArray
        });
      }
    })

      .catch(error => {
        this.setState({ roundArray: [] });
      });
  }

  fetchMoreData = () => {
    if (!this.state.isLoading) {
      this.getPrivateContestList();
    }
  };

  deletedContest = (contestUniqueId, idx) => {
    const { contestList } = this.state;
    let param = {
      contest_unique_id: contestUniqueId
    }
    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.CANCEL_CONTEST, param).then(responseJson => {
      this.setState({
        isLoading: false
      });
      if (responseJson.response_code == NC.successCode) {
        notify.show(responseJson.message, "success", 3000);
        contestList.splice(idx, 1);
        this.setState({
          contestList: contestList
        });
      }
    })
  }

  goOnLeaderBoard = item => {



    let state = {
      selectedCategory: this.state.selectedCategory,
      selectedRound: {
        label: item.round_name,
        value: item.round_id
      },
      // selectedLeague: {
      //   label: item.name,
      //   value: item.contest_type
      // },
      selectedLeague: {
        label: item.contest_name,
        value: item.contest_id
      },
      // selectedContest: {
      //   label: item.contest_name,
      //   value: item.contest_id
      // },
      selectedMonth: {
        label: item.month_name,
        value: item.month
      },
      selectedContestType: Constants.contest_type[1]
    }
    // console.log(state); return;
    this.props.history.push({
      pathname: "/leaderboard",
      state: state
    });
  };


  editContest = (item) => {
    this.props.history.push({
      pathname: "edit-contest",
      state: { data: item }
    })
  }

  render() {
    const {
      contestList,
      isLoading,
      contestSchedule,
      selectedRound,
      roundArray,
      showContestDetail,
      contestInfo,
      hasMore,
      selectedCategory,
      selectedLeague
    } = this.state;
    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container private-contest-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.contests.title}</title>
              <meta name="description" content={MetaData.contests.description} />
              <meta name="keywords" content={MetaData.contests.keywords} />
            </Helmet>
            <Header {...this.props} HeaderOption={HeaderOption} showNavigation={false} />
            {isLoading && <Loader />}
            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Private Contests" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                </Col>
              </Row>

              <FormGroup className="input-label-center input-transparent font-14 mb-0">
                <div className="my-contest-filter">
                  <div
                    className="select-option-btn"
                    onClick={() =>
                      Header.FilterModalShow(
                        {
                          filterOption: ["league", "round", "category"],
                          selectedRound: selectedRound,
                          selectedCategory: selectedCategory,
                          selectedLeague: selectedLeague,
                          isFrom: "PrivateContest"
                        },
                        this.filterAction.bind(this)
                      )
                    }
                  >
                    {(selectedCategory != "" && selectedCategory.id != 1) || selectedRound != "" || selectedLeague != ""
                      ? "Filters Applied"
                      : "Select"}
                    <div className="arrow-section">
                      <i className="icon-arrow-sort" />
                    </div>
                  </div>
                </div>
              </FormGroup>

              {/* <div className="filter-wrapper">
                <FormGroup className="input-label-center input-transparent font-14 m-b-sm">
                  <div className="filter">
                    <div className="filterStyle">
                      <Select
                        onChange={this.handleRoundChange}
                        options={roundArray}
                        classNamePrefix="secondary"
                        className="select-secondary minusML10"
                        value={selectedRound}
                        placeholder="Select"
                        menuPlacement="auto"
                        isSearchable={false}
                        isClearable={false}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary: "#37003C"
                          }
                        })}
                      />
                    </div>
                    <span className="select-arr">
                      <i className="icon-arrow-sort" />
                    </span>
                    <div className="gender-border col-sm-12" />
                  </div>
                </FormGroup>
              </div> */}
              {contestList.length == 0 && !isLoading && (
                <NoDataScreen
                  Content={{
                    ImgShown: Images.no_data_bg_image,
                    IsButton: true,
                    ButtonText: "Go to Lobby",
                    Title: "Oops! You may have joined this",
                    NoDataContent: "contest already, or it's closed.",
                    BtnAction: () => {
                      this.props.history.push('/dashboard/0');
                    },
                    WithFilter: "1"
                  }}
                />
              )}
              {contestList.length > 0 &&
                <React.Fragment>


                  <div>
                    {contestSchedule.game_starts_in_timestamp &&
                      UtilityFunctions.showCountDown(
                        contestSchedule
                      ) ? (
                        <div className="countdown-start">
                          {'Expires in '}
                          <CountdownTimer
                            deadlineTimeStamp={
                              contestSchedule.game_starts_in_timestamp
                            }
                            currentDateTimeStamp={
                              contestSchedule.current_timestamp
                            }
                          />
                        </div>
                      ) : (
                        <span />
                      )}
                  </div>

                  <InfiniteScroll
                    pullDownToRefresh={false}
                    dataLength={contestList.length}
                    next={this.fetchMoreData}
                    hasMore={hasMore}
                  >
                    <div className="wrapper-spacing my-private-contest-wrap">
                      {contestList.map((item, idx) => {
                        return (
                          <div key={idx} className="collection-list-wrapper">
                            <div className="collection-list-body">
                              {item.total_user_joined == 0 && item.is_owner == 1 &&
                                <React.Fragment>


                                  <a>
                                    <i className={'icon-edit'} onClick={() => this.editContest(item)}></i>
                                  </a>

                                  <a href
                                    onClick={() =>
                                      Header.showAlertModal({
                                        message: "Are you sure you want delete this contest?",
                                        ModalAction: () => {
                                          this.deletedContest(item.contest_unique_id, idx)
                                        }
                                      }
                                      )
                                    }>

                                    <i className="icon-delete " />
                                  </a>

                                </React.Fragment>
                              }

                              <div className="collection-heading">
                                <span
                                  className="contest-name"
                                  onClick={event =>
                                    this.ContestDetailShow(event, item, idx)
                                  }
                                >
                                  {item.contest_name}
                                </span>
                                {(item.is_user_joined != 0 || item.contest_status != 0 || parseInt(item.size) <= parseInt(item.total_user_joined)) &&
                                  <span className="your-rank-label">your rank</span>
                                }
                              </div>
                              <div className="prizepool">
                                Prize pool
                                    <span className="prizepool-amt">
                                  ₦{item.prize_pool}
                                </span>
                                <span className="prizepool-for">
                                  Top {item.number_of_winner}
                                </span>
                                {(item.is_user_joined != 0 || item.contest_status != 0 || parseInt(item.size) <= parseInt(item.total_user_joined)) &&
                                  <span className="your-rank-count">
                                    {item.game_rank != null ?
                                      <span>
                                        {item.contest_status == 0 ? '--' : item.game_rank}
                                      </span> :
                                      <span>--</span>
                                    }
                                  </span>
                                }
                              </div>
                              {(item.is_user_joined == 0 && item.contest_status == 0 && parseInt(item.size) > parseInt(item.total_user_joined)) &&
                                <a
                                  href
                                  onClick={(event) => { this.joinContest(item.contest_id, item.round_id, item.entry_fee, item.contest_name, this.state.roundPredictionCount, event, idx) }}
                                  className="btn btn-rounded"
                                >
                                  Join ₦{item.entry_fee}
                                </a>
                              }
                              {(item.is_user_joined != 0 || item.contest_status != 0 || parseInt(item.size) <= parseInt(item.total_user_joined)) &&
                                <a
                                  href
                                  className="btn btn-rounded btn-primary"
                                  onClick={() => this.goOnLeaderBoard(item)}
                                >
                                  View Standing
                                    </a>
                              }
                              <ProgressBar
                                className={
                                  parseInt(item.total_user_joined) < parseInt(item.minimum_size)
                                    ? "danger"
                                    : ""
                                }
                                now={this.showProgressBar(item.total_user_joined, item.size)}
                              />
                              <div className="progressbar-entries">
                                {item.total_user_joined} Joined
                                  </div>
                            </div>
                            <div className="creater-info">
                              Created by - <span>{item.user_name}</span>
                            </div>
                          </div>
                        );
                      })
                      }
                    </div>
                  </InfiniteScroll>
                </React.Fragment>
              }
            </div>
            {showContestDetail && (
              <ContestDetailModal
                IsContestDetailShow={showContestDetail}
                onJoinBtnClick={this.joinContest}
                IsContestDetailHide={this.ContestDetailHide}
                showContestDetailFor={contestInfo}
                roundPredictionCount={this.state.roundPredictionCount}
                isFrom={"privatecontest"}
              />
            )}
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

