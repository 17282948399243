import React from "react";
import { Row, Col, FormControl } from "react-bootstrap";
import { Header, SubHeader, Images, Loader, NoDataScreen } from "../components";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import MetaData from "../helper/MetaData";
import InfiniteScroll from "react-infinite-scroll-component";
import * as Constants from "../helper/Constants";

export default class TeamDetail extends React.Component {
    constructor(props) {
        super(props);
        this.SearchUserReq = _.debounce(this.SearchUserReq.bind(this), 700);
        this.state = {
            isLoading: false,
            userList: [],
            selectedUserList: [],
            teamData: [],
            Searchkey: '',
            offset: 0,
            hasMore: false,
            inviteGrpID: this.props.match && this.props.match.params ? this.props.match.params.invite_group_id || '' : '',
            group_name: this.props.match && this.props.match.params ? this.props.match.params.group_name || '' : '',
        };
    }

    componentDidMount() {
        if (this.state.inviteGrpID != '') {
            this.callSaveTeamDetail()
        } else {
            window.location.assign("/dashboard/0");
        }
    }

    callSaveTeamDetail() {
        const { offset, teamData, inviteGrpID } = this.state;
        let param = {
            limit: 0,
            offset: offset,
            invite_group_id: WSManager.decrypt(inviteGrpID)
        };
        if (offset == 0) {
            this.setState({
                isLoading: true
            });
        }
        WSManager.Rest(NC.baseURL + NC.GET_USER_SAVED_LIST_DETAIL, param).then(
            responseJson => {
                if (responseJson.response_code === NC.successCode) {

                    this.setState(
                        {
                            teamData: offset == 0 ? responseJson.data.result : [...teamData, ...responseJson.data.result], offset: responseJson.data.offset,
                            hasMore: responseJson.data.is_load_more
                        },
                        () => {
                            setTimeout(() => {
                                let member = []
                                _.map(this.state.teamData, (itm, idx) => {
                                    member.push(itm.user_id)
                                })

                                this.setState({
                                    isLoading: false,
                                    selectedUserList: member
                                });
                            }, 50);
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }
        );
    }

    SearchUserReq() {
        const { Searchkey } = this.state;
        if (Searchkey.trim().length < 2 && Searchkey.trim().length > 0) return;
        Searchkey.trim().length > 0 && this.callUserAPi()
    }

    fetchMoreData = () => {
        if (!this.state.isLoading) {
            if (this.state.Searchkey.trim().length > 0) {
                this.callUserAPi();
            }
        }
    };

    selectThisUser = item => {
        const { selectedUserList, teamData } = this.state;
        if (selectedUserList.includes(item.user_id)) {
            let idx = selectedUserList.indexOf(item.user_id);
            selectedUserList.splice(idx, 1);
        } else {
            if (teamData.filter(function (x) { return item.user_id == x.user_id; }).length == 0) {
                teamData.push(item)
            }
            selectedUserList.push(item.user_id);
        }
        this.setState({
            selectedUserList: selectedUserList,
            teamData: teamData
        });
    };

    callUserAPi() {
        const { Searchkey, offset, userList } = this.state;
        let param = {
            search_string: Searchkey,
            plan_id: [2, 3],
            limit: 100,
            offset: offset
        };
        if (offset == 0) {
            this.setState({
                isLoading: true
            });
        }
        WSManager.Rest(NC.baseURL + NC.GET_USER_LIST_BY_FILTERS, param).then(
            responseJson => {
                if (responseJson.response_code === NC.successCode) {
                    this.setState(
                        {
                            userList:
                                offset == 0
                                    ? responseJson.data.users
                                    : [...userList, ...responseJson.data.users],
                            offset: responseJson.data.offset,
                            hasMore: responseJson.data.isloadmore
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({
                                    isLoading: false
                                });
                            }, 50);
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }
        );
    }

    onSubmit() {
        const { selectedUserList, inviteGrpID } = this.state;
        let param = {
            invite_group_id: WSManager.decrypt(inviteGrpID),
            invites: selectedUserList
        };
        this.setState({
            isLoading: true
        });
        WSManager.Rest(NC.baseURL + NC.UPDATE_USER_SAVED_LIST, param).then(
            responseJson => {
                this.setState({
                    isLoading: false
                });
                if (responseJson.response_code === NC.successCode) {
                    notify.show(responseJson.message, "success", 3000);
                    this.props.history.push('/dashboard/0')
                }
            }
        );
    }

    render() {
        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true,
        }
        const {
            Searchkey,
            userList,
            isLoading,
            hasMore,
            teamData,
            group_name,
            selectedUserList
        } = this.state;

        let list = Searchkey.trim().length > 0 ? userList : teamData

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container create-contest">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.teamlist.title}</title>
                            <meta name="description" content={MetaData.teamlist.description} />
                            <meta name="keywords" content={MetaData.teamlist.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} />
                        {isLoading && <Loader />}
                        <div>
                            <Row>
                                <Col sm={12}>
                                    <SubHeader Title={WSManager.decrypt(group_name)} ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="single-title-header" />
                                </Col>
                            </Row>
                            <div className="create-league-list p-t">
                                <div className="heading-section p-h-15 m-t-0">
                                    <Row>
                                        <Col xs={12}>
                                            <div className="search-btn search-full">
                                                <FormControl
                                                    type="text"
                                                    placeholder="Search"
                                                    value={Searchkey}
                                                    onChange={e => {
                                                        this.setState({
                                                            Searchkey: e.target.value,
                                                            offset: 0,
                                                            hasMore: false
                                                        });
                                                        this.SearchUserReq();
                                                    }}
                                                />
                                                <div className="search-box">
                                                    <i className="icon-search" />
                                                </div>
                                            </div>
                                            {/* <div className="user-selection-count"></div> */}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="help-text p-xs">You can only invite Premium and Premium+ Members</div>
                                {list.length == 0 && !isLoading && (
                                    <NoDataScreen
                                        Content={{
                                            ImgShown: Images.no_data_bg_image,
                                            IsButton: false,
                                            ButtonText: "Go to Lobby",
                                            Title: "Oops! No data AVAILABLE",
                                            NoDataContent: "",
                                            BtnAction: () => {
                                                this.props.history.push("/dashboard/0");
                                            },
                                            WithFilter: "1"
                                        }}
                                    />
                                )}
                                <InfiniteScroll
                                    pullDownToRefresh={false}
                                    dataLength={list.length}
                                    next={this.fetchMoreData}
                                    hasMore={hasMore}
                                    loader={<h6 className="p text-center">Loading...</h6>}
                                >
                                    {list.length > 0 && (
                                        <table>
                                            <tbody>
                                                {list.map((item, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td className="user-rank">{idx + 1}</td>
                                                            <td className="user-name" style={{
                                                                fontFamily:
                                                                    // item.plan_id == 3 || item.plan_id == 2
                                                                    Constants.PAID_PLANS_ID.includes(item.plan_id)
                                                                        ? "MuliBold"
                                                                        : "MuliRegular"
                                                            }}>
                                                                <img
                                                                    src={
                                                                        item.image
                                                                            ? item.image
                                                                            : Images.DEFAULT_USER
                                                                    }
                                                                    alt=""
                                                                />
                                                                {item.user_name}
                                                            </td>
                                                            <td className="user-action">
                                                                <a
                                                                    href
                                                                    className={
                                                                        "add-user" +
                                                                        (selectedUserList.includes(
                                                                            item.user_id
                                                                        )
                                                                            ? " added"
                                                                            : "")
                                                                    }
                                                                    onClick={() =>
                                                                        this.selectThisUser(item)
                                                                    }
                                                                >
                                                                    <i className="icon-tick" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}

                                            </tbody>
                                        </table>
                                    )}
                                </InfiniteScroll>
                                <div className="page-footer">
                                    <button type="button"
                                        className="btn-block btn-primary btn "
                                        disabled={
                                            selectedUserList.length == 0}
                                        onClick={() => this.onSubmit()}>
                                        Done
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                )}
            </MyContext.Consumer>
        )
    }
}

