import React from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import { Images, Header, ComingSoon, SubHeader } from "../components";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import Select from "react-select";
import MetaData from "../helper/MetaData";

const options = [
  { value: "optoion1", label: "optoion1" },
  { value: "optoion2", label: "optoion2" },
  { value: "optoion3", label: "optoion3" }
];
const sortByOption = [
  { value: "optoion1", label: "optoion1" },
  { value: "optoion2", label: "optoion2" },
  { value: "optoion3", label: "optoion3" }
];

export default class Blog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null
    };
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    const { selectedOption } = this.state;

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container ">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.Blog.title}</title>
              <meta name="description" content={MetaData.Blog.description} />
              <meta name="keywords" content={MetaData.Blog.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Blog" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                  <ComingSoon />
                  {/* Blog Section start */}
                  <div className="sorting-section hide">
                    <Row>
                      <Col xs={4}>
                        <FormGroup className="input-label-center input-transparent font-14 mb-0">
                          <div className="select-blogs">
                            <Select
                              placeholder="All Blogs"
                              value={selectedOption}
                              onChange={this.handleChange}
                              options={options}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col xs={8} className="text-right sort-by-section">
                        Sort by
                        <FormGroup className="input-label-center input-transparent font-14 mb-0">
                          <div className="sortby">
                            <Select
                              placeholder="Most Popular"
                              options={sortByOption}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="blog-section hide">
                    <div className="blog-detail">
                      <div className="blog-header">
                        <img src={Images.BLOG_IMG} alt="" />
                      </div>
                      <div className="blog-body">
                        <div className="blog-time">Oct 12 - 8:00 pm</div>
                        <div className="blog-heading">
                          All six Miami Hurricanes at the Soccer Scouting
                          Combine
                        </div>
                        <div
                          className="blog-description"
                          style={{ WebkitBoxOrient: "vertical" }}
                        >
                          Soccer Draft analyst Mel Kiper Jr. of ESPN indicated
                          Tuesday during a national soccer Draft analyst Mel
                          Kiper Jr. of ESPN indicated Tuesday during a national
                        </div>
                      </div>
                      <div className="blog-action">
                        <span className="user-fav liked">
                          <i className="icon-like" />
                        </span>
                        <span className="txt">546</span>
                        <span className="pull-right mt-1">
                          <i className="icon-comment" />
                          <span className="txt">0</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* Blog section end */}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
