import React from "react";
import { Row, Col, Tab, Nav, NavItem } from "react-bootstrap";
import { Loader, Images, HighGraph, NoDataScreen } from "../components";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";

var pieColors = [
  "#ab2170",
  "#f99ed2",
  "#60e5c1",
  "#f99e9e",
  "#b580e2",
  "#95b6e7",
  "#97d7e0",
  "#60d0e5",
  "#578f99",
  '#295e5c'
];
var bar_color_code_arr = [
  "#00DEA2",
  "#FF6867",
  "#8D36D9",
  "#588FE1",
  "#5BC6D6",
  "#FFD84C",
  "#ADD787",
  "#F58444",
  "#00DEA2",
  "#FF6867",
  "#8D36D9",
  "#588FE1"
];

export default class PerformanceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: props.selectedTab || 0,
      user_profile: WSManager.getProfile(),
      other_user_id: props.other_user_id || "",
      eventKey: props.eventKey || "Accuracy",
      showTabs: props.showTabs != false ? true : props.showTabs,
      AccuracyData: [],
      RankConfigration: null,
      ChartConfigration: null,
      Prize_info: "",
      isPerformance: false,
      refreshPage: true,
      isLoading: false
    };
  }

  componentDidMount() {
    this.callGetGraphData();
  }
  componentWillReceiveProps = nextProps => {
    this.setState(
      {
        refreshPage: false,
        selectedTab: nextProps.selectedTab || 0,
        other_user_id: nextProps.other_user_id || "",
        eventKey: nextProps.eventKey || "Accuracy",
        showTabs: nextProps.showTabs != false ? true : nextProps.showTabs
      },
      () => {
        this.setState({ refreshPage: true });
      }
    );
  };

  callGetGraphData() {

    let {
      selectedCategory, selectedLeague,
      selectedContest, selectedContestType, selectedFollowOption, selectedMainTab, selectedRound, selectedMonth
    } = this.props;

    let param = {
      user_id: this.state.other_user_id || this.state.user_profile.user_id,
      // contest_type_id: selectedLeague != "" ? selectedLeague.value : "",
      // month: selectedMonth != "" ? selectedMonth.value : "",
      // round_id: selectedRound != "" ? selectedRound.value : "",
      // contest_id: selectedContest != "" ? selectedContest.value : "",
      // tab_id: selectedMainTab

    }

    // if (selectedContestType.value == "2" && selectedMainTab == 1) {
    //   param.contest_id = selectedLeague != "" ? selectedLeague.value : "";
    //   param.contest_type_id = 4;
    // }

    // if (selectedMainTab == 2) {
    //   param['follow_type'] = selectedFollowOption.value;
    // }

    if (selectedCategory && selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
      param['type'] = selectedCategory.id;
      if (selectedCategory.id == '2') {
        param['country_id'] = selectedCategory.value;
      }
      if (selectedCategory.id == '3') {
        param['team_id'] = selectedCategory.value;
      }
    }




    if (this.state.isLoading == false) {
      this.setState({ isLoading: true });
      WSManager.Rest(NC.baseURL + NC.GET_USER_PERFORMANCE, param).then(responseJson => {
        this.setState({ isLoading: false });
        if (responseJson.response_code == NC.successCode) {
          this.setState({
            isPerformance: responseJson.data.show_no_data_screen != "1" ? true : false,
            Prize_info: responseJson.data.prize_info,
            AccuracyData: responseJson.data.result,
            RankConfigration: {
              chart: {
                backgroundColor: "transparent",
                type: "spline"
              },
              title: {
                text: ""
              },
              xAxis: {
                tickInterval: 0,
                text: "Rounds",
                categories: responseJson.data.line_chart_arr.category
              },
              yAxis: {
                title: {
                  text: "Rank"
                },
                // type: "logarithmic",
                reversed: true,
                reversedStacks: true,
                allowDecimals: false
              },
              tooltip: {
                headerFormat: "<b>{point.x}</b><br/>",
                pointFormat: "Rank = {point.y}"
              },
              plotOptions: {
                spline: {
                  marker: {
                    radius: 5,
                    lineColor: "#00FF87",
                    lineWidth: 1
                  },
                  color: "#00FF87"
                }
              },
              series: [
                {
                  data: responseJson.data.line_chart_arr.rank,
                  pointStart: 0,
                  name: "Rounds"
                }
              ],
              LineData: [],
              GraphHeaderTitle: [],
              credits: {
                enabled: false
              }
            },
            ChartConfigration: {
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
                backgroundColor: "transparent"
              },
              title: {
                text: ""
              },
              tooltip: {
                pointFormat: "{point.percentage:.1f}%</b>"
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  colors: pieColors,
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.percentage:.1f} %</b>",
                    distance: -50,
                    color: "#ffffff",
                    filter: {
                      property: "percentage",
                      operator: ">",
                      value: 4
                    }
                  },
                  showInLegend: false
                }
              },
              series: [
                {
                  name: "",
                  colorByPoint: true,
                  data: responseJson.data.pie_chart_arr
                }
              ],
              LineData: [],
              GraphHeaderTitle: [],
              credits: {
                enabled: false
              }
            }
          });
        }
      });
    }
  }

  onTabClick = selectedTab => {
    this.setState({
      selectedTab: selectedTab
    });
  };

  render() {
    const {
      isLoading,
      ChartConfigration,
      RankConfigration,
      AccuracyData,
      Prize_info,
      isPerformance,
      eventKey,
      selectedTab,
      showTabs,
      refreshPage
    } = this.state;

    return (
      <div>
        {isLoading && <Loader />}
        {(isPerformance && refreshPage) && (
          <div className="user-performance-section p-v-sm">
            <Tab.Container
              id="tabs-with-dropdown"
              className="circular-tab"
              defaultActiveKey={eventKey}
            >
              <Row className="clearfix">
                {showTabs && (
                  <Col sm={12} className="nav-link-container">
                    <Nav bsStyle="tabs shadow">
                      <NavItem
                        onClick={() => this.onTabClick(0)}
                        eventKey="Accuracy"
                      >
                        Accuracy
                      </NavItem>
                      <NavItem
                        onClick={() => this.onTabClick(1)}
                        eventKey="Chart"
                      >
                        Chart
                      </NavItem>
                      <NavItem
                        onClick={() => this.onTabClick(2)}
                        eventKey="RankGraph"
                      >
                        {" "}
                        Rank Graph
                      </NavItem>
                    </Nav>
                  </Col>
                )}
                <Col sm={12}>
                  <Tab.Content animation>
                    <Tab.Pane eventKey="Accuracy">
                      <div className="accuracy-section">
                        <div className="accuracy-header">
                          <span>Questions</span>
                          <span className="text-right">Correct Answers</span>
                        </div>
                        <ul>
                          {_.map(AccuracyData, (item, idxx) => {
                            return (
                              <li key={idxx}>
                                <div>
                                  {item.question_name}?{" "}
                                  <span className="correct-ans-perc">
                                    {item.percentage}%
                                  </span>
                                </div>
                                <div className="process">
                                  <div className="process-bar">
                                    <label
                                      className="active-bar"
                                      style={{
                                        width: `${item.percentage}%`,
                                        background: bar_color_code_arr[idxx]
                                      }}
                                    />
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Chart">
                      <div className="p_chart">
                        {ChartConfigration && selectedTab == 1 && (
                          <HighGraph
                            {...this.props}
                            HighGraphConfigOption={ChartConfigration}
                          >
                            {" "}
                          </HighGraph>
                        )}
                        <ul>
                          {ChartConfigration &&
                            _.map(
                              ChartConfigration.series[0].data,
                              (item, idxx) => {
                                return (
                                  <li key={idxx}>
                                    <div
                                      className="chart_circle"
                                      style={{
                                        backgroundColor: pieColors[idxx]
                                      }}
                                    >
                                      {item.y}
                                    </div>
                                    {item.name}
                                  </li>
                                );
                              }
                            )}
                        </ul>
                        <ul className="chart_ul">
                          <li>
                            <div
                              className="chart_circle"
                              style={{
                                backgroundColor: "#F1D260"
                              }}
                            >
                              {Prize_info.quad3rick || 0}
                            </div>
                            Quad3rick
                          </li>
                          <li>
                            <div
                              className="chart_circle"
                              style={{
                                backgroundColor: "#C8E1B1"
                              }}
                            >
                              {Prize_info.c6rrect || 0}
                            </div>
                            C6rrect
                          </li>
                          <li>
                            <div
                              className="chart_circle"
                              style={{
                                backgroundColor: "#F3AF89"
                              }}
                            >
                              {Prize_info.contest_won || 0}
                            </div>
                            Contest Won
                          </li>
                        </ul>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="RankGraph">
                      {RankConfigration && (
                        <div style={{ textAlign: "-webkit-center" }}>
                          <HighGraph
                            {...this.props}
                            HighGraphConfigOption={RankConfigration}
                          >
                            {" "}
                          </HighGraph>
                        </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        )}
        {!isPerformance && !isLoading && (
          <NoDataScreen
            Content={{
              ImgShown: Images.no_data_bg_image,
              IsButton: true,
              ButtonText: "Go to Lobby",
              Title: "OOPS! NO DATA AVAILABLE.",
              NoDataContent: "CHECK LATER!",
              BtnAction: () => {
                this.props.history.push({
                  pathname: "/dashboard/0"
                });
              },
              WithFilter: ""
            }}
          />
        )}
      </div>
    );
  }
}
