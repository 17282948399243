import React from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images, SubHeader } from "../components";
import MetaData from "../helper/MetaData";

export default class ProfileSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container w-header">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.settings.title}</title>
              <meta
                name="description"
                content={MetaData.settings.description}
              />
              <meta name="keywords" content={MetaData.settings.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Profile Setting" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <ul className="setting-list">
                    <li
                      onClick={() =>
                        this.props.history.push("/change-password")
                      }
                    >
                      Change Password
                      <i className="icon-arrow-right" />
                    </li>
                    <li
                      onClick={() =>
                        this.props.history.push("/set-security-pin")
                      }
                    >
                      <span>Set Security Pin</span>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-right">
                            <strong>
                              Set security pin to add layer on each transfer and
                              withdrawal request.
                            </strong>
                          </Tooltip>
                        }
                      >
                        <i
                          className="icon-info cursor-pointer tooltip-info"
                          onClick={e => e.stopPropagation()}
                        />
                      </OverlayTrigger>
                      <i className="icon-arrow-right" />
                    </li>

                    <li
                      onClick={() => Header.logoutAlertShow()}
                    >
                      Logout
                      <i className="ic icon-logout" />
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
