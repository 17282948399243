import React from "react";
import { Row, Col, Tabs, Tab, FormControl } from "react-bootstrap";
import { Header, SubHeader, Images, Loader, NoDataScreen } from "../components";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import MetaData from "../helper/MetaData";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import _ from "lodash";


export default class CreateAddUser extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.SearchUserReq = _.debounce(this.SearchUserReq.bind(this), 700);
    this.state = {
      selectedListMemberCount: 0,
      isLoading: false,
      value: "",
      key: 1,
      userList: [],
      savedList: [],
      selectedSavedList: [],
      selectedUserList: [],
      offset: 0,
      hasMore: false,
      Searchkey: ''
    };
  }

  componentDidMount() {
    if (Constants.createContestData.ContestData) {
      this.callUserAPi();
    } else {
      window.location.assign("/dashboard/0");
    }
  }

  handleSelect(key) {
    this.setState({ key, offset: 0, hasMore: false, Searchkey: '' }, () => {
      if (key == 1) {
        this.callUserAPi();
      } else {
        this.callSavedList();
      }
    });
  }

  SearchUserReq() {
    const { Searchkey } = this.state;
    if (Searchkey.trim().length < 2 && Searchkey.trim().length > 0) return;
    if (this.state.key == 1) {
      this.callUserAPi();
    } else {
      this.callSavedList();
    }
  }

  fetchMoreData = () => {
    if (!this.state.isLoading) {
      if (this.state.key == 1) {
        this.callUserAPi();
      } else {
        this.callSavedList();
      }
    }
  };

  DeleteList = (invite_group_id, idx, member_count) => {

    let param = {
      invite_group_id: invite_group_id,
    };
    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.DELETE_SAVED_LIST, param).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          this.state.savedList.splice(idx, 1);
          if (this.state.selectedSavedList.includes(invite_group_id)) {
            let idx = this.state.selectedSavedList.indexOf(invite_group_id);
            this.state.selectedSavedList.splice(idx, 1);
            this.state.selectedListMemberCount = this.state.selectedListMemberCount - member_count;
          }
          this.setState({
            savedList: this.state.savedList
          });
        }
        this.setState({
          isLoading: false
        });
      }
    );
  }

  callUserAPi() {
    const { Searchkey, offset, userList } = this.state;
    const {categoryType, categoryId} = Constants.createContestData;

    let param = {
      search_string: Searchkey,
      // plan_id: [2, 3],
      limit: 100,
      offset: offset,
      exclude_free_user:1,
      type:categoryType
    };

    if (categoryType == '2') {
      param['country_id'] = categoryId;
    }
    if (categoryType == '3') {
      param['team_id'] = categoryId;
    }


    if (offset == 0) {
      this.setState({
        isLoading: true
      });
    }
    WSManager.Rest(NC.baseURL + NC.GET_USER_LIST_BY_FILTERS, param).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          this.setState(
            {
              userList:
                offset == 0
                  ? responseJson.data.users
                  : [...userList, ...responseJson.data.users],
              offset: responseJson.data.offset,
              hasMore: responseJson.data.isloadmore
            },
            () => {
              setTimeout(() => {
                this.setState({
                  isLoading: false
                });
              }, 50);
            }
          );
        } else {
          this.setState({
            isLoading: false
          });
        }
      }
    );
  }

  callSavedList() {
    const { offset, savedList, Searchkey } = this.state;
    const {categoryType, categoryId} = Constants.createContestData;
    let param = {
      limit: 20,
      offset: offset,
      search_string: Searchkey,
      type:categoryType,
      exclude_free_user:1
    };

    if (categoryType == '2') {
      param['country_id'] = categoryId;
    }
    if (categoryType == '3') {
      param['team_id'] = categoryId;
    }


    if (offset == 0) {
      this.setState({
        isLoading: true
      });
    }
    WSManager.Rest(NC.baseURL + NC.GET_USER_SAVED_LIST, param).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          this.setState(
            {
              savedList:
                offset == 0
                  ? responseJson.data.result
                  : [...savedList, ...responseJson.data.result],
              offset: responseJson.data.offset,
              hasMore: responseJson.data.is_load_more
            },
            () => {
              setTimeout(() => {
                this.setState({
                  isLoading: false
                });
              }, 50);
            }
          );
        } else {
          this.setState({
            isLoading: false
          });
        }
      }
    );
  }

  selectThisUser = user_id => {
    const { selectedUserList } = this.state;
    if (selectedUserList.includes(user_id)) {
      let idx = selectedUserList.indexOf(user_id);
      selectedUserList.splice(idx, 1);
    } else {
      selectedUserList.push(user_id);
    }
    this.setState({
      selectedUserList: selectedUserList
    });
  };

  selectSaveList = (invite_group_id, member_count) => {
    const { selectedSavedList } = this.state;

    if (selectedSavedList.includes(invite_group_id)) {
      let idx = selectedSavedList.indexOf(invite_group_id);
      selectedSavedList.splice(idx, 1);
      this.state.selectedListMemberCount = this.state.selectedListMemberCount - member_count;
    } else {
      selectedSavedList.push(invite_group_id);
      this.state.selectedListMemberCount = this.state.selectedListMemberCount + member_count;
    }
    this.setState({
      selectedSavedList: selectedSavedList
    });
  };

  

  onSubmit() {
    const { selectedUserList, selectedSavedList } = this.state;
    const {
      ContestData,
      minSize,
      maxSize,
      ContestName,
      SelectedEntryFee,
      SelectedWinner,
      round_id,
      categoryType,
      categoryId,
      is_auto_recurring,
      contest_logo,
      type,
      contest_unique_id,
      is_season_long
    } = Constants.createContestData;
    let param = {
      round_id: round_id,
      contest_type: ContestData.contest_type_id,
      size_min: minSize,
      size: maxSize,
      contest_name: ContestName,
      entry_fee: SelectedEntryFee.value,
      master_contest_type_id: SelectedWinner.value,
      invites: selectedUserList,
      invite_group_id: selectedSavedList,
      type:categoryType,
      is_auto_recurring,
      contest_access_type:1,
      contest_logo,
      is_season_long
    };

    if(type == 2){
      param['contest_unique_id'] = contest_unique_id;
    }

    if (categoryType == '2') {
      param['country_id'] = categoryId;
    }
    if (categoryType == '3') {
      param['team_id'] = categoryId;
    }

    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.CREATE_USER_CONTEST, param).then(
      responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          notify.show(responseJson.message, "success", 3000);
          Constants.SetValues.createContestData({});
          this.props.history.push("/my-contest");
        }
      }
    );
  }

  render() {
    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };
    const {
      Searchkey,
      userList,
      isLoading,
      selectedUserList,
      hasMore,
      savedList
    } = this.state;
    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container create-contest">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.createcontest.title}</title>
              <meta
                name="description"
                content={MetaData.createcontest.description}
              />
              <meta name="keywords" content={MetaData.createcontest.keywords} />
            </Helmet>
            <Header {...this.props} HeaderOption={HeaderOption} showNavigation={false} />
            {isLoading && <Loader />}
            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader
                    Title="Create Private League"
                    ImgShown={Images.HEADER_BG}
                    BannerImage=""
                    AddClassName="single-title-header-lg"
                  />
                </Col>
              </Row>
              <div className="create-league-list">
                <Tabs
                  activeKey={this.state.key}
                  onSelect={this.handleSelect}
                  id="controlled-tab-example"
                >
                  <Tab eventKey={1} title="Add Users">
                    <div className="heading-section p-h-15 m-t-0">
                      <Row>
                        <Col xs={12}>
                          <div className="search-btn search-full">
                            <FormControl
                              type="text"
                              placeholder="Search"
                              value={Searchkey}
                              onChange={e => {
                                this.setState({
                                  Searchkey: e.target.value,
                                  offset: 0,
                                  hasMore: false
                                });
                                this.SearchUserReq();
                              }}
                            />
                            <div className="search-box">
                              <i className="icon-search" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="p-xs list-help-text">You can only invite Premium and Premium+ Members</div>
                    <div className="p-xs list-help-text-left">You have to pick minimum <span>{Constants.createContestData.minSize}</span> users
                      <div className="picked-user-count">
                        Added Users : <span>{selectedUserList.length + this.state.selectedListMemberCount}</span>
                      </div>
                    </div>
                    {userList.length == 0 && !isLoading && (
                      <NoDataScreen
                        Content={{
                          ImgShown: Images.no_data_bg_image,
                          IsButton: false,
                          ButtonText: "Go to Lobby",
                          Title: "Oops! No data AVAILABLE",
                          NoDataContent: "",
                          BtnAction: () => {
                            this.props.history.push("/dashboard/0");
                          },
                          WithFilter: "1"
                        }}
                      />
                    )}
                    <InfiniteScroll
                      pullDownToRefresh={false}
                      dataLength={userList.length}
                      next={this.fetchMoreData}
                      hasMore={hasMore}
                      loader={<h6 className="p text-center">Loading...</h6>}
                    >
                      {userList.length > 0 && (
                        <table className="m-t-0">
                          <tbody>
                            {userList.map((item, idx) => {
                              return (
                                <tr key={idx} className={selectedUserList.includes(item.user_id) ? 'selected-tr' : ''}>
                                  <td className="user-name">
                                    <img
                                      src={
                                        item.image
                                          ? item.image
                                          : Images.DEFAULT_USER
                                      }
                                      alt=""
                                    />
                                    {item.user_name}
                                  </td>
                                  <td className="user-action">
                                    <a
                                      href
                                      className={
                                        "add-user" +
                                        (this.state.selectedUserList.includes(
                                          item.user_id
                                        )
                                          ? " added"
                                          : "")
                                      }
                                      onClick={() =>
                                        this.selectThisUser(item.user_id)
                                      }
                                    >
                                      <i className="icon-tick" />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}

                          </tbody>
                        </table>
                      )}
                    </InfiniteScroll>
                  </Tab>
                  <Tab eventKey={2} title="Select from saved list">
                    <div className="heading-section p-h-15 m-t-0">
                      <Row>
                        <Col xs={12}>
                          <div className="search-btn search-full">
                            <FormControl
                              type="text"
                              placeholder="Search"
                              value={Searchkey}
                              onChange={e => {
                                this.setState({
                                  Searchkey: e.target.value,
                                  offset: 0,
                                  hasMore: false
                                });
                                this.SearchUserReq();
                              }}
                            />
                            <div className="search-box">
                              <i className="icon-search" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="help-text p-xs">You can only invite Premium and Premium+ Members</div>
                    <div className="p-xs list-help-text-left">You have to pick minimum <span>{Constants.createContestData.minSize}</span> users
                      <div className="picked-user-count">
                        Added Users : <span>{selectedUserList.length + this.state.selectedListMemberCount}</span>
                      </div>
                    </div>
                    {savedList.length == 0 && !isLoading && (
                      <NoDataScreen
                        Content={{
                          ImgShown: Images.no_data_bg_image,
                          IsButton: false,
                          ButtonText: "Go to Lobby",
                          Title: "Oops! No data AVAILABLE",
                          NoDataContent: "",
                          BtnAction: () => {
                            this.props.history.push("/dashboard/0");
                          },
                          WithFilter: "1"
                        }}
                      />
                    )}
                    <InfiniteScroll
                      pullDownToRefresh={false}
                      dataLength={savedList.length}
                      next={this.fetchMoreData}
                      hasMore={hasMore}
                      loader={<h6 className="p text-center">Loading...</h6>}
                    >
                      {savedList.length > 0 && (
                        <table>
                          <tbody>
                            {savedList.map((item, idx) => {
                              return (
                                <tr key={idx} className={this.state.selectedSavedList.includes(item.invite_group_id) ? 'selected-tr' : ''}>
                                  <td onClick={() => window.open('/team-detail/' + WSManager.encrypt(item.group_name) + '/' + WSManager.encrypt(item.invite_group_id), "_blank")} className="list-name cursor-pointer">
                                    {item.group_name}
                                    <p>{item.members_count} Members</p>
                                  </td>
                                  <td className="user-action selected-team-action">
                                    <a href
                                      className="delete-team"
                                      onClick={() =>
                                        Header.showAlertModal({
                                          message: "Are you sure you want delete this list?",
                                          ModalAction: () => {
                                            this.DeleteList(item.invite_group_id, idx, parseInt(item.members_count))
                                          }
                                        }
                                        )
                                      }
                                    >
                                      <i className="icon-delete"></i>
                                    </a>
                                    <a
                                      href
                                      className={
                                        "add-user" +
                                        (this.state.selectedSavedList.includes(
                                          item.invite_group_id
                                        )
                                          ? " added"
                                          : "")
                                      }
                                      onClick={() =>
                                        this.selectSaveList(item.invite_group_id, parseInt(item.members_count))
                                      }
                                    >
                                      <i className="icon-tick" />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </InfiniteScroll>

                  </Tab>
                </Tabs>
                <div className="page-footer">
                  <button
                    type="button"
                    className="btn-block btn-primary btn "
                    disabled={
                      (selectedUserList.length + this.state.selectedListMemberCount) <
                      Constants.createContestData.minSize
                    }
                    onClick={() => this.onSubmit()}
                  >
                    Invite AND Create Contest
                      </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
