import React from "react";
import { Row, Col, Button, FormGroup, Table, Checkbox } from "react-bootstrap";
import { inputStyle } from "../helper/input-style";
import { notify } from "react-notify-toast";
import { Header, SubHeader, Images, Loader, RKFloatingLabelInput, NoDataScreen } from "../components";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import WSManager from "../helper/WSManager";
import FloatingLabel from "floating-label-react";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import _ from "lodash";
import Select from "react-select";
import MetaData from "../helper/MetaData";
import Validation from "./../helper/validation";
import util from "util";
import Slider from "react-slick";
import { ContestShareModal } from '../Modals';
import config from '../Config';

export default class CreatePrivateContest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: WSManager.getProfile(),
      submitClick: false,
      isLoading: false,
      private_league_id: Constants.PRIVATE_LEAGUE_ID,
      public_league_id: Constants.PUBLIC_LEAGUE_ID,
      NoOFWinners: [],
      SelectedWinner: "",
      EnteryFeeData: [],
      SelectedEntryFee: "",
      ContestData: "",
      minSize: 0,
      maxSize: 0,
      ContestName: "",
      PrizePool: "0.00",
      PrizeDistribution: [],
      isNotEligible: true,
      categories_list: Constants.categories,
      categoryObj: "",
      selectedCategory: "",
      selectedType: "",
      round_id: '',
      current_roundData: {},
      is_auto_recurring: 0,
      pageBanner: [],
      contestType: 1,
      contestTypeObj: Constants.contest_type[0],
      displayImageFile: "",
      displayImage: "",
      is_season_long: "",
      contest_round_type_options: Constants.contest_duration,
      selectedContestRoundType: Constants.contest_duration[0]
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // this.getPageBanner();
    const user_permissions = WSManager.getUserPermissions();

    if (user_permissions.create_league == '0') {
      window.location.assign("/dashboard/0");
    }
    else {
      if (this.props.type == 1) {
        this.setCategory();
      }
      if (this.props.type == 2) {
        this.getContestDetail();
      }
    }

  }


  getContestDetail = () => {
    // console.log(this.props.data)
    let data = this.props.data;

    let param = {
      contest_unique_id: data.contest_unique_id
    }

    this.setState({ isLoading: true })
    WSManager.Rest(NC.baseURL + NC.GET_CONTEST_DETAIL, param)
      .then(res => {
        if (res.response_code === NC.successCode) {

          let res_data = res.data;
          let contest_detail = res_data.contest_detail;


          let EntryFeeArray = [];
          let entryFee = "";
          _.map(res_data.entry_fee_data, (item, idx) => {

            if (contest_detail.entry_fee == item.entry_fee) {
              entryFee = item
            }

            item["label"] = item.entry_fee;
            item["value"] = item.entry_fee;
            EntryFeeArray.push(item);
          });


          let winnerArray = [];
          let SelectedWinner = "";
          _.map(res_data.number_of_winner_validation, (item, idx) => {

            if (contest_detail.places == item.places) {
              SelectedWinner = item
            }
            item["label"] = item.master_contest_type_desc;
            item["value"] = item.master_contest_type_id;
            item["percentage_share"] = item.distribution_percentage ? item.distribution_percentage.split(',') : null;
            winnerArray.push(item);

          });

          let selectedContestRoundType = this.state.contest_round_type_options[0];
          _.map(this.state.contest_round_type_options, (item, idx) => {
            if (contest_detail.is_season_long == item.value) {
              selectedContestRoundType = item
            }
          })


          let categoryObj = "";
          let selectedCategory = "";
          let selectedType = ""

          _.map(Constants.categories, (item, idx) => {
            if (item.id == contest_detail.type) {
              categoryObj = item;
              selectedCategory = item.value;
              selectedType = item.id
            }
          })

          this.setState({
            ContestName: contest_detail.contest_name,
            isNotEligible: false,
            ContestData: res_data.contest_data[0],
            minSize: contest_detail.minimum_size,
            PrizeDistribution: contest_detail.prize_distibution_detail,
            contestType: contest_detail.contest_type == this.state.public_league_id ? 1 : 2,
            displayImage: contest_detail.contest_logo,
            EnteryFeeData: EntryFeeArray,
            SelectedEntryFee: entryFee,
            NoOFWinners: winnerArray,
            SelectedWinner,
            maxSize: null,
            current_roundData: { round_id: contest_detail.round_id },
            selectedContestRoundType,
            categoryObj,
            selectedCategory,
            selectedType,
            isLoading: false,
            contestTypeObj: contest_detail.contest_type == '4' ? Constants.contest_type[1] : Constants.contest_type[0],
            is_auto_recurring:contest_detail.is_auto_recurring
          })

          this.getPrizePool(contest_detail.site_rake, contest_detail.entry_fee, contest_detail.minimum_size)
        }
      })
  }

  setCategory = () => {
    let { selectedCategory } = this.props;
    if (selectedCategory.id == this.state.selectedType) return;
    this.setState({
      categoryObj: selectedCategory,
      selectedCategory: selectedCategory.value,
      selectedType: selectedCategory.id,
    }, () => {
      this.getCurrentRoundData();
    })
  }

  componentWillReceiveProps() {
    console.log('nextProps')
  }


  componentDidUpdate() {

    if (this.props.type == 1 && this.props.selectedCategory.id != this.state.selectedType) {
      this.setCategory()
    }
  }

  getPageBanner = () => {

    let param = {
      page_type_id: Constants.BANNER_PAGE_TYPE_ID.statsAwards,
      position_id: "1"
    }
    WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }


  getCurrentRoundData = () => {
    let { selectedType, selectedCategory, contestType, private_league_id, public_league_id } = this.state;
    let param = {
      type: selectedType,
      contest_type_id: contestType == 1 ? public_league_id : private_league_id,
      contest_access_type: contestType == 1 ? 0 : 1
    };

    if (selectedType == '2') {
      param['country_id'] = selectedCategory;
    }
    if (selectedType == '3') {
      param['team_id'] = selectedCategory;
    }
    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.GET_CURRENT_GAME_ROUND, param).then(res => {
      if (res.response_code === NC.successCode) {
        this.setState({ current_roundData: res.data })
        this.checkContestEligibility();
      }
      else {
        this.setState({
          isLoading: false
        });
      }
    })
  }

  getContestDataDetail = () => {
    let { selectedType, selectedCategory } = this.state;
    let param = {
      type: selectedType
    };

    if (selectedType == '2') {
      param['country_id'] = selectedCategory;
    }
    if (selectedType == '3') {
      param['team_id'] = selectedCategory;
    }

    WSManager.Rest(NC.baseURL + NC.GET_CURRENT_GAME_ROUND, param).then(res => {
      if (res.response_code === NC.successCode) {
        let data = res;
        if (data.ContestData) {
          this.setState(
            {
              ContestName: data.ContestName,
              SelectedEntryFee: data.SelectedEntryFee,
              SelectedWinner: data.dataSelectedWinner,
              minSize: data.minSize,
              maxSize: data.maxSize,
              ContestData: data.ContestData,
              submitClick: true,
            },
            () => {
              this.GetWinnerPrizeDetail();
            }
          );
        }
      }
      this.checkContestEligibility();
    }
    )
  }

  selectCategory = (itm) => {
    if (this.state.selectedCategory == itm.value) return;

    this.setState({
      selectedCategory: itm.value,
      selectedType: itm.id,
      categoryObj: itm,
    }, () => {
      this.getCurrentRoundData();

    })
  }

  checkContestEligibility() {

    let { selectedType, selectedCategory, private_league_id, public_league_id, contestType } = this.state;
    let param = {
      // round_id: Constants.current_roundData.round_id
      type: selectedType,
      contest_type_id: contestType == 1 ? public_league_id : private_league_id,
      contest_access_type: contestType == 1 ? 0 : 1
    };

    if (selectedType == '2') {
      param['country_id'] = selectedCategory;
    }
    if (selectedType == '3') {
      param['team_id'] = selectedCategory;
    }


    this.setState({
      isLoading: true
    });

    WSManager.Rest(NC.baseURL + NC.PRIVATE_CONTEST_ELIGIBILITY, param).then(
      responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {

          if (responseJson.data.count == 0) {
            this.GetContestData();
            this.setState({
              isNotEligible: false
            });
          } else {
            this.setState({
              isNotEligible: true
            });
          }
        }
      }
    );
  }

  GetContestData() {
    const { private_league_id, contestType, public_league_id } = this.state;
    let param = {
      contest_type_id: contestType == 1 ? public_league_id : private_league_id,
      contest_access_type: contestType == 1 ? 0 : 1
    };
    this.setState({
      isLoading: true
    });

    WSManager.Rest(NC.baseURL + NC.GET_ALL_PRIZE_DATA, param).then(
      responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          let winnerArray = [];
          _.map(responseJson.data.number_of_winner_validation, (item, idx) => {
            item["label"] = item.master_contest_type_desc;
            item["value"] = item.master_contest_type_id;
            item["percentage_share"] = item.distribution_percentage ? item.distribution_percentage.split(',') : null;
            winnerArray.push(item);
          });

          let EntryFeeArray = [];
          _.map(responseJson.data.entry_fee_data, (item, idx) => {
            item["label"] = item.entry_fee;
            item["value"] = item.entry_fee;
            EntryFeeArray.push(item);
          });
          if (responseJson.data.contest_data.length > 0) {
            this.setState({
              ContestData: responseJson.data.contest_data[0],
              minSize: parseInt(responseJson.data.contest_data[0].min_size),
              maxSize: parseInt(responseJson.data.contest_data[0].max_size)
            });
          }
          this.setState({
            NoOFWinners: winnerArray,
            EnteryFeeData: EntryFeeArray
          });


        }
      }
    );
  }

  getPrizePool(SiteRake, EntryFee, TeamSize) {
    let prizePool = EntryFee * TeamSize;
    if (prizePool > 0) {
      prizePool = prizePool - (SiteRake * prizePool) / 100;
    } else {
      prizePool = 0;
    }
    this.setState({
      PrizePool: prizePool.toFixed(2)
    });
    return prizePool.toFixed(2);
  }

  GetWinnerPrizeDetail() {
    const {
      ContestData,
      minSize,
      SelectedEntryFee,
      SelectedWinner
    } = this.state;


    if (
      SelectedEntryFee != "" &&
      SelectedWinner != "" &&
      parseInt(minSize) >= parseInt(ContestData.min_size)
      // && parseInt(minSize) <= parseInt(ContestData.max_size)
    ) {

      console.log('GetWinnerPrizeDetail2')

      let numberOfWinners = parseInt(SelectedWinner.places);
      let tempPrizePool = this.getPrizePool(
        parseInt(ContestData.site_rake),
        parseInt(SelectedEntryFee.value),
        parseInt(minSize)
      );

      let individualPer = parseFloat((100 / numberOfWinners).toFixed(2));
      let individualAmount = parseFloat(((tempPrizePool * individualPer) / 100).toFixed(2));


      let equalIndividualPer = parseFloat((100 / numberOfWinners).toFixed(2));
      let equalIndividualAmount = parseFloat(((tempPrizePool * individualPer) / 100).toFixed(2));



      // var firstPer = 0;
      // var lastPer = 0;

      // if (individualPer * numberOfWinners < 100.0) {
      //   firstPer = parseFloat((100.0 - individualPer * numberOfWinners).toFixed(2));
      // } else if (individualPer * numberOfWinners > 100.0) {
      //   lastPer = parseFloat((individualPer * numberOfWinners - 100.0).toFixed(2));
      // }

      var firstPer = this.getRestPercentage(individualPer, numberOfWinners, 0);
      var lastPer = this.getRestPercentage(individualPer, numberOfWinners, 1);

      // console.log(firstPer, newFirstPer, lastPer, newLastPer)

      // var firstAmount = 0;
      // var lastAmount = 0;

      // if (individualAmount * numberOfWinners < tempPrizePool) {
      //   firstAmount = parseFloat((tempPrizePool - individualAmount * numberOfWinners).toFixed(2));
      // } else if (individualAmount * numberOfWinners > tempPrizePool) {
      //   lastAmount = parseFloat((individualAmount * numberOfWinners - tempPrizePool).toFixed(2));
      // }


      var firstAmount = this.getRestAmount(individualAmount, numberOfWinners, tempPrizePool, 0);
      var lastAmount = this.getRestAmount(individualAmount, numberOfWinners, tempPrizePool, 1);


      // console.log(firstAmount, newFirstAmount, lastAmount, newLastAmount)

      var tempPrizeArraY = [];



      var x = 0;
      for (var i = 1; i <= numberOfWinners; i++) {
        // console.log((individualPer).toFixed(2), individualPer, firstPer)
        let prizeDictionary = {
          min: i,
          max: i,
          per_old:
            i == 1
              ? (individualPer + firstPer).toFixed(2)
              : i == numberOfWinners
                ? (individualPer - lastPer).toFixed(2)
                : individualPer.toFixed(2),
          amount_old:
            i == 1
              ? (individualAmount + firstAmount).toFixed(2)
              : i == numberOfWinners
                ? (individualAmount - lastAmount).toFixed(2)
                : individualAmount.toFixed(2),

          per: SelectedWinner.position_or_percentage == "1" ?
            SelectedWinner.percentage_share[x]
            // (
            //   i == 1 ?
            //     (parseFloat(SelectedWinner.percentage_share[x]) + parseFloat(this.getRestPercentage(SelectedWinner.percentage_share[x], numberOfWinners, 0))).toFixed(2)
            //     : i == numberOfWinners ?
            //       (parseFloat(SelectedWinner.percentage_share[x]) + parseFloat(this.getRestPercentage(SelectedWinner.percentage_share[x], numberOfWinners, 4))).toFixed(2)
            //       : parseFloat(SelectedWinner.percentage_share[x]).toFixed(2)
            // )
            :
            (
              i == 1
                ? (individualPer + firstPer).toFixed(2)
                : i == numberOfWinners
                  ? (individualPer - lastPer).toFixed(2)
                  : individualPer.toFixed(2)
            ),

          amount: SelectedWinner.position_or_percentage == "1" ?

            parseFloat(((tempPrizePool * SelectedWinner.percentage_share[x]) / 100).toFixed(2))
            :
            (
              i == 1
                ? (individualAmount + firstAmount).toFixed(2)
                : i == numberOfWinners
                  ? (individualAmount - lastAmount).toFixed(2)
                  : individualAmount.toFixed(2)
            )
        };
        tempPrizeArraY.push(prizeDictionary);
        x++;
      }

      console.log(tempPrizeArraY, 'tempPrizeArraY')
      this.setState({
        PrizeDistribution: tempPrizeArraY
      });
    } else {
      this.setState({
        PrizeDistribution: [],
        PrizePool: "0.00"
      });
    }
  }

  getRestPercentage = (individualPer, numberOfWinners, firstLastPerson = 0) => {
    var Per = 0;
    if (firstLastPerson == 0) {
      if (individualPer * numberOfWinners < 100.0) {
        Per = parseFloat((100.0 - individualPer * numberOfWinners).toFixed(2));
      }
    }

    if (firstLastPerson == 1) {
      if (individualPer * numberOfWinners > 100.0) {
        Per = parseFloat((individualPer * numberOfWinners - 100.0).toFixed(2));
      }
    }
    return Per;
  }

  getRestAmount = (individualAmount, numberOfWinners, tempPrizePool, firstLastPerson = 0) => {
    var Amt = 0;


    if (firstLastPerson == 0) {
      if (individualAmount * numberOfWinners < tempPrizePool) {
        Amt = parseFloat((tempPrizePool - individualAmount * numberOfWinners).toFixed(2));
      }
    }

    if (firstLastPerson == 1) {
      if (individualAmount * numberOfWinners > tempPrizePool) {
        Amt = parseFloat((individualAmount * numberOfWinners - tempPrizePool).toFixed(2));
      }
    }

    return Amt;
  }


  handleWinnerChange = option => {
    this.setState({ SelectedWinner: option }, () => {
      this.GetWinnerPrizeDetail();
    });
  };
  handleFeeChange = option => {
    this.setState({ SelectedEntryFee: option }, () => {
      this.GetWinnerPrizeDetail();
    });
  };

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      if (name == "minSize") {
        this.GetWinnerPrizeDetail();
      }
    });
  }

  validateForm() {
    const {
      ContestName,
      SelectedEntryFee,
      SelectedWinner,
      minSize,
      maxSize,
      ContestData,
      categories_list
    } = this.state;

    let isValid = true;

    if (Validation.validate("contest_name", ContestName) == 'error') {
      notify.show(Constants.Messages.contest_name, "error", 3000);
      isValid = false;
    } else if (SelectedEntryFee == "") {
      notify.show(Constants.Messages.entry_fee, "error", 3000);
      isValid = false;
    } else if (SelectedWinner == "") {
      notify.show(Constants.Messages.winner, "error", 3000);
      isValid = false;
    } else if (minSize == "") {
      notify.show(Constants.Messages.min_size, "error", 3000);
      isValid = false;
    } else if (maxSize == "") {
      notify.show(Constants.Messages.max_size, "error", 3000);
      isValid = false;
    } else if (parseInt(minSize) < parseInt(ContestData.min_size)) {
      notify.show(util.format(Constants.Messages.min_team_value, ContestData.min_size), "error", 3000);
      isValid = false;
    } else if (parseInt(maxSize) > parseInt(ContestData.max_size)) {
      notify.show(util.format(Constants.Messages.max_team_value, ContestData.max_size), "error", 3000);
      isValid = false;
    } else if (parseInt(maxSize) < parseInt(minSize)) {
      notify.show(Constants.Messages.max_min_size, "error", 3000);
      isValid = false;
    }
    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      submitClick: true
    });
    if (this.validateForm()) {
      const {
        ContestName,
        SelectedEntryFee,
        SelectedWinner,
        minSize,
        maxSize,
        ContestData,
        current_roundData,
        selectedType,
        selectedCategory,
        is_auto_recurring,
        contestType,
        public_league_id,
        displayImage,
        private_league_id,
        is_season_long,
        selectedContestRoundType
      } = this.state;



      if (contestType == 1 || this.props.type == 2) {

        let param = {
          round_id: current_roundData.round_id,
          contest_type: contestType == 1 ? public_league_id : private_league_id,
          size_min: minSize,
          size: maxSize,
          contest_name: ContestName,
          entry_fee: SelectedEntryFee.value,
          master_contest_type_id: SelectedWinner.value,
          type: selectedType,
          is_auto_recurring,
          contest_access_type: contestType == 1 ? 0 : 1,
          contest_logo: displayImage,
          is_season_long: selectedContestRoundType.value
        }

        if (this.props.type == 2) {
          param['contest_unique_id'] = this.props.data.contest_unique_id
        }



        if (selectedType == '2') {
          param['country_id'] = selectedCategory;
        }
        if (selectedType == '3') {
          param['team_id'] = selectedCategory;
        }

        this.setState({ isLoading: true })
        WSManager.Rest(NC.baseURL + NC.CREATE_USER_CONTEST, param).then(res => {
          this.setState({
            isLoading: false
          })

          if (res.response_code === NC.successCode) {
            this.setState({
              isNotEligible: true,
              ContestName: '',
              displayImage: ""
              // ContestShareCode:res.data.contest_unique_id
            })


            notify.show(res.message, "success", 3000);
            if (this.props.type == 2) {

              this.props.history.push('/')

            } else {
              let title =
                `Your friend ${this.state.profile.display_name} has challenged you for the contest.
                Join and win big by beating your friend, using the following link : ${config.MyUrl}/contest/detail/${res.data.contest_unique_id}
                Cheers,
                Team Refpredictor`;
              Header.ShareModalShow(res.data.contest_unique_id, title);

            }


          }
        }

        )

      } else {
        Constants.SetValues.createContestData({
          ContestName: ContestName,
          SelectedEntryFee: SelectedEntryFee,
          SelectedWinner: SelectedWinner,
          minSize: minSize,
          maxSize: maxSize,
          ContestData: ContestData,
          round_id: current_roundData.round_id,
          categoryType: selectedType,
          categoryId: selectedCategory,
          is_auto_recurring,
          contest_logo: displayImage,
          type: this.props.type,
          contest_unique_id: this.props.type == 2 ? this.props.data.contest_unique_id : null,
          is_season_long: selectedContestRoundType.value
        });
        this.props.history.push("/create-contest/add-users");
      }


    }
  }

  checkboxHandler = (e) => {
    this.setState({ [e.target.name]: this.state[e.target.name] == 1 ? 0 : 1 })
  }

  componentWillReceiveProps(nextProps) {

  }


  changeContestType = (key) => {
    if (key.value == this.state.contestType) return;

    this.setState(
      {
        contestType: key.value,
        contestTypeObj: key,
        EnteryFeeData: [],
        SelectedEntryFee: "",
        NoOFWinners: [],
        SelectedWinner: "",
        PrizeDistribution: [],
        PrizePool: "0.00",
        isLoading: true,
        submitClick: false,
        selectedContestRoundType: Constants.contest_duration[0]
      }, () => {
        this.getCurrentRoundData();
      }
    )
  }



  onSelectImage = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let mfile = e.target.files[0];
    reader.onloadend = () => {
      if (mfile.size / 1024000 > 40) {
        notify.show(Constants.Messages.file_size, "error", 5000);
      } else {
        this.setState(
          {
            displayImageFile: mfile,
            displayImage: reader.result
          },
          () => {
            // this.upload.value = "";
            this.uploadImage(this);
          }
        );
      }
    };
    reader.readAsDataURL(mfile);
  }


  uploadImage = (globalThis) => {
    this.setState({ isLoading: true });
    var data = new FormData();
    data.append("contest_logo", this.state.displayImageFile);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        globalThis.setState({ isLoading: false });
        var response = JSON.parse(this.responseText);
        if (response !== "" && response.response_code === NC.successCode) {
          var imagePath = response.data.logo_path;
          globalThis.setState({
            displayImage: imagePath,
            displayImageName: response.data.file_name
          });
        } else {
          var errorMsg = WSManager.parseErrorMessage(response)
          if (response.response_code == NC.sessionExpireCode) {
          } else {
            notify.show(errorMsg, "error", 5000);
          }
          globalThis.setState(
            {
              displayImageFile: '',
              displayImage: ''
            });

        }
      }
    });

    xhr.open("POST", NC.baseURL + NC.UPLOAD_CONTEST_LOGO);
    xhr.setRequestHeader("session_key", WSManager.getToken());
    xhr.send(data);
  }

  handleContestRoundChange = (e) => {
    this.setState({ selectedContestRoundType: e });
  }


  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    const {
      isLoading,
      NoOFWinners,
      SelectedWinner,
      EnteryFeeData,
      SelectedEntryFee,
      ContestData,
      minSize,
      maxSize,
      ContestName,
      submitClick,
      PrizeDistribution,
      PrizePool,
      isNotEligible,
      selectedType,
      categories_list,
      current_roundData,
      is_auto_recurring,
      pageBanner,
      contestType,
      showContestShareModal,
      ContestShareCode,
      displayImage,
      contestTypeObj,
      is_season_long,
      contest_round_type_options,
      selectedContestRoundType
    } = this.state;

    

    return (
      <MyContext.Consumer>
        {context => (
          <div className="create-contest">

            {/* {
              (pageBanner && pageBanner.length > 0) &&
              <div className={'lb-ban-wrap'}>
                <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                  {pageBanner.map((itm, i) => {
                    return <div key={i} className={'lb-banner'}>
                      <img src={itm.image} />
                    </div>
                  })}
                </Slider>
              </div>
            } */}


            {/* <div className={'lb_type_tab15'}>
              {categories_list.map((itm, i) => {
                return <div
                  className={`ct-tab ${selectedType == itm.id ? 'active' : ''}`}
                  onClick={() => this.selectCategory(itm)}
                  key={i}>{itm.name}</div>
              })}
            </div> */}


            {
              this.props.type == 1 &&
              <div className="verification-block mt-4">
                <Row>
                  <Col xs={12} className={'text-center'}>
                    <div className={'rad-switch'}>

                      {
                        Constants.contest_type.map((itm, i) => {
                          return <div
                            className={`${contestTypeObj.value == itm.value ? 'active' : ''}`}
                            onClick={() => this.changeContestType(itm)}
                            key={i}
                          >
                            <span className={'icon-tick check'}></span> {itm.label}
                          </div>
                        })
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            }

            {isLoading && <Loader />}

            <React.Fragment>

              {((current_roundData.is_started == 1 && this.props.type == 1) || isNotEligible) ? (
                <NoDataScreen
                  Content={{
                    ImgShown: Images.no_data_bg_image,
                    IsButton: true,
                    ButtonText: "Go to Lobby",
                    Title: "Oops! You may have already created a private contest",
                    NoDataContent:
                      "for current round, or current round has been started.",
                    BtnAction: () => {
                      this.props.history.push("/dashboard/0");
                    },
                    WithFilter: ""
                  }}
                />
              ) : (
                  ContestData != "" && (
                    <form className="form-section" onSubmit={this.onSubmit}>
                      <div>
                        <div className="create-contest-form">


                          <div className={'d-pic-ar'}>
                            <label>
                              <div className={'d-pic'}>
                                <img src={displayImage || Images.DEFAULT_USER} />
                              </div>
                              <input type={'file'} accept="image/*" onChange={this.onSelectImage} />
                            </label>


                          </div>

                          <div className="verification-block ">
                            <Row>
                              <Col xs={12}>
                                <FormGroup
                                  className="input-label-center input-transparent font-14"
                                  controlId="formBasicText"
                                  validationState={
                                    submitClick &&
                                    Validation.validate("contest_name", ContestName)
                                  }
                                >
                                  <FloatingLabel
                                    autoComplete="off"
                                    styles={inputStyle}
                                    id="ContestName"
                                    name="ContestName"
                                    placeholder="Contest Name"
                                    type="text"
                                    value={ContestName}
                                    onChange={this.onChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>


                          {
                            contestTypeObj.value == 1 &&


                            <div className="verification-block mb-4" >
                              <Row>
                                <Col xs={12} className="input-label-spacing zIndex10">
                                  <FormGroup
                                    className="input-label-center input-transparent font-14 "
                                    validationState={
                                      submitClick &&
                                      (selectedContestRoundType !== ""
                                        ? "success"
                                        : "error")
                                    }
                                  >
                                    <div className="select-league">
                                      <label style={inputStyle.label}>Contest Type</label>
                                      <div className="genderStyle">
                                        <Select
                                          onChange={this.handleContestRoundChange}
                                          options={contest_round_type_options}
                                          classNamePrefix="secondary"
                                          className="select-secondary minusML10"
                                          value={selectedContestRoundType}
                                          placeholder="--"
                                          isSearchable={false}
                                          isClearable={false}
                                          theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#37003C"
                                            }
                                          })}
                                        />
                                      </div>
                                      <span className="select-arr">
                                        <i className="icon-arrow-sort" />
                                      </span>
                                      <div className="league-border col-sm-12" />
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          }

                          <div className="verification-block ">
                            <Row>
                              <Col xs={6} className="input-label-spacing zIndex9">
                                <div className="verification-block ">
                                  <FormGroup
                                    className="input-label-center input-transparent font-14 league-select"
                                    validationState={
                                      submitClick &&
                                      (SelectedEntryFee !== ""
                                        ? "success"
                                        : "error")
                                    }
                                  >
                                    <div className="select-league">
                                      <label style={inputStyle.label}>
                                        Entry Fee (<i className={'icon-Rits'}></i>)
                                  </label>
                                      <div className="genderStyle">
                                        <Select
                                          onChange={this.handleFeeChange}
                                          options={EnteryFeeData}
                                          classNamePrefix="secondary"
                                          className="select-secondary minusML10"
                                          value={SelectedEntryFee}
                                          placeholder="--"
                                          isSearchable={false}
                                          isClearable={false}
                                          theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#37003C"
                                            }
                                          })}
                                        />
                                      </div>
                                      <span className="select-arr">
                                        <i className="icon-arrow-sort" />
                                      </span>
                                      <div className="league-border col-sm-12" />
                                    </div>
                                  </FormGroup>
                                </div>
                                <span className="bordered-span" />
                              </Col>
                              <Col xs={6} className="input-label-spacing zIndex9">
                                <div className="verification-block ">
                                  <FormGroup
                                    className="input-label-center input-transparent font-14 league-select"
                                    validationState={
                                      submitClick &&
                                      (SelectedWinner !== "" ? "success" : "error")
                                    }
                                  >
                                    <div className="select-league">
                                      <label style={inputStyle.label}>
                                        No. of Winners
                                  </label>
                                      <div className="genderStyle">
                                        <Select
                                          onChange={this.handleWinnerChange}
                                          options={NoOFWinners}
                                          classNamePrefix="secondary"
                                          className="select-secondary minusML10"
                                          value={SelectedWinner}
                                          placeholder="--"
                                          isSearchable={false}
                                          isClearable={false}
                                          theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#37003C"
                                            }
                                          })}
                                        />
                                      </div>
                                      <span className="select-arr">
                                        <i className="icon-arrow-sort" />
                                      </span>
                                      <div className="league-border col-sm-12" />
                                    </div>
                                  </FormGroup>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="verification-block ">
                            <Row>
                              <Col xs={6} className="input-label-spacing">
                                <FormGroup
                                  className="input-label-center input-transparent font-14"
                                  controlId="formBasicText"
                                  validationState={
                                    parseInt(minSize) >= parseInt(ContestData.min_size)
                                      // && parseInt(minSize) <= parseInt(ContestData.max_size)
                                      ? "success"
                                      : "error"
                                  }
                                >
                                  <RKFloatingLabelInput
                                    className="rkfloatingLabelInput withdraw"
                                    id="minSize"
                                    name="minSize"
                                    autocomplete="off"
                                    type="text"
                                    isNumberOnly={true}
                                    value={minSize}
                                    placeholder="Min Team Size"
                                    onChange={this.onChange}
                                  />
                                </FormGroup>
                                <span className="bordered-span" />
                              </Col>
                              <Col xs={6} className="input-label-spacing">
                                <FormGroup
                                  className="input-label-center input-transparent font-14"
                                  controlId="formBasicText"
                                // validationState={
                                //   parseInt(maxSize) >= parseInt(minSize) &&
                                //     parseInt(maxSize) <= parseInt(ContestData.max_size)
                                //     ? "success"
                                //     : "error"
                                // }
                                >
                                  <RKFloatingLabelInput
                                    className="rkfloatingLabelInput withdraw"
                                    id="maxSize"
                                    name="maxSize"
                                    autocomplete="off"
                                    type="text"
                                    isNumberOnly={true}
                                    value={maxSize || 'Unlimited'}
                                    placeholder="Max Team Size"
                                    disabled={true}
                                  // onChange={this.onChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>





                          {
                            (selectedContestRoundType.value == 0 && contestType == 1) &&
                            <div className={'verification-block text-center'}>
                              <Checkbox
                                checked={is_auto_recurring ? true : false}
                                onChange={this.checkboxHandler}
                                className="custom-checkbox cr-lg-checkie"
                                value=""
                                name="is_auto_recurring"
                                id="is_auto_recurring"
                              >
                                <span>Auto-recurrent for all rounds</span>
                              </Checkbox>
                            </div>

                          }





                          <div className="prize-pool">
                            <span className="prize-pool-label">Prize Pool</span>
                            <span> <i className={'icon-Rits'}></i> {PrizePool}</span>
                          </div>
                        </div>
                        {PrizeDistribution.length > 0 && (
                          <div className="prize-detail">
                            <div className="prize-heading">
                              <span>Prizes</span>
                            </div>
                            <Table>
                              <thead>
                                <tr>
                                  <th>Rank</th>
                                  <th className="text-center">Winning %</th>
                                  <th className="text-right">Winning</th>
                                </tr>
                              </thead>
                              <tbody>
                                {_.map(PrizeDistribution, (itm, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>#{itm.min}</td>
                                      <td className="text-center winner-distribution-input">
                                        <div
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: 4,
                                            overflow: "hidden"
                                          }}
                                          className="d-inline-flex"
                                        >
                                          {itm.per}
                                        </div>
                                      </td>
                                      <td className="text-right"> <i className={'icon-Rits'}></i> {itm.amount}</td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td colSpan="3" className="text-right">
                                    <span>Total</span><i className={'icon-Rits'}></i> {PrizePool}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        )}

                        <div className="page-footer">
                          <Button
                            bsStyle="primary"
                            className="btn btn-block"
                            type="submit"
                            onClick={this.onSubmit}
                          >{
                              this.props.type == 2 ?
                                "Update Contest" :
                                contestType == 1 ? "Create Contest" : "Create & Add User"
                            } </Button>
                        </div>



                      </div>
                    </form>
                  )
                )}


            </React.Fragment>
            {/* } */}
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}


CreatePrivateContest.defaultProps = {
  type: 1 //1 for add,2 for edit
}