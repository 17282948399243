import React from "react";
import { Row, Col, Media, FormControl, FormGroup } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images, SubHeader } from "../components";
import MetaData from "../helper/MetaData";

export default class BlogComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container refsocial-web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.Blog.title}</title>
              <meta name="description" content={MetaData.Blog.description} />
              <meta name="keywords" content={MetaData.Blog.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            <div className="post-comment-section">
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Comments (70)" ImgShown={Images.REFSOCIAL_HEADER_BG} BannerImage="" AddClassName="single-title-header" />
                  {/* Comment section start */}
                  <div className="post-card">
                    <div className="post-card-header">
                      <div className="postuser-detail">
                        <img src={Images.DEFAULT_USER} alt="" />
                        <div className="user-name">
                          Mark.Zucker<span className="user-tag">Posted</span>
                        </div>
                        <div className="post-time">1 min ago</div>
                        <i className="icon-add-user" />
                        <i className="icon-share" />
                      </div>
                    </div>
                    <div className="post-card-body">
                      <div className="post-description">
                        Derby county on brink of Deal for 2018 AFCON-Winning
                        Defender, Lampard Reveals :: All Nigeria Soccer - The
                        complete Nigerian Football Portal
                      </div>
                      <div className="posted-img">
                        <img src={Images.BLOG_IMG} alt="" />
                      </div>
                      <div className="blog-action post-action-section">
                        <Row>
                          <Col xs={3}>
                            <span className="user-fav liked">
                              <i className="icon-like" />
                            </span>
                            <span className="txt">546</span>
                          </Col>

                          <Col xs={3} className="text-center">
                            <span className="action-count">
                              <i className="icon-reload" />
                              <span className="txt">0</span>
                            </span>
                          </Col>
                          <Col xs={3} className="text-center">
                            <span className="action-count">
                              <i className="icon-comment" />
                              <span className="txt">0</span>
                            </span>
                          </Col>
                          <Col xs={3} className="text-center">
                            <span className="action-count">
                              <i className="icon-dislike" />
                              <span className="txt">0</span>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="comment-section">
                    <Media className="incoming-msg msg-section">
                      <img
                        src={Images.DEFAULT_USER}
                        alt="sender-img"
                        className="user-img"
                      />
                      <Media.Body>
                        <div className="comment">
                          <div className="comment-txt">
                            <div className="comment-by">Joyce Williams</div>
                            Louis; Can you tell me what a clean escalation..
                          </div>
                          <div className="comment-time">
                            5 mins ago
                            <span className="comment-like">
                              <i className="icon-like-empty" /> Like
                            </span>
                            <span className="comment-rply">
                              <i className="icon-reply" /> Reply
                            </span>
                          </div>
                        </div>
                      </Media.Body>
                    </Media>
                    <Media className="incoming-msg msg-section">
                      <img
                        src={Images.DEFAULT_USER}
                        alt="sender-img"
                        className="user-img"
                      />
                      <Media.Body>
                        <div className="comment">
                          <div className="comment-txt">
                            <div className="comment-by">Joyce Williams</div>
                            Louis; Can you tell me what a clean escalation..
                          </div>
                          <div className="comment-time">
                            5 mins ago
                            <span className="comment-like">
                              <i className="icon-like-empty" /> Like
                            </span>
                            <span className="comment-rply">
                              <i className="icon-reply" /> Reply
                            </span>
                          </div>
                        </div>
                      </Media.Body>
                    </Media>
                  </div>
                  <div className="enter-comment-section">
                    <Media className="incoming-msg msg-section">
                      <img
                        src={Images.DEFAULT_USER}
                        alt="sender-img"
                        className="user-img"
                      />
                      <Media.Body>
                        <div className="type-comment-area">
                          <form>
                            <FormGroup controlId="formInlineName">
                              <FormControl type="text" placeholder="Jane Doe" />
                              <i className="icon-send" />
                            </FormGroup>
                          </form>
                        </div>
                      </Media.Body>
                    </Media>
                  </div>
                  {/* Comment section end */}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
