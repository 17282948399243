import React from "react";
import { Panel } from "react-bootstrap";
import _ from "lodash";
import Rating from "react-rating";
import PerformanceComponent from "./PerformanceComponent";
import { Images } from "../components";
import UtilityFunctions from "../helper/UtilityFunctions";
import * as Constants from "../helper/Constants";
import WSManager from "../helper/WSManager";

export default class LeaderBoardRecord extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPerformace: ""
    };
  }

  SkillBadgesTitle = percent => {
    if (percent < 40) {
      return "Amateur";
    }
    if (percent < 50) {
      return "Average";
    }
    if (percent < 60) {
      return "Advanced";
    }
    if (percent < 70) {
      return "Pro";
    }
    if (percent < 80) {
      return "Expert";
    }
    if (percent >= 80) {
      return "Genius";
    }
  };

  SkillBadgesStar = percent => {
    if (percent < 40) {
      return 0;
    }
    if (percent < 50) {
      return 20;
    }
    if (percent < 60) {
      return 40;
    }
    if (percent < 70) {
      return 60;
    }
    if (percent < 80) {
      return 80;
    }
    if (percent >= 80) {
      return 100;
    }
  };

  goTo = (path) => {
    return this.props.history.push(path)
  }

  render() {
    const { selectedRound, selectedMonth, idx, item, isOwn, isQualification, isElimination, selectedCategory, selectedLeague,
      selectedContest, selectedContestType, selectedFollowOption, selectedMainTab

    } = this.props;

    return (
      <div >
        <Panel key={idx} eventKey={idx}>
          <Panel.Heading>
            <Panel.Title toggle={isQualification ? false : true}>
              {
                isQualification ?
                  <div
                    className={
                      "leaderboard-table-view" + (isOwn ? " current-user-data " : " white-bg ot-list")
                    }
                  >

                    <div className="rank-section serial">{idx + 1}</div>


                    <div
                      className="ref-section username sss"
                      style={{
                        fontFamily:
                          item.plan_id != 1
                            ? "MuliBlack"
                            : "MuliRegular"
                      }}
                    >
                      <img src={item.image || Images.DEFAULT_USER} alt="" />
                      {
                        item.plan_id == 3 &&
                        // user_permissions.premium_badge == '1' &&
                        (
                          <img src={Images.P_PLUS} alt="" className="p-plus-img" />
                        )}
                      <span>{item.user_name}</span>
                    </div>
                    <div className="pl-section batch">{item.month ? Constants.MonthList[item.month || '1'].short_name : '-'} - {item.round_name}</div>
                    {isOwn ? (
                      <div className="follow-section" />
                    ) : (
                        <div className="follow-section">
                          <i
                            onClick={event =>
                              this.props.followAction(
                                item.user_id,
                                idx,
                                item.is_following,
                                event
                              )
                            }
                            className={
                              item.is_following == 0
                                ? "icon-add-user"
                                : "icon-user-select"
                            }
                          />
                        </div>
                      )}
                  </div>
                  :
                  <div
                    style={isElimination && item.color != '#fff' ? { background: (item.color || '#fff') } : {}}
                    className={
                      "leaderboard-table-view" +
                      (isOwn ? " current-user-data " : " ot-list") +
                      (item.user_type == 1 ? " selected-player-data" : "")
                    }
                  >
                    <div className="rank-section">
                      {item.rank ? UtilityFunctions.abbreviateNumber(parseInt(item.rank || 0)) : '--'}
                      <i
                        className={
                          parseInt(item.last_rank) == 0
                            ? "no-change"
                            : parseInt(item.rank) < parseInt(item.last_rank)
                              ? "icon-sorting-arrow-up"
                              : parseInt(item.rank) > parseInt(item.last_rank)
                                ? "icon-sorting-arrow-down"
                                : "no-change"
                        }
                      />
                    </div>
                    <div
                      className="ref-section"
                      style={{
                        fontFamily:
                          // item.plan_id == 3 || item.plan_id == 2
                          item.plan_id != 1
                            ? "MuliBlack"
                            : "MuliRegular"
                      }}
                    // onClick={()=>this.goTo('/')}
                    >
                      <img src={item.image || Images.DEFAULT_USER} alt="" />
                      {isOwn ? <span>
                        {item.user_name || '-'}
                        <div className={'us_name'}>
                          @{item.user_name} {item.plan_id == 3 && (<img src={Images.P_PLUS} alt="" className="p-plus-img-2" />)}
                        </div>
                      </span> :
                        <span>
                          {/* {item.first_name || '-'} {item.last_name || '-'} */}
                          {item.display_name || '-'}
                          <div className={'us_name'}>
                            @{item.user_name} {item.plan_id == 3 && (<img src={Images.P_PLUS} alt="" className="p-plus-img-2" />)}
                          </div>
                        </span>
                      }

                      {isElimination && <span className="g-name"> ({item.group_name})</span>}
                    </div>
                    <div className="pl-section">{item.total_plays || "--"}</div>
                    <div className="rp-section">{item.round_points || "--"}</div>
                    {(selectedMonth != "" && !selectedRound.value) && (
                      <div className="sp-section">{item.month_points || "--"}</div>
                    )}
                    {(selectedMonth == "" || selectedRound.value) && !isElimination && (
                      <div className="sp-section">{item.season_point || "--"}</div>
                    )}
                    {(selectedMonth == "" || selectedRound.value) && isElimination && (
                      <div className="sp-section">{item.cup_points || "--"}</div>
                    )}
                    {isOwn ? (
                      <div className="follow-section" />
                    ) : (
                        <div className="follow-section">
                          <i
                            onClick={event =>
                              this.props.followAction(
                                item.user_id,
                                idx,
                                item.is_following,
                                event
                              )
                            }
                            className={
                              item.is_following == 0
                                ? "icon-add-user"
                                : "icon-user-select"
                            }
                          />
                        </div>
                      )}
                  </div>
              }
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <div className="user-more-info">
              {(item.goldenstar > 0 ||
                item.goldenkey > 0 ||
                item.c6rrect > 0) && (
                  <div className="user-earned">
                    <div className="label">Badges</div>
                    <div className="data badges-earned">
                      {item.goldenstar > 3 ? (
                        <React.Fragment>
                          <img src={Images.STAR_IMG} alt="" />
                          <span className="badge-count">{item.goldenstar}</span>
                        </React.Fragment>
                      ) : (
                          _.times(item.goldenstar, i => {
                            return (
                              <React.Fragment key={i}>
                                <img src={Images.STAR_IMG} alt="" />
                              </React.Fragment>
                            );
                          })
                        )}

                      {item.goldenkey > 3 ? (
                        <React.Fragment>
                          <img src={Images.KEY} alt="" />
                          <span className="badge-count">{item.goldenkey}</span>
                        </React.Fragment>
                      ) : (
                          _.times(item.goldenkey, i => {
                            return (
                              <React.Fragment key={i}>
                                <img src={Images.KEY} alt="" />
                              </React.Fragment>
                            );
                          })
                        )}

                      {item.c6rrect > 3 ? (
                        <React.Fragment>
                          <img src={Images.C6RRECT} alt="" />
                          <span className="badge-count">{item.c6rrect}</span>
                        </React.Fragment>
                      ) : (
                          _.times(item.c6rrect, i => {
                            return (
                              <React.Fragment key={i}>
                                <img src={Images.C6RRECT} alt="" />
                              </React.Fragment>
                            );
                          })
                        )}
                    </div>
                  </div>
                )}

              {item.skill_bagde && (
                <div className="user-earned">
                  <div className="label">
                    <span>Skill Gauge - </span>
                    {this.SkillBadgesTitle(item.skill_bagde || 0)}
                  </div>
                  <div className="data">
                    <Rating
                      start={0}
                      stop={100}
                      step={20}
                      initialRating={this.SkillBadgesStar(
                        item.skill_bagde || 0
                      )}
                      emptySymbol="icon-star"
                      fullSymbol="icon-star highlighted"
                      fractions={3}
                      readonly
                    />
                  </div>
                </div>
              )}

              <div className="user-earned">
                <div className="label">
                  FORM <span>(Last 6 games)</span>
                </div>
                <div className="data last-match-status">
                  {item.form && item.form.length > 6 ? (
                    <React.Fragment>
                      {_.map(item.form, (obj_item, indx) => {

                        // console.log(obj_item, indx, item.form[indx - 1])
                        return indx === 0 ? (
                          ""
                        ) : (
                            <img
                              key={indx}
                              src={
                                parseInt(obj_item) < parseInt(item.form[indx - 1])
                                  ? Images.TEAM_WIN
                                  : parseInt(obj_item) > parseInt(item.form[indx - 1])
                                    ? Images.TEAM_LOSE
                                    : Images.TEAM_TIE
                              }
                              alt=""
                            />
                          );
                      })}
                    </React.Fragment>
                  ) : item.form && item.form.length <= 6 ? (
                    <React.Fragment>
                      {_.map(item.form, (obj_item, indx) => {
                        // console.log(obj_item,  indx, item.form[indx - 1])
                        return indx === 0 ? (
                          <img key={indx} src={Images.TEAM_TIE} alt="" />
                        ) : (
                            <img
                              key={indx}
                              src={
                                parseInt(obj_item) < parseInt(item.form[indx - 1])
                                  ? Images.TEAM_WIN
                                  : parseInt(obj_item) >
                                    parseInt(item.form[indx - 1])
                                    ? Images.TEAM_LOSE
                                    : Images.TEAM_TIE
                              }
                              alt=""
                            />
                          );
                      })}
                    </React.Fragment>
                  ) : (
                        "--"
                      )}
                </div>
              </div>
              <div className="user-earned">
                <div className="label">Performance</div>
                <div className="data">
                  <a
                    
                    className={
                      this.state.selectedPerformace.user_id == item.user_id &&
                        this.state.selectedPerformace.eventKey == "Accuracy"
                        ? "active-perform"
                        : ""
                    }
                    onClick={() => {
                      this.setState({
                        selectedPerformace: {
                          user_id: item.user_id,
                          eventKey: "Accuracy"
                        }
                      });
                    }}
                  >
                    accuracy
                  </a>
                  <a
                    href
                    className={
                      this.state.selectedPerformace.user_id == item.user_id &&
                        this.state.selectedPerformace.eventKey == "Chart"
                        ? "active-perform"
                        : ""
                    }
                    onClick={() => {
                      this.setState({
                        selectedPerformace: {
                          user_id: item.user_id,
                          eventKey: "Chart"
                        }
                      });
                    }}
                  >
                    Chart
                  </a>
                  <a
                    href
                    className={
                      this.state.selectedPerformace.user_id == item.user_id &&
                        this.state.selectedPerformace.eventKey == "RankGraph"
                        ? "active-perform"
                        : ""
                    }
                    onClick={() => {
                      this.setState({
                        selectedPerformace: {
                          user_id: item.user_id,
                          eventKey: "RankGraph"
                        }
                      });
                    }}
                  >
                    Rank Graph
                  </a>
                </div>
              </div>

              {this.state.selectedPerformace.user_id == item.user_id && (
                <PerformanceComponent
                  history={this.props.history}
                  key={item.user_id}
                  selectedTab={1}
                  other_user_id={this.state.selectedPerformace.user_id}
                  eventKey={this.state.selectedPerformace.eventKey}
                  showTabs={false}

                  selectedCategory={selectedCategory}
                  selectedLeague={selectedLeague}
                  selectedContest={selectedContest}
                  selectedMonth={selectedMonth}
                  selectedRound={selectedRound}
                  selectedContestType={selectedContestType}
                  selectedFollowOption={selectedFollowOption}
                  selectedMainTab={selectedMainTab}
                />
              )}
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}
