import React from "react";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import { notify } from "react-notify-toast";
import { Link, NavLink } from "react-router-dom";
import { MyContext } from "../context";
import { Images, NavigationBar } from "../components";
import {
  Login, Signup, ForgotPassword, CompleteProfile, ScoringRules, Thankyou, PredictionConfirmation, MembershipModal, PaymentConfirmModal, CongratsPopup,
  ShareLinkModal, SelectFilterModal, ConfirmationModal, SubmitModal, AlertModal, ProfileView, ContestShareModal, ComingSoonModal
} from "../Modals";
import _ from "lodash";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import WSManager from "../helper/WSManager";
import Main from "../Main";
import Config from '../Config';
import { encode } from "base-64";


var globalthis = null;

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DrawerMenuOpen: false,
      profile: WSManager.getProfile(),
      isModalFrom: "",
      user_type:
        this.props.match && this.props.match.params
          ? this.props.match.params.user_type || ""
          : "",
      showLoginModal: false,
      showSignupModal: false,
      showCompleteProfile: false,
      showForgotPassword: false,
      showThankyouModal: false,
      showScoringModal: false,
      showPredictionCheckModal: false,
      showMembershipModal: false,
      showPaymentConfirmModal: false,
      showCongratsModal: false,
      showShareLinkModal: false,
      showFilterModal: false,
      showConfirmationModal: false,
      showSubmitModal: false,
      showAlertModal: false,
      profileModalShow: false,
      predConfrmData: '',
      membershipID: "",
      membershipAmount: "",
      countineFremium: "",
      countineType: "",
      countineFremiumData: "",
      contestEntryFee: 0,
      contestName: "",
      Congratsmsg: "",
      filterData: {},
      filterAction: "",
      showShadow: false,
      selectedDraweroptions: 0,
      AlertData: "",
      ProfileViewData: '',
      ActBoxOpen: false,
      showContestShareModal: false,
      ContestShareCode: "",
      ContestShareTitle: "",
      ContestShareLink: "",

      showComingSoonModal: false,
      comingSoonModule: ""
    };
    this.scrollElement = React.createRef();
  }

  componentDidMount = () => {

    if (this.state.user_type == "pundit") {
      if (WSManager.loggedIn()) {
        notify.show(Constants.Messages.logout_alert, "error", 2500);
        this.props.history.push("/dashboard/0");
      } else {
        Header.SignupModalShow("");
      }
    }
    if (WSManager.loggedIn()) {
      this.getPermissions()
      Header.callGetMyProfileApi()
      if (this.props.HeaderOption.Notification) {
        this.getUserNotificationsCount();
      }
    }
  };


  getPermissions = () => {
    WSManager.Rest(NC.baseURL + NC.GET_USER_PERMISSIONS, {}).then(
      res => {
        if (res.response_code === NC.successCode) {
          WSManager.setUserPermissions(res.data.features)
        }
      }
    )
  }

  getUserNotificationsCount() {
    let param = {};
    WSManager.Rest(NC.baseURL + NC.GET_UNREAD_NOTIFICATION, param).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          if (typeof responseJson.data != "undefined") {
            WSManager.setNotificationCount(responseJson.data.unread_count);
          }
        }
      }
    );
  }

  BuyPlan(planid, ActionType) {
    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.PURCHASE_MEMBERSHIP, {
      plan_id: planid
    }).then(responseJson => {
      this.setState({
        isLoading: false
      });
      if (responseJson.response_code === NC.successCode) {
        Constants.SetUserBalance.callBalanceApi().then(isSuccess => { });
        let profile = WSManager.getProfile();
        profile["plan_id"] = responseJson.data.plan_id;
        profile["plan_round_id"] = responseJson.data.plan_round_id;
        WSManager.setProfile(profile);
        this.props.history.push(
          "/dashboard/" + (ActionType == "contest" ? "1" : "0")
        );
        let msg = `You are now a ${responseJson.data.plan_id == 2 || responseJson.data.parent_plan_id == 2 ? "Premium" : "Premium+"
          } member`;
        Header.CongratsModalShow(msg);
      }
    });
  }

  callLogoutApi() {
    let param = {
      user_id: this.state.profile.user_id
    };
    WSManager.Rest(NC.baseURL + NC.GET_LOGOUT, param).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
      }
    });
  }

  UserLogout = () => {

    WSManager.Rest(NC.baseURL + NC.GET_LOGOUT, { session_key: WSManager.getToken() }).then(res => {
      if (res.response_code === NC.successCode) {
        this.setState({ profile: "" });
        WSManager.logout();
        notify.show(Constants.Messages.logout_success, "success", 2500);
        setTimeout(() => {
          WSManager.goToAppUrl("refpredictor", "logout=true");
        }, 500);
      }
    })

  };

  static logoutAlertShow() {
    Header.showAlertModal({
      message: "Are you sure you want to logout?",
      ModalAction: globalthis.UserLogout.bind(this)
    }
    );
  }

  openEditProfile() {
    this.props.history.push({ pathname: "/edit-profile" });
  }

  goToNotification() {
    // this.props.history.push({ pathname: "/notification" });
    // WSManager.goToAppUrl('refsocial', 'topage=notification', false)
    WSManager.goToAppUrl('refsocial', `notification?id=${encode(this.state.profile.user_id)}`, true)
  }

  goToMessage = () => {
    this.props.history.push({ pathname: "/message" })
  }

  goTo = (path) => {
    this.props.history.push({ pathname: path })
  }


  showFilter = () => {
    this.props.showFilter();
  };

  DrawerMenuTrigger = DrawerMenuOpen => {
    this.setState({
      DrawerMenuOpen: !DrawerMenuOpen,
      ActBoxOpen: false
    });
  };

  OpenSubMenu = ShowSubMenuFor => {
    if (ShowSubMenuFor === "dashboard") {
      this.setState({
        selectedDraweroptions: 0
      });
    }
    else if (ShowSubMenuFor === "membership") {
      this.setState({
        selectedDraweroptions: 1
      });
    } else if (ShowSubMenuFor === "tournament") {
      this.setState({
        selectedDraweroptions: 2
      });
    } else if (ShowSubMenuFor === "prize") {
      this.setState({
        selectedDraweroptions: 3
      });
    } else if (ShowSubMenuFor === "socialhub") {
      this.setState({
        selectedDraweroptions: 4
      });
    } else {
      this.setState({
        selectedDraweroptions: 0
      });
    }
  };

  static callGetMyProfileApi() {
    WSManager.Rest(NC.baseURL + NC.GET_MY_PROFILE).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        WSManager.setProfile(responseJson.data.user_profile);
        WSManager.setProfileCompleted(
          responseJson.data.user_profile.is_profile_complete
        );
        if (responseJson.data.user_profile.is_profile_complete == 0) {
          Header.CompleteProfileShow();
        }
        if (globalthis != null) {
          globalthis.setState({ profile: responseJson.data.user_profile })
        }
      }
    });
  }

  static updateHeaderShadow(showShadow) {
    globalthis.setState({
      showShadow: showShadow
    });
  }

  static LoginModalShow = isModalFrom1 => {
    globalthis.setState({
      showLoginModal: true,
      isModalFrom: isModalFrom1
    });
  };
  LoginModalHide = () => {
    this.setState({
      showLoginModal: false
    });
  };

  static SignupModalShow = isModalFrom => {
    globalthis.setState({
      showSignupModal: true,
      isModalFrom: isModalFrom
    });
  };

  SignupModalHide = () => {
    this.setState({
      showSignupModal: false
    });
  };

  static CompleteProfileShow = () => {
    globalthis.setState({
      showCompleteProfile: true
    });
  };

  CompleteProfileHide = () => {
    this.setState({
      showCompleteProfile: false
    });
  };

  static showScoringModal = () => {
    globalthis.setState({
      showScoringModal: true
    });
  };

  ScoringModalHide = () => {
    this.setState({
      showScoringModal: false
    });
  };

  static showPredictionCheckModal = (
    predConfrmData
  ) => {
    globalthis.setState({
      showPredictionCheckModal: true,
      predConfrmData: predConfrmData
    });
  };

  PredictionCheckModalHide = () => {
    this.setState({
      showPredictionCheckModal: false
    });
  };

  static showMembershipModal = (data, type, action, EntryFee) => {
    globalthis.setState({
      showMembershipModal: true,
      countineFremium: action,
      countineType: type,
      countineFremiumData: data,
      contestEntryFee: EntryFee
    });
  };

  MembershipModalHide = () => {
    this.setState({
      showMembershipModal: false
    });
  };

  static showPaymentConfirmModal = (plan_id, planAmount, type) => {
    globalthis.setState({
      showPaymentConfirmModal: true,
      membershipID: plan_id,
      membershipAmount: planAmount,
      countineType: type
    });
  };

  PaymentConfirmModalHide = () => {
    this.setState({
      showPaymentConfirmModal: false
    });
  };

  static ForgotPasswordShow = isModalFrom => {
    globalthis.setState({
      showForgotPassword: true,
      isModalFrom: isModalFrom
    });
  };

  ForgotPasswordHide = () => {
    this.setState({
      showForgotPassword: false
    });
  };

  static showThankyouModal = () => {
    globalthis.setState({
      showThankyouModal: true
    });
  };

  ThankyouModalHide = () => {
    this.setState({
      showThankyouModal: false
    });
  };

  static showShareLinkModal = () => {
    globalthis.setState({
      showShareLinkModal: true
    });
  };

  ShareLinkModalHide = () => {
    this.setState({
      showShareLinkModal: false
    });
  };

  static showAlertModal = (AlertData) => {
    globalthis.setState({
      showAlertModal: true,
      AlertData: AlertData
    });
  };

  AlertModalHide = () => {
    this.setState({
      showAlertModal: false
    });
  };

  static showConfirmationModal = (
    data,
    type,
    action,
    EntryFee,
    contestName
  ) => {
    globalthis.setState({
      showConfirmationModal: true,
      countineFremium: action,
      countineType: type,
      countineFremiumData: data,
      contestEntryFee: EntryFee,
      contestName: contestName
    });
  };

  ConfirmationModalHide = () => {
    this.setState({
      showConfirmationModal: false
    });
  };

  static showSubmitModal = (msg, type) => {
    globalthis.setState({
      showSubmitModal: true,
      Congratsmsg: msg,
      countineType: type
    });
  };

  SubmitModalHide = () => {
    this.setState({
      showSubmitModal: false
    });
  };

  static CongratsModalShow = msg => {
    globalthis.setState({
      showCongratsModal: true,
      Congratsmsg: msg
    });
  };
  CongratsModalHide = () => {
    this.setState({
      showCongratsModal: false
    });
  };

  static FilterModalShow = (filterData, filterAction) => {
    globalthis.setState({
      showFilterModal: true,
      filterData: filterData,
      filterAction: filterAction
    });
  };

  FilterModalHide = () => {
    this.setState({
      showFilterModal: false
    });
  };
  static ShowProfileModal = (modalData) => {
    globalthis.setState({
      profileModalShow: true,
      ProfileViewData: modalData
    });
  };


  goToMembership = (plan_id, idx) => {
    this.props.history.push({ pathname: '/membership-plan', state: { idx, plan_id } })
  }

  renderDrawer() {
    const { DrawerMenuOpen } = this.state;
    const drawerProps = {
      right: "false",
      open: DrawerMenuOpen
    };


    const user_permissions = WSManager.getUserPermissions();

    const profile = WSManager.getProfile();

    return (
      <div className="d-block">
        <React.Fragment>
          <div
            className={
              "drawer-menu-wrapper" +
              (DrawerMenuOpen ? " drawer-menu-wrapper-open" : "")
            }
            {...drawerProps}
            onChange={open => this.setState({ DrawerMenuOpen: open })}
          >

            <div className="inner-drawer-menu-wrapper">
              <div
                className={
                  "drawer-header-wrap" +
                  (this.state.scrollTop > 0 ? " header-shadow" : "")
                }
              >
                <i
                  className="icon-close drawer-close"
                  onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}
                />
                {/* <img
                  onClick={() => {
                    this.scrollElement.current.scrollTop > 0
                      ? this.scrollElement.current.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                      })
                      : WSManager.loggedIn()
                        ? this.props.history.push("/dashboard/0")
                        : window.open(NC.pageURL, "_blank");
                  }}
                  style={{ cursor: "pointer" }}
                  src={Images.LOGO_NEW}
                  alt=""
                /> */}

              </div>
              <div
                ref={this.scrollElement}
                className="drawer-scrollarea"
                onScroll={() =>
                  this.setState({
                    scrollTop: this.scrollElement.current.scrollTop
                  })
                }
              >

                <div className={'drawer-prof-content2'}>

                  <div className={'prf-cont'}>
                    <div className={'l-p'}>
                      <div className={'prof-img'} onClick={() => WSManager.goToAppUrl('refsocial', `user-profile?id=${encode(this.state.profile.user_id)}`, true)}>
                        <img
                          src={profile.image != null && profile.image !== ""
                            ? profile.image
                            : Images.DEFAULT_USER
                          }
                          alt=""
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    </div>

                    {
                      WSManager.loggedIn() &&
                      <div className={'r-p d-none'}>
                        <div className={'add-acc-box'}>
                          <div className={'prof-thumb'}>
                            <img
                              src={profile.image != null && profile.image !== ""
                                ? profile.image
                                : Images.DEFAULT_USER
                              }
                              alt=""
                            />
                          </div>
                          <div className={'prof-thumb'}>
                            <img
                              src={profile.image != null && profile.image !== ""
                                ? profile.image
                                : Images.DEFAULT_USER
                              }
                              alt=""
                            />
                          </div>
                          <div className={'add-prof'}>
                            <i className={'icon icon-plus'}></i>
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  {
                    WSManager.loggedIn() &&
                    <React.Fragment>
                      <h4>{profile.display_name}</h4>
                      <div className={'usr-nm'}>@{profile.user_name}</div>
                      <div className={'fp-foot'}>
                        <div className={'ref-id'}>R- {profile.ref_id}</div>
                        <div className={'add-bal'}>
                          <span onClick={() => this.goToApp('refpay')}><i className={'icon-Rits'}></i> {profile.balance || '00'}</span> &nbsp;
                          <i
                            className={'icon-plus add-ic'}
                            onClick={() => WSManager.goToAppUrl('refpay', `authenticate?token=${WSManager.getToken()}&topage=add-funds`)}
                          ></i>
                        </div>
                      </div>

                    </React.Fragment>
                  }




                  <div className={'r-p'}></div>
                </div>


                <div className="drawer-profile-content d-none">
                  <div
                    onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}
                    className="profile-thumbnail"
                  >
                    <NavLink exact to="/profile">
                      <img
                        src={
                          profile.image != null && profile.image !== ""
                            ? profile.image
                            : Images.DEFAULT_USER
                        }
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    </NavLink>
                  </div>
                  {WSManager.loggedIn() && (
                    <div className="">
                      <span className="user-full-name ">
                        {profile.user_name || "--"}
                      </span>
                      <div className={'ref-id'}>
                        {profile.ref_id ? `R-${profile.ref_id}` : "--"}
                      </div>

                      <div className="user-acc-bal">
                        {" "}
                        {profile.currency}
                        {parseFloat(
                          Constants.userBalanceInfo.user_balance
                            ? Constants.userBalanceInfo.user_balance
                              .real_amount +
                            Constants.userBalanceInfo.user_balance
                              .winning_amount
                            : profile.balance || 0
                        ).toFixed(2)}
                        <div
                          className="dr_add_fund_btn m-0"
                          onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}
                        >
                          <a onClick={() => this.goToApp('refpay')}>
                            <i className="icon-add-circular" />
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="drawer-list-wrapper">
                  <ul className="drawer-menu-list drawer-heading-list">
                    <li className="drawer-menu-item">
                      <a
                        className={
                          this.state.selectedDraweroptions == 0 ? " active" : ""
                        }
                        // href="true"
                        onClick={() => this.OpenSubMenu("dashboard")}
                      >
                        Menu <i className="icon-arrow-right" />
                      </a>
                    </li>
                    <li className="drawer-menu-item">
                      <a
                        className={
                          this.state.selectedDraweroptions == 1 ? " active" : ""
                        }
                        // href
                        onClick={() => this.OpenSubMenu("membership")}
                      >
                        Membership <i className="icon-arrow-right" />
                      </a>
                    </li>
                    <li className="drawer-menu-item">
                      <a
                        className={
                          this.state.selectedDraweroptions == 2 ? " active" : ""
                        }
                        // href
                        onClick={() => this.OpenSubMenu("tournament")}
                      >
                        Tournaments <i className="icon-arrow-right" />
                      </a>
                    </li>

                    {/* <li className="drawer-menu-item">
                      <a
                        className={
                          this.state.selectedDraweroptions == 3 ? " active" : ""
                        }
                        // href
                        onClick={() => this.OpenSubMenu("prize")}
                      >
                        Prizes
                        <i className="icon-arrow-right" />
                      </a>
                    </li>
                    <li className="drawer-menu-item">
                      <a
                        className={
                          this.state.selectedDraweroptions == 4 ? " active" : ""
                        }
                        // href
                        onClick={() => this.OpenSubMenu("socialhub")}
                      >
                        Social Hub <i className="icon-arrow-right" />
                      </a>
                    </li> */}
                    {/* <li className="drawer-menu-item">
                      <a
                        className={
                          this.state.selectedDraweroptions == 4 ? " active" : ""
                        }
                        href
                        onClick={() => this.OpenSubMenu("dashboard")}
                      >
                       Menu <i className="icon-arrow-right" />
                      </a>
                    </li> */}
                  </ul>

                  <ul className="drawer-menu-list drawer-submenu-list">
                    {this.state.selectedDraweroptions == 0 && (
                      <React.Fragment>
                        {WSManager.loggedIn() &&
                          _.map(Constants.Drawer_options, (item, idx) => {

                            if (
                              // this.state.profile.plan_id != 3 && 
                              user_permissions.create_league != '1' &&
                              item.title == "Create Contest"
                            ) {
                              return
                            } else {
                              return (
                                <li
                                  key={idx}
                                  className="drawer-menu-item"
                                  onClick={() =>
                                    this.DrawerMenuTrigger(DrawerMenuOpen)
                                  }
                                >
                                  {
                                    item.title == 'My Profile'
                                    ?
                                      <a onClick = {() => WSManager.goToAppUrl('refsocial', 'user-profile?id=' + encode(profile.user_id), true)} target="#">
                                        <i className={item.icon} />
                                        {item.title}
                                      </a>
                                    :
    
                                      <NavLink exact to={ (item.title == 'My Profile' ? Config.RefsocialUrl + 'user-profile?id=' + encode(profile.user_id) : item.link)}>
                                        <i className={item.icon} />
                                        {item.title != "Notifications" && item.title}
                                        {item.title == "Notifications" && (
                                          <React.Fragment>
                                            <div style={{ display: "flex" }}>
                                              Notifications
                                            {WSManager.getNotificationCount() &&
                                                WSManager.getNotificationCount() >
                                                0 && (
                                                  <div
                                                    style={{
                                                      fontSize: 9,
                                                      fontFamily: "MuliBold",
                                                      textAlign: "center",
                                                      marginLeft: 8,
                                                      width: 20,
                                                      height: 20,
                                                      marginTop: -2,
                                                      paddingTop: 5,
                                                      borderRadius: "100%",
                                                      backgroundColor: "red",
                                                      color: "#fff"
                                                    }}
                                                  >
                                                    {WSManager.getNotificationCount() >
                                                      99
                                                      ? "99+"
                                                      : WSManager.getNotificationCount()}
                                                  </div>
                                                )}
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </NavLink>
                                    

                                  }
                                </li>
                              );
                            }
                          })}

                        <li
                          className="drawer-menu-item"
                          onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}
                        >
                          {/* {WSManager.loggedIn() && (
                            <a
                              // href
                              className="xlogout-btn"
                              onClick={this.logoutAlertShow}
                            >
                              <i className="ic icon-logout" />
                              Logout
                            </a>
                          )} */}
                          {!WSManager.loggedIn() && (
                            <div className="text-center p-20">
                              <Button
                                className="btn btn-primary m-t-20 p-h-lg"
                                onClick={() => WSManager.goToAppUrl('refpredictor')}
                              >
                                Login
                              </Button>
                            </div>
                          )}
                        </li>
                      </React.Fragment>
                    )}

                    {/* {console.log(Constants.MembershipSubOptions)} */}
                    {this.state.selectedDraweroptions == 1 && (
                      <React.Fragment>
                        {_.map(Constants.MembershipSubOptions, (item, idx) => {
                          return (
                            <li
                              key={idx}
                              className="drawer-menu-item drawer-menu-without-icon"
                              onClick={() =>
                                this.DrawerMenuTrigger(DrawerMenuOpen)
                              }
                            >
                              <a onClick={() => this.goToMembership(item.master_plan_id, idx)} target="__blank">
                                {item.plan_name}
                              </a>
                            </li>
                          );
                        })}
                      </React.Fragment>
                    )}
                    {this.state.selectedDraweroptions == 2 && (
                      <React.Fragment>
                        {_.map(Constants.TournamentSubOptions, (item, idx) => {
                          return (
                            <li
                              key={idx}
                              className="drawer-menu-item drawer-menu-without-icon"
                              onClick={() =>
                                this.DrawerMenuTrigger(DrawerMenuOpen)
                              }
                            >
                              <a href={item.href} target="__blank">
                                {item.title}
                              </a>
                            </li>
                          );
                        })}
                      </React.Fragment>
                    )}
                    {this.state.selectedDraweroptions == 3 && (
                      <React.Fragment>
                        {_.map(Constants.PrizesSubOptions, (item, idx) => {
                          return (
                            <li
                              key={idx}
                              className="drawer-menu-item drawer-menu-without-icon"
                              onClick={() =>
                                this.DrawerMenuTrigger(DrawerMenuOpen)
                              }
                            >
                              <a href={item.href} target="__blank">
                                {item.title}
                              </a>
                            </li>
                          );
                        })}
                      </React.Fragment>
                    )}
                    {this.state.selectedDraweroptions == 4 && (
                      <React.Fragment>
                        {_.map(Constants.SocialHubSubOptions, (item, idx) => {
                          return (
                            <li
                              key={idx}
                              className="drawer-menu-item drawer-menu-without-icon"
                              onClick={() =>
                                this.DrawerMenuTrigger(DrawerMenuOpen)
                              }
                            >
                              <a href={item.href} target="__blank">
                                {item.title}
                              </a>
                            </li>
                          );
                        })}
                      </React.Fragment>
                    )}
                    {/* {this.state.selectedDraweroptions == 1 && (
                      <React.Fragment>
                        {WSManager.loggedIn() &&
                          _.map(Constants.Drawer_options, (item, idx) => {
                            if (this.state.profile.plan_id != 3 && item.title == "Create Contest") {
                              return
                            } else {
                              return (
                                <li
                                  key={idx}
                                  className="drawer-menu-item"
                                  onClick={() =>
                                    this.DrawerMenuTrigger(DrawerMenuOpen)
                                  }
                                >
                                  <NavLink exact to={item.link}>
                                    <i className={item.icon} />
                                    {item.title != "Notifications" && item.title}
                                    {item.title == "Notifications" && (
                                      <React.Fragment>
                                        <div style={{ display: "flex" }}>
                                          Notifications
                                        {WSManager.getNotificationCount() &&
                                            WSManager.getNotificationCount() >
                                            0 && (
                                              <div
                                                style={{
                                                  fontSize: 9,
                                                  fontFamily: "MuliBold",
                                                  textAlign: "center",
                                                  marginLeft: 8,
                                                  width: 20,
                                                  height: 20,
                                                  marginTop: -2,
                                                  paddingTop: 5,
                                                  borderRadius: "100%",
                                                  backgroundColor: "red",
                                                  color: "#fff"
                                                }}
                                              >
                                                {WSManager.getNotificationCount() >
                                                  99
                                                  ? "99+"
                                                  : WSManager.getNotificationCount()}
                                              </div>
                                            )}
                                        </div>
                                      </React.Fragment>
                                    )}
                                  </NavLink>
                                </li>
                              );
                            }
                          })}

                        <li
                          className="drawer-menu-item"
                          onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}
                        >
                          {WSManager.loggedIn() && (
                            <a
                              href
                              className="xlogout-btn"
                              onClick={this.logoutAlertShow}
                            >
                              <i className="ic icon-logout" />
                              Logout
                            </a>
                          )}
                          {!WSManager.loggedIn() && (
                            <div className="text-center p-20">
                              <Button
                                className="btn btn-primary m-t-20 p-h-lg"
                                onClick={() => Header.LoginModalShow()}
                              >
                                Login
                              </Button>
                            </div>
                          )}
                        </li>
                      </React.Fragment>
                    )} */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <span
            className={
              "drawer-menu-overlay" +
              (this.state.DrawerMenuOpen ? " drawer-menu-overlay-open" : "")
            }
            onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}
          />
        </React.Fragment>
      </div>
    );
  }

  renderActBox = () => {
    let { ActBoxOpen } = this.state;
    return <div className={`act-box-wrap ${ActBoxOpen ? 'open' : ''}`}>
      <div className={'overlay'} onClick={() => this.ActBoxTrigger(ActBoxOpen)}></div>
      <div className={'act-box'}></div>
    </div>
  }

  ActBoxTrigger = ActBoxOpen => {
    this.setState({
      ActBoxOpen: !ActBoxOpen
    });
  };




  getRefLeagueActive = () => {

    let refLeagueUrls = ["/dashboard/:tab_id", "/profile", "/leaderboard", '/create-contest', "/my-contest", "/private-contest"];
    let refPAyUrls = ["/refpay", "/add-funds", "/withdraw"];
    let refLiveUrls = ["/my-games/:tab_id"];

    let param = this.props.match.path;
    if (param && !refPAyUrls.includes(param) && !refLiveUrls.includes(param)) {
      return true;
    }
    return false;
  }


  goToApp = (key) => {
    // For live 
    // if (!Config.isLOCAL) {
    //   if (key == "refsocial" || key == "refid") {
    //     Header.showComingSoonModal(key);
    //     return;
    //   }
    // }

    if (WSManager.loggedIn()) {
      WSManager.goToAppUrl(key);
    } else {
      window.location.href = Config.RefpredictoUrl
    }
  }


  static ShareModalShow = (code, title, link = "") => {
    globalthis.setState({
      showContestShareModal: true,
      ContestShareCode: code,
      ContestShareTitle: title,
      ContestShareLink: link
    })
  }

  onShareModalHide = () => {
    this.setState({
      showContestShareModal: false,
      ContestShareCode: "",
      ContestShareTitle: "",
      ContestShareLink: ""
    })
  }


  static showComingSoonModal(key) {
    globalthis.setState({
      showComingSoonModal: true,
      comingSoonModule: key
    });
  }


  render() {
    globalthis = this;
    const {
      DrawerMenuOpen,
      showThankyouModal,
      showLoginModal,
      showSignupModal,
      showCompleteProfile,
      showForgotPassword,
      isModalFrom,
      showScoringModal,
      showPredictionCheckModal,
      showMembershipModal,
      showPaymentConfirmModal,
      showCongratsModal,
      showShareLinkModal,
      showFilterModal,
      showSubmitModal,
      showConfirmationModal,
      showShadow,
      showAlertModal,
      ProfileViewData,
      profileModalShow,
      ActBoxOpen,
      showContestShareModal,
      ContestShareCode,
      ContestShareTitle,
      ContestShareLink,
      showComingSoonModal,
      comingSoonModule
      // profile
    } = this.state;

    const { HeaderOption } = this.props;

    const profile = WSManager.getProfile();



    const ComingSoonModalProps = {
      show: showComingSoonModal,
      onHide: () => this.setState({ showComingSoonModal: false }),
      module: comingSoonModule
    }

    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <div
              className={
                "header-wrap" +
                (DrawerMenuOpen ? " xp-0" : "") +
                (showShadow ? " header-shadow" : "")
              }
            >
              {this.renderDrawer()}

              <Row className="xhidden-lg ">
                <Col xs={4}>

                  <div className={'left-cl'}>

                    {HeaderOption.menu && (
                      <span
                        className={'toggle-ic'}
                        onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}
                      >
                        <img
                          src={
                            profile.image != null && profile.image !== ""
                              ? profile.image
                              : Images.DEFAULT_USER
                          }
                          alt=""
                          style={{ objectFit: "cover" }}
                        />
                      </span>
                    )}

                    {HeaderOption.menu && (
                      <React.Fragment>
                        <span className={'act-toggle'} onClick={() => this.ActBoxTrigger(ActBoxOpen)}>
                          <i className="icon-activity" />
                        </span>
                        <div className={`act-box-wrap ${ActBoxOpen ? 'open' : ''}`}>
                          <div className={'overlay'} onClick={() => this.ActBoxTrigger(ActBoxOpen)}></div>
                          <div className={'act-box'}>
                            <Row className={'men-lnk-wrp'}>

                              <Col xs={6}>
                                <a onClick={() => this.goToApp('refsocial')}>
                                  <div className={'men-lnk'} >
                                    <img src={Images.REFSOCIAL} />
                                    <span>ReFSocial</span>
                                  </div>
                                </a>
                              </Col>
                              <Col xs={6}>
                                <Link className={this.getRefLeagueActive() ? 'active' : ''} to={'/dashboard/0'}>
                                  <div className={'men-lnk'} >
                                    <img src={Images.REFPREDICTOR} />
                                    <span>ReFLeague</span>
                                  </div>
                                </Link>

                              </Col>

                              <Col xs={6}>
                                <NavLink to={'/my-games/0'}>
                                  <div className={'men-lnk'} >
                                    <img src={Images.REFLIVE} />
                                    <span>ReFLive</span>
                                  </div>
                                </NavLink>
                              </Col>

                              <Col xs={6}>
                                <a onClick={() => this.goToApp('refpay')}>
                                  <div className={'men-lnk'} >
                                    <img src={Images.REFPAY} />
                                    <span>ReFPurse</span>
                                  </div>
                                </a>
                              </Col>

                            </Row>
                          </div>
                        </div>
                      </React.Fragment>

                    )}




                    {/* {HeaderOption.menu && (
                    <a
                      href
                      // onClick={() => this.DrawerMenuTrigger(DrawerMenuOpen)}
                      className="header-action"
                    >
                      <i className="icon-nav-ic" />
                    </a>
                  )} */}
                    {HeaderOption.back && (
                      <React.Fragment>
                        {WSManager.loggedIn() === true && (
                          <a

                            className="header-action"
                            onClick={() => !HeaderOption.backLink? this.props.history.goBack(): this.props.history.push(HeaderOption.backLink)}
                          >
                            <i className="icon-left-arrow" />
                          </a>
                        )}
                      </React.Fragment>
                    )}




                  </div>
                </Col>
                <Col xs={4} className="text-center">
                  {HeaderOption.Logo && (
                    <a
                    href={Config.RefpredictoUrl}
                      className="header-action brand-logo">
                      {/* <i
                        onClick={() =>
                          Constants.WindowScrollY > 0
                            ? Main.scrollToTop()
                            : WSManager.loggedIn()
                              ? this.props.history.push("/dashboard/0")
                              : window.open(NC.pageURL, "_blank")
                        }
                        className={'icon-logo-new'}></i> */}
                      <img
                        // onClick={() =>
                        //   Constants.WindowScrollY > 0
                        //     ? Main.scrollToTop()
                        //     : WSManager.loggedIn()
                        //       ? this.props.history.push("/dashboard/0")
                        //       : window.open(NC.pageURL, "_blank")
                        // }
                        src={Images.LOGO_NEW}
                        alt=""
                      />
                    </a>
                  )}
                </Col>
                <Col xs={4}>
                  {HeaderOption.filter && (
                    <a
                      // href
                      className="header-action right"
                      onClick={() => this.showFilter()}
                    >
                      <i className="icon-filter" />
                    </a>
                  )}



                  {HeaderOption.Notification && (
                    <a
                      // href
                      className="header-action right header-action-inline-block"
                      onClick={() => this.goToNotification()}
                    >
                      <i className="icon-bell">
                        {WSManager.getNotificationCount() &&
                          WSManager.getNotificationCount() > 0 && (
                            <div className="notification-circle">
                              <span>
                                {WSManager.getNotificationCount() > 99
                                  ? "99+"
                                  : WSManager.getNotificationCount()}
                              </span>
                            </div>
                          )}
                      </i>
                    </a>
                  )}


                  {HeaderOption.setting && (
                    <a
                      // href
                      onClick={() => {
                        this.props.history.push("/profile-setting");
                      }}
                      className="header-action right"
                    >
                      <i className="icon-setting" />
                    </a>
                  )}

                  {HeaderOption.edit && (
                    <a
                      // href
                      onClick={() => this.openEditProfile()}
                      className="header-action right"
                    >
                      <i className="icon-edit-line" />
                    </a>
                  )}


                  {HeaderOption.Notification && (
                    <a
                      // href={Config.RefpredictoUrl}
                      className="header-action right header-action-inline-block"
                      // onClick={() => this.goToMessage()}

                    >
                      <i className="icon-inbox"></i>
                    </a>
                  )}
                </Col>
              </Row>

              {showLoginModal && (
                <Login
                  history={this.props.history}
                  isOpenFrom={isModalFrom}
                  IsLoginModalShow={showLoginModal}
                  IsLoginModalHide={this.LoginModalHide}
                />
              )}
              {showSignupModal && (
                <Signup
                  history={this.props.history}
                  isOpenFrom={isModalFrom}
                  user_type={this.state.user_type}
                  IsSignupModalShow={showSignupModal}
                  IsSignupModalHide={this.SignupModalHide}
                />
              )}
              {/* {showCompleteProfile && (
                <CompleteProfile
                  history={this.props.history}
                  IsCompleteProfileShow={showCompleteProfile}
                  IsCompleteProfileHide={this.CompleteProfileHide}
                />
              )} */}
              {showForgotPassword && (
                <ForgotPassword
                  history={this.props.history}
                  isOpenFrom={isModalFrom}
                  IsForgotPasswordShow={showForgotPassword}
                  IsForgotPasswordHide={this.ForgotPasswordHide}
                />
              )}
              {showThankyouModal && (
                <Thankyou
                  history={this.props.history}
                  IsThankyouModalShow={showThankyouModal}
                  IsThankyouModalHide={this.ThankyouModalHide}
                />
              )}
              {showScoringModal && (
                <ScoringRules
                  history={this.props.history}
                  IsScoringModalShow={showScoringModal}
                  IsScoringModalHide={this.ScoringModalHide}
                />
              )}
              {showPredictionCheckModal && (
                <PredictionConfirmation
                  history={this.props.history}
                  IsPredictionCheckShow={showPredictionCheckModal}
                  IsPredictionCheckHide={this.PredictionCheckModalHide}
                  predConfrmData={this.state.predConfrmData}
                />
              )}
              {showMembershipModal && (
                <MembershipModal
                  history={this.props.history}
                  IsMembershipModalShow={showMembershipModal}
                  IsMembershipModalHide={this.MembershipModalHide}
                  countineAction={this.state.countineFremium}
                  countineData={this.state.countineFremiumData}
                  countineType={this.state.countineType}
                  contestEntryFee={this.state.contestEntryFee}
                />
              )}
              {showPaymentConfirmModal && (
                <PaymentConfirmModal
                  history={this.props.history}
                  IsPaymentModalShow={showPaymentConfirmModal}
                  IsPaymentModalHide={this.PaymentConfirmModalHide}
                  confirmAction={this.BuyPlan.bind(this)}
                  countineType={this.state.countineType}
                  plan_id={this.state.membershipID}
                  amount={this.state.membershipAmount}
                />
              )}
              {showCongratsModal && (
                <CongratsPopup
                  history={this.props.history}
                  IsCongratsModalShow={showCongratsModal}
                  IsCongratsModalHide={this.CongratsModalHide}
                  message={this.state.Congratsmsg}
                />
              )}
              {showShareLinkModal && (
                <ShareLinkModal
                  history={this.props.history}
                  IsShareLinkModalShow={showShareLinkModal}
                  IsShareLinkModalHide={this.ShareLinkModalHide}
                  message={this.state.Congratsmsg}
                />
              )}
              {showFilterModal && (
                <SelectFilterModal
                  history={this.props.history}
                  IsFilterModalShow={showFilterModal}
                  IsFilterModalHide={this.FilterModalHide}
                  filterData={this.state.filterData}
                  filterAction={this.state.filterAction}
                />
              )}
              {showConfirmationModal && (
                <ConfirmationModal
                  history={this.props.history}
                  IsConfirmationModalShow={showConfirmationModal}
                  IsConfirmationModalHide={this.ConfirmationModalHide}
                  countineAction={this.state.countineFremium}
                  countineData={this.state.countineFremiumData}
                  countineType={this.state.countineType}
                  contestEntryFee={this.state.contestEntryFee}
                  contestName={this.state.contestName}
                />
              )}
              {showSubmitModal && (
                <SubmitModal
                  history={this.props.history}
                  IsSubmitModalShow={showSubmitModal}
                  IsSubmitModalHide={this.SubmitModalHide}
                  message={this.state.Congratsmsg}
                  countineType={this.state.countineType}
                />
              )}
              {showAlertModal && (
                <AlertModal
                  history={this.props.history}
                  IsAlertModalShow={showAlertModal}
                  IsAlertModalHide={this.AlertModalHide}
                  AlertData={this.state.AlertData}
                />
              )}
              {profileModalShow && (
                <ProfileView
                  history={this.props.history}
                  profileModalShow={profileModalShow}
                  profileModalHide={() => {
                    this.setState({ profileModalShow: false })
                  }}
                  ProfileViewData={ProfileViewData}
                />
              )}
            </div>

            {
              this.props.showNavigation && <NavigationBar {...this.props} />
            }


            <ContestShareModal
              isOpen={showContestShareModal}
              onHide={this.onShareModalHide}
              code={ContestShareCode}
              title={ContestShareTitle}
              link={ContestShareLink}
            />


            {showComingSoonModal && <ComingSoonModal {...ComingSoonModalProps} />}

          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

Header.defaultProps = {
  showNavigation: true
}