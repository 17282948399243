import React from "react";
import { Row, Col, Tab, Nav, NavItem, Modal } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Loader, Images, NoDataScreen, SubHeader } from "../components";
import ContestDetailModal from "../Modals/ContestDetail";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import MetaData from "../helper/MetaData";
import * as Constants from "../helper/Constants";
import CountdownTimer from "./CountDownTimer";
import UtilityFunctions from "../helper/UtilityFunctions";
import Moment from "react-moment";
import Slider from "react-slick";
import config from '../Config';

var globalThis = null;

const JoinedFixtureListData = ({ joinedFixtureData, myContext, tabFor }) => {
  return (
    <div className="card-view">
      <i className="icon-share" onClick={() => globalThis.shareContest(joinedFixtureData.fixture_unique_id)}></i>
      <div className="card-view-right">
        <h2>
          {joinedFixtureData.match_data.home_team}
          <span> v </span>
          {joinedFixtureData.match_data.away_team}
        </h2>
        <p className="mb-0">
          Referee:
          <span>
            {" "}
            {joinedFixtureData.referee_name} |{" "}
            {joinedFixtureData.match_data.venue_name}{" "}
          </span>
        </p>
        <p>Media: {joinedFixtureData.media_text}</p>
        {tabFor == Constants.CONTEST_UPCOMING && (
          <React.Fragment>
            <div className="match-date">
              <Moment
                date={UtilityFunctions.getUtcToLocal(
                  joinedFixtureData.scheduled_timestamp
                )}
                format="ddd, MMM DD"
              />
            </div>
            <div
              className={
                "match-time" +
                (UtilityFunctions.showCountDown(joinedFixtureData)
                  ? " danger"
                  : "")
              }
            >
              <i className="icon-clock" />
              {UtilityFunctions.showCountDown(joinedFixtureData) ? (
                <div className="countdown time-line">
                  <CountdownTimer
                    deadlineTimeStamp={
                      joinedFixtureData.game_starts_in_timestamp
                    }
                    currentDateTimeStamp={joinedFixtureData.current_timestamp}
                  />
                </div>
              ) : (
                  <span>
                    {" "}
                    <Moment
                      date={UtilityFunctions.getUtcToLocal(
                        joinedFixtureData.scheduled_timestamp
                      )}
                      format="hh:mm A"
                    />
                  </span>
                )}
            </div>
            <div className="game-closed">
              <span>Game Closes</span>
              {
                <Moment
                  date={UtilityFunctions.getUtcToLocal(
                    joinedFixtureData.game_starts_in_timestamp
                  )}
                  format="hh:mm A"
                />
              }
              <div className="text-right pull-right">
                {tabFor == Constants.CONTEST_UPCOMING && (
                  <a
                    href
                    className="play-btn"
                    onClick={() => globalThis.onEditClick(joinedFixtureData)}
                  >
                    Edit
                  </a>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
        {tabFor == Constants.CONTEST_COMPLETED && (
          <React.Fragment>
            <div className="match-date">You Scored</div>
            <div className="match-time">
              {joinedFixtureData.total_score}/
              {joinedFixtureData.total_contest_point}
              <div className="text-right pull-right">
                {tabFor == Constants.CONTEST_COMPLETED && (
                  <a
                    href
                    className="play-btn"
                    onClick={() =>
                      globalThis.onRefliveClick(joinedFixtureData, "completed")
                    }
                  >
                    Result
                  </a>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
        <img src={Images.VISSEL} alt="" className="vissel-img" />
        {tabFor == Constants.CONTEST_LIVE && (
          <div className="text-right pull-right">
            <a
              href
              className="play-btn"
              onClick={() =>
                globalThis.onRefliveClick(joinedFixtureData, "live")
              }
            >
              Reflive
            </a>
          </div>
        )}
      </div>
      <div className="card-view-left">
        <div className="team-section">
          <img src={joinedFixtureData.match_data.home_flag} alt="" />
          <div className="vs-section">
            <i className="icon-football" />
          </div>
        </div>
        <div className="team-section">
          <img src={joinedFixtureData.match_data.away_flag} alt="" />
        </div>
      </div>
    </div>
  );
};

export default class MyGames extends React.Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showContestDetail: false,
      showModal: false,
      joinedFixtureList: [],
      selectedTab:
        this.props.match && this.props.match.params
          ? this.props.match.params.tab_id || Constants.CONTEST_UPCOMING
          : Constants.CONTEST_UPCOMING,
      isLoading: false,
      categories_list: [],
      selectedType: Constants.GLOBAL_CATEGORY,
      selectedCategory: Constants.GLOBAL_CATEGORY,
      pageBanner: [],
      userProfile: WSManager.getProfile(),
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  componentDidMount() {
    globalThis = this;
  }

  componentWillMount() {
    let { selectedTab } = this.state;
    this.setCategories()
    this.JoinedFixtureList(selectedTab);
    this.getPageBanner()
  }


  getPageBanner = () => {

    let param = {
      page_type_id: Constants.BANNER_PAGE_TYPE_ID.myGames,
      position_id: "1"
    }
    WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }

  setCategories = () => {
    let cats = Constants.categories;
    this.setState({
      categories_list: cats,
      selectedCategory: cats.length > 0 ? cats[0].id : ''
    })
  }


  selectCategory = (itm) => {
    if (this.state.selectedCategory == itm.value) return;

    this.setState({
      selectedCategory: itm.value,
      selectedType: itm.id,
    }, this.JoinedFixtureList
    )
  }

  gotoDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard/0"
    });
  };

  onEditClick = item => {
    this.props.history.push({
      pathname: "/prediction/" + item.fixture_unique_id,
      state: { ContestData: item, isEdit: true }
    });
  };

  onRefliveClick = (item, isFrom) => {
    this.props.history.push({
      pathname: "/answer/" + item.fixture_unique_id,
      state: { ContestData: item, isFrom: isFrom }
    });
  };

  JoinedFixtureList = () => {

    let { selectedType, selectedCategory, selectedTab } = this.state;

    let param = {
      status: selectedTab,
      sports_id: NC.sportsId,
      type: selectedType
    };

    if (selectedType == '2') {
      param['country_id'] = selectedCategory;
    }
    if (selectedType == '3') {
      param['team_id'] = selectedCategory;
    }

    this.setState({
      isLoading: true
    });

    WSManager.Rest(NC.baseURL + NC.GET_FIXTURE_BY_STATUS, param).then(
      responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          this.setState({
            joinedFixtureList: responseJson.data
          });
        }
      }
    );
  };

  ContestDetailShow = () => {
    this.setState({
      showContestDetail: true
    });
  };

  ContestDetailHide = () => {
    this.setState({
      showContestDetail: false
    });
  };

  onTabClick = selectedTab => {
    // this.props.history.push({ pathname: "/my-games/" + parseInt(selectedTab) });
    this.setState(
      {
        selectedTab: selectedTab,
        selectedCategory: Constants.GLOBAL_CATEGORY,
        selectedType: Constants.GLOBAL_CATEGORY,
        joinedFixtureList: []
      },
      () => {
        this.JoinedFixtureList(selectedTab);
      }
    );
  };

  shareContest = (contest_unique_id) => {
    let title =
      `Your friend ${this.state.userProfile.display_name} has challenged you for the game. Join and win big by beating your friend, using the following link : ${config.MyUrl}/game/detail/${contest_unique_id}
    or
    Join through the game code ${contest_unique_id}

    Cheers,
    Team Refpredictor`;

    let gameLink = `${config.MyUrl}/game/detail/${contest_unique_id}`

    Header.ShareModalShow(contest_unique_id, title, gameLink);
  }


  render() {
    const {
      showContestDetail,
      activeTab,
      selectedTab,
      joinedFixtureList,
      isLoading,
      categories_list,
      selectedType,
      pageBanner
    } = this.state;

    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container  mygames-web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.MyGames.title}</title>
              <meta name="description" content={MetaData.MyGames.description} />
              <meta name="keywords" content={MetaData.MyGames.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            {isLoading && <Loader />}
            <div>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={parseInt(selectedTab)}
              >
                <Row>
                  <Col sm={12}>
                    <SubHeader Title="My Games" ImgShown={Images.HEADER_BG} BannerImage="" />
                    <Nav className="tab-navigation tab-navigation-upper">
                      <NavItem
                        onClick={() =>
                          this.onTabClick(Constants.CONTEST_UPCOMING)
                        }
                        eventKey={Constants.CONTEST_UPCOMING}
                      >
                        Upcoming
                        </NavItem>
                      <NavItem
                        onClick={() =>
                          this.onTabClick(Constants.CONTEST_LIVE)
                        }
                        eventKey={Constants.CONTEST_LIVE}
                      >
                        <span className="live-indicator" />Live
                        </NavItem>
                      <NavItem
                        onClick={() =>
                          this.onTabClick(Constants.CONTEST_COMPLETED)
                        }
                        eventKey={Constants.CONTEST_COMPLETED}
                      >
                        Completed
                        </NavItem>
                    </Nav>


                    {
                      (pageBanner && pageBanner.length > 0) &&
                      <div className={'lb-ban-wrap'}>
                        <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                          {pageBanner.map((itm, i) => {
                            return <div key={i} className={'lb-banner'}>
                              <img src={itm.image} />
                            </div>
                          })}
                        </Slider>
                      </div>
                    }

                    <div className={'lb_type_tab15'}>
                      {categories_list.map((itm, i) => {
                        return <div
                          className={`ct-tab ${selectedType == itm.id ? 'active' : ''}`}
                          onClick={() => this.selectCategory(itm)}
                          key={i}>{itm.name}</div>
                      })}
                    </div>

                    <Tab.Content>
                      <Tab.Pane eventKey={Constants.CONTEST_UPCOMING}>
                        <div className="fixture-collection-wrapper">
                          {/* {joinedFixtureList.length > 0 &&
                            <div className="text-right">
                              <a href className="earn-points-link text-uppercase" onClick={() => this.gotoDashboard()}>Go to fixtures</a>
                            </div>
                          } */}
                          {joinedFixtureList.length > 0 &&
                            joinedFixtureList.map((item, idx) => {
                              return (
                                <JoinedFixtureListData
                                  key={"joinedFixtureList" + idx}
                                  joinedFixtureData={item}
                                  myContext={this}
                                  tabFor={Constants.CONTEST_UPCOMING}
                                />
                              );
                            })}
                          {joinedFixtureList.length == 0 && !isLoading && (
                            <NoDataScreen
                              Content={{
                                ImgShown: Images.no_data_bg_image,
                                IsButton: true,
                                ButtonText: "Go to Lobby",
                                Title: "OOPS! NO FIXTURE YET.",
                                NoDataContent: "CHECK LATER!",
                                BtnAction: this.gotoDashboard,
                                WithFilter: ""
                              }}
                            />
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey={Constants.CONTEST_LIVE}>
                        <div className="fixture-collection-wrapper live-fixture-collection-wrapper">
                          {joinedFixtureList.length > 0 &&
                            <div className="text-right">
                              <a href className="earn-points-link text-uppercase" onClick={() => this.gotoDashboard()}>Go to fixtures</a>
                            </div>
                          }
                          {joinedFixtureList.length > 0 &&
                            joinedFixtureList.map((item, idx) => {
                              return (
                                <JoinedFixtureListData
                                  key={"joinedFixtureList" + idx}
                                  joinedFixtureData={item}
                                  myContext={this}
                                  tabFor={Constants.CONTEST_LIVE}
                                />
                              );
                            })}
                          {joinedFixtureList.length == 0 && !isLoading && (
                            <NoDataScreen
                              Content={{
                                ImgShown: Images.no_data_bg_image,
                                IsButton: true,
                                ButtonText: "Go to Lobby",
                                Title: "OOPS! NO FIXTURE YET.",
                                NoDataContent: "CHECK LATER!",
                                BtnAction: this.gotoDashboard,
                                WithFilter: ""
                              }}
                            />
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey={Constants.CONTEST_COMPLETED}>
                        <div className="fixture-collection-wrapper completed-fixture-collection-wrapper">
                          {joinedFixtureList.length > 0 &&
                            <div className="text-right">
                              <a href className="earn-points-link text-uppercase" onClick={() => this.gotoDashboard()}>Go to fixtures</a>
                            </div>
                          }
                          {joinedFixtureList.length > 0 &&
                            joinedFixtureList.map((item, idx) => {
                              return (
                                <JoinedFixtureListData
                                  key={"joinedFixtureList" + idx}
                                  joinedFixtureData={item}
                                  myContext={this}
                                  tabFor={Constants.CONTEST_COMPLETED}
                                />
                              );
                            })}
                          {joinedFixtureList.length == 0 && !isLoading && (
                            <NoDataScreen
                              Content={{
                                ImgShown: Images.no_data_bg_image,
                                IsButton: true,
                                ButtonText: "Go to Lobby",
                                Title: "OOPS! NO FIXTURE YET.",
                                NoDataContent: "CHECK LATER!",
                                BtnAction: this.gotoDashboard,
                                WithFilter: ""
                              }}
                            />
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
              {showContestDetail && (
                <ContestDetailModal
                  IsContestDetailShow={showContestDetail}
                  onJoinBtnClick={this.onSubmitBtnClick}
                  IsContestDetailHide={this.ContestDetailHide}
                  activeTabIndex={activeTab}
                />
              )}

              <Modal
                show={this.state.show}
                onHide={this.handleClose}
                className="joined-modal"
              >
                <Modal.Body>
                  <div className="text-center">
                    <img
                      src={Images.THUMBSUP}
                      alt=""
                      className="thumbs-up-img"
                    />
                    <div className="name">BRAVO!</div>
                    <img src={Images.SWEDEN} alt="" className="team-img" />
                    <p className="joined-txt">
                      You joined the Fans Challenge <br /> as NFA fan
                    </p>
                    <a href className="btn btn-rounded btn-block btn-xlg">
                      Proceed
                    </a>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
