const MetaData = {
  template: "ReFSocial",
  signup: {
    title: "Signup",
    description: "lobby page",
    keywords: "fantasy, contest"
  },
  verifymobile: {
    title: "Verify Your Mobile",
    description: "lobby page",
    keywords: "fantasy, contest"
  },
  referral: {
    title: "Referral",
    description: "lobby page",
    keywords: "fantasy, contest"
  },
  Achievements: {
    title: "Achievements",
    description: "Achievements page",
    keywords: "fantasy, contest"
  },
  Result: {
    title: "Result",
    description: "Result page",
    keywords: "fantasy, contest"
  },
  awards: {
    title: "Stats & Awards",
    description: "Stats & Awards page",
    keywords: "fantasy, contest"
  },
  settings: {
    title: "Profile Settings",
    description: "Profile Settings page",
    keywords: "fantasy, contest"
  },
  pin: {
    title: "Set Security Pin",
    description: "Set Security Pin page",
    keywords: "fantasy, contest"
  },
  Blog: {
    title: "Blog",
    description: "Blog page",
    keywords: "fantasy, contest"
  },
  RefPodcast: {
    title: "RefPodcast",
    description: "RefPodcast page",
    keywords: "fantasy, contest"
  },
  ChangePassword: {
    title: "Change Password",
    description: "Change Password page",
    keywords: "fantasy, contest"
  },
  pickemail: {
    title: "Pick Your Email",
    description: "lobby page",
    keywords: "fantasy, contest"
  },
  pickusername: {
    title: "Pick Your Username",
    description: "lobby page",
    keywords: "fantasy, contest"
  },
  Performance: {
    title: "Performance",
    description: "Performance page",
    keywords: "Accuracy, Chart, Rank"
  },
  Prediction: {
    title: "Prediction",
    description: "Prediction page",
    keywords: "Accuracy, Chart, Rank"
  },
  lobby: {
    title: "Lobby",
    description: "lobby page",
    keywords: "fantasy, contest"
  },
  RefSocial: {
    title: "RefSocial",
    description: "RefSocial page",
    keywords: "fantasy, contest"
  },
  Refmail: {
    title: "RefMail",
    description: "RefMail page",
    keywords: "fantasy, contest"
  },
  contestListing: {
    title: "Contest Listing",
    description: "lobby page",
    keywords: "fantasy, contest"
  },
  lineup: {
    title: "Lineup",
    description: "roster page",
    keywords: "fantasy, contest"
  },
  "field-view": {
    title: "Field View",
    description: "roster page",
    keywords: "fantasy, contest"
  },
  "select-captain": {
    title: "Select Captain | Vice Captain",
    description: "roster page",
    keywords: "fantasy, contest"
  },
  contests: {
    title: "My Contests",
    description: "My Contests page",
    keywords: "fantasy, contest"
  },
  myprofile: {
    title: "Profile",
    description: "Profile page",
    keywords: "fantasy, contest"
  },
  editprofile: {
    title: "Edit Profile",
    description: "Edit Profile page",
    keywords: "fantasy, contest"
  },
  addfunds: {
    title: "Add Funds",
    description: "Add Funds page",
    keywords: "fantasy, contest"
  },
  createcontest: {
    title: "Create Contest",
    description: "Create Contest page",
    keywords: "fantasy, contest"
  },
  teamlist: {
    title: "Team Detail",
    description: "Team Detail",
    keywords: "fantasy, contest"
  },
  help: {
    title: "Help",
    description: "Help page",
    keywords: "fantasy, contest"
  },
  leaderboard: {
    title: "Leaderboard",
    description: "Leaderboard page",
    keywords: "fantasy, contest"
  },
  referfriend: {
    title: "Refer a Friend",
    description: "Refer a Friend page",
    keywords: "fantasy, contest"
  },
  mywallet: {
    title: "Refpay",
    description: "refpay page",
    keywords: "refpay, add-funds, transfer, withdraw, subscribe"
  },
  transactions: {
    title: "Transactions",
    description: "Transactions page",
    keywords: "Transactions History"
  },
  MyGames: {
    title: "My Games",
    description: "My Games page",
    keywords: "My Games list"
  },
  membership: {
    title: "Membership Plan",
    description: "Membership Plan page",
    keywords: "Membership Plan, freemium, premium, premium+"
  },
  notification: {
    title: "Notification",
    description: "Notification page",
    keywords: "fantasy, contest"
  },
  howtoplay: {
    title: "How To Play",
    description: "How To Play page",
    keywords: "fantasy, contest"
  },
  aboutus: {
    title: "About Us",
    description: "About Us page",
    keywords: "fantasy, contest"
  },
  faqs: {
    title: "FAQs",
    description: "FAQs page",
    keywords: "fantasy, contest"
  },
  withdraw: {
    title: "Withdraw",
    description: "Withdraw page",
    keywords: "fantasy, contest"
  },
  termsconditions: {
    title: "Terms and Conditions",
    description: "Terms and Conditions page",
    keywords: "fantasy, contest"
  },
  rulesscoring: {
    title: "Rules and Scoring",
    description: "Rules and Scoring page",
    keywords: "fantasy, contest"
  },
  privacypolicy: {
    title: "Privacy Policy",
    description: "Privacy Policy page",
    keywords: "fantasy, contest"
  },
  partners: {
    title: "Partners",
    description: "Partners page",
    keywords: "fantasy, contest"
  },
  ContactUs: {
    title: "ContactUs",
    description: "ContactUs page",
    keywords: "fantasy, contest"
  },
  compare: {
    title: "Compare",
    description: "Compare page",
    keywords: "fantasy, contest"
  }
};

export default MetaData;
