import React, { Fragment } from "react";
import { Images, Header, Loader } from "../components";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";

export default class RPLFinal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            finalData: '',
            isLoading: false
        };
    }
    componentDidMount() {
        this.CallApiGetFinalData(this.props.tab)
    }

    CallApiGetFinalData(tab) {
        if (tab === 2) {
            this.setState({ isLoading: true })
            WSManager.Rest(NC.baseURL + NC.GET_RPL_CUP_FINAL_DATA).then(
                responseJson => {
                    this.setState({ isLoading: false })
                    if (responseJson.response_code === NC.successCode) {
                        this.setState({
                            finalData: responseJson.data
                        });
                    }
                }
            );
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps !== this.props) {
            this.CallApiGetFinalData(nextProps.tab)
        }
    };


    renderEmptyPlace(key, span) {
        return (
            <Fragment key={key}>
                <li>
                    <img onClick={() => Header.ShowProfileModal({ image: Images.DEFAULT_USER })} src={Images.DEFAULT_USER} alt="" />
                    {span && <span></span>}
                </li>
                <li>
                    <img onClick={() => Header.ShowProfileModal({ image: Images.DEFAULT_USER })} src={Images.DEFAULT_USER} alt="" />
                    {span && <span></span>}
                </li>
            </Fragment>
        );
    }
    renderFillPlace(key, item, span) {
        return (
            <Fragment key={key}>
                <li>
                    <img onClick={() => Header.ShowProfileModal({ image: item.home.image || Images.DEFAULT_USER })} src={item.home.image || Images.DEFAULT_USER} alt="" />
                    {span && <span></span>}
                </li>
                <li>
                    <img onClick={() => Header.ShowProfileModal({ image: item.away.image || Images.DEFAULT_USER })} src={item.away.image || Images.DEFAULT_USER} alt="" />
                    {span && <span></span>}
                </li>
            </Fragment>
        );
    }

    render() {
        return (
            <div>
                {this.state.isLoading && <Loader />}
                <div className="flex-container">
                    <div className="first-user-list">
                        <ul>
                            {
                                this.state.finalData.stage1 ?
                                    _.map(this.state.finalData.stage1.list, (item, key) => {
                                        return this.renderFillPlace(key, item)
                                    })
                                    :
                                    _.map([1, 2, 3, 4, 5, 6, 7, 8], (item, key) => {
                                        return this.renderEmptyPlace(key)
                                    })
                            }
                        </ul>
                    </div>
                    <div className="second-user-list">
                        <ul>
                            {
                                this.state.finalData.stage2 ?
                                    _.map(this.state.finalData.stage2.list, (item, key) => {
                                        return this.renderFillPlace(key, item, true)
                                    })
                                    :
                                    _.map([1, 2, 3, 4], (item, key) => {
                                        return this.renderEmptyPlace(key, true)
                                    })
                            }
                        </ul>
                    </div>
                    <div className="third-user-list">
                        <ul>
                            {
                                this.state.finalData.stage3 ?
                                    _.map(this.state.finalData.stage3.list, (item, key) => {
                                        return this.renderFillPlace(key, item, true)
                                    })
                                    :
                                    _.map([1, 2], (item, key) => {
                                        return this.renderEmptyPlace(key, true)
                                    })
                            }
                        </ul>
                    </div>
                    <div className="fourth-user-list">
                        <ul>
                            {
                                this.state.finalData.stage4 ?
                                    _.map(this.state.finalData.stage4.list, (item, key) => {
                                        return this.renderFillPlace(key, item, true)
                                    })
                                    :
                                    _.map([1], (item, key) => {
                                        return this.renderEmptyPlace(key, true)
                                    })
                            }
                        </ul>
                    </div>
                    <div className="fifth-user-list">
                        <ul>
                            {
                                this.state.finalData.stage5 ?
                                    <li>
                                        <div className="winner-section">
                                            <img onClick={() => Header.ShowProfileModal({ image: this.state.finalData.stage5.list[0].home.image || Images.DEFAULT_USER })} src={this.state.finalData.stage5.list[0].home.image || Images.DEFAULT_USER} alt="" />
                                            <p className="winner-name">RPL Cup Winner</p>
                                        </div>
                                        <span></span>
                                    </li>
                                    :
                                    <li>
                                        <div className="winner-section">
                                            <img onClick={() => Header.ShowProfileModal({ image: Images.DEFAULT_USER })} src={Images.DEFAULT_USER} alt="" />
                                            <p className="winner-name">RPL Cup Winner</p>
                                        </div>
                                        <span></span>
                                    </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
