import React from "react";
import { Row, Col, Tab, Nav, NavItem, ProgressBar, Modal, FormGroup, FormControl } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { MyContext } from "../context";
import { notify } from "react-notify-toast";
import { Header, Loader, Images, ComingSoon, NoDataScreen, SubHeader } from "../components";
import Select from "react-select";
import WSManager from "../helper/WSManager";
import ContestDetailModal from "../Modals/ContestDetail";
import MetaData from "../helper/MetaData";
import * as NC from "../helper/NetworkingConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import CountdownTimer from "./CountDownTimer";
import Moment from "react-moment";
import UtilityFunctions from "../helper/UtilityFunctions";
import _ from "lodash";
import * as Constants from "../helper/Constants";
import util from "util";
import Slider from "react-slick";
import CreatePrivateContest from './CreatePrivateContest';
import config from '../Config';

var globalThis = null;

const ReFPredictorIO = ({ item, myContext, roundPredictionCount, idx }) => {
  return (
    <div className="collection-list-wrapper">

      <div className={'wrp-body'}>

        <div className={'actn-area'}>

          {
            (item.contest_access_type == 0) &&

            <i className="icon-share" onClick={() => globalThis.shareContest(item.contest_unique_id)} />
          }


          {
            (item.is_owner == 1 && item.total_user_joined == 0) &&
            <React.Fragment>

              <i
                className="icon-edit"
                onClick={() => {
                  globalThis.props.history.push({
                    pathname: "/edit-contest",
                    state: { data: item }
                  })
                }}

              />
              <i
                className="icon-delete"
                onClick={() => {
                  console.log(idx)
                  Header.showAlertModal({
                    message: "Are you sure you want delete this contest?",
                    ModalAction: () => {
                      globalThis.deletedContest(item.contest_unique_id, idx)
                    }
                  }
                  )
                }
                }
              />
            </React.Fragment>
          }

        </div>


        <span
          onClick={event =>
            globalThis.ContestDetailShow(event, item, roundPredictionCount)
          }
          className="collection-heading cursor-pointer"
        >
          {item.contest_name}
        </span>
        <div className="prizepool">
          Prize pool
        <span className="prizepool-amt"><i className={'icon-Rits font-12'}></i> {item.prize_pool}</span>
          {<span className="prizepool-for">Top {item.number_of_winner}</span>}
          {/* <a
          // href
          onClick={event =>
            globalThis.ContestDetailShow(event, item, roundPredictionCount)
          }
          className="scoring-link"
        >
          Rules
        </a> */}
        </div>
        <a
          // href
          className="btn btn-rounded"
          onClick={event =>
            globalThis.joinGame(
              item.contest_id,
              item.round_id,
              item.entry_fee,
              item.contest_name,
              roundPredictionCount,
              event
            )
          }
        >
          Join <i className={'icon-Rits font-12'}></i> {item.entry_fee}
        </a>
        <ProgressBar
          className={
            parseInt(item.total_user_joined) < parseInt(item.minimum_size)
              ? "danger"
              : ""
          }
          now={globalThis.showProgressBar(item.total_user_joined, item.size)}
        />
        <div
          className={
            "progressbar-entries " +
            (parseInt(item.total_user_joined) < parseInt(item.minimum_size)
              ? "danger"
              : "")
          }
        >
          <span>{item.total_user_joined} </span>/
        {item.size == null ? (
            <i className="icon-infinite" />
          ) : (
              item.size + " Entries"
            )}
        </div>
      </div>
      <div class="creater-info">Created by - <span>{item.user_name || 'N/A'}</span></div>

    </div>
  );
};

const ContestList = ({ ContestListItem, mContext }) => {
  return (
    <div className="card-view">
      <i className="icon-share" />
      <div className="card-view-right">
        <h2>
          {ContestListItem.match_data ? ContestListItem.match_data.home_team : '-'}
          <span> v </span>
          {ContestListItem.match_data ? ContestListItem.match_data.away_team : '-'}
        </h2>
        <p className="mb-0">
          Referee: {ContestListItem.referee_name},
          {ContestListItem.match_data ? ContestListItem.match_data.venue_name : '-'}
        </p>
        <p>Media: {ContestListItem.media_text}</p>
        <div className="match-date">
          <Moment
            date={UtilityFunctions.getUtcToLocal(
              ContestListItem.scheduled_timestamp
            )}
            format="ddd, MMM DD"
          />
        </div>
        <div
          className={
            "match-time" +
            (UtilityFunctions.showCountDown(ContestListItem) ? " danger" : "")
          }
        >
          <i className="icon-clock" />

          {UtilityFunctions.showCountDown(ContestListItem) ? (
            <div className="countdown time-line">
              <CountdownTimer
                deadlineTimeStamp={ContestListItem.game_starts_in_timestamp}
                currentDateTimeStamp={ContestListItem.current_timestamp}
              />
            </div>
          ) : (
              <span>
                <Moment
                  date={UtilityFunctions.getUtcToLocal(
                    ContestListItem.scheduled_timestamp
                  )}
                  format="hh:mm A"
                />
              </span>
            )}
        </div>
        <div className="game-closed">
          Game Closes{" "}
          {
            <Moment
              date={UtilityFunctions.getUtcToLocal(
                ContestListItem.game_starts_in_timestamp
              )}
              format="hh:mm A"
            />
          }
          <div className="text-right pull-right">
            <a
              // href
              className="play-btn"
              onClick={() => globalThis.onPlayFixtureClick(ContestListItem)}
            >
              Play
            </a>
          </div>
        </div>
        <img src={Images.VISSEL} alt="" className="vissel-img" />
      </div>
      <div className="card-view-left">
        <div className="team-section">
          <img
            src={
              ContestListItem.match_data ?
                ContestListItem.match_data.home_flag
                  ? ContestListItem.match_data.home_flag
                  : Images.DEFAULT_FLAG_IMG : Images.DEFAULT_FLAG_IMG
            }
            alt=""
          />

          <div className="vs-section">
            <i className="icon-football" />
          </div>
        </div>
        <div className="team-section">
          <img
            src={
              ContestListItem.match_data ?
                ContestListItem.match_data.away_flag
                  ? ContestListItem.match_data.away_flag
                  : Images.DEFAULT_FLAG_IMG : Images.DEFAULT_FLAG_IMG
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default class Lobby extends React.Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShowMoreOption = this.handleShowMoreOption.bind(this);
    this.handleCloseMoreOption = this.handleCloseMoreOption.bind(this);
    this.SearchUserReq = _.debounce(this.SearchUserReq.bind(this), 700);
    this.state = {
      showContestDetail: false,
      showModal: false,
      showMoreOption: false,
      selectedLeague: "",
      contestList: [],
      offset: 0,
      limit: 20,
      contestListOffset: 0,
      isLoading: false,
      hasMoreFixture: false,
      hasMoreContest: false,
      contestFilterData: [],
      selectedFilter: [],
      filteredContestList: [],
      filteredContestShedule: [],
      selectedTab: this.props.match && this.props.match.params ? this.props.match.params.tab_id || Constants.FIXTURES : Constants.FIXTURES,
      predictionCount: "",
      contestInfo: "",
      round_id: "",
      user_type: this.props.match && this.props.match.params ? this.props.match.params.user_type || "" : "",
      searchClick: false,
      Searchkey: "",
      categories_list: [],
      selectedType: Constants.GLOBAL_CATEGORY,
      selectedCategory: "",
      userProfile: WSManager.getProfile(),
      categoryObj: Constants.categories[0],
      pageBanner: [],
      selectedContestType: 2,

      contestType: 1,
      contestTypeObj: Constants.contest_type[0]
    };
  }

  SearchUserReq() {
    const { Searchkey } = this.state;
    if (Searchkey.trim().length < 2 && Searchkey.trim().length > 0) return;
    this.callContestListApi(this.state.selectedFilter.value);
  }

  handleRefresh = () => {
    if (this.state.selectedTab == 0) {
      this.setState(
        {
          offset: 0,
          hasMoreFixture: false
        },
        () => {
          this.fixtureList();
        }
      );
    } else if (this.state.selectedTab == 1) {
      this.setState(
        {
          contestListOffset: 0,
          hasMoreContest: false
        },
        () => {
          this.callContestListApi(this.state.selectedFilter.value);
        }
      );
    }
  };

  onTabClick = selectedTab => {
    if (selectedTab == 0) {
      this.props.history.push({ pathname: "/dashboard/" + selectedTab });
      this.setState(
        {
          selectedTab: selectedTab,
          selectedCategory: "",
          selectedType: Constants.GLOBAL_CATEGORY,
          contestList: [],
          offset: 0,
          selectedFilter: '',
          selectedContestType: 2,
          categoryObj: Constants.categories[0]
        },
        () => {
          this.fixtureList();
        }
      );
    }
    else {
      if (WSManager.loggedIn()) {
        this.props.history.push({ pathname: "/dashboard/" + selectedTab });

        this.setState({
          selectedTab: selectedTab,
          selectedCategory: "",
          selectedType: Constants.GLOBAL_CATEGORY,
          categoryObj: Constants.categories[0]
        });
        if (selectedTab == 1) {
          if (this.state.selectedContestType == 2) {
            this.contestFilter();
          }

        }
      }
      else {
        // Header.LoginModalShow();
        WSManager.goToAppUrl('refpredictor')
      }
    }
  };


  shareContest = (contest_unique_id) => {
    let title =
      `Your friend ${this.state.userProfile.display_name} has challenged you for the contest. Join and win big by beating your friend, using the following link : ${config.MyUrl}/contest/detail/${contest_unique_id}

    Cheers,
    Team Refpredictor`;

    Header.ShareModalShow(contest_unique_id, title);
  }

  gotoDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard/0"
    });
    this.onTabClick(0)
  };

  showProgressBar = (join, total) => {
    return (join * 100) / total;
  };

  fetchMoreContestData = () => {

    if (this.state.selectedTab == 0) {
      this.fixtureList();
    } else if (this.state.selectedTab == 1) {
      this.callContestListApi(this.state.selectedFilter.value);
    }
  };

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleShowMoreOption() {
    this.setState({ showMoreOption: true });
  }

  handleCloseMoreOption() {
    this.setState({ showMoreOption: false });
  }

  ContestDetailShow = (event, item) => {
    event.stopPropagation();
    this.setState({
      showContestDetail: true,
      contestInfo: item
    });
  };

  ContestDetailHide = () => {
    this.setState({
      showContestDetail: false
    });
  };

  onPlayFixtureClick = ContestListItem => {
    if (WSManager.loggedIn()) {
      if (WSManager.isProfileCompleted()) {

        if (WSManager.getProfile().plan_id == 1) {
          Header.showMembershipModal(
            ContestListItem,
            "fixture",
            this.countinueAsFree.bind(this)
          );
        } else {
          this.goOnPrediction(ContestListItem);
        }
        // if (
        //   WSManager.getProfile().plan_id == 2 ||
        //   WSManager.getProfile().plan_id == 3
        // ) {
        //   this.goOnPrediction(ContestListItem);
        // } else {
        //   Header.showMembershipModal(
        //     ContestListItem,
        //     "fixture",
        //     this.countinueAsFree.bind(this)
        //   );
        // }
      } else {
        Header.CompleteProfileShow();
      }
    } else {
      Header.LoginModalShow();
    }
  };

  goOnPrediction = item => {
    this.props.history.push({
      pathname: "/prediction/" + item.fixture_unique_id,
      state: { ContestData: item }
    });
  };

  componentDidMount() {
    globalThis = this;
    this.setCategories()
    this.getPageBanner()


    if (this.state.user_type != "pundit") {
      if (
        window.location.href == NC.baseURL + "login" &&
        !WSManager.loggedIn()
      ) {
        Header.LoginModalShow();
      } else if (
        window.location.href == NC.baseURL + "signup" &&
        !WSManager.loggedIn()
      ) {
        Header.SignupModalShow();
      } else {
        this.onTabClick(
          WSManager.loggedIn() ? this.state.selectedTab : Constants.FIXTURES
        );
      }
    }

  }

  getPageBanner = () => {

    let param = {
      page_type_id: Constants.BANNER_PAGE_TYPE_ID.lobby,
      position_id: "1"
    }
    WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }


  setCategories = () => {
    let cats = Constants.categories;
    this.setState({
      categories_list: cats,
      selectedCategory: cats.length > 0 ? cats[0].value : '',
      selectedType: cats.length > 0 ? cats[0].id : Constants.GLOBAL_CATEGORY,
      categoryObj: cats[0],
    })
  }

  fixtureList = () => {
    let { offset, limit, contestList, selectedType, selectedCategory } = this.state;
    let param = {
      sports_id: 5,
      sort_field: "season_scheduled_date",
      sort_order: "ASC",
      is_referral: 1,
      offset: offset,
      limit: limit,
      type: selectedType
    };

    if (selectedType == '2') {
      param['country_id'] = selectedCategory;
    }
    if (selectedType == '3') {
      param['team_id'] = selectedCategory;
    }

    this.setState({
      isLoading: true
    });

    WSManager.Rest(NC.baseURL + NC.GET_FIXTURE_LIST, param)
      .then(responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          if (offset === 0) {
            this.setState({
              contestList: responseJson.data.fixture_list,
              offset: responseJson.data.fixture_list.length
            });
          } else {
            this.setState({
              contestList: [...contestList, ...responseJson.data.fixture_list],
              offset: offset + responseJson.data.fixture_list.length
            });
          }
          if (responseJson.data.fixture_list.length < limit) {
            this.setState({ hasMoreFixture: false });
          } else {
            this.setState({ hasMoreFixture: true });
          }
        }
      })
      .catch(error => {
        this.setState({ hasMoreFixture: false });
      });
  };

  contestFilter() {

    let { selectedType, selectedCategory, categoryObj, contestType } = this.state;

    // if (selectedType != 2 || categoryObj.value != 160) {
    //   return;
    // }


    let param = {
      // contest_type_id: [1, 2],
      type: selectedType,
      country_id: categoryObj.value,
      contest_type_id: contestType == 1 ? Constants.PUBLIC_LEAGUE_ID : Constants.PRIVATE_LEAGUE_ID,
      contest_access_type: contestType == 1 ? 0 : 1
    };

    // if(selectedType == '2'){
    //   param['country_id'] = selectedCategory;
    // }
    // if(selectedType == '3'){
    //   param['team_id'] = selectedCategory;
    // }

    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.GET_FILTER_CONTEST_TYPE, param)
      .then(responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          let tmpArray = [];

          if (responseJson.data.length > 0) {
            _.map(responseJson.data, (item, idx) => {
              tmpArray.push({
                label: item.name,
                value: item.contest_type_id
              });
            });
            this.setState(
              {
                contestFilterData: tmpArray,
                selectedFilter: tmpArray[0],
                hasMoreContest: false,
                contestListOffset: 0
              },
              () => {
                this.callContestListApi();
              }
            );
          }
          else {
            this.setState({
              filteredContestList: [],
              contestFilterData: [],
              selectedFilter: ""
            }, () => {
              this.callContestListApi();
            })

          }

        }

      })
      .catch(error => {
        this.setState({ contestFilterData: [] });
      });
  }

  handleContestFilter = selectedOption => {
    this.setState(
      {
        selectedFilter: selectedOption,
        contestListOffset: 0,
        hasMoreContest: false
      },
      () => {
        this.callContestListApi(selectedOption.value);
      }
    );
  };

  callContestListApi = (filterBy) => {

    console.log('callContestListApi')
    let {
      contestListOffset,
      limit,
      filteredContestList,
      Searchkey,
      selectedType,
      selectedCategory,
      selectedFilter,
      contestType
    } = this.state;


    let param = {
      contest_type: selectedFilter.value,
      contest_name: Searchkey,
      offset: contestListOffset,
      limit: limit,
      type: selectedType,
      contest_type_id: contestType == 1 ? Constants.PUBLIC_LEAGUE_ID : Constants.PRIVATE_LEAGUE_ID,
      contest_access_type: contestType == 1 ? 0 : 1
    };


    if (selectedType == '2') {
      param['country_id'] = selectedCategory;
    }
    if (selectedType == '3') {
      param['team_id'] = selectedCategory;
    }



    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.GET_CONTEST_LIST, param)
      .then(responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          if (contestListOffset === 0) {
            this.setState({
              predictionCount: responseJson.data.current_round_prediction_count,
              filteredContestList: responseJson.data.result,
              filteredContestShedule: responseJson.data.schedule,
              contestListOffset: contestListOffset + responseJson.data.result.length
            });
          } else {
            this.setState({
              predictionCount: responseJson.data.current_round_prediction_count,
              filteredContestList: [
                ...filteredContestList,
                ...responseJson.data.result
              ],
              contestListOffset:
                contestListOffset + responseJson.data.result.length,
              filteredContestShedule: []
            });
          }
          if (responseJson.data.result.length < limit) {
            this.setState({ hasMoreContest: false });
          } else {
            this.setState({ hasMoreContest: true });
          }
        }
      })
      .catch(error => {
        this.setState({ hasMoreContest: false });
      });
  };


  deletedContest = (contestUniqueId, idx) => {
    const { filteredContestList } = this.state;
    let param = {
      contest_unique_id: contestUniqueId
    }
    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.CANCEL_CONTEST, param).then(responseJson => {
      this.setState({
        isLoading: false
      });
      if (responseJson.response_code == NC.successCode) {
        notify.show(responseJson.message, "success", 3000);
        filteredContestList.splice(idx, 1);
        this.setState({
          filteredContestList: filteredContestList
        });
      }
    })
  }


  selectCategory = (itm) => {
    // if (this.state.selectedCategory == itm.value) return;

    this.setState({
      selectedCategory: itm.value,
      selectedType: itm.id,
      categoryObj: itm,
      contestList: [],
      offset: 0,
      selectedFilter: ""
    }, () => {
      if (this.state.selectedTab == 0) {
        this.fixtureList();
      }
      if (this.state.selectedTab == 1) {
        if (this.state.selectedContestType == 2) {
          this.contestFilter();
        }

      }
    })
  }

  joinGame = (
    contestid,
    round_id,
    entryFee,
    contestname,
    roundPredictionCount,
    event
  ) => {
    event.stopPropagation();
    this.setState({
      round_id: round_id
    });
    let EntryFee = parseFloat(entryFee);
    if (WSManager.isProfileCompleted()) {
      if (roundPredictionCount != 0) {
        if (
          parseFloat(Constants.userBalanceInfo.user_balance.real_amount) <
          EntryFee
        ) {
          if (WSManager.getProfile().plan_id == 1) {
            notify.show(util.format(Constants.Messages.less_fund, 'join the contest'), "error", 4000);
          } else {
            notify.show(util.format(Constants.Messages.less_fund, 'join the contest'), "error", 4000);
          }

          WSManager.goToAppUrl('refpay', `topage=add-funds`)
          // this.props.history.push("/add-funds");
        } else {
          if (
            WSManager.getProfile().plan_id == 2 ||
            WSManager.getProfile().plan_id == 3 ||
            WSManager.getProfile().plan_id == 4 ||
            WSManager.getProfile().plan_id == 5 ||
            WSManager.getProfile().plan_id == 6
          ) {
            Header.showConfirmationModal(
              contestid,
              "contest",
              this.countinueAsFree.bind(this),
              EntryFee,
              contestname
            );
          } else {
            Header.showMembershipModal(
              contestid,
              "contest",
              this.countinueAsFree.bind(this),
              EntryFee
            );
          }
        }
      } else {
        notify.show(Constants.Messages.pred_count, "error", 2000);
      }
    } else {
      notify.show('Please Complete Your profile.', "error", 2000);
      // Header.CompleteProfileShow();
    }
  };

  countinueAsFree(data, action_type) {
    if (action_type == "fixture") {
      this.goOnPrediction(data);
    }
    if (action_type == "contest") {
      this.callJoinGameApi(data);
    }
  }

  callJoinGameApi = contestid => {
    let param = {
      contest_id: contestid
    };
    this.setState({ isLoading: true });
    WSManager.Rest(NC.baseURL + NC.CONTEST_JOIN_GAME, param)
      .then(responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          Header.showSubmitModal(responseJson.message, "contest");
        }
      })
      .catch(error => {
        this.setState({ hasMoreFixture: false });
      });
  };

  openShareLinkModal() {
    Header.showShareLinkModal();
  }
  openConfirmationModal() {
    Header.showConfirmationModal();
  }
  openSubmitModal() {
    Header.showSubmitModal();
  }

  selectContestTab = (key) => {

    if (key == 1) {
      const user_permissions = WSManager.getUserPermissions();

      if (user_permissions.create_league == '0') {
        notify.show('Upgrade to Premium+ to create your own contest.', "error", 2000);
        return;
      }
    }

    this.setState({
      selectedContestType: key,
      selectedCategory: "",
      selectedType: Constants.GLOBAL_CATEGORY,
      categoryObj: Constants.categories[0]
    }, () => {
      if (this.state.selectedContestType == 2) {
        this.contestFilter();
      }
    })
  }

  onShareModalHide = () => {
    this.setState({ showContestShareModal: false })
  }



  changeContestType = (key) => {
    if (key.value == this.state.contestType) return;

    this.setState(
      {
        contestType: key.value,
        contestTypeObj: key,
        hasMoreContest: false,
        contestListOffset: 0
      }, () => {
        this.contestFilter()
      }
    )
  }



  render() {
    const {
      showContestDetail,
      searchClick,
      hasMoreFixture,
      isLoading,
      contestFilterData,
      selectedFilter,
      filteredContestList,
      filteredContestShedule,
      selectedTab,
      predictionCount,
      contestInfo,
      hasMoreContest,
      contestList,
      categories_list,
      selectedCategory,
      selectedType,
      pageBanner,
      selectedContestType,
      categoryObj,
      contestTypeObj
    } = this.state;

    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    let profile = WSManager.getProfile();

    return (
      <MyContext.Consumer>
        {context => (
          <div className={`web-container lobby-web-container `}>
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.lobby.title}</title>
              <meta name="description" content={MetaData.lobby.description} />
              <meta name="keywords" content={MetaData.lobby.keywords} />
            </Helmet>
            {isLoading && <Loader />}
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
              showNavigation={selectedTab == Constants.JOIN_CONTEST && selectedContestType == 1 ? false : true}
            />
            <div>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={parseInt(selectedTab)}
              >
                <Row>
                  <Col sm={12}>
                    <SubHeader Title="Lobby" AddClassName="" ImgShown={Images.HEADER_BG} BannerImage="" />
                    <Nav className="tab-navigation tab-navigation-upper lobby-nav">
                      <NavItem
                        onClick={() => this.onTabClick(Constants.FIXTURES)}
                        // eventKey={Constants.FIXTURES}
                        className={`${selectedTab == Constants.FIXTURES ? 'active' : ''}`}
                      >
                        Fixtures
                        </NavItem>
                      <NavItem
                        onClick={() =>
                          this.onTabClick(Constants.JOIN_CONTEST)
                        }
                        className={`create-join ${selectedTab == Constants.JOIN_CONTEST ? 'active' : ''}`}
                      // eventKey={Constants.JOIN_CONTEST}
                      >
                        Create / <br />join contest
                        </NavItem>
                      <NavItem
                        onClick={() => this.onTabClick(Constants.SPONSORED)}
                        // eventKey={Constants.SPONSORED}
                        className={`refsocial ${selectedTab == Constants.SPONSORED ? 'active' : ''}`}
                      >
                        ReFSocial
                        </NavItem>
                    </Nav>


                    {
                      (pageBanner && pageBanner.length > 0) &&
                      <div className={'lb-ban-wrap'}>
                        <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                          {pageBanner.map((itm, i) => {
                            return <div key={i} className={'lb-banner'}>
                              <img src={itm.image} />
                            </div>
                          })}
                        </Slider>
                      </div>
                    }

                    {
                      selectedTab == Constants.JOIN_CONTEST && (
                        <div className={'contest-tabs lb_type_tab15'}>
                          <div onClick={() => this.selectContestTab(1)} className={`ct-tab ${selectedContestType == 1 ? 'active' : ''}`} >Create Contest</div>
                          <div onClick={() => this.selectContestTab(2)} className={`ct-tab ${selectedContestType == 2 ? 'active' : ''}`}>Join Contest</div>
                        </div>
                      )
                    }

                    {
                      (selectedTab != 2) && (
                        // selectedTab == 1 && selectedContestType == 1 ?
                        //   null :
                        <div className={'lb_type_tab15'}>
                          {categories_list.map((itm, i) => {
                            return <div
                              className={`ct-tab ${categoryObj.id == itm.id ? 'active' : ''}`}
                              onClick={() => this.selectCategory(itm)}
                              key={i}>{itm.name}</div>
                          })}
                        </div>)
                    }



                    {/* </div> */}
                    <Tab.Content>
                      <Tab.Pane className={`${selectedTab == Constants.FIXTURES ? 'active in' : ''}`} eventKey={Constants.FIXTURES}>
                        <div className="fixture-collection-wrapper">
                          <InfiniteScroll
                            pullDownToRefresh={false}
                            refreshFunction={() => this.handleRefresh()}
                            pullDownToRefreshContent={
                              <h3 style={{ textAlign: "center", fontSize: 14 }}>
                                {""}
                              </h3>
                            }
                            releaseToRefreshContent={
                              <h3 style={{ textAlign: "center", fontSize: 14 }}>
                                {""}
                              </h3>
                            }
                            dataLength={contestList.length}
                            next={this.fetchMoreContestData}
                            hasMore={hasMoreFixture}
                          >
                            {contestList.length > 0 &&
                              contestList.map((item, idx) => {
                                return (
                                  <ContestList
                                    key={"contestListKey" + idx}
                                    ContestListItem={item}
                                    mContext={this}
                                  />
                                );
                              })}
                            {contestList.length == 0 && !isLoading && (
                              <NoDataScreen
                                Content={{
                                  ImgShown: Images.no_data_bg_image,
                                  IsButton: false,
                                  ButtonText: "Go to Lobby",
                                  Title: "OOPS! NO FIXTURE YET.",
                                  NoDataContent: "CHECK LATER!",
                                  BtnAction: this.gotoDashboard,
                                  WithFilter: ""
                                }}
                              />
                            )}
                          </InfiniteScroll>
                        </div>
                      </Tab.Pane>


                      <Tab.Pane className={`${selectedTab == Constants.JOIN_CONTEST ? 'active in' : ''}`} eventKey={Constants.JOIN_CONTEST}>



                        {
                          selectedTab == Constants.JOIN_CONTEST &&
                            selectedContestType == 1 ?
                            <React.Fragment>
                              <CreatePrivateContest
                                {...this.props}
                                selectedCategory={categoryObj}
                                type={1}
                              />
                            </React.Fragment>

                            :
                            <React.Fragment>

                              <div className="wrapper-spacing ">


                                <div className="my-4">
                                  <Row>
                                    <Col xs={12} className={'text-center'}>
                                      <div className={'rad-switch'}>
                                        {
                                          Constants.contest_type.map((itm, i) => {
                                            return <div
                                              className={`${contestTypeObj.value == itm.value ? 'active' : ''}`}
                                              onClick={() => this.changeContestType(itm)}
                                              key={i}
                                            >
                                              <span className={'icon-tick check'}></span> {itm.label}
                                            </div>
                                          })
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                <FormGroup className="input-label-center input-transparent font-14 m-b-sm">

                                  {/* {
                                    (selectedType == 2 && contestFilterData.length > 0) && ( */}
                                  <div className="filter">
                                    <div className="filterStyle">
                                      <Select
                                        onChange={this.handleContestFilter}
                                        options={contestFilterData}
                                        classNamePrefix="secondary"
                                        className="select-secondary minusML10"
                                        placeholder="Select Filter"
                                        isSearchable={false}
                                        isClearable={false}
                                        value={selectedFilter}
                                        theme={theme => ({
                                          ...theme,
                                          borderRadius: 0,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#37003C"
                                          }
                                        })}
                                      />
                                    </div>
                                    <span className="select-arr">
                                      <i className="icon-arrow-sort" />
                                    </span>
                                    <div className="gender-border col-sm-12" />
                                  </div>
                                  {/* ) */}
                                  {/* } */}


                                </FormGroup>
                                <div className="heading-section p-0">
                                  <Row>
                                    <Col
                                      md={6}
                                      xs={12}
                                      className={
                                        "hidden-xs" +
                                        (filteredContestShedule.game_starts_in_timestamp
                                          ? " p-0"
                                          : " p-v-sm")
                                      }
                                    >
                                      {selectedFilter.label}

                                      <span>
                                        {filteredContestShedule.game_starts_in_timestamp &&
                                          UtilityFunctions.showCountDown(
                                            filteredContestShedule
                                          ) ? (
                                            <div className="countdown time-line text-danger">
                                              <CountdownTimer
                                                deadlineTimeStamp={
                                                  filteredContestShedule.game_starts_in_timestamp
                                                }
                                                currentDateTimeStamp={
                                                  filteredContestShedule.current_timestamp
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <span />
                                          )}
                                      </span>
                                    </Col>
                                    <Col md={6} xs={12}>
                                      <div
                                        className={
                                          "search-btn" +
                                          (searchClick ? " search-full" : "")
                                        }
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.Searchkey}
                                          placeholder="Search"
                                          onChange={e => {
                                            this.setState({
                                              Searchkey: e.target.value,
                                              contestListOffset: 0,
                                              hasMoreContest: false
                                            });
                                            this.SearchUserReq();
                                          }}
                                        />
                                        <div
                                          className="search-box"
                                          onClick={() =>
                                            this.setState(
                                              {
                                                searchClick: !searchClick
                                              },
                                              () => {
                                                if (searchClick && this.state.Searchkey.length > 0) {
                                                  this.setState(
                                                    {
                                                      Searchkey: "",
                                                      contestListOffset: 0,
                                                      hasMoreContest: false
                                                    },
                                                    () => {
                                                      this.callContestListApi(this.state.selectedFilter.value);
                                                    }
                                                  );
                                                }
                                              }
                                            )
                                          }
                                        >
                                          <i
                                            className={
                                              searchClick
                                                ? "icon-close"
                                                : "icon-search"
                                            }
                                          />
                                        </div>
                                      </div>

                                      <span
                                        className={
                                          "filter-by-name" +
                                          (filteredContestShedule.game_starts_in_timestamp
                                            ? " p-0"
                                            : "")
                                        }
                                      >
                                        {selectedFilter.label}
                                      </span>
                                      <span className="hidden-lg hidden-md hidden-sm">
                                        {filteredContestShedule.game_starts_in_timestamp &&
                                          UtilityFunctions.showCountDown(
                                            filteredContestShedule
                                          ) ? (
                                            <div className="countdown time-line text-danger">
                                              <CountdownTimer
                                                deadlineTimeStamp={
                                                  filteredContestShedule.game_starts_in_timestamp
                                                }
                                                currentDateTimeStamp={
                                                  filteredContestShedule.current_timestamp
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <span />
                                          )}
                                      </span>
                                    </Col>
                                  </Row>
                                </div>

                                {profile.is_pundit == 1 &&
                                  selectedFilter.value == 2 ? (
                                    <NoDataScreen
                                      Content={{
                                        ImgShown: Images.no_data_bg_image,
                                        IsButton: false,
                                        ButtonText: "Go to Lobby",
                                        Title:
                                          "Sorry! Pundit users cannot join I/O contests",
                                        NoDataContent: "",
                                        BtnAction: this.gotoDashboard,
                                        WithFilter: "1"
                                      }}
                                    />
                                  ) : (
                                    <InfiniteScroll
                                      pullDownToRefresh={false}
                                      refreshFunction={() => this.handleRefresh()}
                                      pullDownToRefreshContent={
                                        <h3
                                          style={{ textAlign: "center", fontSize: 14 }}
                                        >
                                          {""}
                                        </h3>
                                      }
                                      releaseToRefreshContent={
                                        <h3
                                          style={{ textAlign: "center", fontSize: 14 }}
                                        >
                                          {""}
                                        </h3>
                                      }
                                      dataLength={filteredContestList.length}
                                      next={this.fetchMoreContestData}
                                      hasMore={hasMoreContest}
                                    >
                                      {/* ReFPredictor I/O section start */}
                                      {filteredContestList.length > 0 &&
                                        filteredContestList.map((item, idx) => {
                                          return (
                                            <ReFPredictorIO
                                              item={item}
                                              idx={idx}
                                              key={"refpredictorIO" + idx}
                                              myContext={this}
                                              roundPredictionCount={predictionCount}
                                            />
                                          );
                                        })}

                                      {filteredContestList.length == 0 && !isLoading && (
                                        <NoDataScreen
                                          Content={{
                                            ImgShown: Images.no_data_bg_image,
                                            IsButton: true,
                                            ButtonText: "Go to Lobby",
                                            Title: "Oops! You may have joined this",
                                            NoDataContent: "contest already, or it's closed.",
                                            BtnAction: this.gotoDashboard,
                                            WithFilter: "1"
                                          }}
                                        />
                                      )}

                                      {/* ReFPredictor I/O section end */}
                                    </InfiniteScroll>
                                  )}

                                {/* Fans Challenge section start */}
                                <div className="heading-section hide">
                                  Fans Challenge <i className="icon-search" />
                                </div>
                                <div className="fans-challenge-list-wrapper hide">
                                  <ul>
                                    <li>
                                      <img
                                        src={Images.SWEDEN}
                                        alt=""
                                        className="team-img"
                                      />
                                      <p className="team-name">NFA</p>
                                      <p className="joined-entries">
                                        300/500 Entries
                                </p>
                                      <a
                                        // href 
                                        className="btn btn-rounded">
                                        Join
                                </a>
                                    </li>
                                    <li>
                                      <div className="contest-type">
                                        Fans <br /> Challenge
                                </div>
                                      <div className="timer">20 : 59 : 00</div>
                                    </li>
                                    <li>
                                      <img
                                        src={Images.SWEDEN}
                                        alt=""
                                        className="team-img"
                                      />
                                      <p className="team-name">NFA</p>
                                      <p className="joined-entries">
                                        300/500 Entries
                                </p>
                                      <a
                                        // href
                                        className="btn btn-rounded"
                                        onClick={this.handleShow}
                                      >
                                        Join
                                </a>
                                    </li>
                                  </ul>
                                </div>
                                {/* Fans Challenge section end */}
                              </div>

                              {/* Fans Challenge section select palyer start */}
                              <div className="heading-section-alt hide">
                                Fans Challenge
                        </div>
                              <div className="fan-challenge-team-selection hide">
                                <div className="fans-challenge-list-wrapper">
                                  <span className="comment-span">
                                    <i className="icon-comment" />
                                  </span>
                                  <ul>
                                    <li>
                                      <img
                                        src={Images.SWEDEN}
                                        alt=""
                                        className="team-img"
                                      />
                                      <p className="team-name">NFA</p>
                                      <p className="joined-entries">
                                        300/500 Entries
                                </p>
                                      <a
                                        // href 
                                        className="btn btn-rounded">
                                        <i className="icon-tick" /> Joined
                                </a>
                                    </li>
                                    <li>
                                      <div className="contest-type">
                                        Fans <br /> Challenge
                                </div>
                                      <div className="timer">20 : 59 : 00</div>
                                    </li>
                                    <li>
                                      <img
                                        src={Images.SWEDEN}
                                        alt=""
                                        className="team-img"
                                      />
                                      <p className="team-name">NFA</p>
                                      <p className="joined-entries">
                                        300/500 Entries
                                </p>
                                    </li>
                                  </ul>
                                </div>
                                <div className="select-player-section">
                                  <table className="select-player-list">
                                    <thead>
                                      <tr>
                                        <th>Username</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <img src={Images.USERIMG3} alt="" />
                                          <span>Anna</span>
                                          <i className="icon-user-select" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <img src={Images.USERIMG} alt="" />
                                          <span>Mark.Zucker</span>
                                          <i className="icon-add-user" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <img src={Images.USERIMG} alt="" />
                                          <span>Mark.Zucker</span>
                                          <i className="icon-add-user" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <img src={Images.USERIMG3} alt="" />
                                          <span>Mark.Zucker</span>
                                          <i className="icon-user-select" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <img src={Images.USERIMG2} alt="" />
                                          <span>Mark.Zucker</span>
                                          <i className="icon-add-user" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <img src={Images.USERIMG} alt="" />
                                          <span>Mark.Zucker</span>
                                          <i className="icon-add-user" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <img src={Images.USERIMG2} alt="" />
                                          <span>Anna</span>
                                          <i className="icon-add-user" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <img src={Images.USERIMG3} alt="" />
                                          <span>Mark.Zucker</span>
                                          <i className="icon-user-select" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table className="select-player-list select-player-list-right">
                                    <thead>
                                      <tr>
                                        <th className="text-right">Username</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="text-right">
                                          <i className="icon-user-select" />
                                          <span>Mark.Zucker</span>
                                          <img src={Images.USERIMG} alt="" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-right">
                                          <i className="icon-user-select" />
                                          <span>Anna</span>
                                          <img src={Images.USERIMG2} alt="" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-right">
                                          <i className="icon-add-user" />
                                          <span>Mark.Zucker</span>
                                          <img src={Images.USERIMG} alt="" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-right">
                                          <i className="icon-user-select" />
                                          <span>Mark.Zucker</span>
                                          <img src={Images.USERIMG3} alt="" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-right">
                                          <i className="icon-add-user" />
                                          <span>Mark.Zucker</span>
                                          <img src={Images.USERIMG} alt="" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-right">
                                          <i className="icon-add-user" />
                                          <span>Anna</span>
                                          <img src={Images.USERIMG} alt="" />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-right">
                                          <i className="icon-user-select" />
                                          <span>Mark.Zucker</span>
                                          <img src={Images.USERIMG3} alt="" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* Fans Challenge section select palyer end */}
                              <table className="select-player-list last-fan-joined-section hide">
                                <tbody>
                                  <tr>
                                    <td>
                                      <p className="timer">18:10:08</p>
                                Last fan joined
                              </td>
                                    <td>
                                      <p className="timer">18:10:08</p>
                                Last fan joined
                              </td>
                                  </tr>
                                </tbody>
                              </table>



                            </React.Fragment>

                        }

                      </Tab.Pane>



                      <Tab.Pane className={`${selectedTab == Constants.SPONSORED ? 'active in' : ''}`} eventKey={Constants.SPONSORED}>
                        <div className="sponsered-coming-soon">
                          <ComingSoon />
                        </div>
                        <div className="wrapper-spacing hide">
                          <div className="select-option-btn">
                            Select
                            <div className="arrow-section">
                              <i className="icon-arrow-sort" />
                            </div>
                          </div>
                          <div className="heading-section">Fans Challenge</div>
                          <div className="collection-list-wrapper">
                            {/* <i className="icon-share" /> */}
                            <div className="collection-heading">
                              Fantasy Winners League
                            </div>
                            <div className="prizepool">Practice Contest</div>
                            <a
                              // href 
                              className="btn btn-rounded">
                              Join ₦10
                            </a>
                            <ProgressBar now={60} />
                            <div className="progressbar-entries">
                              <span>5 </span>/ 50 Entries
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>


                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
              {showContestDetail && (
                <ContestDetailModal
                  roundPredictionCount={predictionCount}
                  history={this.props.history}
                  IsContestDetailShow={showContestDetail}
                  onJoinBtnClick={this.joinGame.bind(this)}
                  IsContestDetailHide={this.ContestDetailHide}
                  showContestDetailFor={contestInfo}
                />
              )}

              <Modal
                show={this.state.show}
                onHide={this.handleClose}
                className="joined-modal"
              >
                <Modal.Body>
                  <div className="text-center">
                    <img
                      src={Images.THUMBSUP}
                      alt=""
                      className="thumbs-up-img"
                    />
                    <div className="name">{profile.user_name}</div>
                    <img src={Images.SWEDEN} alt="" className="team-img" />
                    <p className="joined-txt">
                      You joined the Fans Challenge <br /> as NFA fan
                    </p>
                    <a
                      // href 
                      className="btn btn-rounded btn-block btn-xlg">
                      Proceed
                    </a>
                  </div>
                </Modal.Body>
              </Modal>




            </div>
          </div>



        )}
      </MyContext.Consumer>
    );
  }
}
