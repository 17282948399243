import React from "react";
import { Row, Col } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Images, Loader, Header, NoDataScreen, SubHeader } from "../components";
import TransactionRecord from "./TransactionRecord";
import MetaData from "../helper/MetaData";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {FooterClassAdd} from '../ReduxStore/Actions'

class Transaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionList: [],
      offset: 0,
      limit: 20,
      hasMore: false,
      isLoading: false
    };
  }

  componentDidMount(){
    this.props.FooterClassAdd('refpay-foot')
    this.callWSForTransaction();
  }

  componentWillUnmount(){
    this.props.FooterClassAdd('')
  }



  callWSForTransaction = () => {
    const { offset, transactionList, limit } = this.state;

    if (transactionList.length == 0) {
      this.setState({
        isLoading: true
      });
    }
    WSManager.Rest(NC.baseURL + NC.GET_TRANSACTION_HISTORY, {
      offset: offset,
      limit: limit,
      season_type: 1
    }).then(responseJson => {
      this.setState({
        isLoading: false
      });
      if (responseJson.response_code === NC.successCode) {
        if (offset == 0) {
          this.setState({
            transactionList: responseJson.data.transaction_history,
            offset: responseJson.data.transaction_history.length
          });
        } else {
          this.setState({
            transactionList: [
              ...transactionList,
              ...responseJson.data.transaction_history
            ],
            offset: offset + responseJson.data.transaction_history.length
          });
        }
        if (responseJson.data.transaction_history.length < limit) {
          this.setState({ hasMore: false });
        } else {
          this.setState({ hasMore: true });
        }
      }
    });
  };

  handleRefresh() {
    this.setState(
      {
        offset: 0,
        hasMore: false
      },
      () => {
        this.callWSForTransaction();
      }
    );
  }

  render() {
    const { hasMore, transactionList, isLoading } = this.state;

    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container refpay-web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.transactions.title}</title>
              <meta name="description" content={MetaData.transactions.description} />
              <meta name="keywords" content={MetaData.transactions.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
              showNavigation={false}
            />
            {isLoading && <Loader />}
            <div style={{ backgroundColor: "#fff" }}>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="ReFPay" ImgShown={Images.REFPAY_HEADER_BG} BannerImage="" AddClassName="single-title-header" />

                </Col>
              </Row>
              <div className="transaction-label-header">Transactions</div>
              <div className="trasaction-record full-trasaction">
                <ul>
                  <InfiniteScroll
                    pullDownToRefresh={false}
                    refreshFunction={() => this.handleRefresh()}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: "center", fontSize: 14 }}>
                        {""}
                      </h3>
                    }
                    releaseToRefreshContent={
                      <h3 style={{ textAlign: "center", fontSize: 14 }}>
                        {""}
                      </h3>
                    }
                    dataLength={transactionList.length} //This is important field to render the next data
                    next={this.callWSForTransaction}
                    hasMore={hasMore}
                  >
                    {_.map(transactionList, (item, idx) => {
                      return (
                        <TransactionRecord key={idx} item={item} idx={idx} />
                      );
                    })}
                  </InfiniteScroll>
                </ul>
              </div>
              {transactionList.length == 0 && !isLoading && (
                <NoDataScreen Content={{
                  ImgShown: Images.no_data_bg_image,
                  IsButton: false,
                  ButtonText: "Go to Lobby",
                  Title: "There is no transaction.",
                  NoDataContent: "",
                  BtnAction: () => {
                    this.props.history.push({
                      pathname: "/dashboard/0"
                    });
                  },
                  WithFilter: ''
                }} />
              )}
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  FooterClassAdd
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
