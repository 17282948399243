import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../context';
import { Header, SubHeader, ComingSoon, Images } from '../components';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";

export default class Help extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true,
        }
        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container help-web-container w-header">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.help.title}</title>
                            <meta name="description" content={MetaData.help.description} />
                            <meta name="keywords" content={MetaData.help.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} />
                        <div >
                            <Row>
                                <Col sm={12}>
                                    <SubHeader Title="Help" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                                    <ComingSoon />
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
