import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../context';
import { Helmet } from "react-helmet";
import { Images } from '../components';
import MetaData from "../helper/MetaData";

export default class RefPodcastDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.RefPodcast.title}</title>
                            <meta name="description" content={MetaData.RefPodcast.description} />
                            <meta name="keywords" content={MetaData.RefPodcast.keywords}></meta>
                        </Helmet>

                        <div >
                            <Row>
                                <Col sm={12}>
                                    <div className="refpodcast-header">
                                        <div className="music-name">Ponta de Lança Africano</div>
                                        <div className="singer-name">by Manu Chao</div>
                                        <a href className="btn btn-rounded"> <i className="icon-left-arrow"></i> Download</a>
                                    </div>
                                    <div className="refpodcast-subheader">Related Podcast</div>
                                    <div className="refpodcast-section">
                                        <div className="card-playlist">
                                            <img src={Images.SINGER_IMG} alt="" />
                                            <div className="music-name">La Vida Tombola</div>
                                            <div className="singer-name">by Manu Chao</div>
                                            <img src={Images.PLAY_BUTTON} alt="" className="audio-action" />
                                            <div>
                                                <a href className="download"><i className="icon-left-arrow"></i> Download</a>
                                            </div>
                                        </div>
                                        <div className="card-playlist highlight-card-playlist">
                                            <img src={Images.SINGER_IMG} alt="" />
                                            <div className="music-name">La Vida Tombola</div>
                                            <div className="singer-name">by Manu Chao</div>
                                            <img src={Images.PAUSE} alt="" className="audio-action" />
                                            <div>
                                                <a href className="download"><i className="icon-left-arrow"></i> Download</a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
