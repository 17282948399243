import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { notify } from "react-notify-toast";
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, EmailShareButton } from "react-share";
import { MyContext } from "../context";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import { Header, Images, NoDataScreen, Loader } from "../components";
import Slider from "react-slick";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import MetaData from "../helper/MetaData";
import Moment from "react-moment";
import UtilityFunctions from "../helper/UtilityFunctions";
import _ from "lodash";

var settings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: false,
  centerPadding: "20px",
  centerMode: true,
  initialSlide: 0,
  className: "center",
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 414,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default class ReferFriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalJoined: [],
      totalEarned: "",
      userList: [],
      UserReferralList: [],
      shortUrls: [],
      copied: false,
      profile: WSManager.getProfile(),
      hasMore: false,
      isLoaderShow: false,
      userRefOffset: 0,
      selectedTab: 1,
      userReferralPoints: "",
      isLoading: false
    };
  }

  componentDidMount() {
    this.callGetAffiliateUserListApi();
    this.callGetAffiliateMyReferralListApi();
  }

  callGetAffiliateUserListApi() {
    this.setState({ isLoading: true });
    let param = {
      offset: 0,
      limit: 100,
     // blank:1
    };

    WSManager.Rest(NC.baseURL + NC.GET_AFFILIATE_USER_LIST, param).then(
      responseJson => {
        this.setState({ isLoading: false });
        if (responseJson && responseJson.response_code === NC.successCode) {
          this.setState({
            userList: responseJson.data.user_list
          }, () => {
            if(!_.isEmpty(this.state.userList)){
              if (window.innerWidth > 414) {
                this.slider.slickGoTo(1, false)
  
              } else {
                this.slider.slickGoTo(0, false)
              }
            }
            
          });
        }
      }
    );
  }

  fetchMoreData = () => {
    this.callGetAffiliateMyReferralListApi();
  };

  onCopyCode = () => {
    notify.show(Constants.Messages.code_copy, "success", 1000);
    this.setState({ copied: true });
  };

  onCopyLink = () => {
    notify.show(Constants.Messages.link_copy, "success", 1000);
    this.setState({ copied: true });
  };

  callGetAffiliateMyReferralListApi() {
    let param = {
      offset: this.state.userRefOffset,
      limit: 100
    };

    WSManager.Rest(NC.baseURL + NC.GET_AFFILIATE_MYREFERRAL_LIST, param).then(
      responseJson => {
        if (responseJson && responseJson.response_code === NC.successCode) {
          this.setState({
            totalJoined: responseJson.data.total_joined,
            totalEarned: responseJson.data.total_bonus_earned
          });
          if (this.state.userRefOffset == 0) {
            this.setState({ UserReferralList: responseJson.data.user_list });
          } else {
            this.setState({
              UserReferralList: [...this.state.UserReferralList, ...responseJson.data.user_list]
            });
          }
          this.setState({
            hasMore: responseJson.data.is_load_more,
            userRefOffset: responseJson.data.offset
          });
        }
      }
    );
  }

  render() {
    const {
      totalJoined,
      totalEarned,
      userList,
      UserReferralList,
      hasMore,
      isLoaderShow,
      profile,
      isLoading
    } = this.state;

    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container refer-friend">
            {isLoading && <Loader />}
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.referfriend.title}</title>
              <meta
                name="description"
                content={MetaData.referfriend.description}
              />
              <meta name="keywords" content={MetaData.referfriend.keywords} />
            </Helmet>
            <Header {...this.props} HeaderOption={HeaderOption} />
            <Row>
              <Col xs={12}>
                <div className="page-inner-header page-inner-header-sm">
                  <h2>Invite "Friends" and earn "Points" to win Prizes</h2>
                  <img src={Images.HEADER_BG} alt="" className="header-bg" />
                </div>
                <div className="bg-white">
                  <div className="my-referals">
                    <div className="referals-heading display-table-cell">
                      My Referrals
                    </div>
                    <div className="display-table-cell">
                      <span>{totalJoined}</span>
                      <div className="referal-points-heading">Joined</div>
                    </div>
                    <div className="display-table-cell">
                      <span className="text-primary">{totalEarned}</span>
                      <div className="referal-points-heading">
                        Referral Points
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Tabs
                    defaultActiveKey={1}
                    id="uncontrolled-tab-example"
                    className="custom-nav-tabs tabs-two default-tab default-tab-two "
                  >
                    <Tab eventKey={1} title="Share">
                      <div className="inner-tab-content">
                        <div className="social-linking">
                          <div className="link-heading">
                            Invite Your Friends Via
                          </div>
                          <ul className="social-icons">
                            <li>
                              <FacebookShareButton
                                className="social-circle icon-facebook"
                                url={NC.baseURL}
                                quote={`Earn Referral Points on ReFPredictor League. Every month top 10 referrers will be rewarded ₦10,000*.\nSign Up now using my referral code: ${
                                  profile.referral_code
                                  } & earn 1 referral point right away.`}
                              />
                              <label>Facebook</label>
                            </li>
                            <li>
                              <TwitterShareButton
                                className="social-circle icon-twitter"
                                url={NC.baseURL}
                                title={`Earn Referral Points on ReFPredictor League. Every month top 10 referrers will be rewarded ₦10,000*.\nSign Up now using my referral code: ${
                                  profile.referral_code
                                  } & earn 1 referral point right away.`}
                              />
                              <label>Twitter</label>
                            </li>
                            <li>
                              <WhatsappShareButton
                                className="social-circle icon-whatsapp"
                                url={NC.baseURL}
                                title={`Earn Referral Points on ReFPredictor League. Every month top 10 referrers will be rewarded ₦10,000*.\nSign Up now using my referral code: ${
                                  profile.referral_code
                                  } & earn 1 referral point right away.`}
                              />
                              <label>Whatsapp</label>
                            </li>

                            <li>
                              <EmailShareButton
                                url=""
                                subject={
                                  NC.AppName + " - Your friend has referred you"
                                }
                                body={`Earn Referral Points on ReFPredictor League. Every month top 10 referrers will be rewarded ₦10,000*.\nSign Up now using my referral code: ${
                                  profile.referral_code
                                  } & earn 1 referral point right away.`}
                                className="social-circle icon-email"
                                quote={""}
                              />
                              <label>Email</label>
                            </li>
                          </ul>
                          <div className="referal-code">
                            <div className="referal-body">
                              <div>Share referral code</div>
                              <CopyToClipboard
                                onCopy={this.onCopyCode}
                                text={profile.referral_code}
                              >
                                <div>
                                  <div className="copy-text">Copy</div>
                                  <i className="icon-copy-file">
                                    {" "}
                                    <h1>{profile.referral_code}</h1>{" "}
                                  </i>
                                </div>
                              </CopyToClipboard>
                            </div>
                            <div className="referal-footer">
                              Tell your friends to enter this code on Signup.
                            </div>
                          </div>
                        </div>
                        {userList.length > 0 && (
                          <div className="top-referals">
                            <div className="link-heading">Top Referrers</div>
                            <Slider ref={slider => (this.slider = slider)} {...settings}>
                              {userList.map((item, idx) => {
                                return (
                                  <div key={idx} className="p-2">
                                    <div className="list-card list-card-with-img">
                                      <figure className="square32 position-absolute">
                                        <img src={item.image} alt="" />
                                      </figure>
                                      <div className="list-data">
                                        {item.user_name}
                                        <p>
                                          <span className="text-dark-blue text-black">
                                            {item.total_earning}
                                          </span>{" "}
                                          Referral Points
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey={2} title="Referral">
                      <div className="page-sub-header">
                        Friends You Referred
                      </div>
                      <div className="clearfix" />
                      <InfiniteScroll
                        dataLength={UserReferralList.length}
                        next={this.fetchMoreData.bind(this)}
                        hasMore={hasMore}
                        loader={
                          isLoaderShow == true && (
                            <h4 className="table-loader">Loading...</h4>
                          )
                        }
                      >
                        <ul className="referral-list" id="test">
                          {UserReferralList.length > 0 &&
                            UserReferralList.map((item, idx) => {
                              return (
                                <li key={idx}>
                                  <div className="list-card list-card-wrapper">
                                    <div className="display-table">
                                      <div className="user-cell display-table-cell user-joined-info">
                                        <div className="text-bold referal-name">
                                          {item.user_name}
                                          <span>
                                            <Moment
                                              date={UtilityFunctions.getUtcToLocal(
                                                item.added_date
                                              )}
                                              format="MMM DD, ddd"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          {UserReferralList.length == 0 && (
                            <NoDataScreen
                              Content={{
                                ImgShown: Images.no_data_bg_image,
                                IsButton: true,
                                ButtonText: "Go to Lobby",
                                Title: "OOPS! YOU HAVEN'T REFERRED",
                                NoDataContent: "ANY FRIEND YET",
                                BtnAction: () => {
                                  this.props.history.push({
                                    pathname: "/dashboard/0"
                                  });
                                },
                                WithFilter: "2"
                              }}
                            />
                          )}
                        </ul>
                      </InfiniteScroll>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
