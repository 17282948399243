import React from "react";
import { Row, Col, FormControl, FormGroup } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images } from "../components";
import MetaData from "../helper/MetaData";

export default class CreatePost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container web-container-white p-0">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.lobby.title}</title>
              <meta name="description" content={MetaData.lobby.description} />
              <meta name="keywords" content={MetaData.lobby.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <div className="create-post-section">
                    <div className="user-info">
                      <img src={Images.USERIMG} alt="" />
                      <div className="user-name">Mark.Zucker</div>
                    </div>
                    <div className="create-post-body">
                      <FormGroup controlId="formControlsTextarea">
                        <FormControl
                          componentClass="textarea"
                          placeholder="What’s on your mind?"
                        />
                      </FormGroup>
                    </div>
                    <div className="create-post-footer">
                      <i className="icon-picture" />
                      <i className="icon-recorder" />
                      <i className="icon-record" />
                      <i className="icon-attach" />
                      <a disabled="true" href className="btn btn-rounded">
                        Post
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
