import React from "react";
import { Row, Col, Media, FormControl, FormGroup } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Header, Images, SubHeader } from "../components";
import MetaData from "../helper/MetaData";

export default class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const HeaderOption = {
      menu: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.lobby.title}</title>
              <meta name="description" content={MetaData.lobby.description} />
              <meta name="keywords" content={MetaData.lobby.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="NFA vs AFA" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                  {/* Comment section start */}
                  <div className="comment-section">
                    <Media className="incoming-msg msg-section">
                      <img
                        src={Images.DEFAULT_USER}
                        alt="sender-img"
                        className="user-img"
                      />
                      <Media.Body>
                        <div className="comment">
                          <div className="comment-txt">
                            <div className="comment-by">Joyce Williams</div>
                            Louis; Can you tell me what a clean escalation..
                          </div>
                          <div className="comment-time">5 mins ago</div>
                        </div>
                      </Media.Body>
                    </Media>
                    <div className="text-center">
                      <span className="date-tag">today</span>
                    </div>
                    <Media className="outgoing-msg msg-section">
                      <Media.Body>
                        <div className="comment">
                          <div className="comment-txt">
                            <div className="comment-by">Joyce Williams</div>
                            Louis; Can you tell me what a clean escalation..
                          </div>
                          <div className="comment-time">5 mins ago</div>
                        </div>
                      </Media.Body>
                      <img
                        src={Images.DEFAULT_USER}
                        alt="sender-img"
                        className="user-img"
                      />
                    </Media>
                  </div>
                  <div className="enter-comment-section">
                    <Media className="incoming-msg msg-section">
                      <img
                        src={Images.DEFAULT_USER}
                        alt="sender-img"
                        className="user-img"
                      />
                      <Media.Body>
                        <div className="type-comment-area">
                          <form>
                            <FormGroup controlId="formInlineName">
                              <FormControl type="text" placeholder="Jane Doe" />
                              <i className="icon-send" />
                            </FormGroup>
                          </form>
                        </div>
                      </Media.Body>
                    </Media>
                  </div>
                  {/* Comment section end */}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
