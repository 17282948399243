import React from 'react';
import { MyContext } from '../context';
import { Helmet } from "react-helmet";
import { Header, SubHeader, ComingSoon, Images } from '../components';
import MetaData from "../helper/MetaData";
import InfiniteScroll from "react-infinite-scroll-component";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";

class StatsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ques_detail: {

            },
            offset: 0,
            limit: 20,
            total: 0,
            hasMore: false,
            user_rank_list: [],
            selectedCategory: "",
            selectedRound: "",
            selectedLeague: "",
            selectedContest: "",
            selectedMonth: "",
            selectedFormat: "",
        }
    }

    setParams = () => {
        if (this.props.location.state) {

            let data = this.props.location.state;
            this.setState({
                selectedLeague: data.selectedLeague,
                selectedRound: data.selectedRound,
                selectedContest: data.selectedContest,
                selectedMonth: data.selectedMonth,
                selectedFormat: data.selectedFormat,
                selectedCategory: data.selectedCategory,
                ques_detail: data.player
            }, () => {
                this.fetchMoreData();
            })

        } else {
            window.location.href = "/stats-awards"
        }
    }

    componentDidMount() {
        this.setParams()

    }


    getUserRank = () => {
        const { ques_detail, limit, offset, selectedLeague, selectedRound, selectedContest, selectedMonth,
            selectedFormat, selectedCategory } = this.state;

        let param = {
            question: ques_detail.question_name,
            limit,
            offset,
            contest_type_id: (selectedLeague && selectedLeague != "") ? selectedLeague.value : "",
            month: (selectedMonth && selectedMonth != "") ? selectedMonth.value : "",
            round_id: (selectedRound && selectedRound != "") ? selectedRound.value : "",
            contest_id: (selectedContest && selectedContest != "") ? selectedContest.value : "",
        }

        if (selectedCategory != "" && selectedCategory.hasOwnProperty('id')) {
            param['type'] = selectedCategory.id;
            if (selectedCategory.id == '2') {
                param['country_id'] = selectedCategory.value;
            }
            if (selectedCategory.id == '3') {
                param['team_id'] = selectedCategory.value;
            }
        }
        this.setState({ isLoading: true });

        WSManager.Rest(NC.baseURL + NC.GET_USER_RANK_BY_QUESTION, param)
            .then(responseJson => {
                this.setState({ isLoading: false });
                if (responseJson.response_code === NC.successCode) {
                    if (typeof responseJson.data != "undefined") {

                        let list = [...this.state.user_rank_list, ...responseJson.data.list];
                        let offset = responseJson.data.offset;
                        let hasMore = list.length < responseJson.data.total ? true : false;

                        this.setState({
                            user_rank_list: list,
                            hasMore,
                            isLoading: false,
                            total: responseJson.data.total,
                            offset
                        })

                    } else {
                        this.setState({ hasMore: false, isLoading: false })
                    }
                }
            })

    }

    fetchMoreData = () => {
        if (!this.state.isLoading) {
            this.getUserRank();
        }
    };


    render() {

        const HeaderOption = {
            menu: false,
            Notification: false,
            Logo: true,
            back: true
        }

        const { ques_detail, user_rank_list, hasMore, selectedCategory,
            selectedRound, selectedLeague, selectedContest, selectedMonth, selectedFormat,
        } = this.state;

        return (
            <React.Fragment>
                <MyContext.Consumer>
                    {(context) => (
                        <div className="web-container stats-detail-web-container">
                            <Helmet titleTemplate={`${MetaData.template} | %s`}>
                                <title>{MetaData.awards.title}</title>
                                <meta name="description" content={MetaData.awards.description} />
                                <meta name="keywords" content={MetaData.awards.keywords}></meta>
                            </Helmet>
                            <Header {...this.props} HeaderOption={HeaderOption} showNavigation={false} />

                            <SubHeader Title={ques_detail.question_name || ""} ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="" />


                            <div className={'stats-detail-ar'}>
                                <div className={'stats-det-head'}>
                                    <div className={'th rank'}>Rank</div>
                                    <div className={'th ref'}>Ref</div>
                                    <div className={'th act'}></div>
                                    <div className={'th pts'}>Points</div>
                                </div>

                                <div className={'stats-det-body'}>

                                    <InfiniteScroll
                                        dataLength={user_rank_list.length}
                                        next={this.fetchMoreData.bind(this)}
                                        hasMore={hasMore}
                                        loader={
                                            hasMore && (
                                                <h4 className="text-center font-12 m-t-10">Loading...</h4>
                                            )
                                        }
                                    >

                                        {
                                            user_rank_list.map((itm, i) => {
                                                return (
                                                    <div className={'st-tr'} key={i}>
                                                        <div className={'td rank'}>{itm.user_question_rank}</div>
                                                        <div className={'td ref'}>
                                                            <div className={'usr-det'}>
                                                                <div className={'prof-pc'}><img src={itm.image || Images.DEFAULT_USER} /></div>
                                                                <div>
                                                                    <h4>#{itm.display_name}</h4>
                                                                    <h5>@{itm.user_name}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'td act'}>
                                                            <i 
                                                            title={'Compare'}
                                                            // onClick={() => this.props.history.push('/compare')} 
                                                            className={'icon icon-compare compare-usr'}
                                                            onClick={() => this.props.history.push({
                                                                pathname: "compare",
                                                                state: {
                                                                    player:itm,
                                                                    selectedCategory,
                                                                    selectedLeague,
                                                                    selectedRound,
                                                                    selectedContest,
                                                                    selectedMonth,
                                                                    selectedFormat,
                                                                }
                                                            })
                                                            }
                                                            ></i>
                                                        </div>
                                                        <div className={'td pts'}>{itm.score}</div>
                                                    </div>
                                                )
                                            })
                                        }


                                    </InfiniteScroll>



                                </div>


                            </div>
                        </div>
                    )}
                </MyContext.Consumer>

            </React.Fragment>
        )
    }
}

export default StatsDetails;