import React from "react";
import { Row, Col, FormGroup, Button } from "react-bootstrap";
import FloatingLabel, { floatingStyles, focusStyles, inputStyles, labelStyles } from "floating-label-react";
import { MyContext } from "../context";
import { Header, Loader, Images, SubHeader } from "../components";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import Validation from "./../helper/validation";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import MetaData from "../helper/MetaData";
const md5 = require("md5");

const formInputStyle = {
  floating: {
    ...floatingStyles,
    color: "#7E7E7E",
    fontSize: "12px",
    borderBottomColor: "#c5c0c0",
    fontFamily: "MuliRegular"
  },
  focus: {
    ...focusStyles,
    borderColor: "#c5c0c0"
  },
  input: {
    ...inputStyles,
    borderBottomWidth: 1,
    borderBottomColor: "#c5c0c0",
    width: "100%",
    fontSize: "16px",
    color: "#333",
    fontFamily: "MuliBold",
    padding: "16px 0px 10px",
    backgroundColor: "transparent"
  },
  label: {
    ...labelStyles,
    paddingBottom: "0px",
    marginBottom: "0px",
    width: "100%",
    fontSize: "12px",
    color: "#7E7E7E",
    fontFamily: "MuliRegular"
  }
};


export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitClick: false,
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
      posting: false,
      isLoading: false,
      isShowNewPassword: false,
      isShowOldPassword: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  IsFormValid() {
    const { oldpassword, newpassword, confirmpassword } = this.state;

    var isValid = true;

    if (
      Validation.validate("required", oldpassword) !== "success" ||
      Validation.validate("password", oldpassword) !== "success"
    ) {
      isValid = false;
    } else if (
      Validation.validate("required", newpassword) !== "success" ||
      Validation.validate("password", newpassword) !== "success"
    ) {
      isValid = false;
    } else if (newpassword != confirmpassword) {
      isValid = false;
    }
    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      submitClick: true
    });

    if (this.IsFormValid()) {
      this.setState({ isLoading: true, posting: true });
      let param = {
        old_password: md5(this.state.oldpassword),
        password: md5(this.state.newpassword)
      };

      WSManager.Rest(NC.baseURL + NC.CHANGE_PASSWORD, param).then(
        responseJson => {
          this.setState({ isLoading: false, posting: false });
          if (responseJson && responseJson.response_code === NC.successCode) {
            notify.show(responseJson.message, "success", 3000);
            this.props.history.goBack();
          }
        }
      );
    }
  }

  render() {
    const {
      submitClick,
      oldpassword,
      newpassword,
      confirmpassword,
      posting,
      isLoading,
      isShowPassword,
      isShowNewPassword,
      isShowOldPassword
    } = this.state;
    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container ">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.ChangePassword.title}</title>
              <meta name="description" content={MetaData.ChangePassword.description} />
              <meta name="keywords" content={MetaData.ChangePassword.keywords} />
            </Helmet>
            {isLoading && <Loader />}
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
              showNavigation={false}
            />
            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Change Password" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="page-inner-header-sm" />
                </Col>
              </Row>
              <form
                className="form-section change-password-form"
                onSubmit={this.onSubmit}
              >
                <div className="verification-block">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        validationState={
                          submitClick &&
                          Validation.validate("required", oldpassword) &&
                          Validation.validate("password", oldpassword)
                        }
                        controlId="formBasicText"
                        className="input-label-left input-label-center"
                      >
                        <FloatingLabel
                          styles={formInputStyle}
                          id="oldpassword"
                          name="oldpassword"
                          placeholder="Old Password"
                          value={oldpassword}
                          type={isShowOldPassword ? "text" : "password"}
                          required
                          maxLength="50"
                          autocomplete="off"
                          onChange={this.onChange}
                        />
                      </FormGroup>
                      <a
                        href
                        className="hidden-align"
                        onClick={() => this.setState({ isShowOldPassword: !isShowOldPassword })}
                      >
                        {isShowOldPassword ? (
                          <img alt='' src={Images.EYE_ICON} width="23px" />
                        ) : (
                            <img alt='' src={Images.EYE_CANCEL_ICON} width="23px" />
                          )}
                      </a>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        controlId="formBasicText"
                        className="input-label-left input-label-center"
                        validationState={
                          submitClick &&
                          Validation.validate("required", newpassword) &&
                          Validation.validate("password", newpassword)
                        }
                      >
                        <FloatingLabel
                          styles={formInputStyle}
                          id="newpassword"
                          name="newpassword"
                          placeholder="New Password"
                          value={newpassword}
                          type={isShowNewPassword ? "text" : "password"}
                          required
                          maxLength="50"
                          autocomplete="off"
                          onChange={this.onChange}
                        />
                      </FormGroup>
                      <a
                        href
                        className="hidden-align"
                        onClick={() => this.setState({ isShowNewPassword: !isShowNewPassword })}
                      >
                        {isShowNewPassword ? (
                          <img src={Images.EYE_ICON} width="23px" alt="" />
                        ) : (
                            <img src={Images.EYE_CANCEL_ICON} width="23px" alt="" />
                          )}
                      </a>
                    </Col>
                  </Row>
                </div>
                <div className="verification-block">
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        controlId="formBasicText"
                        className="input-label-left input-label-center"
                        validationState={
                          submitClick
                            ? confirmpassword.trim() != "" &&
                              newpassword == confirmpassword
                              ? "success"
                              : "error"
                            : ""
                        }
                      >
                        <FloatingLabel
                          styles={formInputStyle}
                          id="confirmpassword"
                          name="confirmpassword"
                          placeholder="Confirm Password"
                          value={confirmpassword}
                          type={isShowPassword ? "text" : "password"}
                          required
                          maxLength="50"
                          autocomplete="off"
                          onChange={this.onChange}
                        />
                      </FormGroup>
                      <a
                        href
                        className="hidden-align"
                        onClick={() => this.setState({ isShowPassword: !isShowPassword })}
                      >
                        {isShowPassword ? (
                          <img src={Images.EYE_ICON} width="23px" alt="" />
                        ) : (
                            <img src={Images.EYE_CANCEL_ICON} width="23px" alt="" />
                          )}
                      </a>
                    </Col>
                  </Row>
                </div>
              </form>
            </div>
            <div className="text-center profile-btn-section">
              <Button
                onClick={this.onSubmit}
                className="btn btn-rounded btn-primary mt-4"
                disabled={posting}
              >
                Save changes
              </Button>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
